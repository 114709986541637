import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  FormControlLabel,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import ShowMessage from "../ShowMessage/ShowMessage";
import ShareOptions from "../ShareOptions/ShareOptions";

import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router";

import Iconify from "../iconify";

import SrtGenerator from "../SrtGenerator/SrtGenerator";
import SubscribeOffer from "../SubscribeOffer/SubscribeOffer";

import { alpha, useTheme } from "@mui/material/styles";

export default function ExportButton({
  processingDownload,
  handleDownload,
  enableExportAll,
  disable,
  projectId,
  isDesktop,
  allSectionsReady,
  fields,
  project,
  TriggerDownload,
  isPremiumUser,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [includeSrt, setIncludeSrt] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [showShareDialog, setshowShareDialog] = useState(false);
  const navigate = useNavigate();

  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const showShareProject = async () => {
    setshowShareDialog(!showShareDialog);
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText("50OFF")
      .then(() => {
        setShowMessage(true);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const handleSrtCheck = (event) => {
    setIncludeSrt(event.target.checked);
  };

  const handleIncludeAllCheck = (event) => {
    setIncludeAll(event.target.checked);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleUpgradeClick = () => {
    navigate("/billing");
  };

  return (
    <>
      <Tooltip
        title={
          disable ? "Play full audio before exporting" : "Audio can be exported"
        }
      >
        <span>
          <Button
            size="small"
            variant="contained"
            disabled={disable}
            //aria-controls={open ? "split-button-menu" : undefined}
            //aria-expanded={open ? "true" : undefined}
            //aria-label="select export type"
            aria-haspopup="menu"
            onClick={handleToggle}
            startIcon={
              isDesktop ? <Iconify icon="material-symbols:download" /> : null
            }
          >
            {isDesktop ? (
              "Export"
            ) : (
              <Iconify icon="material-symbols:download" />
            )}
          </Button>
        </span>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          <Stack justifyContent="space-between" direction="row">
            Export
            <IconButton size="small" variant="text" onClick={handleClose}>
              <Iconify icon="material-symbols:close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Stack>
              <FormControlLabel
                disabled={!isPremiumUser}
                control={<Checkbox />}
                label={
                  <Stack spacing={2} direction="row">
                    <Typography>Generate subtitles (SRT) file </Typography>

                    {!isPremiumUser ? (
                      <Chip
                        label={
                          <Typography
                            sx={{ fontSize: "10px" }}
                            variant="subtitle2"
                          >
                            UPGRADE
                          </Typography>
                        }
                        size="small"
                        onClick={handleUpgradeClick}
                        variant="contained"
                        sx={{
                          color: "#ff6f01",
                          backgroundColor: alpha("#d55d00", 0.3),
                        }}
                      ></Chip>
                    ) : null}
                  </Stack>
                }
                onChange={handleSrtCheck}
              />
              <FormControlLabel
                control={<Checkbox />}
                disabled={!isPremiumUser}
                label={
                  <Stack spacing={2} direction="row">
                    <Typography>
                      Include each section as a separate audio file
                    </Typography>

                    {!isPremiumUser ? (
                      <Chip
                        label={
                          <Typography
                            sx={{ fontSize: "10px" }}
                            variant="subtitle2"
                          >
                            UPGRADE
                          </Typography>
                        }
                        size="small"
                        onClick={handleUpgradeClick}
                        variant="contained"
                        sx={{
                          color: "#ff6f01",
                          backgroundColor: alpha("#d55d00", 0.3),
                        }}
                      ></Chip>
                    ) : null}
                  </Stack>
                }
                onChange={handleIncludeAllCheck}
              />

              <Stack mt={2}>
                {!isPremiumUser && (
                  <Stack alignItems="center" mt={2} spacing={2}>
                    <Typography variant="h5">
                      🎁 Limited Time Offer: Get 50% OFF 🎁
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography variant="subtitle">Use the code</Typography>
                      <Chip
                        label={
                          <Typography
                            sx={{ fontSize: "14px" }}
                            variant="subtitle2"
                          >
                            50OFF
                          </Typography>
                        }
                        size="large"
                        onClick={handleCopy}
                        variant="contained"
                        sx={{
                          color: "#01f0ff",
                          backgroundColor: alpha("#006bd5", 0.3),
                          borderRadius: "10px",
                        }}
                      ></Chip>
                      <Typography variant="subtitle">
                        <Link href="/billing">during checkout</Link>
                      </Typography>
                    </Stack>
                    <ConfettiExplosion
                      zIndex={2000}
                      duration={2500}
                    ></ConfettiExplosion>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction="row"
                justifyContent={
                  !isPremiumUser && fields[0].voice.cloud === "custom"
                    ? "center"
                    : "flex-end"
                }
                spacing={1}
                mt={4}
              >
                {includeSrt && (
                  <SrtGenerator
                    fields={fields}
                    allSectionsReady={allSectionsReady}
                    TriggerDownload={TriggerDownload}
                    project={project}
                  ></SrtGenerator>
                )}

                {!isPremiumUser && fields[0].voice.cloud === "custom" ? (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                    sx={{ border: "1px solid #424242", borderRadius: "30px" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: "#C62828",
                        textAlign: "center",
                      }}
                    >
                      Exporting voice clone audio requires a subscription
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" spacing={2}>
                    <Tooltip title="Share" placement="top">
                      <Button
                        onClick={() => showShareProject(true)}
                        variant="outlined"
                        startIcon={<Iconify icon="material-symbols:share" />}
                      >
                        <div>Share</div>
                      </Button>
                    </Tooltip>
                    <LoadingButton
                      variant="contained"
                      loading={processingDownload}
                      onClick={() => handleDownload(includeAll)}
                    >
                      Download
                    </LoadingButton>
                  </Stack>
                )}
              </Stack>
              <ShowMessage
                showFileError={showMessage}
                setShowFileError={setShowMessage}
                message="Promo code copied to clipboard"
              />
            </Stack>
            {/* Share dialog */}
            <ShareOptions
              showShareDialog={showShareDialog}
              setshowShareDialog={setshowShareDialog}
              projectId={projectId}
              mode={"embed"}
            />
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
}
