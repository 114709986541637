import React from "react";
import { useNavigate, useNavigation } from "react-router";

const SideBar = ({ children }) => {
  const pathname = useNavigate();

  return (
    <div
      className={`${
        pathname === "/subtitles" ? "md:w-[540px] " : "md:w-[394px] "
      }min-w-[394px] transition duration-300 ease-in-out flex items-center bg-slate-900`}
    >
      <div className="border-solid border-t-0 border-l-0 border-b-0 border-r border-slate-600 w-full h-full justify-content-unset">
        <div className="rounded-md overflow-y-auto overflow-x-hidden w-full h-full px-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
