import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// @mui
import {
  Button,
  Container,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { analytics, logEvent } from "../firebase.js";

import { styled } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

import { useUserAuth } from "../UserAuthContext";

import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import Logo from "../components/logo";
import Iconify from "../components/iconify";
import ConfettiExplosion from "react-confetti-explosion";

const getOrderFunction = httpsCallable(functions, "orderDetails");

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 0),
}));

export default function ThanksPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { user } = useUserAuth();

  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("");

  const getItemName = (amountSubtotal) => {
    switch (amountSubtotal) {
      case "900":
        return "pro";
      case "2900":
        return "premium";
      case "2000":
        return "prepaid1";
      case "5000":
        return "prepaid2";
      default:
        return "ultra";
    }
  };

  useEffect(() => {
    const getOrderDetails = async () => {
      const sessionId = searchParams.get("session_id");

      try {
        const { data } = await getOrderFunction({
          sessionId,
        });
        const orderDetails = data.sessionInfo;
        //console.log(orderDetails);
        return orderDetails;
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const redirectUser = () => {
      const to = "/";
      const redirectTimeout = setTimeout(() => {
        navigate(to);
      }, 5000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    };

    getOrderDetails().then((order) => {
      if (order) {
        logEvent(analytics, "purchase", {
          currency: "USD",
          value: order.amount_subtotal / 100,
          transaction_id: order.id,
          items: [
            {
              item_name: getItemName(order.amount_subtotal),
            },
          ],
        });
      }
      redirectUser();
    });
  }, []);

  if (loading) {
    return (
      <Stack mt={2}>
        <CircularProgress size={20} />
      </Stack>
    );
  }

  return (
    <>
      <Helmet>
        <title> Thanks | Acoust </title>
      </Helmet>

      <Container>
        <StyledRoot>
          <Container maxWidth="lg">
            <Paper elevation={10}>
              <StyledContent>
                <Stack
                  direction="column"
                  spacing={2}
                  mb={4}
                  alignItems="center"
                  justifyContent="center"
                >
                  <ConfettiExplosion duration={2500}></ConfettiExplosion>
                  <Logo></Logo>
                </Stack>

                <Stack spacing={3} alignItems="center">
                  <Typography variant="h3">
                    Thank you for subscribing
                  </Typography>

                  <Typography color="text.secondary" variant="body1">
                    You will be redirected in 5 seconds...
                  </Typography>
                  <Button sx={{ width: "300px" }} variant="outlined" href="/">
                    Home
                  </Button>
                </Stack>
                <Stack
                  alignItems="center"
                  mt={4}
                  minHeight={400}
                  sx={{
                    backgroundImage: "url(/happy.svg)",
                    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.5),
                    backgroundSize: "contain", // You can adjust this based on your needs
                    backgroundPosition: "bottom", // You can adjust this based on your needs
                    backgroundRepeat: "no-repeat",
                    height: "60%",
                    borderRadius: "50%",
                  }}
                ></Stack>
              </StyledContent>
            </Paper>
          </Container>
        </StyledRoot>
      </Container>
    </>
  );
}
