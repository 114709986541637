import { useNavigate, useParams } from "react-router-dom";
import "../App.css";
// import Home from './Home';
import SideBar from "../components/SideBar";
import MuiThemeProvider from "../providers/theme";
import ContentArea from "../components/ContentArea";
import Navbar from "../components/Navbar";
import useResponsive from "../hooks/useResponsive";

// import Elements from './elements/page';
// import Record from './record/page';
import { CanvasStore, StoreContext } from "../canvas/store/canvasStore";
import { connect, Provider } from "react-redux";
import TimeLine from "../Timeline/TimeLine";
import React, { useContext, useEffect, useRef, useState } from "react";

function VideoPage({ children }) {
  const mainContentRef = useRef();
  const [timelineHeight, setTimelineHeight] = useState(112);
  const { activeObject, activeCanvas } = useContext(CanvasStore);
  const router = useNavigate();
  const { id } = useParams();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (activeObject) {
      switch (activeObject.type) {
        case "text":
        case "i-text": {
          router(`/v/${id}/edit-text/`);
          break;
        }
        case "video":
        case "image": {
          router(`/v/${id}/edit-video/`);
          break;
        }
        case "audio": {
          router(`/v/${id}/edit-audio/`);
          break;
        }
        default:
          break;
      }
    } else {
      router(`/v/${id}`);
    }
  }, [activeObject]);

  const handleTimelineUpdate = (val) => {
    setTimelineHeight(val);
    setTimeout(() => {
      const rect = mainContentRef.current.getBoundingClientRect();
      activeCanvas.setHeight(rect.height);
      activeCanvas.renderAll();
    }, 100);
  };

  if (!isDesktop) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 p-4 bg-slate-900">
        <div>
          <div className="text-center text-white text-lg font-semibold">
            Oops! Our video editor need a bit more room to work it's magic
          </div>
          <br />
          <div className="text-center text-white text-lg font-semibold">
            Try resizing your browser or try a bigger screen
          </div>
        </div>
      </div>
    );
  }

  return (
    <MuiThemeProvider>
      <div className="fixed top-0 left-0 w-full h-full flex flex-row z-10 bg-gray-100 outline-none">
        <Navbar />
        <div className="flex flex-col h-full w-full overflow-hidden relative bg-gray-100">
          <div
            ref={mainContentRef}
            className="flex flex-row justify-items-stretch h-full flex-1"
            style={{ maxHeight: `calc(-${timelineHeight}px + 100vh)` }}
          >
            <SideBar>{children}</SideBar>
            <ContentArea />
          </div>

          <TimeLine onUpdateTimelineUpdate={handleTimelineUpdate} />
        </div>
      </div>
    </MuiThemeProvider>
  );
}

export default connect((state) => {
  const { processing } = state;
  return { processing };
})(VideoPage);
