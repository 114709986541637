import React, { useContext, useRef, useState } from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CanvasStore } from "../../../../canvas/store/canvasStore";
import { handleAddAudio } from "../../../../canvas/utils/helper";
import ReactAudioPlayer from "react-audio-player";

import {
  Button,
  IconButton,
  Paper,
  Stack,
  Select,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  Divider,
} from "@mui/material";
import Iconify from "../../../../components/iconify/Iconify";

const MusicStock = ({ audioList }) => {
  const { activeCanvas } = useContext(CanvasStore);
  const [previewUrl, setPreviewUrl] = useState("");
  const [previewIndex, setPreviewIndex] = useState(-1);
  const audioRef = useRef(null);

  const handlePreviewEnded = () => {
    setPreviewUrl(null);
    setPreviewIndex(-1);
  };

  const handlePreviewButton = (item, index) => {
    if (audioRef.current) {
      audioRef.current.audioEl.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPreviewUrl((prevUrl) => (prevUrl === item.url ? null : item.url));
    setPreviewIndex(index);
  };

  const handleAudioClick = (item) => {
    handleAddAudio(item.url, item.name, activeCanvas);
  };

  const handlePlusButtonClick = (item) => {
    handleAudioClick(item);
  };

  return (
    <div className="my-4 group">
      <div className="relative flex flex-col gap-3">
        {audioList.length > 0 &&
          audioList.map((item, index) => {
            return (
              <Paper elevation={4} key={index}>
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  padding={2}
                >
                  <Typography variant="caption" sx={{ color: "#fff" }}>
                    {item.name}
                  </Typography>

                  <Stack
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Typography variant="caption">{item.time}</Typography>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => handlePreviewButton(item, index)}
                    >
                      <Iconify
                        icon={
                          previewIndex === index
                            ? "material-symbols:pause"
                            : "octicon:play"
                        }
                      />
                    </IconButton>
                    <IconButton
                      variant="contained"
                      color="primary"
                      onClick={() => handlePlusButtonClick(item)}
                    >
                      <Iconify icon={"zondicons:add-solid"} />
                    </IconButton>
                  </Stack>
                </Stack>
                {/* Plus Button */}
              </Paper>
            );
          })}
      </div>
      <ReactAudioPlayer
        onEnded={handlePreviewEnded}
        src={previewUrl}
        preload="none"
        autoPlay
        ref={audioRef}
      />
    </div>
  );
};

export default MusicStock;
