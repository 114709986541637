import React, { useContext, useState, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";

import { CanvasStore } from "../../canvas/store/canvasStore";

import Iconify from "../iconify";
import { useUserAuth } from "../../UserAuthContext";
import ReactPlayer from "react-player";
import { handleAddAudio } from "../../canvas/utils/helper";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export default function UserMediaFiles(props) {
  const { activeCanvas } = useContext(CanvasStore);

  const [loading, setLoading] = useState(false);
  const [playPreview, setPlayPreview] = useState(false);
  const [playerUrl, setPlayerUrl] = useState("");

  const [userFiles, setUserFiles] = useState([]);
  const { user } = useUserAuth();

  const handlePlusButtonClick = (item) => {
    handleAddAudio(item.url, item.name, activeCanvas);
  };

  useEffect(() => {
    async function getMediaFiles() {
      const q = query(
        collection(db, "uploads"),
        where("user", "==", user.email),
        where("type", "==", props.mediaType),
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      setUserFiles(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          timestamp: doc.data()["timestamp"].toDate().toLocaleString(),
          gsUrl: doc.data()["gs_url"],
          url: doc.data()["player_url"] || doc.data()["url"],
          name: doc.data()["file_name"],
        }))
      );
    }
    setLoading(true);
    getMediaFiles();
    setLoading(false);
  }, []);

  const handlePlayPreview = (url) => {
    setPlayerUrl(url);
    if (url === playerUrl) {
      setPlayPreview(false);
      setPlayerUrl("");
    } else {
      setPlayPreview(true);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div className="my-4 group">
      {!userFiles.length && (
        <Stack
          justifyContent="center"
          alignItems="center"
          padding={4}
          spacing={2}
        >
          <Typography variant="caption">
            You haven't uploaded any files yet
          </Typography>
        </Stack>
      )}
      <div className="relative flex flex-col gap-3">
        {userFiles.map((file, index) => (
          <Paper elevation={4} key={index}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
            >
              <Tooltip title={file.name} arrow placement="top">
                <Typography
                  variant="caption"
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    maxWidth: "200px", // Set your desired maximum width
                    color: "#fff",
                  }}
                >
                  {file.name}
                </Typography>
              </Tooltip>

              <Stack
                spacing={1}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => handlePlayPreview(file.url)}
                >
                  {playPreview && file.url === playerUrl ? (
                    <Iconify icon="material-symbols:pause" />
                  ) : (
                    <Iconify icon="octicon:play" />
                  )}
                </IconButton>

                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => handlePlusButtonClick(file)}
                >
                  <Iconify icon={"zondicons:add-solid"} />
                </IconButton>
              </Stack>
            </Stack>
          </Paper>
        ))}

        <ReactPlayer playing={playPreview} url={playerUrl} />
      </div>
    </div>
  );
}
