import { React, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  InputLabel,
  ListSubheader,
  ListItemText,
  Paper,
  Select,
  Stack,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";

import { alpha, useTheme } from "@mui/material/styles";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import ReactAudioPlayer from "react-audio-player";

import LoadingButton from "@mui/lab/LoadingButton";
import { generateRandomString } from "../../utils/utils";

import mixpanel from "mixpanel-browser";

import Iconify from "../iconify";

export default function AIVoices({
  applyAll,
  handleApplyAll,
  cloudProvider,
  handleVoiceChange,
  index,
  generateVoicePreviewAudio,
  languages,
  selectedPopularOption,
  selectedVoice,
  selectedLanguage,
  setSelectedVoice,
  setSelectedLanguage,
  insertVoice,
  insertVoiceWithEmotion,
  user,
}) {
  const [voices, setVoices] = useState([]);
  const [favoriteVoices, setFavoriteVoices] = useState([]);
  const [showEmotions, setShowEmotions] = useState(false);
  const [tempVoice, setTempVoice] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [voiceLoading, setVoiceLoading] = useState(false);
  const genders = ["Male", "Female"]; // Available genders
  const [selectedGender, setSelectedGender] = useState(""); // Default is all
  const [showFavorites, setShowFavorites] = useState(false);

  const theme = useTheme();

  async function loadVoices() {
    console.log(`loading voices for:`, selectedLanguage);
    if (!selectedLanguage) {
      return;
    }

    // console.log(selectedLanguage);
    setLoading(true);

    let q = query(
      collection(db, "voices"),
      where("languageCodes", "array-contains", selectedLanguage)
    );

    if (selectedGender) {
      q = query(q, where("gender", "==", selectedGender));
    }

    if (cloudProvider) {
      q = query(q, where("cloud", "==", cloudProvider));
    }

    if (selectedPopularOption === "Popular") {
      q = query(q, where("popular", "==", selectedGender));
    }

    // order by
    q = query(q, orderBy("order"), orderBy("display_name"));
    const querySnapshot = await getDocs(q);

    let defaultVoice;
    if (showFavorites) {
      const tempVoices = querySnapshot.docs
        .map((doc) => ({
          ...doc.data(),
        }))
        .filter((voice) =>
          favoriteVoices.some((favVoice) => favVoice === voice.voice)
        );
      setVoices(tempVoices);
    } else {
      setVoices(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
      defaultVoice = querySnapshot.docs[0].data();
    }

    // Set a default voice if there wasn't a voice already selected by user
    if (!selectedVoice) {
      // console.log("no default voice");
      //setSelectedVoice(defaultVoice);
      //handleVoiceChange(defaultVoice, index);
    }
    setLoading(false);
  }

  async function loadFavorites() {
    const userDocRef = doc(db, "customers", user.uid);
    const userDocSnapShot = await getDoc(userDocRef);
    if (userDocSnapShot.exists() && userDocSnapShot.data().favoriteVoices) {
      setFavoriteVoices(userDocSnapShot.data().favoriteVoices);
    }
  }

  const toggleEmotionView = (voice) => {
    if (voice !== tempVoice) {
      setShowEmotions(true);
      setTempVoice(voice);
    } else {
      setShowEmotions(!showEmotions);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await loadVoices();
      await loadFavorites();
    };
    loadData().then(() => setLoading(false));
  }, [selectedLanguage]);

  const handleGenderChange = (gender) => {
    if (gender === selectedGender) {
      setSelectedGender("");
    } else {
      setSelectedGender(gender);
    }
    mixpanel.track("Filter by gender", {
      gender: gender,
    });
  };

  const handleFavoritesButton = () => {
    setShowFavorites(!showFavorites);
  };

  // if voice exists in favorites, remove it else add it
  const toggleFavorites = async (voice) => {
    let tempFavoriteVoices = [];
    if (favoriteVoices.some((favoriteVoice) => favoriteVoice === voice.voice)) {
      // console.log("remove");
      tempFavoriteVoices = favoriteVoices.filter(
        (favoriteVoice) => favoriteVoice !== voice.voice
      );
      setFavoriteVoices(tempFavoriteVoices);
    } else {
      // console.log("add");
      mixpanel.track("Favorited voice", {
        voice: voice.display_name,
      });
      tempFavoriteVoices = [...favoriteVoices, voice.voice];
      setFavoriteVoices(tempFavoriteVoices);
    }

    await updateDoc(doc(db, "customers", user.uid), {
      favoriteVoices: tempFavoriteVoices,
    });
  };

  const isFavorite = (voice) => {
    return favoriteVoices.some((favoriteVoice) => favoriteVoice === voice);
  };

  const playPreview = async (voice, emotion) => {
    // console.log(voice)
    setVoiceLoading(true);
    setTempVoice(voice);

    let url = "";
    if (cloudProvider === "gcs") {
      url = voice.preview;
    } else if (voice.preview && selectedLanguage === "en-US") {
      // (TODO) fix this temp hack for preview
      url = voice.preview;
    } else {
      url = await generateVoicePreviewAudio(
        voice,
        emotion,
        generateRandomString()
      );
    }
    // console.log(url);

    setPreviewUrl(url);
    setVoiceLoading(false);
  };

  const handlePreviewEnded = () => {
    setPreviewUrl(null);
  };

  if (loading) {
    return <CircularProgress></CircularProgress>;
  }
  return (
    <>
      <Paper elevation={4}>
        <Grid
          container
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={2}
          padding={2}
        >
          <Grid item>
            <FormControl sx={{ minWidth: 150 }} size="small">
              <InputLabel sx={{ fontSize: "12px" }} id="language-input-label">
                Language
              </InputLabel>
              <Select
                sx={{ fontSize: "12px" }}
                labelId="langugage-select-label"
                id="language-select"
                label="Language"
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                <ListSubheader sx={{ fontSize: "10px" }}>Popular</ListSubheader>
                {languages.map((language, index) => [
                  <MenuItem sx={{ fontSize: "12px" }} value={language.code}>
                    {language.language}
                  </MenuItem>,
                  index === 3 && (
                    <ListSubheader
                      key="other-languages"
                      sx={{ fontSize: "10px" }}
                    >
                      Other Languages
                    </ListSubheader>
                  ),
                ])}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            {/* Gender filter */}
            <ButtonGroup>
              {genders.map((gender) => (
                <Button
                  size="small"
                  key={gender}
                  onClick={() => handleGenderChange(gender)}
                  variant={selectedGender === gender ? "contained" : "outlined"}
                  sx={{ borderRadius: "10px" }}
                >
                  {gender}
                </Button>
              ))}
            </ButtonGroup>
          </Grid>
          {/* Favorites filter */}
          <Grid item>
            <Button
              variant={showFavorites ? "contained" : "outlined"}
              size="small"
              onClick={() => handleFavoritesButton()}
              //variant={
              //  selectedGender === gender ? "contained" : "outlined"
              //}
              sx={{ borderRadius: "10px" }}
            >
              Favorites
            </Button>
          </Grid>

          <Grid item>
            <Stack direction="row" alignItems="center" mr={6}>
              <Checkbox checked={applyAll} onClick={handleApplyAll} />
              <Typography sx={{ fontSize: "11px" }}>
                Apply voice to the entire project
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
      {showFavorites && voices.length === 0 && (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          sx={{ height: "50vh" }}
        >
          <Typography>
            🧐 &nbsp; hmm, it looks like we couldn't locate any favorite voices
            for the selected language ({selectedLanguage})
          </Typography>
        </Stack>
      )}
      <Grid container spacing={2} mt={1}>
        {voices.map((voice) => (
          <Grid item xs={12} md={3} key={voice.voice}>
            <Paper elevation={4}>
              <Stack
                direction="row"
                spacing={4}
                padding={2}
                minHeight={120}
                alignItems="center"
                justifyContent="space-around"
              >
                <Avatar
                  alt={voice.gender}
                  src={
                    voice.gender === "Male"
                      ? "/man.png"
                      : voice.gender === "Female"
                      ? "/woman.png"
                      : null
                  }
                  sx={{
                    backgroundColor: (theme) =>
                      alpha(theme.palette.common.white, 0.4),
                  }}
                >
                  {(voice.gender === "Neutral" ||
                    voice.gender === "Female (Child)") && (
                    <Iconify width="30px" icon="material-symbols:person" />
                  )}
                </Avatar>

                <Stack direction="column">
                  <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                    {voice.display_name}
                    {voice.isNew && (
                      <Iconify color="#B71C1C" icon="ic:outline-fiber-new" />
                    )}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "text.secondary",
                    }}
                  >
                    {voice.gender}
                  </Typography>
                </Stack>

                {/* stack for hot & emptions */}
                <Stack spacing={1}>
                  {voice.emotions && (
                    <Chip
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: "11px",
                        borderRadius: "5px",
                      }}
                      deleteIcon={
                        <Iconify
                          icon={
                            showEmotions && voice === tempVoice
                              ? "mdi:chevron-up"
                              : "mdi:chevron-down"
                          }
                        />
                      }
                      label={`${voice.emotions.length} styles`}
                      onDelete={() => toggleEmotionView(voice)}
                    />
                  )}
                  {voice.popular && (
                    <Chip
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: "11px",
                        color: "#E65100",
                        backgroundColor: alpha(
                          theme.palette.warning.light,
                          0.08
                        ),
                        borderColor: theme.palette.warning.light,
                        borderRadius: "5px",
                      }}
                      label={"hot 🔥"}
                    />
                  )}
                </Stack>
              </Stack>
              <Divider variant="middle" />
              {/* Preview / Play */}
              <Stack
                alignItems="center"
                padding={1}
                justifyContent="space-evenly"
                direction="row"
              >
                <Tooltip title="Preview" placement="top" arrow>
                  <LoadingButton
                    loading={tempVoice === voice && voiceLoading}
                    size="small"
                    onClick={() => playPreview(voice, "")}
                  >
                    <Iconify icon="octicon:play-16" />
                  </LoadingButton>
                </Tooltip>
                <Tooltip title="Favorite" placement="top" arrow>
                  <LoadingButton
                    size="small"
                    onClick={() => toggleFavorites(voice, "")}
                  >
                    {isFavorite(voice.voice) ? (
                      <Iconify icon="material-symbols:favorite" />
                    ) : (
                      <Iconify icon="material-symbols:favorite-outline" />
                    )}
                  </LoadingButton>
                </Tooltip>

                {insertVoice ? (
                  <Tooltip title="Use this voice" placement="top" arrow>
                    <Button
                      variant="text"
                      size="small"
                      onClick={() => insertVoice(voice)}
                    >
                      Select
                    </Button>
                  </Tooltip>
                ) : null}
              </Stack>

              <Stack>
                {voice === tempVoice && voice.emotions && showEmotions && (
                  <Box sx={{ mt: 1, ml: 3 }}>
                    <FormControl variant="standard" sx={{ minWidth: 120 }}>
                      {voice.emotions.map((emotion) => (
                        <List key={emotion}>
                          <ListItem sx={{ fontSize: "12px" }} key={emotion}>
                            <ListItemIcon>
                              <Tooltip
                                title="Preview voice"
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => playPreview(voice, emotion)}
                                >
                                  <Iconify icon="icon-park-solid:play" />
                                </IconButton>
                              </Tooltip>
                            </ListItemIcon>
                            <ListItemText>
                              <Box sx={{ width: "100px" }}>
                                <Typography sx={{ fontSize: "11px" }}>
                                  {emotion}
                                </Typography>
                              </Box>
                            </ListItemText>
                            <ListItemIcon>
                              <Tooltip
                                title="Use this voice"
                                placement="top"
                                arrow
                              >
                                <Button
                                  size="small"
                                  onClick={() =>
                                    insertVoiceWithEmotion(voice, emotion)
                                  }
                                >
                                  Select
                                </Button>
                              </Tooltip>
                            </ListItemIcon>
                          </ListItem>
                        </List>
                      ))}
                    </FormControl>
                  </Box>
                )}
              </Stack>
            </Paper>
          </Grid>
        ))}
        {previewUrl && (
          <ReactAudioPlayer
            onEnded={handlePreviewEnded}
            src={previewUrl}
            preload="none"
            autoPlay
          />
        )}
      </Grid>
    </>
  );
}
