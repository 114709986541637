import React, { useEffect, useState } from "react";

import SectionHeader from "../../../shared/SectionHeader";

import MusicStock from "./components/MusicStock";
//import { fetchAudios, fetchVideos } from "./../../../firebaseServices";
import { getStorage } from "firebase/storage";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebase.js";

const StockMusic = () => {
  const [audioList, setAudioList] = useState([]);
  const [filteredAudiosList, setFilteredAudiosList] = useState([]);

  useEffect(() => {
    const fetchAudioStocks = async () => {
      try {
        const q = query(
          collection(db, "stock"),
          where("type", "==", "track"),
          orderBy("display")
        );
        const querySnapshot = await getDocs(q);
        const audioFiles = querySnapshot.docs.map((doc) => ({
          time: doc.data().time,
          name: doc.data().display,
          url: doc.data().url,
        }));
        // console.log(audioFiles);
        setAudioList(audioFiles);
        setFilteredAudiosList(audioFiles);
      } catch (e) {
        console.error(e);
      }
    };
    fetchAudioStocks();
  }, []);

  const handleSearchAudio = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (!searchValue) {
      setFilteredAudiosList(audioList);
    } else {
      const filteredList = audioList.filter((item) =>
        item.name.toLowerCase().includes(searchValue)
      );
      setFilteredAudiosList(filteredList);
    }
  };
  return (
    <div className={`mt-6`}>
      <SectionHeader heading={"Stock Audios"} />
      {/*<ButtonTabs />*/}
      <div className={`text-xs text-gray-500`}>Audio by Pixabay</div>
      <MusicStock audioList={filteredAudiosList} />
    </div>
  );
};

export default StockMusic;
