import React, { useState } from "react";
import { Button, Box, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../UserAuthContext";

import { db } from "../../firebase";

// Create a root reference
const storage = getStorage();
const maxFileSize = 20 * 1024 * 1024; // 20 MB
const bucketName = "textspeech-55a09.appspot.com";

export default function UploadMedia(props) {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("error");
  const { user } = useUserAuth();

  const uploadFiletoStorage = async (file) => {
    const timestamp = new Date().getTime();
    const fname = `${file.name}`;

    const uid = user.uid;
    // console.log(uid)

    const storageRef = ref(storage, `${uid}/media/${fname}`);
    const mediaGsUrl = `gs://${bucketName}/${uid}/media/${fname}`;

    try {
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);

      const mediaUrl = await getDownloadURL(
        ref(storage, `${uid}/media/${fname}`)
      );
      await addDoc(collection(db, "uploads"), {
        user: user.email,
        file_name: fname,
        timestamp: serverTimestamp(),
        type: props.mediaType,
        gs_url: mediaGsUrl,
        player_url: mediaUrl,
      });

      return mediaGsUrl;
    } catch (error) {
      console.log("Unable to upload file");
    }
  };

  const getPreviewUrl = async (gsUrl) => {
    const mediaUrl = await getDownloadURL(ref(storage, gsUrl));
    return mediaUrl;
  };

  const handleFileChange = async (event) => {
    setIsFileUploading(true);
    const files = event.target.files;
    const file = files[0];
    const name = files[0].name;

    const fileUrl = await uploadFiletoStorage(file);
    setIsFileUploading(false);

    if (file.size > maxFileSize) {
      setShowFileError(true);
      setErrorMessage(`File size exceeds 20MB limit`);
      return;
    }

    if (file.type.startsWith("video/") || file.type.startsWith("audio/")) {
      const previewUrl = await getPreviewUrl(fileUrl);
      if (props.index !== null && props.index >= 0) {
        props.handleFieldMediaChange(fileUrl, previewUrl, props.index);
      } else {
        props.handleMediaChange(name, fileUrl, previewUrl);
      }
      props.closeMediaModal();
    } else {
      // Show error message or take appropriate action for disallowed file types
      setShowFileError(true);
      setErrorMessage("Invalid format");
      setIsFileUploading(false);
    }
  };

  return (
    <>
      <Stack alignItems="center">
        <Box sx={{ height: "50px" }}>
          <input
            style={{ display: "none" }}
            type="file"
            accept={
              props.mediaType === "video"
                ? "video/mp4, video/x-m4v, video/*"
                : props.mediaType === "audio"
                ? "audio/mpeg, audio/wav, audio/*"
                : "image/gif, image/jpg, image/jpeg"
            }
            id="contained-button-file"
            onChange={handleFileChange}
          />
        </Box>
        <Box justifyContent="center">
          <label htmlFor="contained-button-file">
            <LoadingButton
              component="span"
              loading={isFileUploading}
              size="small"
              variant="outlined"
              startIcon={<Iconify icon="material-symbols:cloud-upload" />}
            >
              Upload
            </LoadingButton>
          </label>
        </Box>
        <ShowMessage
          showFileError={showFileError}
          setShowFileError={setShowFileError}
          severity={errorSeverity}
          message={errorMessage}
        />
      </Stack>
    </>
  );
}
