import ErrorIcon from "@mui/icons-material/Error";
import { Button, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import Size from "../../settings/components/Size";
import CommonModal from "../../shared/CommonModal";
import ShowMessage from "../ShowMessage/ShowMessage";
import Iconify from "../../components/iconify/Iconify";

import Background from "./Background";
import Canvas from "./Canvas";
import useModal from "../../utils/hooks";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { db } from "../../firebase.js";
import { doc, updateDoc } from "firebase/firestore";

const NameContainer = () => {
  const { open, openModal, closeModal } = useModal();
  const [projectTitle, setProjectTitle] = useState("");

  const { activeCanvas, projectInfo } = useContext(CanvasStore);

  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    if (activeCanvas) {
      setProjectTitle(projectInfo.name);
      activeCanvas.set({ projectTitle: projectInfo.name }).requestRenderAll();
      setLoading(false);
    }
  }, [activeCanvas]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateProject();
    }
  };

  const updateProject = async () => {
    const projectRef = doc(db, "projects", projectInfo.id);
    await updateDoc(projectRef, {
      name: projectTitle,
    });
    setShowMessage(true);
    setMessage("Project name updated");
  };

  const handleProjectTitle = async (e) => {
    let { value } = e.target;
    setProjectTitle(value);
    activeCanvas.set({ projectTitle: projectInfo.name }).requestRenderAll();
  };

  const saveDesign = async () => {
    await activeCanvas.saveToDatabase();
    //setSnackbarMessage("Design saved successfully!");
    // setSnackbarOpen(true);
    setShowMessage(true);
    setMessage("Project saved");
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <>
      <div className="flex items-center min-h-0 pointer-events-auto z-1 gap-2">
        <TextField
          variant="standard"
          size="small"
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "14px",
              color: "#fff",
              border: "0px",
            },
            "& .MuiInput-underline.Mui-focused:after": {},
            width: "200px",
          }}
          onChange={handleProjectTitle}
          onKeyDown={handleKeyDown}
          value={projectTitle}
        />
        <Button
          startIcon={<Iconify icon="material-symbols:save" />}
          variant="outlined"
          onClick={saveDesign}
        >
          Save
        </Button>
        {/*<Button
          variant="contained"
          className="ml-4"
          onClick={() => openModal()}
        >
          Resize Modal
        </Button>
        */}
      </div>

      <CommonModal open={open} onClose={closeModal}>
        <div className="max-w-[900px] w-[900px] bg-white">
          <div className="grid grid-cols-2">
            <div className="col-span-1 py-20 px-6">
              <h2 className="m-0 text-lg font-inter text-black">
                Resize for Social Media
              </h2>
              <p className="text-sm text-gray-400 mb-4 m-0">
                Create different versions of your video with just one single
                click!
              </p>

              <Size />
              <Canvas />
              <Background />

              <Button
                variant="contained"
                className="font-bold text-white text-[13px] w-full mt-4 h-12"
              >
                <div className="flex justify-center items-center gap-1">
                  <span>Duplicate & Resize</span>
                  <ErrorIcon sx={{ rotate: "180deg" }} />
                </div>
              </Button>
            </div>
            <div className="col-span-1 h-full flex items-center justify-center bg-gray-100 self-stretch">
              <div className="w-[80%] h-[250px] bg-brownLight rounded-md"></div>
            </div>
          </div>
        </div>
      </CommonModal>
      <ShowMessage
        showFileError={showMessage}
        setShowFileError={setShowMessage}
        message={message}
      />
    </>
  );
};

export default NameContainer;
