import React, { useState, useEffect } from "react";
import {
  IconButton,
  Grid,
  Input,
  Link,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useUserAuth } from "../../UserAuthContext";
import ReactPlayer from "react-player";

import { addDoc, collection, serverTimestamp } from "firebase/firestore";

import { db } from "../../firebase.js";

import { functions, httpsCallable } from "../../firebase";

const storage = getStorage();

export default function StockMedia(props) {
  const [loading, setLoading] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [query, setQuery] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("error");
  const [userFiles, setUserFiles] = useState([]);
  const { user } = useUserAuth();

  const getMediaFiles = async () => {
    setLoading(true);
    const getMediaFunction = httpsCallable(functions, "getMedia");

    const result = await getMediaFunction({ query });
    const videos = result.data.videos.videos;
    console.log(result.data.videos);
    setUserFiles(videos);
    await addDoc(collection(db, "mediasearch"), {
      user: user.email,
      timestamp: serverTimestamp(),
      query: query,
    });
    setLoading(false);
  };

  const insertMedia = async (videos, id) => {
    console.log(id);

    // (TODO) filter for smallest in the set
    const hdVideoFiles = videos.filter((item) => item.quality === "hd");

    const hdVideoFile = hdVideoFiles[0];
    console.log(hdVideoFile);

    props.handleFieldMediaChange("", hdVideoFile.link, props.index, id);
    props.closeMediaModal();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter") {
      getMediaFiles();
    }
  };
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Stack direction="row" mt={2} mb={2}>
        <Input
          value={query}
          onKeyDown={handleKeyDown}
          onChange={handleQueryChange}
          fullWidth
          placeholder="Enter search terms..."
          sx={{
            "& ::placeholder": {
              fontSize: "12px", // Change the font size here
            },
          }}
        />
        <Tooltip title="Search" placement="top" arrow>
          <LoadingButton
            size="small"
            color="primary"
            loading={loading}
            onClick={getMediaFiles}
            sx={{
              color: "#212121",
            }}
            disabled={props.isContentLoading || query.length == 0}
          >
            <Iconify icon="mdi:search" />
          </LoadingButton>
        </Tooltip>
      </Stack>

      <Stack alignItems="center" justifyContent="center" minHeight="100vh">
        <Grid container>
          {userFiles.map((file, index) => (
            <Grid justifyContent="center" item key={index} m={1}>
              <Stack>
                <img width="165px" height="90px" src={file.image} />
                <Stack
                  sx={{
                    borderColor: "#d7dde1",
                  }}
                  border={1}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "text.secondary",
                        paddingLeft: "5px",
                      }}
                    >
                      Length: {file.duration} secs
                    </Typography>
                    <Typography
                      sx={{
                        width: "120px",
                        fontSize: "12px",
                        color: "text.secondary",
                        paddingLeft: "5px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      By: <Link href={file.user.url}>{file.user.name}</Link>
                    </Typography>
                  </Stack>

                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => insertMedia(file.video_files, file.id)}
                  >
                    <Iconify icon="icons8:plus" />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Stack>
    </>
  );
}
