//---------Gesture start
import {fabric} from "fabric";

export function gestureEvents(canvas) {
    if(canvas instanceof fabric.StaticCanvas){
        staticCanvasGestureEvents(canvas);
        return;
    }

    canvas.on('mouse:wheel', (opt) => {
        opt.e.preventDefault()
        opt.e.stopPropagation()
        let evt = opt.e
        if (evt.ctrlKey === true) {
            let delta = evt.deltaY
            let zoom = canvas.getZoom()
            zoom *= 0.995 ** delta
            if (zoom < 0.13) zoom = 0.13
            // forceZoom(zoom)
            let {pointer: {x, y}} = opt
            canvas.zoomToPoint(
                new fabric.Point(x, y),
                zoom,
            )
            canvas.requestRenderAll()
            canvas.fire('zoom:update')

        } else {
            let {deltaX, deltaY} = evt
            // allow only 1 axis to scroll H or V
            Math.abs(deltaX) > Math.abs(deltaY) ? deltaY = 0 : deltaX = 0
            canvas.relativePan(new fabric.Point(-deltaX, -deltaY))
            canvas.renderAll()
        }

    })
}
function staticCanvasGestureEvents(canvas) {
    canvas.__mainEditorWrapperEl.addEventListener('wheel', (evt) => {
        evt.preventDefault()
        evt.stopPropagation()
        if (evt.ctrlKey === true) {
            let delta = evt.deltaY
            let zoom = canvas.getZoom()
            zoom *= 0.995 ** delta
            if (zoom < 0.13) zoom = 0.13
            // forceZoom(zoom)
            let x=canvas.width/2;
            let y=canvas.height/2;
            // let {pointer: {x, y}} = opt
            canvas.zoomToPoint(
                new fabric.Point(x, y),
                zoom,
            )
            canvas.requestRenderAll()
            canvas.fire('zoom:update')
            // this.publishZoomUpdate();
            // setDisplayDimensions({...displayDimensions, zoom})
        } else {
            let {deltaX, deltaY} = evt
            // allow only 1 axis to scroll H or V
            Math.abs(deltaX) > Math.abs(deltaY) ? deltaY = 0 : deltaX = 0
            canvas.relativePan(new fabric.Point(-deltaX, -deltaY))
            canvas.renderAll()
        }

    })
}
//---------Gesture end


