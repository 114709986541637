import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  IconButton,
  Input,
  Stack,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import Iconify from "../iconify";

export default function Translate({
  languages,
  translateContent,
  setSelectedLanguage,
  selectedLang,
  translating,
  setTranslating,
  openTranslateModal,
  handleCloseTranslateModal,
}) {
  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  const handletranslateContent = async () => {
    setTranslating(true);
    await translateContent();
    setTranslating(false);
  };

  return (
    <Dialog
      fullWidth
      open={openTranslateModal}
      onClose={handleCloseTranslateModal}
    >
      <DialogTitle mt={2}>Translation</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mb={2} alignItems="center">
          <Typography>Translate content to?</Typography>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
            <Select
              sx={{ fontSize: "12px" }}
              labelId="langugage-select-label"
              id="language-select"
              label="Language"
              value={selectedLang}
              onChange={(e) => handleLanguageChange(e)}
            >
              {languages
                .filter((language) => language.translate === true)
                .sort((a, b) => a.language.localeCompare(b.language))
                .map((language) => (
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    key={language.language}
                    value={language.code}
                  >
                    {language.code.includes("zh")
                      ? language.language
                      : language.language.split(" ")[0]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <LoadingButton
            loading={translating}
            onClick={handletranslateContent}
            size="small"
            variant="outlined"
          >
            Translate
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
