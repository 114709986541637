export const MediaIcons = ({ iconName, ...props }) => {
  const icons = {
    original: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 256 256"
        {...props}
      >
        <path
          fill="white"
          d="m162.22 108.67l-48-32A4 4 0 0 0 108 80v64a4 4 0 0 0 2.11 3.53a4 4 0 0 0 4.11-.2l48-32a4 4 0 0 0 0-6.66M116 136.53V87.47L152.79 112ZM216 44H40a12 12 0 0 0-12 12v112a12 12 0 0 0 12 12h176a12 12 0 0 0 12-12V56a12 12 0 0 0-12-12m4 124a4 4 0 0 1-4 4H40a4 4 0 0 1-4-4V56a4 4 0 0 1 4-4h176a4 4 0 0 1 4 4Zm8 40a4 4 0 0 1-4 4H32a4 4 0 0 1 0-8h192a4 4 0 0 1 4 4"
        />
      </svg>
    ),
    youTube: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#ff0000"
        {...props}
      >
        <g clip-path="url(#clip0_6907_1214)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.6915 5.28682C23.0642 5.6595 23.3327 6.1233 23.4704 6.63205C24.2631 9.82283 24.0799 14.8622 23.4858 18.1761C23.3481 18.6849 23.0795 19.1487 22.7069 19.5213C22.3342 19.894 21.8704 20.1626 21.3616 20.3002C19.4992 20.8082 12.0033 20.8082 12.0033 20.8082C12.0033 20.8082 4.50731 20.8082 2.64486 20.3002C2.13612 20.1626 1.67231 19.894 1.29964 19.5213C0.926961 19.1487 0.658419 18.6849 0.520754 18.1761C-0.276556 14.9992 -0.0579885 9.95674 0.505362 6.64744C0.643027 6.13869 0.911568 5.67489 1.28424 5.30221C1.65692 4.92954 2.12072 4.661 2.62947 4.52333C4.49191 4.01539 11.9879 4 11.9879 4C11.9879 4 19.4838 4 21.3463 4.50794C21.855 4.6456 22.3188 4.91415 22.6915 5.28682ZM15.8205 12.4043L9.60215 16.006V8.80253L15.8205 12.4043Z"
            fill="currentColor"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_6907_1214">
            <rect width="24" height="24" fill="none"></rect>
          </clipPath>
        </defs>
      </svg>
    ),
    youTubeShort: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#ff0000"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3669 1.48526C16.4419 0.403879 19.0111 1.19164 20.1032 3.24519C21.1953 5.29874 20.3986 7.83926 18.3236 8.92064L16.6174 9.81762C18.0873 9.87133 19.4891 10.6788 20.2232 12.0592C21.3153 14.1127 20.5204 16.6532 18.4436 17.7346L9.34492 22.5149C7.26989 23.5963 4.70071 22.8085 3.60859 20.755C2.51647 18.7014 3.31318 16.1609 5.38821 15.0795L7.09443 14.1825C5.62454 14.1288 4.22269 13.3214 3.48864 11.941C2.39652 9.88744 3.19323 7.34691 5.26826 6.26553L14.3669 1.48526ZM9.45592 8.61449L14.9631 12.0144L9.45592 15.3982V8.61449Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    tikTok: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.58333 1C3.05203 1 1 3.05203 1 5.58333V18.4167C1 20.948 3.05203 23 5.58333 23H18.4167C20.948 23 23 20.948 23 18.4167V5.58333C23 3.05203 20.948 1 18.4167 1H5.58333ZM19.3333 7.71048V10.4306C17.9597 10.4773 16.6033 10.1121 15.4375 9.38184C15.4375 10.271 15.4472 11.1607 15.4568 12.05C15.4653 12.8245 15.4737 13.5987 15.4757 14.372C15.4757 15.3532 15.1856 16.3125 14.6421 17.1283C14.0986 17.9442 13.3261 18.5802 12.4223 18.9557C11.5185 19.3312 10.524 19.4294 9.56456 19.238C8.6051 19.0466 7.72377 18.574 7.03204 17.8802C6.3403 17.1863 5.86922 16.3023 5.67838 15.3399C5.48753 14.3775 5.58548 13.3799 5.95984 12.4733C6.33421 11.5667 6.96817 10.7919 7.78156 10.2467C8.59496 9.70155 9.55125 9.41057 10.5295 9.41057C10.8014 9.4111 11.0728 9.43352 11.3411 9.47762V12.1116C10.8809 11.9471 10.3815 11.9282 9.91027 12.0574C9.43902 12.1867 9.01866 12.4578 8.70592 12.8343C8.39318 13.2108 8.20313 13.6745 8.16143 14.1627C8.11972 14.651 8.22837 15.1403 8.4727 15.5647C8.71703 15.989 9.08529 16.328 9.52774 16.5357C9.9702 16.7435 10.4656 16.8101 10.9469 16.7265C11.4283 16.643 11.8726 16.4133 12.2197 16.0684C12.5668 15.7236 12.8001 15.2802 12.888 14.7982V3.75L15.4518 3.81226V4.00861C15.4672 5.00019 15.8268 5.95535 16.4688 6.70959C17.0942 7.37047 18.0634 7.71048 19.3333 7.71048Z"
          fill="currentColor"
        ></path>
      </svg>
    ),
    instagramPost: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#71737C"
        {...props}
      >
        <path
          d="M16.125 1H7.875C4.07862 1 1 4.07862 1 7.875V16.125C1 19.9214 4.07862 23 7.875 23H16.125C19.9214 23 23 19.9214 23 16.125V7.875C23 4.07862 19.9214 1 16.125 1ZM20.9375 16.125C20.9375 18.7788 18.7788 20.9375 16.125 20.9375H7.875C5.22125 20.9375 3.0625 18.7788 3.0625 16.125V7.875C3.0625 5.22125 5.22125 3.0625 7.875 3.0625H16.125C18.7788 3.0625 20.9375 5.22125 20.9375 7.875V16.125Z"
          fill="url(#paint0_linear_6907_1200)"
        ></path>
        <path
          d="M12 6.5C8.96263 6.5 6.5 8.96263 6.5 12C6.5 15.0374 8.96263 17.5 12 17.5C15.0374 17.5 17.5 15.0374 17.5 12C17.5 8.96263 15.0374 6.5 12 6.5ZM12 15.4375C10.1053 15.4375 8.5625 13.8948 8.5625 12C8.5625 10.1039 10.1053 8.5625 12 8.5625C13.8948 8.5625 15.4375 10.1039 15.4375 12C15.4375 13.8948 13.8948 15.4375 12 15.4375Z"
          fill="url(#paint1_linear_6907_1200)"
        ></path>
        <path
          d="M17.9125 6.82037C18.3172 6.82037 18.6454 6.49226 18.6454 6.0875C18.6454 5.68275 18.3172 5.35463 17.9125 5.35463C17.5077 5.35463 17.1796 5.68275 17.1796 6.0875C17.1796 6.49226 17.5077 6.82037 17.9125 6.82037Z"
          fill="url(#paint2_linear_6907_1200)"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_6907_1200"
            x1="3.01389"
            y1="20.9862"
            x2="20.9861"
            y2="3.01376"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_6907_1200"
            x1="4.22204"
            y1="19.778"
            x2="19.778"
            y2="4.22204"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_6907_1200"
            x1="4.22261"
            y1="19.7795"
            x2="19.7774"
            y2="4.22261"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
        </defs>
      </svg>
    ),
    instagramStory: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#71737C"
        {...props}
      >
        <path
          d="M11.081 11.0817H7.58828C7.29266 11.0817 7.05088 11.3242 7.05088 11.6207V12.5639C7.05088 12.8604 7.29266 13.1029 7.58828 13.1029H11.081V16.6064C11.081 16.903 11.3228 17.1455 11.6184 17.1455H12.5587C12.8543 17.1455 13.0961 16.903 13.0961 16.6064V13.1029H16.5888C16.8844 13.1029 17.1262 12.8604 17.1262 12.5639V11.6207C17.1262 11.3242 16.8844 11.0817 16.5888 11.0817H13.0961V7.57818C13.0961 7.28164 12.8543 7.03912 12.5587 7.03912H11.6184C11.3228 7.03912 11.081 7.28164 11.081 7.57818V11.0817Z"
          fill="black"
        ></path>
        <path
          d="M11.081 11.0817H7.58828C7.29266 11.0817 7.05088 11.3242 7.05088 11.6207V12.5639C7.05088 12.8604 7.29266 13.1029 7.58828 13.1029H11.081V16.6064C11.081 16.903 11.3228 17.1455 11.6184 17.1455H12.5587C12.8543 17.1455 13.0961 16.903 13.0961 16.6064V13.1029H16.5888C16.8844 13.1029 17.1262 12.8604 17.1262 12.5639V11.6207C17.1262 11.3242 16.8844 11.0817 16.5888 11.0817H13.0961V7.57818C13.0961 7.28164 12.8543 7.03912 12.5587 7.03912H11.6184C11.3228 7.03912 11.081 7.28164 11.081 7.57818V11.0817Z"
          fill="url(#paint0_linear_26828_53828)"
        ></path>
        <path
          d="M4.92848 17.7653C4.52537 18.1696 4.52537 18.7894 4.92848 19.1938C6.27188 20.5682 7.9913 21.5385 9.84506 21.9695C10.3825 22.1042 10.9467 21.7808 11.081 21.2419C11.2152 20.7029 10.8928 20.1369 10.3556 20.0022C10.3288 20.0022 10.302 20.0022 10.302 19.9753C8.79749 19.625 7.42731 18.8703 6.35254 17.7655C5.94943 17.3881 5.30478 17.3881 4.92848 17.7653Z"
          fill="black"
        ></path>
        <path
          d="M4.92848 17.7653C4.52537 18.1696 4.52537 18.7894 4.92848 19.1938C6.27188 20.5682 7.9913 21.5385 9.84506 21.9695C10.3825 22.1042 10.9467 21.7808 11.081 21.2419C11.2152 20.7029 10.8928 20.1369 10.3556 20.0022C10.3288 20.0022 10.302 20.0022 10.302 19.9753C8.79749 19.625 7.42731 18.8703 6.35254 17.7655C5.94943 17.3881 5.30478 17.3881 4.92848 17.7653Z"
          fill="url(#paint1_linear_26828_53828)"
        ></path>
        <path
          d="M13.875 19.9755C13.3376 20.0834 12.9884 20.6224 13.0959 21.1612C13.2034 21.7003 13.7407 22.0506 14.2779 21.9428H14.3316C19.759 20.7302 23.1442 15.2865 21.9081 9.86957C20.8603 5.26107 16.7765 2 12.0748 2C11.5106 2 11.0538 2.45814 11.0538 3.02408C11.0538 3.59003 11.5106 4.04817 12.0748 4.04817C16.5346 4.04817 20.1346 7.6595 20.1346 12.1328C20.1614 15.8789 17.5555 19.1402 13.875 19.9755Z"
          fill="black"
        ></path>
        <path
          d="M13.875 19.9755C13.3376 20.0834 12.9884 20.6224 13.0959 21.1612C13.2034 21.7003 13.7407 22.0506 14.2779 21.9428H14.3316C19.759 20.7302 23.1442 15.2865 21.9081 9.86957C20.8603 5.26107 16.7765 2 12.0748 2C11.5106 2 11.0538 2.45814 11.0538 3.02408C11.0538 3.59003 11.5106 4.04817 12.0748 4.04817C16.5346 4.04817 20.1346 7.6595 20.1346 12.1328C20.1614 15.8789 17.5555 19.1402 13.875 19.9755Z"
          fill="url(#paint2_linear_26828_53828)"
        ></path>
        <path
          d="M2 12.106C2 13.3457 2.21496 14.5854 2.6717 15.7442C2.85985 16.2562 3.45085 16.5258 3.98827 16.3102C4.49863 16.1215 4.76742 15.5286 4.55248 14.9896C4.20323 14.0464 4.01508 13.0761 4.01508 12.0789C4.01508 11.513 3.55835 11.0548 2.99414 11.0548C2.42993 11.055 2 11.54 2 12.106Z"
          fill="black"
        ></path>
        <path
          d="M2 12.106C2 13.3457 2.21496 14.5854 2.6717 15.7442C2.85985 16.2562 3.45085 16.5258 3.98827 16.3102C4.49863 16.1215 4.76742 15.5286 4.55248 14.9896C4.20323 14.0464 4.01508 13.0761 4.01508 12.0789C4.01508 11.513 3.55835 11.0548 2.99414 11.0548C2.42993 11.055 2 11.54 2 12.106Z"
          fill="url(#paint3_linear_26828_53828)"
        ></path>
        <path
          d="M4.7407 6.66232C4.25715 6.39291 3.63909 6.55451 3.37052 7.03955C3.26304 7.22827 3.15556 7.41678 3.0749 7.6055C2.83313 8.09053 3.02127 8.7105 3.50481 8.95304C3.98836 9.19557 4.60643 9.00683 4.84822 8.5218V8.4949C4.92888 8.3333 5.00932 8.19837 5.08999 8.03676C5.38539 7.57862 5.22424 6.95863 4.7407 6.66232Z"
          fill="black"
        ></path>
        <path
          d="M4.7407 6.66232C4.25715 6.39291 3.63909 6.55451 3.37052 7.03955C3.26304 7.22827 3.15556 7.41678 3.0749 7.6055C2.83313 8.09053 3.02127 8.7105 3.50481 8.95304C3.98836 9.19557 4.60643 9.00683 4.84822 8.5218V8.4949C4.92888 8.3333 5.00932 8.19837 5.08999 8.03676C5.38539 7.57862 5.22424 6.95863 4.7407 6.66232Z"
          fill="url(#paint4_linear_26828_53828)"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_26828_53828"
            x1="3.8457"
            y1="20.1693"
            x2="20.1835"
            y2="3.6984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint1_linear_26828_53828"
            x1="3.8457"
            y1="20.1693"
            x2="20.1835"
            y2="3.6984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint2_linear_26828_53828"
            x1="3.8457"
            y1="20.1693"
            x2="20.1835"
            y2="3.6984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint3_linear_26828_53828"
            x1="3.8457"
            y1="20.1693"
            x2="20.1835"
            y2="3.6984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
          <linearGradient
            id="paint4_linear_26828_53828"
            x1="3.8457"
            y1="20.1693"
            x2="20.1835"
            y2="3.6984"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
        </defs>
      </svg>
    ),
    instagramReel: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        color="#71737C"
        {...props}
      >
        <path
          d="M7.32946 1H16.6705C18.4087 1 19.9902 1.70898 21.1375 2.84766C22.2867 3.98812 23 5.56185 23 7.29134V16.7105C23 18.4364 22.2867 20.0083 21.1411 21.1488L21.1375 21.1523C19.9884 22.2928 18.4069 23 16.6723 23H7.32946C5.58949 23 4.00622 22.291 2.85885 21.1523L2.81562 21.104C1.69527 19.9689 1 18.4149 1 16.7087V7.29134C1 5.56006 1.71148 3.98812 2.85885 2.84766C4.00622 1.70719 5.58769 1 7.32946 1ZM17.4829 6.55371L17.4955 6.57341H21.3861C21.2294 5.55648 20.7413 4.64339 20.0388 3.94336C19.1742 3.08398 17.9818 2.55046 16.6705 2.55046H15.0729L17.4829 6.55371ZM15.6835 6.57341L13.2608 2.55046H7.94727L10.3987 6.57341H15.6835ZM8.5849 6.57341L6.21451 2.68115C5.34812 2.88883 4.5718 3.33464 3.96119 3.94336C3.25872 4.6416 2.77239 5.55648 2.61389 6.57341H8.5849ZM21.4402 8.12386H2.55985V16.7105C2.55985 17.9941 3.0804 19.1632 3.92157 20.019L3.96119 20.0566C4.82577 20.916 6.01998 21.4513 7.32946 21.4513H16.6705C17.9818 21.4513 19.1742 20.9178 20.037 20.0602L20.0406 20.0566C20.9034 19.1991 21.4402 18.0138 21.4402 16.7105V8.12386ZM10.1772 10.8381L15.0098 13.9622C15.0873 14.0124 15.1575 14.0768 15.2134 14.1556C15.4331 14.4725 15.3521 14.9076 15.0332 15.126L10.2204 17.9494C10.0997 18.0479 9.94482 18.1069 9.7737 18.1069C9.38464 18.1069 9.06943 17.7936 9.06943 17.4069V11.411H9.07303C9.07303 11.2731 9.11446 11.1335 9.19912 11.0117C9.42247 10.6948 9.86016 10.6178 10.1772 10.8381Z"
          fill="#18191B"
        ></path>
        <path
          d="M7.32946 1H16.6705C18.4087 1 19.9902 1.70898 21.1375 2.84766C22.2867 3.98812 23 5.56185 23 7.29134V16.7105C23 18.4364 22.2867 20.0083 21.1411 21.1488L21.1375 21.1523C19.9884 22.2928 18.4069 23 16.6723 23H7.32946C5.58949 23 4.00622 22.291 2.85885 21.1523L2.81562 21.104C1.69527 19.9689 1 18.4149 1 16.7087V7.29134C1 5.56006 1.71148 3.98812 2.85885 2.84766C4.00622 1.70719 5.58769 1 7.32946 1ZM17.4829 6.55371L17.4955 6.57341H21.3861C21.2294 5.55648 20.7413 4.64339 20.0388 3.94336C19.1742 3.08398 17.9818 2.55046 16.6705 2.55046H15.0729L17.4829 6.55371ZM15.6835 6.57341L13.2608 2.55046H7.94727L10.3987 6.57341H15.6835ZM8.5849 6.57341L6.21451 2.68115C5.34812 2.88883 4.5718 3.33464 3.96119 3.94336C3.25872 4.6416 2.77239 5.55648 2.61389 6.57341H8.5849ZM21.4402 8.12386H2.55985V16.7105C2.55985 17.9941 3.0804 19.1632 3.92157 20.019L3.96119 20.0566C4.82577 20.916 6.01998 21.4513 7.32946 21.4513H16.6705C17.9818 21.4513 19.1742 20.9178 20.037 20.0602L20.0406 20.0566C20.9034 19.1991 21.4402 18.0138 21.4402 16.7105V8.12386ZM10.1772 10.8381L15.0098 13.9622C15.0873 14.0124 15.1575 14.0768 15.2134 14.1556C15.4331 14.4725 15.3521 14.9076 15.0332 15.126L10.2204 17.9494C10.0997 18.0479 9.94482 18.1069 9.7737 18.1069C9.38464 18.1069 9.06943 17.7936 9.06943 17.4069V11.411H9.07303C9.07303 11.2731 9.11446 11.1335 9.19912 11.0117C9.42247 10.6948 9.86016 10.6178 10.1772 10.8381Z"
          fill="url(#paint0_linear_26828_53827)"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_26828_53827"
            x1="3.01389"
            y1="20.9862"
            x2="20.9861"
            y2="3.01376"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC107"></stop>
            <stop offset="0.507" stop-color="#F44336"></stop>
            <stop offset="0.99" stop-color="#9C27B0"></stop>
          </linearGradient>
        </defs>
      </svg>
    ),
  };

  // Return the requested icon or a default icon if not found
  return icons[iconName];
};
