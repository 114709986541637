import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Button,
  Chip,
  Container,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Popover,
  Select,
  Stack,
  Tabs,
  Tab,
  Tooltip,
  TextField,
  Typography,
} from "@mui/material";

import { alpha, useTheme } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import { db } from "../firebase.js";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  where,
  query,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../UserAuthContext";
import { useSearchParams } from "react-router-dom";

import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import Iconify from "../components/iconify";

// Components
import ShowMessage from "../components/ShowMessage/ShowMessage";
import ProjectName from "../components/ProjectName/ProjectName";
import UploadFile from "../components/UploadFile/UploadFile";
import PlayerBar from "../components/PlayerBar/PlayerBar";
import VoiceSelection from "../components/VoiceSelection/VoiceSelection";
import SubscribeOffer from "../components/SubscribeOffer/SubscribeOffer";
import FetchUrl from "../components/FetchUrl/FetchUrl";

// ----------------------------------------------------------------------

export default function BooksPage() {
  const [inputText, setInputText] = useState("");

  const { isLoading, user } = useUserAuth();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processingDownload, setProcessingDownload] = useState(false);
  const [messageLink, setMessageLink] = useState("");
  const [openVoiceModal, setOpenVoiceModal] = useState(false); // media

  const [audioGsUrl, setAudioGsUrl] = useState("");
  const [audioUrl, setAudioUrl] = useState(null);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("en-US");
  const [statusMessage, setStatusMessage] = useState("");
  const [autoPlay, setAutoPlay] = useState(false);

  // project settings
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState(null);
  const [searchParams] = useSearchParams();

  const [usage, setUsage] = useState(0);
  const [quota, setQuota] = useState(10000);

  const [activeTab, setActiveTab] = useState(0);

  const [error, setError] = useState(false);
  const [severity, setSeverity] = useState("error");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(
    "Something went wrong. Please try again or try refeshing"
  );

  const [importAnchorEl, setImportAnchorEl] = useState(null);

  // Summary
  const [summaryText, setSummaryText] = useState("");
  const [summaryLoading, setSummaryLoading] = useState(false);

  // Show offer
  const [showOffer, setShowOffer] = useState(false);

  // Show fetch url section
  const [showFetchUrl, setShowFetchUrl] = useState(false);

  useEffect(() => {
    async function loadProject() {
      // console.log("loading project");
      const paramProjectId = searchParams.get("id");
      if (paramProjectId) {
        // Store it for use in other parts of the app
        setProjectId(paramProjectId);

        const projectRef = doc(db, "projects", paramProjectId);
        const projSnapShot = await getDoc(projectRef);

        if (!projSnapShot.exists()) {
          handleShowMessage("Something went wrong", "error");
          return;
        }

        const projData = projSnapShot.data();

        setProject(projData);

        // For existing projects restore previous data
        if (projData.text) {
          setInputText(projSnapShot.data().text);
          setAudioUrl(projSnapShot.data().audioUrl);
          setAudioGsUrl(projSnapShot.data().audioGsUrl || "");
          setSelectedVoice(projSnapShot.data().selectedVoice || "");
          setAutoPlay(false); // prevents audio from playing on load
        }
      } else {
        console.log("no id");
      }
    }
    loadProject().then(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const docRef = doc(db, "customers", user.uid);
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setUsage(userData.usage);
        setQuota(userData.quota);
      } else {
        // Document doesn't exist
        handleShowMessage("Something went wrong");
      }
    });
    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // only save when audiourl state is not null
    if (!audioUrl) {
      return;
    }
    const handleSaveProject = async () => {
      await saveProject();
    };
    handleSaveProject();
  }, [audioUrl]);

  const storage = getStorage();

  const saveProject = async (alert = false) => {
    const dataToUpdate = {
      text: inputText,
      selectedVoice: selectedVoice,
    };

    if (audioUrl) {
      dataToUpdate.audioUrl = audioUrl;
      dataToUpdate.audioGsUrl = audioGsUrl;
    }

    console.log(projectId);
    await updateDoc(doc(db, "projects", projectId), dataToUpdate);

    if (alert) {
      handleShowMessage("Project saved", "success");
    }
  };

  const handleShowMessage = (
    message = "Something went wrong. Please contact us if the issue persists",
    sev = "error",
    link
  ) => {
    setShowMessage(true);
    setMessage(message);
    setSeverity(sev);
    setMessageLink(link);
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
    setAudioGsUrl("");
    setAudioUrl("");
    //saveProject();
  };

  const hanldeUpdateText = (text) => {
    setInputText(text);
  };
  const handleShowFetchUrl = () => {
    setShowFetchUrl(!showFetchUrl);
    //saveProject();
  };

  const handleSummaryTextChange = (e) => {
    setSummaryText(e.target.value);
    //saveProject();
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const handleWarningOpen = () => {
    setOpenWarning(true);
  };

  const handleOpenVoiceModal = () => {
    setOpenVoiceModal(true);
  };

  const handleCloseOfferModal = () => setShowOffer(false);

  const playPreview = (event, previewUrl) => {
    event.stopPropagation();
    setAudioUrl(previewUrl);
  };

  const handleCloseVoiceModal = () => {
    setOpenVoiceModal(false);
  };

  const handleVoiceChange = (voice) => {
    setSelectedVoice(voice);
    setAudioGsUrl("");
    setAudioUrl("");
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleImportOverflowClose = () => {
    setImportAnchorEl(null);
  };

  const handleImportClick = (event) => {
    setImportAnchorEl(event.currentTarget);
  };

  const handleSynthesis = async () => {
    await saveProject();
    setOpenWarning(false); // Dialog for usage
    setAutoPlay(true);
    setStatusMessage("This can take a minute. Please wait...");

    const text = activeTab === 0 ? inputText : summaryText;
    if (text.length > quota - usage) {
      setShowOffer(true);
      setProcessing(false);
      return;
    }

    setProcessing(true);
    const synthesizeLongText = httpsCallable(functions, "synthesizeAsync");
    try {
      const response = await synthesizeLongText({
        inputText: text,
        selectedVoice: selectedVoice.voice,
      });
      const gsUrl = response.data.outputGcsUri;
      setAudioGsUrl(gsUrl);
      const audioUrl = await getDownloadURL(ref(storage, gsUrl));
      setAudioUrl(audioUrl);
    } catch (error) {
      handleShowMessage(
        "Something went wrong. Please ensure that sentences aren't too long. Contact us if the issue persists"
      );
    } finally {
      setProcessing(false);
      setStatusMessage("");
      await saveProject();
    }
  };

  const summarizeText = async () => {
    setSummaryLoading(true);
    const summarizeTextFunction = httpsCallable(functions, "aisummary");
    try {
      const response = await summarizeTextFunction({
        content: inputText,
      });
      // console.log(response.data);
      setSummaryText(response.data);
      setActiveTab(1);
    } catch (error) {
      handleShowMessage(
        "Something went wrong. Please ensure that sentences aren't too long. Contact us if the issue persists"
      );
    } finally {
      setSummaryLoading(false);
      setStatusMessage("");
      await saveProject();
    }
  };

  useEffect(() => {
    if (inputText) {
      setIsContentLoading(false);
    }
  }, [inputText]);

  const handleDownload = async () => {
    setProcessingDownload(true);

    await updateDoc(doc(db, "projects", projectId), {
      export: true,
    });

    // Initiate the download
    const xhr = new XMLHttpRequest();
    xhr.open("GET", audioUrl, true);
    xhr.responseType = "blob";

    // Set the download filename
    const fileName = "acoust.wav";

    // Handle the AJAX success event
    xhr.onload = function () {
      if (xhr.status === 200) {
        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(xhr.response);
        link.download = fileName;

        // Simulate a click on the anchor element
        link.click();

        // Clean up the temporary URL object
        window.URL.revokeObjectURL(link.href);
        setProcessingDownload(false);
      }
    };
    // Send the AJAX request
    xhr.send();
  };

  const isAudioReady = () => {
    return !!audioGsUrl;
  };

  if (loading || !project) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Narrator | Acoust </title>
      </Helmet>

      <Container>
        <Stack direction="row">
          <ProjectName pName={project.name} id={projectId} />
          <IconButton onClick={() => saveProject(true)}>
            <Iconify icon="material-symbols:save-outline" />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={4}
          padding={2}
          paddingTop={2}
          paddingBottom={2}
          mb={1}
          mt={2}
          sx={{
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
            borderRadius: "15px",
          }}
        >
          <Tooltip title="Select voice" placement="top" arrow>
            <Chip
              size="small"
              color="primary"
              variant="outlined"
              onDelete={handleOpenVoiceModal}
              deleteIcon={<Iconify icon="eva:chevron-down-outline" />}
              onClick={handleOpenVoiceModal}
              label={
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Iconify icon="mingcute:voice-fill" />
                  &nbsp;
                  <>
                    {selectedVoice.display_name}
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "text.secondary",
                      }}
                    >
                      {`${selectedVoice.language}`}
                    </Typography>
                  </>
                </Stack>
              }
            />
          </Tooltip>

          <Button
            variant="text"
            sx={{ color: "#fff" }}
            onClick={handleImportClick}
          >
            <Stack alignItems="center">
              <Iconify icon="humbleicons:download" />
              Import
            </Stack>
          </Button>
          <LoadingButton
            variant="text"
            onClick={summarizeText}
            disabled={!inputText}
            loading={summaryLoading}
            sx={{ color: "#fff" }}
          >
            <Stack alignItems="center">
              <Iconify icon="fluent-emoji-flat:robot" />
              AI Summarizer
            </Stack>
          </LoadingButton>
          <Popover
            open={Boolean(importAnchorEl)}
            anchorEl={importAnchorEl}
            onClose={handleImportOverflowClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Stack p={2} spacing={2}>
              <LoadingButton
                size="small"
                variant="outlined"
                onClick={handleShowFetchUrl}
                startIcon={<Iconify icon="gg:website" />}
              >
                Webpage (Text only)
              </LoadingButton>
              <LoadingButton
                size="small"
                variant="outlined"
                onClick={handleShowFetchUrl}
                startIcon={<Iconify icon="logos:reddit-icon" />}
              >
                Reddit
              </LoadingButton>
              <UploadFile
                label="Import PDF"
                importFileType="pdf"
                user={user.email}
                setInputText={setInputText}
                setIsContentLoading={setIsContentLoading}
                projectId={projectId}
              />
              <UploadFile
                label="Text file"
                importFileType="txt"
                user={user.email}
                setInputText={setInputText}
                handleImportOverflowClose={handleImportOverflowClose}
                setIsContentLoading={setIsContentLoading}
                projectId={projectId}
              />
            </Stack>
          </Popover>
        </Stack>
        {showFetchUrl && (
          <FetchUrl
            handleUpdateText={hanldeUpdateText}
            handleShowMessage={handleShowMessage}
            handleShowFetchUrl={handleShowFetchUrl}
          />
        )}
        {(isContentLoading || summaryLoading) && (
          <Stack>
            <Box>
              <Typography variant="body1" style={{ fontSize: 12 }} mt={2}>
                Processing... This can take some time...
              </Typography>
            </Box>
            <Box sx={{ width: "320px" }}>
              <LinearProgress />
            </Box>
          </Stack>
        )}
        {!isContentLoading && (
          <Stack mt={1}>
            {summaryText && (
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Text" />
                <Tab label="AI Summary" disabled={!summaryText} />
              </Tabs>
            )}

            {activeTab === 0 && (
              <Stack>
                <TextField
                  value={inputText}
                  placeholder="Enter text here..."
                  variant="standard"
                  multiline
                  fullWidth
                  minRows={12}
                  helperText={
                    inputText ? `${inputText.length} characters` : null
                  }
                  onChange={handleTextChange}
                  inputProps={{
                    maxLength: 250000,
                    style: { fontSize: 14, fontFamily: "Roboto" },
                  }}
                  sx={{
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
                    paddingRight: "10px",
                    paddingLeft: "10px",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                  }}
                  error={inputText.length >= 250000}
                />
              </Stack>
            )}

            {activeTab === 1 && (
              <TextField
                value={summaryText}
                placeholder="Summarized text will appear here..."
                variant="standard"
                multiline
                fullWidth
                minRows={15}
                helperText={
                  summaryText ? `${inputText.length} characters` : null
                }
                onChange={handleSummaryTextChange}
                inputProps={{
                  style: { fontSize: 14, fontFamily: "Roboto" },
                }}
                sx={{
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                  bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
                  paddingRight: "10px",
                  paddingLeft: "10px",
                  paddingTop: "5px",
                  paddingBottom: "5px",
                }}
              />
            )}
          </Stack>
        )}
        <PlayerBar
          audioPlayerUrl={audioUrl}
          setAudioPlayerUrl={setAudioUrl}
          mergedAudioGsUrl={audioUrl}
          isVideoProject={false}
          statusMessage={statusMessage}
          allSectionsReady={inputText !== ""}
          showExportAll={false}
          handleMerge={() => {
            inputText.length > 10000 ? handleWarningOpen() : handleSynthesis();
          }}
          handleDownload={handleDownload}
          processing={processing}
          processingDownload={processingDownload}
          isContentReady={isAudioReady() && !processing}
          autoPlay={autoPlay}
          projectType={project.type}
        />
        <Dialog
          open={openWarning}
          onClose={handleWarningClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Confirm Synthesis`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {`Please note that by proceeding, you will use ${inputText.length} characters`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleWarningClose}>Disagree</Button>
            <Button onClick={handleSynthesis} autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <ShowMessage
          showFileError={showMessage}
          setShowFileError={setShowMessage}
          severity={severity}
          message={message}
          link={messageLink}
        />
        <VoiceSelection
          openVoiceModal={openVoiceModal}
          handleCloseVoiceModal={handleCloseVoiceModal}
          setSelectedVoice={setSelectedVoice}
          setSelectedLanguage={setSelectedLanguage}
          handleVoiceChange={handleVoiceChange}
          selectedLang={selectedLanguage}
          selectedVoice={selectedVoice}
          cloudProvider={"gcs"}
          languages={[{ language: "en-US", code: "en-US", order: "1" }]}
          mode={"books"}
        />
      </Container>
      <Dialog fullWidth open={showOffer} onClose={handleCloseOfferModal}>
        <SubscribeOffer />
      </Dialog>
    </>
  );
}
