import {
  Button,
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Input,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";

import AutoComplete from "../shared/AutoComplete";
import PageHeader from "../shared/PageHeader";
import Color from "../shared/Color";

import Audio from "./components/Audio";
import Background from "./components/Background";
import Size from "./components/Size";
import { withModifiedEvent } from "../canvas/utils/helper";
import { CanvasStore } from "../canvas/store/canvasStore";
import { formatTimeToMinSecMili, formatTimeToMinSec } from "../utils";

const framesOptions = [
  {
    label: "60",
    value: "60",
  },
  {
    label: "50",
    value: "50",
  },
  {
    label: "30",
    value: "30",
  },
  {
    label: "25",
    value: "25",
  },
  {
    label: "24",
    value: "24",
  },
  {
    label: "10",
    value: "10",
  },
];

const Settings = () => {
  const { activeCanvas, setFPS, FPS } = useContext(CanvasStore);
  // TODO: get it from store
  const [totalVideoLength, setTotalVideoLength] = useState(2000);
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(true);
  const anchorRef = useRef(null);
  const [backgroundShow, setBackgroundShow] = useState(false);
  const [canvasFill, setCanvasFill] = useState("#000");

  useEffect(() => {
    if (activeCanvas) {
      let selectedArtboard = activeCanvas.getObjects("artboard")[0];
      setTotalVideoLength(selectedArtboard?.animationDuration || 2000);
      setIsEditable(selectedArtboard?.durationType === "fixed" || false);
      setLoading(false);
    }
  }, [activeCanvas]);

  const handleFramePerSecond = (value) => {
    let selectedArtboard = activeCanvas.getObjects("artboard")[0];
    withModifiedEvent(
      selectedArtboard,
      activeCanvas,
      () => {
        selectedArtboard.set({ FPS: value });

        setFPS(+value);
      },
      ["FPS"],
      "fpsChanges"
    )();
  };

  const handleVideoDurationChange = (e) => {
    let { value } = e.target;

    const totalTimeMiliSec = value * 1000;
    setTotalVideoLength(totalTimeMiliSec);
    if (value < 2 || value > 300) {
      return;
    }
    let selectedArtboard = activeCanvas.getObjects("artboard")[0];
    selectedArtboard.set({ animationDuration: totalTimeMiliSec });

    activeCanvas.fire("animation-duration:updated", {
      target: selectedArtboard,
    });
  };

  const timeInMilliseconds = (time) => {
    const [minutes, seconds] = time.split(":").map(parseFloat);
    return (minutes * 60 + seconds) * 1000;
  };

  const handleChangeDuarationType = ({ target }) => {
    let selectedArtboard = activeCanvas.getObjects("artboard")[0];
    let value = target.value;
    withModifiedEvent(
      selectedArtboard,
      activeCanvas,
      () => {
        selectedArtboard.durationType = value;
        if (value === "automatic") {
          setTotalVideoLength(selectedArtboard.autoCalculateDuration());
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      },
      ["durationType"],
      "durationTypeChange"
    )();
  };

  const handleShowBackground = (show) => {
    setBackgroundShow(show);
  };

  if (loading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      {" "}
      <PageHeader heading="Settings" />
      <Size />
      <div className="mt-6 flex items-center justify-between">
        <p className="text-white text-[13px] mb-1 font-semibold font-inter m-0">
          Background Color
        </p>
        <Button
          size="large"
          sx={{ minWidth: "100px" }}
          ref={anchorRef}
          variant="outlined"
          onClick={() => handleShowBackground(true)}
        >
          <Color
            className={"border-solid border-[1px] border-gray-200 p-3"}
            value={canvasFill}
          />
        </Button>
      </div>
      {/* <Audio />*/}
      <div className="mt-8">
        <p className="text-white text-[13px] mb-1 font-semibold font-inter m-0">
          Duration
        </p>
        <div className="rounded-lg">
          <RadioGroup
            onChange={handleChangeDuarationType}
            value={isEditable ? "fixed" : "automatic"}
          >
            <div
              className="py-2 px-4 flex items-center justify-between"
              style={{ borderBottom: "0.5px solid rgb(100 116 139)" }}
            >
              <FormControlLabel
                value="automatic"
                control={<Radio size="small" />}
                label={<span className="text-sm text-gray-100">Automatic</span>}
              />
            </div>

            <div className="py-2 px-4 flex items-center justify-between">
              <FormControlLabel
                value="fixed"
                control={<Radio size="small" />}
                label={<span className="text-sm text-gray-100">Fixed</span>}
              />
              <TextField
                variant="standard"
                type="number"
                disabled={!isEditable}
                value={totalVideoLength / 1000}
                onChange={handleVideoDurationChange}
                sx={{
                  width: "180px",
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    color: "#fff",
                  },
                }}
                inputProps={{
                  min: 0, // minimum value
                  max: 10, // maximum value (for example, 600 seconds)
                }}
                error={
                  totalVideoLength / 1000 > 300 || totalVideoLength / 1000 < 2
                }
                helperText={
                  totalVideoLength / 1000 > 300 || totalVideoLength / 1000 < 2
                    ? "Set a value between 2 and 300"
                    : ""
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">seconds</InputAdornment>
                  ),
                }}
              />
            </div>
          </RadioGroup>
        </div>
      </div>
      <Background
        id={"popup"}
        anchorEl={anchorRef.current}
        open={backgroundShow}
        selectedColor={(color) => setCanvasFill(color)}
        handleClose={() => {
          handleShowBackground(false);
        }}
      />
      {/*<div className="mt-8">
      <div className="mt-8">
        <p className="text-white text-[13px] mb-1 font-semibold font-inter m-0">
          Frames Per Second
        </p>
        <AutoComplete
          options={framesOptions}
          value={FPS}
          onSelect={(e) => {
            handleFramePerSecond(e.target.value);
          }}
          placeholder="Select..."
        />
      </div>
        <p className="text-[#18191b] text-[13px] mb-1 font-semibold font-inter m-0">
          Version History
        </p>
        <Button
          variant="white"
          className="group text-[#18191b] transition duration-700 w-full p-4 hover:bg-[#eff9ff] hover:!border-[#dbf0ff] flex justify-start"
          style={{
            border: "1px solid #e1e1e3",
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
          }}
        >
          <div className="flex gap-2 w-full items-center">
            <div className=" p-2 flex justify-center items-center rounded-md">
              <VersionHistory />
            </div>
            <div className="flex-1">
              <div className="flex justify-center items-start flex-col gap-0">
                <span className="text-[#18191b] font-semibold font-inter text-[13px]">
                  Restore to a previous version
                </span>
                <span className="text-[#8f9199] font-inter text-[11px]">
                  Creates a new project
                </span>
              </div>
            </div>
          </div>
        </Button>
      </div>*/}
    </div>
  );
};

export default Settings;

const VersionHistory = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      color="#71737C"
    >
      <path
        d="M19.5 10.3v.8h1a2.8 2.8 0 010 5.5h-1v4.8c0 1-.8 1.8-1.9 1.8h-4.8l.2-.9a2.9 2.9 0 10-5.6.9H2.6c-1 0-1.8-.8-1.8-1.8v-4.8l.8.1a2.9 2.9 0 10-.8-5.6V6.3c0-1 .8-1.8 1.8-1.8h4.8v-.8-.2a2.8 2.8 0 015.5 0v1h4.7c1 0 1.9.8 1.9 1.8zm-12 12.9h0s0 0 0 0zm0 0"
        fill="none"
        stroke="currentColor"
      ></path>
    </svg>
  );
};
