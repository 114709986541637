import ZoomInRoundedIcon from "@mui/icons-material/ZoomInRounded";
import ZoomOutRoundedIcon from "@mui/icons-material/ZoomOutRounded";
import { Button, IconButton } from "@mui/material";
import React, { useState, useEffect } from "react";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import SettingsDropdown from "./SettingsDropdown";
import Slider from "../../../shared/Slider";
import { maxTimelineLengthInSeconds } from "../../../Timeline/timeline-related/TimelineRuler";
const RightButtonGroup = ({
  onUpdateZoom,
  zoomValue,
  animationDuration,
  pixelsPerSecond,
}) => {
  const [zoom, setZoom] = useState(zoomValue);
  useEffect(() => {
    setZoom(zoomValue);
  }, [zoomValue]);

  const handleChange = (event, newValue) => {
    if (newValue < 1) newValue = 1;

    onUpdateZoom(newValue);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSetViewFitToWindow = () => {
    let rightMargin = 30;
    let timeLineRefWidth =
      (pixelsPerSecond * maxTimelineLengthInSeconds) / zoomValue;
    let shouldpixelsPerSecond =
      (timeLineRefWidth - rightMargin) / (animationDuration / 1000);
    handleChange(
      null,
      (shouldpixelsPerSecond * maxTimelineLengthInSeconds) / timeLineRefWidth
    );
  };
  return (
    <div
      className="flex flex-1 justify-end items-center gap-2 pr-4
  "
    >
      <div className="flex items-center gap-2 ">
        <IconButton
          onClick={() => {
            handleChange(null, zoom - 0.1);
          }}
          className="rounded-[8px]"
          size="small"
        >
          <ZoomOutRoundedIcon sx={{ fontSize: 20, color: "white" }} />
        </IconButton>
        <Slider
          className="w-24"
          step={0.1}
          hasInput={false}
          size={"small"}
          value={zoom}
          onChange={(value) => handleChange(null, value)}
        />

        <IconButton
          onClick={() => {
            handleChange(null, zoom + 0.1);
          }}
          className="rounded-[8px] "
          size="small"
        >
          <ZoomInRoundedIcon sx={{ fontSize: 20, color: "white" }} />
        </IconButton>
      </div>

      <Button variant="outlined" onClick={handleSetViewFitToWindow}>
        Fit
      </Button>
      {/**
      <div className="w-[1px] h-6 bg-gray-300 mr-2"></div>
      <IconButton
        className=" hover:bg-grayLight rounded-[8px]"
        size="small"
        onClick={handleClick}
      >
        <SettingsOutlinedIcon sx={{ fontSize: 20, color: "#5c5e65" }} />
      </IconButton>

    
      <SettingsDropdown
        id={id}
        handleClose={handleClose}
        open={open}
        anchorEl={anchorEl}
      />
       */}
    </div>
  );
};

export default RightButtonGroup;
