"use client";
import React, { useEffect, useContext } from "react";

import PageHeader from "../shared/PageHeader";
import { textList } from "../utils/common";
import { addText } from "../canvas/canvasAction";
import { CanvasStore } from "../canvas/store/canvasStore";
import { Stack } from "@mui/material";

const Text = () => {
  const { activeCanvas } = useContext(CanvasStore);
  const addTextElement = (object) => {
    addText(object, activeCanvas);
  };

  return (
    <Stack minHeight="90vh">
      <PageHeader heading="Add Text" />

      <div className="grid grid-cols-1 gap-2 mt-4 p-2">
        {textList.map((item) => {
          return (
            <div
              key={`text-${item.id}`}
              className={`text-element-${item.name} "col-span-1"`}
            >
              <TextBox onClick={() => addTextElement(item)}>
                <span
                  style={{
                    fontSize: 25,
                    fontFamily: item.fontFamily,
                    fontWeight: item.fontWeight,
                    color: item.fill,
                    textShadow: item.shadow
                      ? item.title.includes("3D")
                        ? `2px 3px #00796B`
                        : `${item.stroke} 1px 0 10px`
                      : "none", //
                    WebkitAppearance: item.stroke ? `1px ${item.stroke}` : "",
                    borderBottom: item.underline ? "1px solid #fff" : "none",
                    backgroundColor: item.boxed
                      ? "rgba(183, 28, 28, 1)"
                      : "transparent",
                    padding: item.boxed ? "0px 10px 0px 10px" : 0,
                  }}
                  className={`${"col-span-1"}`}
                >
                  {item.title}
                </span>
              </TextBox>
            </div>
          );
        })}
      </div>
    </Stack>
  );
};

const TextBox = ({ children, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="h-16 w-full flex items-center justify-center bg-slate-800 border-solid border-[1px] border-slate-600 text-white rounded-lg cursor-pointer"
    >
      {children}
    </div>
  );
};

export default Text;
