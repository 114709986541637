import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import { Button } from "@mui/material";
import React from "react";
import ExportModal from "./ExportModal";
import NameContainer from "./NameContainer";
import UndoRedoButton from "./UndoRedoButton";
import Iconify from "../../components/iconify/Iconify";

const ContentHeader = () => {
  const [exportPanel, setExportPanel] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setExportPanel(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExportPanel(false);
  };
  const open = Boolean(anchorEl);

  return (
    <div className="flex justify-between items-center py-2 px-2 pl-4 border-b border-t-0  border-r-0 border-l-0 border-solid border-slate-700">
      <div className="flex justify-between flex-1 items-center pr-8 border-r border-t-0 border-b-0 border-l-0 border-gray-700 border-solid">
        <NameContainer />
        <UndoRedoButton />
      </div>
      <div className="flex pl-4 gap-2 items-center">
        {/*<Button
          sx={{ height: "48px", padding: "0px 16px" }}
          variant="neutral"
          startIcon={<PersonAddAlt1RoundedIcon sx={{ color: "black" }} />}
        >
          Share
        </Button>*/}

        <Button
          variant="contained"
          className="ml-4"
          color="primary"
          endIcon={<Iconify icon="material-symbols:download" />}
          onClick={handleClick}
        >
          Export
        </Button>
      </div>
      {exportPanel && <ExportModal handleClose={handleClose} open={open} />}
    </div>
  );
};

export default ContentHeader;
