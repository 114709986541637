import React, { useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Hidden,
  Popover,
  Paper,
  Stack,
  Typography,
  Tooltip,
  LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { alpha } from "@mui/material/styles";

import FetchUrl from "../FetchUrl/FetchUrl";
import Iconify from "../iconify";
// import { db } from "../../firebase.js";
// import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../UserAuthContext";
import UploadFile from "../UploadFile/UploadFile";

export default function EditorMenu(props) {
  const { user } = useUserAuth();
  const [openImportDialog, setOpenImportDialog] = useState(!props.hideImport);
  const [isContentLoading, setIsContentLoading] = useState(false);

  // overflow menus
  const [anchorEl, setAnchorEl] = useState(null);
  const [bottomPadding, setBottomPadding] = useState(0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  // Show fetch url section
  const [fetchUrlSource, setFetchUrlSource] = useState("");

  const handleOverflowClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOverflowClose = () => {
    setAnchorEl(null);
  };

  const handleImportClick = (event) => {
    setOpenImportDialog(true);
  };

  const handleImportDialogClose = () => {
    setOpenImportDialog(false);
  };

  const handleAIButton = () => {
    props.setShowAI(!props.showAI);
  };

  const handleShowFetchUrl = (value) => {
    setFetchUrlSource(value);
  };

  const handleMouseEnter = (index) => {
    setSelectedCardIndex(index);
    setBottomPadding(0.5); // Set a higher elevation when hovering
  };

  const handleMouseLeave = (index) => {
    setSelectedCardIndex(index);
    setBottomPadding(0); // Set a higher elevation when hovering
  };

  const cards = [
    {
      source: "YouTube",
      icon: "logos:youtube-icon",
      type: "web",
      description: "Trascribe audio from YouTube videos",
    },
    {
      source: "Webpage",
      icon: "gridicons:site",
      type: "web",
      description: "Import text from public websites & blogs",
    },
    {
      source: "Reddit Posts",
      icon: "logos:reddit-icon",
      type: "web",
      description: "Convert reddit threads to audio",
    },
    {
      source: "Text File",
      icon: "mdi:file-text",
      type: "text",
      description: "Load contents from text files",
    },
    {
      source: "PDF",
      icon: "teenyicons:pdf-solid",
      type: "pdf",
      description: "Import and convert your pdf files",
    },
  ];
  return (
    <Stack
      direction="row"
      alignItems="baseline"
      justifyContent="center"
      paddingTop={1}
      paddingBottom={1}
      mb={1}
      sx={{
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
        borderRadius: "10px",
      }}
    >
      {/* Super menu */}
      <Stack direction="row" spacing={3}>
        <Tooltip title="Import text, pdf and websites" placement="top" arrow>
          <Button
            sx={{ color: "#fff" }}
            variant="text"
            onClick={handleImportClick}
          >
            <Stack alignItems="center">
              <Iconify icon="humbleicons:download" />
              Import
            </Stack>
          </Button>
        </Tooltip>

        <Tooltip title="Generate content using AI" placement="top" arrow>
          <Button
            variant="text"
            sx={{ color: "#fff" }}
            onClick={handleAIButton}
          >
            <Stack alignItems="center">
              <Iconify icon="fluent-emoji-flat:robot" />
              AI writer
            </Stack>
          </Button>
        </Tooltip>

        <Hidden mdUp>
          <Box flexGrow={1}></Box>
          <IconButton
            aria-controls="overflow-menu"
            aria-haspopup="true"
            onClick={handleOverflowClick}
          >
            <Iconify icon="mdi:dots-vertical" />
          </IconButton>
        </Hidden>

        <Hidden mdDown>
          <Tooltip
            title="Add background music to your generated speech"
            placement="top"
            arrow
          >
            <LoadingButton
              variant="text"
              sx={{ color: "#fff" }}
              onClick={() => props.handleMediaModal("music")}
            >
              <Stack alignItems="center">
                <Iconify icon="majesticons:music" />
                Music
              </Stack>
            </LoadingButton>
          </Tooltip>

          <Tooltip title="Translate text" placement="top" arrow>
            <LoadingButton
              variant="text"
              sx={{ color: "#fff" }}
              onClick={props.handleOpenTranslateModal}
            >
              <Stack alignItems="center">
                <Iconify icon="material-symbols:translate" />
                Translate
              </Stack>
            </LoadingButton>
          </Tooltip>

          <Tooltip title="Customize Pronunciation" placement="top" arrow>
            <LoadingButton
              variant="text"
              sx={{ color: "#fff" }}
              onClick={props.handleOpenSettingsModal}
            >
              <Stack alignItems="center">
                <Iconify icon="ri:speak-line" />
                Pronounce
              </Stack>
            </LoadingButton>
          </Tooltip>
        </Hidden>

        {/* Import Dialog */}
        <Dialog
          open={openImportDialog}
          maxWidth="sm"
          fullWidth
          onClose={handleImportDialogClose}
        >
          <DialogTitle>
            <Stack justifyContent="space-between" direction="row">
              Import
              <Button
                size="small"
                variant="text"
                onClick={handleImportDialogClose}
              >
                Skip
              </Button>
            </Stack>
          </DialogTitle>
          <DialogContent>
            {isContentLoading && (
              <Stack>
                <LinearProgress variant="indeterminate"></LinearProgress>
                <Typography variant="body2" color="text.secondary">
                  Please wait... This can take a minute or so.
                </Typography>
              </Stack>
            )}

            {/* UI for fetch url functionality */}
            {fetchUrlSource && (
              <FetchUrl
                handleUpdateText={props.handleContent}
                handleShowFetchUrl={handleShowFetchUrl}
                handleShowMessage={props.handleShowMessage}
                fetchUrlSource={fetchUrlSource}
                setFetchUrlSource={setFetchUrlSource}
                canTranscribe={props.canTranscribe}
                handleImportDialogClose={handleImportDialogClose}
              />
            )}

            <Grid
              container
              spacing={2}
              mt={4}
              padding={2}
              justifyContent="center" // Center horizontally
              alignItems="center" // Center vertically
            >
              {!isContentLoading &&
                !fetchUrlSource &&
                cards.map((card, index) => (
                  <Grid
                    item
                    key={index}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Paper
                      elevation={5}
                      sx={{
                        bgcolor: (theme) =>
                          alpha(theme.palette.grey[500], 0.09),
                        border: (theme) =>
                          index === selectedCardIndex
                            ? `${bottomPadding}px solid ${theme.palette.grey[600]}`
                            : 0,
                      }}
                    >
                      {card.type === "web" && (
                        <Stack
                          spacing={1}
                          width={250}
                          padding={4}
                          onClick={() => handleShowFetchUrl(card.source)}
                        >
                          <Iconify icon={card.icon} />
                          <Stack direction="row">
                            {card.source}
                            {card.source === "YouTube" && (
                              <Typography
                                sx={{ color: "red", fontSize: "10px" }}
                              >
                                <sup>&nbsp;Preview</sup>
                              </Typography>
                            )}
                          </Stack>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.secondary" }}
                          >
                            {card.description}
                          </Typography>
                        </Stack>
                      )}

                      {card.type === "text" && (
                        <UploadFile
                          label="Text file"
                          description="Import content from a text (.txt) file"
                          importFileType="txt"
                          user={user.email}
                          setInputText={props.handleContent}
                          handleImportOverflowClose={handleImportDialogClose}
                          setIsContentLoading={setIsContentLoading}
                          projectId={props.projectId}
                        />
                      )}

                      {/*card.type === "pdf" && (
                        <UploadFile
                          label="PDF"
                          importFileType="pdf"
                          user={user.email}
                          setInputText={props.handleContent}
                          handleImportOverflowClose={handleImportDialogClose}
                          setIsContentLoading={setIsContentLoading}
                          projectId={props.projectId}
                        />
                      )*/}
                    </Paper>
                  </Grid>
                ))}
              {/*}
              <Grid item>
                <LoadingButton
                  variant="outlined"
                  loading={props.transcribing}
                  onClick={props.handleOpenUploadModal}
                  startIcon={<Iconify icon="ri:user-voice-fill" />}
                >
                  Audio (Speech to Text)
                </LoadingButton>
                        </Grid>*/}
            </Grid>
          </DialogContent>
        </Dialog>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleOverflowClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {/* Your hidden items go here */}
          <Stack p={2} spacing={2}>
            <Tooltip title="Translate text" placement="top" arrow>
              <LoadingButton
                size="small"
                variant="outlined"
                onClick={props.handleOpenTranslateModal}
                startIcon={<Iconify icon="material-symbols:translate" />}
              >
                Translate
              </LoadingButton>
            </Tooltip>
            <Tooltip title="Customize Pronunciation" placement="top" arrow>
              <LoadingButton
                size="small"
                variant="outlined"
                onClick={props.handleOpenSettingsModal}
                startIcon={<Iconify icon="ri:speak-line" />}
              >
                Pronunciation
              </LoadingButton>
            </Tooltip>

            <Tooltip
              title="Add background music to your audio"
              placement="top"
              arrow
            >
              <LoadingButton
                size="small"
                variant="outlined"
                onClick={() => props.handleMediaModal("audio")}
                startIcon={<Iconify icon="majesticons:music" />}
              >
                Music
              </LoadingButton>
            </Tooltip>
          </Stack>
        </Popover>
      </Stack>
    </Stack>
  );
}
