import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import { Button } from '@mui/material';
import React from 'react';
const Background = () => {
  return (
    <div className="mt-4">
      <p className="text-[#18191b] text-[13px] mb-1 font-semibold font-inter m-0">Background</p>

      <Button
        className="flex flex-1 h-12 justify-between text-[#18191b] text-sm border border-gray-300 w-full py-1 hover:border-primary hover:text-primary hover:bg-primary/10"
        variant="outlined"
      >
        <span className="text-semibold font-inter text-sm">color</span>
        <div className="flex gap-1 items-center">
          <span className="text-gray-500 text-sm">#49292</span>
          <div className="h-[22px] w-[22px] rounded-full bg-brownLight flex items-center justify-center">
            <FormatColorFillIcon sx={{ width: '12px', height: '12px' }} />
          </div>
        </div>
      </Button>
    </div>
  );
};

export default Background;
