import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { IconButton } from '@mui/material';

const AISlider = () => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerPadding: '60px',
    className: 'center',
  };
  const cards = [
    {
      id: 1,
      image: 'https://assets.pipio-avatar.net/assets/avatars/avery-bg.png',
    },
    {
      id: 2,
      image: 'https://assets.pipio-avatar.net/assets/avatars/isabella-bg.png',
    },
    {
      id: 3,
      image: 'https://assets.pipio-avatar.net/assets/avatars/devon-casual-bg.png',
    },
    {
      id: 4,
      image: 'https://assets.pipio-avatar.net/assets/avatars/lily-casual-bg.png',
    },
    {
      id: 5,
      image: 'https://assets.pipio-avatar.net/assets/avatars/avery-bg.png',
    },
    {
      id: 6,
      image: 'https://assets.pipio-avatar.net/assets/avatars/isabella-bg.png',
    },
    {
      id: 7,
      image: 'https://assets.pipio-avatar.net/assets/avatars/devon-casual-bg.png',
    },
    {
      id: 8,
      image: 'https://assets.pipio-avatar.net/assets/avatars/lily-casual-bg.png',
    },
  ];
  const next = () => {
    sliderRef?.current?.slickNext();
  };
  const previous = () => {
    sliderRef?.current.slickPrev();
  };
  return (
    <div className="mt-4 group">
      <div className="relative">
        <Slider {...settings} ref={sliderRef}>
          {cards.map((item, index) => {
            return (
              <div key={index} className="">
                <div key={item.id} className="rounded-lg h-20  flex items-center bg-grayLight mr-2">
                  <img
                    src={item.image}
                    className="w-full h-full object-cover bg-grayLight rounded-lg"
                    alt=""
                  />
                </div>
              </div>
            );
          })}
        </Slider>

        <IconButton
          sx={{ transform: 'translateY(-50%)' }}
          className="group-hover:block hidden  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={previous}
        >
          <ArrowBackRoundedIcon className="w-4 h-4" />
        </IconButton>

        <IconButton
          sx={{ transform: 'translateY(-50%)' }}
          className="group-hover:block hidden right-0  transition duration-700 cursor-pointer bg-white text-black text-xs border border-black h-9 w-9 absolute top-1/2 hover:bg-white"
          onClick={next}
        >
          <ArrowForwardRoundedIcon className="w-4 h-4" />
        </IconButton>
      </div>
    </div>
  );
};

export default AISlider;
