import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import React, { useContext, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { handleAddImage, handleAddVideo } from "../../canvas/utils/helper";
import ShowMessage from "../../components/ShowMessage/ShowMessage";
import { db } from "../../firebase";
import { useUserAuth } from "../../UserAuthContext";
const storage = getStorage();

const Uploader = ({ image = false }) => {
  const { activeCanvas } = useContext(CanvasStore);
  const { user } = useUserAuth();
  const fileTypes = ["mp4", "webm", "jpg", "jpeg", "png"];
  const [showError, setShowError] = useState(false);

  // Handles multiple file uploads in parallel
  // only supported for media files for now.
  async function processFiles(fileList) {
    const promises = [];

    for (const file of fileList) {
      promises.push(handleFileUpload(file));
    }

    await Promise.all(promises);
  }

  const handleFileUpload = async (file) => {
    const fname = file.name;
    const uid = user.uid;

    const storageRef = ref(storage, `${uid}/uploads/${fname}`);
    // console.log(storageRef);
    await uploadBytes(storageRef, file);

    const url = await getDownloadURL(storageRef);

    let type;
    if (file.type.startsWith("video/")) {
      handleAddVideo(url, file.name, activeCanvas);
      type = "video";
    } else if (file.type.startsWith("image/")) {
      handleAddImage(url, file.name, activeCanvas);
      type = "image";
    } else {
      alert("Please upload a video file.");
    }
    await addDoc(collection(db, "uploads"), {
      user: user.email,
      file_name: fname,
      timestamp: serverTimestamp(),
      type: type,
      url: url,
    });
  };

  const handleShowMessage = () => {
    setShowError(true);
  };

  return (
    <FileUploader
      handleChange={processFiles}
      multiple={true}
      name="file"
      types={fileTypes}
      maxSize={100}
      onSizeError={handleShowMessage}
    >
      <div className="cursor-pointer rounded-lg bg-black border border-dashed border-gray-300 text-center p-4 bg-gray-900 transition duration-300 ease-in-out hover:border-primary hover:hover:bg-primary/10">
        <div className="flex items-center justify-center flex-col ">
          <CloudUploadOutlinedIcon className=" text-[#71737c]" />
          <span className=" text-[13px] text-white my-1">Upload File(s)</span>
          <span className=" text-xs text-brownMedium ">
            Click to browse or drag & drop files
          </span>
        </div>
      </div>
      <ShowMessage
        showFileError={showError}
        setShowFileError={setShowError}
        severity="error"
        message="File size exceeds maximum limit (100MB)"
      />
    </FileUploader>
  );
};

export default Uploader;
