import { Helmet } from "react-helmet-async";
// @mui
import { Container, Typography } from "@mui/material";

import AIVoices from "../components/AIVoices/AIVoices";
import { useUserAuth } from "../UserAuthContext";

// ----------------------------------------------------------------------

export default function VoiceSamplesPage() {
  const { user } = useUserAuth();

  return (
    <>
      <Helmet>
        <title> Voice Samples | Acoust </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
          Voice Samples
        </Typography>

        <AIVoices
          languages={[{ language: "en-US", code: "en-US", order: "1" }]}
          selectedLanguage={"en-US"}
          user={user}
          mode="full"
        ></AIVoices>
      </Container>
    </>
  );
}
