import React, { useContext, useEffect, useState } from "react";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { withModifiedEvent } from "../../canvas/utils/helper";
import { Button } from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { formatTimeToMinSecMili } from "../../utils";

export default function AnimationStartEndControl({ object }) {
  const { activeCanvas } = useContext(CanvasStore);
  const [startTime, setStartTime] = useState("00:00:0");
  const [endTime, setEndTime] = useState("00:00:0");

  useEffect(() => {
    if (!object) return;
    let a = formatTimeToMinSecMili(object.animationStartTime * 1000);
    setStartTime(a);
    setEndTime(
      formatTimeToMinSecMili(
        (object.animationStartTime + object.endTime) * 1000
      )
    );
  }, [object]);

  const timeInSeconds = (time) => {
    const [minutes, seconds] = time.split(":").map(parseFloat);
    return minutes * 60 + seconds;
  };

  const handleStartTimeChange = (e) => {
    if (!object) return;
    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        let time = e.target.value;
        if (!isNaN(parseFloat(time))) {
          setStartTime(time);
          let totalSeconds = timeInSeconds(time);
          object.set({ animationStartTime: totalSeconds });
          activeCanvas.requestRenderAll();
          activeCanvas.fire("animate:prop:updated", { target: object });
        } else {
          console.error("Invalid start time format");
        }
      },
      ["animationStartTime"],
      "textAnimationStartTimeChange"
    )();
  };

  const handleEndTimeChange = (e) => {
    if (!object) return;
    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        let time = e.target.value;
        if (!isNaN(parseFloat(time))) {
          setEndTime(time);
          let totalSeconds = timeInSeconds(time);
          object.set({ endTime: totalSeconds });
          activeCanvas.requestRenderAll();
          activeCanvas.fire("animate:prop:updated", { target: object });
        } else {
          console.error("Invalid end time format");
        }
      },
      ["endTime"],
      "textEndTimeChange"
    )();
  };

  return (
    <div
      variant="white"
      className="rounded-[8px]  group text-[#18191b] transition duration-700 w-full p-4  hover:!border-primary flex justify-between"
      style={{
        border: "1px solid gray",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
      }}
    >
      <div
        className="flex justify-between gap-2 items-center"
        style={{ borderRight: "1px solid #e1e1e3" }}
      >
        <TimerOutlinedIcon className="text-gray-500 w-5 h-5" />
        <span className="text-sm text-gray-200">Start</span>
        <input
          type="text"
          value={startTime}
          onChange={handleStartTimeChange}
          className="text-white bg-transparent w-20 text-center outline-none border-none"
        />
      </div>
      <div className="flex justify-between gap-2 items-center">
        <span className="text-sm text-gray-200">End</span>
        <input
          type="text"
          value={endTime}
          onChange={handleEndTimeChange}
          className="text-white bg-transparent w-20 text-center outline-none border-none"
        />
      </div>
    </div>
  );
}
