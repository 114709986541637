import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
// components
import Iconify from "../../../components/iconify";
//
import AccountPopover from "./AccountPopover";
import { useEffect, useState } from "react";
// import { useUserAuth } from "../../../UserAuthContext";

import { db } from "../../../firebase.js";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  where,
  query,
} from "firebase/firestore";
// Removing notifications for now
//import NotificationsPopover from './NotificationsPopover';

// ----------------------------------------------------------------------

const NAV_WIDTH = 220;

const HEADER_MOBILE = 50;

const HEADER_DESKTOP = 50;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const Banner = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: "#4527A0", blur: 1 }),
  alignContent: "center",
  justifyContent: "center",
  minHeight: "45px",
  height: "45px",
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  //const navigate = useNavigate();
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerIcon, setBannerIcon] = useState("");

  const [messageExpiration, setMessageExpiration] = useState("");

  // const { isUserPremium } = useUserAuth();

  const getBannerMessage = async () => {
    const bannerDocRef = doc(db, "zadmin", "notifications");
    const bannerDoc = await getDoc(bannerDocRef);
    const message = bannerDoc.data().message;
    const icon = bannerDoc.data().icon;
    const expiry = new Date(bannerDoc.data().expiry.toDate()).getTime();
    setMessageExpiration(expiry);
    const dismissBannerValue = Number(
      window.localStorage.getItem("dismissBanner")
    );

    if (
      message &&
      (dismissBannerValue == null || expiry > dismissBannerValue)
    ) {
      setBannerIcon(icon);
      setBannerMessage(message);
    }
  };

  const handleHideBanner = () => {
    window.localStorage.setItem("dismissBanner", messageExpiration);
    setBannerMessage("");
  };

  useEffect(() => {
    //console.log(window.localStorage.getItem("dismissBanner"));
    //isUserPremium().then((isPrem) => {
    //  if (!isPrem) {
    //  }
    // });

    getBannerMessage();
  }, []);

  return (
    <StyledRoot>
      {bannerMessage && (
        <Banner>
          <Stack
            mt={0.5}
            direction="row"
            padding={2}
            sx={{ width: "100%" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack
              padding={1}
              direction="row"
              spacing={2}
              alignItems="flex-start"
            >
              <Typography variant="subtitle2">
                {bannerIcon || <Iconify icon="material-symbols:info-outline" />}
              </Typography>

              <Typography variant="subtitle2">{bannerMessage}</Typography>
            </Stack>

            <IconButton onClick={handleHideBanner}>
              <Iconify
                sx={{ color: "common.white" }}
                icon="material-symbols:close"
              />
            </IconButton>
          </Stack>
        </Banner>
      )}
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: "text.primary",
            display: { lg: "none" },
          }}
        >
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <AccountPopover />
        </Stack>
      </StyledToolbar>
    </StyledRoot>
  );
}
