import ObjectID from "bson-objectid";
import { fabric } from "fabric";
import { propertiesToInclude } from "../customFabric";
import { addNewArtboard } from "../customFabric/Page/Artboard";
import customEvents from "../events/customEvents";
import { cloneDeep } from "lodash";
import { getVideoElement } from "../../Animations/utils";

const PAGES_GAP = 100;
export const createNewPage = async (options, canvas) => {
  let newArtboard = addNewArtboard({
    ...options,
    _id: ObjectID().toHexString(),
    left: options.left + options.width + PAGES_GAP,
  });
  await newArtboard.addHeader();
  canvas.fire(customEvents.NEW_PAGE_CREATED, {
    target: newArtboard,
  });
  return newArtboard;
};

export const BACKGROUND_OBJECT_TYPE = "background";

export function getElementById(targetObject, id) {
  // searches for an element in the targetObject by id
  if (targetObject.id === id) {
    return targetObject;
  }
  if (!targetObject.children) {
    return null;
  }
  let objects = getObjects(targetObject);
  for (const child of objects) {
    const subTargetObject = getElementById(child, id);
    if (subTargetObject) return subTargetObject;
  }

  return null;
}

function getObjects(object) {
  return object._objects || object.objects || [];
}

export function getBlankPageOld({
  order,
  pageTitle = "Untitled",
  width = 1000,
  height = 1000,
  pageId,
}) {
  let id = ObjectID().toHexString();
  pageId = pageId || ObjectID().toHexString();
  return {
    pageId: pageId,
    file: pageId + ".json",
    title: pageTitle,
    json: {
      id: id,
      pageId: pageId,
      objects: [
        new fabric.Rect({
          width,
          height,
          fill: "#fff",
          objectType: BACKGROUND_OBJECT_TYPE,
          evented: false,
          lockMovementX: true,
          lockMovementY: true,
        }).toJSON(propertiesToInclude),
      ],
      order,
      title: pageTitle,
      height,
      width,
    },
    image: "",
  };
}

export function getBlankPage({
  order,
  pageTitle = "Untitled",
  backgroundColor = "#fff",
  width = 1000,
  height = 1000,
  pageId,
}) {
  let id = ObjectID().toHexString();
  pageId = pageId || ObjectID().toHexString();
  return {
    pageId: pageId,
    file: pageId + ".json",
    title: pageTitle,
    json: {
      id: id,
      pageId: pageId,
      objects: [
        {
          ...cloneDeep(defaultFrame),
          _id: ObjectID().toHexString(),
          width,
          height,
          fill: backgroundColor,
        },
      ],
      order,
      title: pageTitle,
      height,
      width,
    },
    image: "",
  };
}

export const defaultFrame = {
  type: "artboard",
  version: "4.6.0",
  originX: "left",
  originY: "top",
  left: 0,
  top: 0,
  width: 2000,
  height: 562,
  fill: "#FFFFFF",
  stroke: "#979797",
  strokeWidth: 0,
  strokeDashArray: null,
  strokeLineCap: "butt",
  strokeDashOffset: 0,
  strokeLineJoin: "miter",
  strokeUniform: true,
  strokeMiterLimit: 4,
  scaleX: 1,
  scaleY: 1,
  angle: 0,
  flipX: false,
  flipY: false,
  opacity: 1,
  shadow: null,
  visible: true,
  backgroundColor: "",
  fillRule: "nonzero",
  paintFirst: "fill",
  globalCompositeOperation: "source-over",
  skewX: 0,
  skewY: 0,
  _id: "623587d2f610e430c1e7122f",
  uniformScaling: false,
  evented: true,
  selectable: true,
  title: "Main Canvas",
  isClipPath: true,
  createdBy: "Me",
  timestamp: 1647675346,
  children: [],
  hidden: false,
};
export const objectDefaults = {
  artboard: {
    objectType: "artboard",
  },
  plus: {
    objectType: "plusIndicator",
    inActiveFill: "#E6E7EE",
    activeFill: "#000000",
  },
  section: {
    ingredient: {
      objectType: "ingredient-section-title",
    },
    tool: {
      objectType: "tool-section-title",
    },
    steps: {
      objectType: "steps-section-title",
    },
    instruction: {
      objectType: "instruction-section-title",
    },
  },
  headerIcon: {
    objectType: "headerIcon",
  },
  headerElement: {
    title: {
      objectType: "recipeTitle",
    },
    yield: {
      objectType: "recipeYield",
    },
    time: {
      objectType: "recipeTime",
    },

    objectType: "headerElement",
  },
  ingredient: {
    objectType: "ingredient",
  },
  simpleIngredient: {
    objectType: "simpleIngredient",
  },
  tool: {
    objectType: "tool",
  },
  steps: {
    objectType: "step-text",
  },
  stepCheckIcon: {
    objectType: "stepCheckMark",
  },

  instruction: {
    objectType: "bulletStep",
  },
  stepAddIcon: {
    objectType: "addStepBtn",
    stroke: "#0000ff",
    borderRadius: 5,
    textColor: "#0000ff",
    width: 100,
    height: 32,
  },
  ingredientRowAddIcon: {
    objectType: "addIngredientRowBtn",
    stroke: "#0000ff",
    borderRadius: 5,
    textColor: "#0000ff",
    width: 100,
    height: 32,
  },
  toolRowAddIcon: {
    objectType: "addToolRowBtn",
    stroke: "#0000ff",
    borderRadius: 5,
    textColor: "#0000ff",
    width: 100,
    height: 32,
  },
  pageAddIcon: {
    objectType: "addPageBtn",
    stroke: "#0000ff",
    borderRadius: 5,
    textColor: "#0000ff",
    width: 100,
    height: 32,
  },
};

export function getHeaderElementTypes() {
  let headerElement = objectDefaults.headerElement;
  return [
    headerElement.objectType,
    headerElement.title.objectType,
    headerElement.time.objectType,
    headerElement.yield.objectType,
  ];
}

/**
 * @description fires the object:modified event for undo/redo and saving to DB
 *
 * @param {fabric.Object} object
 * @param {fabric.Canvas} canvas
 * @param {function} cb - callback function
 * @param {string[]} keys - keys to compare with This helps determine if value has actually changed
 *
 * @returns {function}
 */
export function withModifiedEvent(
  object,
  canvas,
  cb,
  keys = [],
  action,
  original = {},
  force = false
) {
  if (original.current) original = original.current;

  return async function (opts, ...args) {
    if (!object.canvas) return cb(opts, ...args);

    const eventObject = {
      target: object,
      eventSource: "keyboard",
      e: {},
      action: opts?.action || action,
      transform: {
        original: {
          ...fabric.util.saveObjectTransform(object),
          ...original,
        },
      },
    };

    await cb(opts, ...args);

    let hasChanged = keys.find((key) => {
      return (
        JSON.stringify(eventObject.transform.original[key]) !==
        JSON.stringify(object[key])
      );
    });

    if (!!hasChanged || force) {
      canvas.fire("object:modified", eventObject);
    }
  };
}

export const handleAddAudio = (url, fileName, canvas) => {
  const audioInstance = new fabric.Audio({
    src: url,
    left: canvas.getObjects("artboard")[0].width / 2,
    top: canvas.getObjects("artboard")[0].height / 2,
    width: 0,
    height: 0,
    custom: {
      name: fileName,
      source: "upload",
    },
  });
  canvas.add(audioInstance);
  canvas.renderAll();
  audioInstance.once("audio:buffer:loaded", () => {
    audioInstance.endTime = audioInstance.duration;
    if (
      audioInstance.__parentArtboard &&
      audioInstance.__parentArtboard.durationType !== "fixed"
    ) {
      audioInstance.__parentArtboard.autoCalculateDuration();
    }
  });
};

export const handleAddVideo = (url, fileName, canvas) => {
  let artboard = canvas.getObjects("artboard")[0];
  getVideoElement(url).then((element) => {
    let start =
      artboard.getChildren().length > 0
        ? artboard.autoCalculateDuration() / 1000
        : 0;

    let video = new fabric.Video(element, {
      left: artboard.width / 2,
      top: artboard.height / 2,
      isVideo: true,
      videoSrc: url,
      duration: element.duration,
      animationStartTime: start,
      endTime: element.duration,
      custom: {
        source: "upload",
        name: fileName,
      },
    });
    video.name = fileName;
    canvas.add(video);

    if (artboard) {
      artboard.addChild(video);

      let videoAspect = video.width / video.height;
      let canvasAspect = artboard.width / artboard.height;

      let scale;
      if (canvasAspect > videoAspect) {
        // Canvas is wider than video
        scale = artboard.height / video.height;
      } else {
        // Canvas is taller or equal in aspect ratio to video
        scale = artboard.width / video.width;
      }

      video.scaleX = scale;
      video.scaleY = scale;
      canvas.setActiveObject(video);
      canvas.renderAll();
    }
  });
};

export const handleAddImage = (url, file, canvas) => {
  let artBoard = canvas.getObjects("artboard")[0];
  fabric.Image.fromURL(url, (img) => {
    img.scaleToWidth(artBoard.width);
    img.scaleToHeight(artBoard.height);
    img.set({
      custom: { name: file, source: "upload" },
    });
    if (artBoard) {
      artBoard.addChild(img);
    }
    canvas.add(img).setActiveObject(img).renderAll();
  });
};
