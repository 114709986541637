import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";

// import LogRocket from "logrocket";

// Analytics
import { clarity } from "clarity-js";
import mixpanel from "mixpanel-browser";
import { Crisp } from "crisp-sdk-web";

import { Provider } from "react-redux";

export const store = require("./store/configureStore").configure();
Crisp.configure("b88a55b8-f72d-478b-bdb7-68c0fbfbf39c");
// import Hotjar from "@hotjar/browser";
// hotjar variables
// const siteId = 3599107;
// const hotjarVersion = 6;

// clarity variables
const projID = "g6t9mjq29a";

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </HelmetProvider>
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// 3P tools
if (process.env.NODE_ENV === "production") {
  // LogRocket.init("9tfgyy/acoust");
  // Hotjar.init(siteId, hotjarVersion);
  clarity.start({
    projectId: projID,
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true,
  });
}
mixpanel.init("ecfe983721eed499531916415c34c945", {
  debug: false,
  track_pageview: true,
  persistence: "localStorage",
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
