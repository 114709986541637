import { Button } from "@mui/material";
import React from "react";

import { Translate, Watermark } from "../../components/ContentArea/ExportModal";

const Audio = () => {
  return (
    <div className="mt-8">
      <p className="text-white text-[13px] mb-1 font-semibold font-inter m-0">
        Audio
      </p>
      <Button
        variant="white"
        className="group text-white transition duration-700 w-full p-4 hover:bg-[#eff9ff] hover:!border-[#dbf0ff] flex justify-start"
        style={{
          border: "1px solid #e1e1e3",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
        }}
      >
        <div className="flex gap-2 w-full items-center">
          <div className="bg-primary/20 p-2 flex justify-center items-center rounded-md">
            <Translate color="stroke-primary" />
          </div>
          <div className="flex-1 flex justify-between items-center">
            <div className="flex justify-center items-start flex-col gap-0">
              <span className="text-white font-semibold font-inter text-sm">
                Translate Voice
              </span>
              <span className="text-[#8f9199] font-inter text-[11px]">
                Add voice translations in multi-languages
              </span>
            </div>
            <div className="bg-primary/90 text-white font-bold text-xs p-1 rounded-md font-gloria ">
              Beta
            </div>
          </div>
        </div>
      </Button>

      <Button
        variant="white"
        className="group mt-2 text-white transition duration-700 w-full p-4  hover:!border-gray flex justify-start"
        style={{
          border: "1px solid #e1e1e3",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 2px",
        }}
      >
        <div className="flex gap-2 w-full items-center">
          <div className="bg-primary/20 p-2 flex justify-center items-center rounded-md">
            <CleanAudio />
          </div>
          <div className="flex-1 flex justify-between items-center">
            <div className="flex justify-center items-start flex-col gap-0">
              <span className="text-white font-semibold font-inter text-sm">
                Clean Audio
              </span>
              <span className="text-[#8f9199] font-inter text-[11px]">
                Remove background noise
              </span>
            </div>
            <p className="flex items-center gap-2 px-2 py-1.5 bg-[#ffeaca] rounded-md text-[13px] m-0 font-medium font-inter text-white">
              <div className="bg-orangeMedium w-4 h-4 flex justify-center items-center rounded-sm">
                <Watermark />
              </div>
            </p>
          </div>
        </div>
      </Button>
    </div>
  );
};

export default Audio;

const CleanAudio = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#0098FD"
    >
      <path
        d="M11.7674 2.63388C11.8507 2.42284 12.1493 2.42284 12.2326 2.63388L12.912 2.36591L12.2326 2.63388L14.5371 8.47702C14.7149 8.92804 15.072 9.28505 15.523 9.46293L21.3661 11.7674C21.5771 11.8507 21.5771 12.1493 21.3661 12.2326L15.523 14.5371C15.072 14.7149 14.7149 15.072 14.5371 15.523L12.2326 21.3661C12.1493 21.5771 11.8507 21.5771 11.7674 21.3661L9.46293 15.523C9.28505 15.072 8.92804 14.7149 8.47702 14.5371L2.63388 12.2326L2.36522 12.9137L2.63388 12.2326C2.42284 12.1493 2.42284 11.8507 2.63388 11.7674L2.36591 11.088L2.63388 11.7674L8.47702 9.46293C8.92804 9.28505 9.28505 8.92804 9.46293 8.47702L11.7674 2.63388L11.0862 2.36522L11.7674 2.63388Z"
        stroke="currentColor"
      ></path>
    </svg>
  );
};
