import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Divider,
  Typography,
} from "@mui/material";

import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import ShowMessage from "../ShowMessage/ShowMessage.js";
import Iconify from "../iconify/Iconify.js";

export default function ManageSSO(props) {
  const manageSsoFunction = httpsCallable(functions, "managesso");

  // State to store form data
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState(false);

  useEffect(() => {
    const loadSso = async () => {
      // console.log(props.tenantProvider);
      try {
        const tenantConfig = await manageSsoFunction({
          tenantId: props.tenantId,
          tenantProvider: props.tenantProvider,
        });

        if (tenantConfig.data) {
          setFormData(tenantConfig.data.tenantConfig);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      // console.log(data);
    };
    loadSso();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    // console.log(name);
    // console.log(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formData);

    // Call the manageSso function to update the tenant config

    await manageSsoFunction({
      tenantId: props.tenantId,
      tenantProvider: props.tenantProvider,
      clientId: formData.clientId,
      clientSecret: formData.clientSecret,
      issuer: formData.issuer,
    });

    // console.log(tenantConfig);

    setShowMessage(true);
    setMessage("Updated SSO configuration");
  };

  if (loading) {
    return (
      <Stack mt={2}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} mt={4} maxWidth={600}>
          <TextField
            label="Client ID"
            name="clientId"
            value={formData.clientId || ""}
            onChange={handleChange}
            fullWidth
          />

          <TextField
            label="Client Secret"
            name="clientSecret"
            value={formData.clientSecret || ""}
            onChange={handleChange}
            fullWidth
          />

          {/* Display Name */}
          <TextField
            label="Issuer (URL)"
            name="issuer"
            value={formData.issuer || ""}
            onChange={handleChange}
            fullWidth
            helperText="For example: https://login.microsoftonline.com/{APP_ID}/v2.0/"
          />

          {/* Submit Button */}
          <Button
            type="submit"
            disabled={
              !formData.clientId || !formData.clientSecret || !formData.issuer
            }
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Stack>
      </form>

      <Stack mt={4} spacing={2} maxWidth={600}>
        <Divider></Divider>
        <Typography variant="caption" color="textSecondary">
          Copy the following redirect URL information to your IdP
        </Typography>
        <Stack direction="row">
          <TextField
            variant="filled"
            label="Callback URL"
            value="https://auth.acoust.io/__/auth/handler"
            fullWidth
          />
          <IconButton
            variant="outlined"
            color="primary"
            onClick={() => {
              navigator.clipboard.writeText(
                "https://auth.acoust.io/__/auth/handler"
              );
            }}
          >
            <Iconify icon="oui:copy-clipboard" />
          </IconButton>
        </Stack>
      </Stack>
      <ShowMessage
        showFileError={showMessage}
        setShowFileError={setShowMessage}
        message={message}
      />
    </>
  );
}
