import { propertiesToInclude } from "../customFabric";
import { fabric } from "fabric";
export function copyObject(target) {
  let targetJSON = target.toObject(propertiesToInclude);
  navigator.clipboard.writeText(
    JSON.stringify({ targetFabObject: [targetJSON] })
  );
}
export async function pasteObject(canvas) {
  let parsedJson = JSON.parse(await navigator.clipboard.readText());
  let copiedObjectsList = parsedJson.targetFabObject;
  if (copiedObjectsList && copiedObjectsList.length) {
    fabric.util.enlivenObjects(copiedObjectsList, function (objects) {
      canvas.add(...objects);
      let artboard=canvas.getObjects('artboard')[0]
      objects.forEach((o) => {
        artboard.positionChildToCenter(o);
      });
      canvas.renderAll();
    });
  }
}

