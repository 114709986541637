import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import React from "react";
import { useNavigation, useNavigate } from "react-router";

const PageHeader = ({
  heading,
  onClicked,
  subHeading,
  showBackButton = false,
}) => {
  const router = useNavigate();
  return (
    <div className="py-6">
      <p className="text-lg text-white tracking-wide leading-6  flex items-center gap-3  m-0 shrink-1 whitespace-nowrap overflow-hidden text-ellipsis font-inter font-semibold">
        {showBackButton && (
          <ArrowBackIosRoundedIcon
            className="hover:text-primary w-4 h-4 cursor-pointer"
            onClick={() => onClicked()}
          />
        )}
        {heading}
      </p>
      {subHeading && (
        <div>
          <h3 className={"text-xs text-brownMedium mt-1  "}>{subHeading}</h3>
        </div>
      )}
    </div>
  );
};

export default PageHeader;
