import { Helmet } from "react-helmet-async";
import { useState, useEffect, useRef } from "react";

import "@fontsource/roboto/400.css";

// import SsmlEditor from "../components/SsmlEditor/SsmlEditor"
import ButtonWithMenu from "../components/ButtonWithMenu/ButtonWithMenu";
import SpeechOptions from "../components/SpeechOptions/SpeechOptions";

import ChatGPT from "../components/ChatGPT/ChatGPT";
//import FetchUrl from "../components/FetchUrl/FetchUrl"
import ShowMessage from "../components/ShowMessage/ShowMessage";
import AudioPlayer from "../components/AudioPlayer/AudioPlayer";

import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import ReactAudioPlayer from "react-audio-player";

// @mui
import {
  Box,
  Button,
  Chip,
  Container,
  CircularProgress,
  FormLabel,
  IconButton,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import LoadingButton from "@mui/lab/LoadingButton";

import { db, analytics, logEvent } from "../firebase.js";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  where,
  query,
  serverTimestamp,
  addDoc,
  setDoc,
} from "firebase/firestore";

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../UserAuthContext";
import { useNavigate } from "react-router-dom";

// components
import Iconify from "../components/iconify";

const storage = getStorage();

export default function ConvertPage() {
  const { user, isLoading } = useUserAuth();
  const [isContentLoading, setIsContentLoading] = useState(false);

  const [isOverAIQuota, setIsOverAIQuota] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disableDownload, setDisableDownload] = useState(true);

  const [inputText, setInputText] = useState("");
  // const [audioUrl, setAudioUrl] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [docId, setDocId] = useState("");

  // Language and voice options
  const [voices, setVoices] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [bgTracks, setBgTracks] = useState([]);
  const [voiceEmotions, setVoiceEmotions] = useState([]);

  // user preferences for conversion
  const [inputType, setInputType] = useState("ssml");
  const [showAI, setShowAI] = useState(false);
  const [showFetch, setShowFetch] = useState(false);

  const [selectedLang, setSelectedLanguage] = useState("");
  const [selectedVoice, setSelectedVoice] = useState("");
  const [selectedVoiceCloud, setSelectedVoiceCloud] = useState("");
  const [speed, setSpeed] = useState(1);
  const [pitch, setPitch] = useState(0);
  const [sayAsValue, setSayAsValue] = useState("");
  const [selectedMusic, setSelectedMusic] = useState("");
  const [selectedEmotion, setSelectedEmotion] = useState("");

  // Other options
  const [showError, setShowError] = useState(false);
  const [showAnnounce, setshowAnnounce] = useState(true);

  // Editor options
  const pauseOptions = ["1s", "2s", "5s"];
  const emphasisOptions = ["strong", "moderate", "reduced"];
  const speedOptions = [0.5, 0.8, 1, 1.25, 1.5, 1.75, 2.0];
  const pitchOptions = ["Low", "Medium", "High"];

  const musicOptions = ["Chill", "Corp"];

  // user quota
  const navigate = useNavigate();

  const [selectedText, setSelectedText] = useState("");

  const textRef = useRef();

  const handleEditor = (e) => {
    if (e.target.value === "date" && selectedText !== "") {
      setInputText(
        inputText.replace(
          selectedText,
          `<say-as interpret-as='date'>${selectedText}</say-as>`
        )
      );
      setSelectedText("");
      setSayAsValue("");
    }
  };

  // Loads the languages available for selection
  useEffect(() => {
    async function loadLanguages() {
      const q = query(
        collection(db, "languages"),
        orderBy("order"),
        orderBy("language")
      );
      const querySnapshot = await getDocs(q);
      setLanguages(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
      setSelectedLanguage(querySnapshot.docs[0].data().code);
    }
    loadLanguages();

    async function loadBgTracks() {
      const q = query(collection(db, "bgtracks"), orderBy("display"));
      const querySnapshot = await getDocs(q);
      setBgTracks(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
    }
    loadBgTracks();

    async function checkQuota() {
      const docRef = doc(db, "customers", user.uid);
      const docSnap = await getDoc(docRef);
      if (!docSnap.empty) {
        const userData = docSnap.data();
        if (userData.usage >= userData.quota) {
          let path = `/billing`;
          navigate(path, { state: { overquota: true } });
        }
      }
    }
    checkQuota();
  }, []);

  // Loads the voices based on selected Language
  useEffect(() => {
    if (!selectedLang.length) {
      return;
    }
    async function loadVoices() {
      const q = query(
        collection(db, "voices"),
        where("code", "==", selectedLang),
        orderBy("order"),
        orderBy("display_name")
      );
      const querySnapshot = await getDocs(q);
      setVoices(querySnapshot.docs.map((doc) => ({ ...doc.data() })));
      setSelectedVoice(querySnapshot.docs[0].data().voice);
      setSelectedVoiceCloud(querySnapshot.docs[0].data().cloud);
      if (querySnapshot.docs[0].data().emotions) {
        setVoiceEmotions(querySnapshot.docs[0].data().emotions.sort());
      } else {
        setVoiceEmotions([]);
        setSelectedEmotion("");
      }
    }
    loadVoices();
  }, [selectedLang]);

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "customers", user.uid), (doc) => {
      const userData = doc.data();
      if (userData.ai_usage >= userData.ai_quota) {
        setIsOverAIQuota(true);
      } else {
        setIsOverAIQuota(false);
      }
    });
  });

  /* 
  When a new text is submitted, wait for the synthesize cloud function to
  update the document. 
  */
  useEffect(() => {
    if (docId) {
      const unsubscribe = onSnapshot(doc(db, "texts", docId), (doc) => {
        // console.log("Current data: ", doc.data());
        let gsUrl = doc.data().audio_url;
        if (gsUrl) {
          // console.log(`gs url: ${gsUrl}`);

          getDownloadURL(ref(storage, gsUrl)).then((aUrl) => {
            setAudioUrl(aUrl);
            setDisableDownload(false);
            setProcessing(false);
            unsubscribe();
            setDocId("");
          });
        }
      });
    }
  }, [docId]);

  useEffect(() => {
    const ssmlSample = `\
To get started, replace this text with your content or use our AI writer (powered by ChatGPT) to supercharge your creativity.`;
    setInputText(ssmlSample);
  }, [inputType]);

  const ssmlAzure = () => {
    var ssmlText = `<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xmlns:mstts="https://www.w3.org/2001/mstts" xml:lang="en-US">`;
    if (selectedMusic) {
      ssmlText += `<mstts:backgroundaudio src="${selectedMusic}" volume="0.7" fadein="1000" fadeout="4000"/>`;
    }
    ssmlText += `<voice name="${selectedVoice}">`;
    if (pitch || speed) {
      ssmlText += `<prosody pitch="${
        pitch === 0 ? "default" : pitch
      }" rate="${speed}">`;
    }
    if (selectedEmotion) {
      ssmlText += `<mstts:express-as style="${selectedEmotion}" styledegree="2">
        ${inputText}
      </mstts:express-as>`;
    } else {
      ssmlText += inputText;
    }
    if (pitch || speed) {
      ssmlText += `</prosody>`;
    }
    ssmlText += "</voice></speak>";
    // console.log(ssmlText)
    return ssmlText;
  };

  const sendTextAzure = async () => {
    //console.log("calling azure")
    const synthesizeAzure = httpsCallable(functions, "synAzure");
    try {
      const ssmlText = ssmlAzure();
      const result = await synthesizeAzure({ ssml: ssmlText });
      let gsUrl = result.data.gcsUrl;
      let audioUrl = await getDownloadURL(ref(storage, gsUrl));
      setAudioUrl(audioUrl);
      setProcessing(false);
    } catch (error) {
      console.log(error);
      setShowError(true);
      setProcessing(false);
    }
  };

  const sendTextGoogle = async () => {
    // only submit if text has been entered
    if (!inputText.length) return;
    // console.log("Sending text for processing");

    // Add <speak tags for ssml>
    let ssmlText;

    if (selectedMusic) {
      ssmlText = `<speak><par> \ 
<media xml:id="speech" begin="0.0s"> \
<speak>${inputText}</speak> \ 
</media> \
<media begin="0.0s" end="speech.end" fadeOutDur="0.2s" soundLevel="-6dB"> \
<audio \
src="${selectedMusic}" /> \
</media></par></speak>`;
    } else {
      ssmlText = `<speak>${inputText}</speak>`;
    }

    // console.log(ssmlText)

    // Save the customer text into firestore which is then processed
    // using cloud function
    await addDoc(collection(db, "texts"), {
      customer_text: ssmlText,
      timestamp: serverTimestamp(),
      speed: speed,
      pitch: pitch,
      language: selectedLang,
      voice: selectedVoice,
      user: user.email,
      input_type: inputType,
    }).then((docRef) => {
      const ddoc = docRef.id;
      console.log(ddoc);
      setDocId(docRef.id);
    });
  };

  const handleAIButton = () => {
    setShowAI(!showAI);
  };

  const handleFetchButton = () => {
    setShowFetch(!showFetch);
  };

  const handleSpeedChange = (newValue) => {
    setSpeed(newValue);
  };

  const handlePitchChange = (newValue) => {
    setPitch(newValue.toLowerCase());
  };

  const handleTextChange = (e) => {
    setInputText(e.target.value);
  };

  const handleVoiceChange = (e) => {
    var voice = e.target.value;
    const voiceObject = Array.from(voices.values()).find(
      (obj) => obj.voice === voice
    );
    setSelectedVoice(voice);
    setSelectedVoiceCloud(voiceObject.cloud);
    if (voiceObject.emotions) {
      setVoiceEmotions(voiceObject.emotions.sort());
    } else {
      setVoiceEmotions([]);
      setSelectedEmotion("");
    }
  };

  const playPreview = (event, previewUrl) => {
    event.stopPropagation();
    setAudioUrl(previewUrl);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Convert | Acoust AI</title>
      </Helmet>
      <Container>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-start"
        >
          <Stack direction="row" alignItems="flex-end">
            <ButtonWithMenu
              labelText="Insert Pause"
              options={pauseOptions}
              inputText={inputText}
              updateText={setInputText}
              icon="material-symbols:auto-read-pause-outline"
              tip="Add pause at the current cursor position"
            />

            <ButtonWithMenu
              labelText="Emphasis"
              options={emphasisOptions}
              inputText={inputText}
              updateText={setInputText}
              icon="game-icons:speaker"
              tip="Emphasize text"
            />
          </Stack>
          <Stack
            spacing={1}
            direction="row"
            alignItems="baseline"
            justifyContent="flex-start"
          >
            <Tooltip title="Generate content using AI" placement="top">
              <Button
                size="small"
                variant="outlined"
                onClick={handleAIButton}
                startIcon={<Iconify icon="fluent-emoji-flat:robot" />}
              >
                AI Writer
              </Button>
            </Tooltip>
          </Stack>
          <Box
            display="flex"
            flexGrow={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography sx={{ color: "text.secondary", fontSize: "8px" }}>
              VOICE SETTINGS:
            </Typography>
            <SpeechOptions
              labelText="Speed"
              options={speedOptions}
              handleSpeedChange={handleSpeedChange}
              icon="ic:round-speed"
              tip="Change the speed of the generated speech"
            />

            <SpeechOptions
              labelText="Pitch"
              options={pitchOptions}
              handlePitchChange={handlePitchChange}
              icon="ri:sound-module-fill"
              tip="Change the pitch of the generated speech"
            />
          </Box>
          {/*<Box>
            <UploadFile
              setInputText={setInputText}
              setIsContentLoading={setIsContentLoading}
              user={user.email}
        />
          </Box>*/}
        </Stack>
        <Stack mt={1} sx={{ maxWidth: "750px" }}>
          {showAI && (
            <ChatGPT
              updateText={setInputText}
              isContentLoading={isContentLoading}
              setIsContentLoading={setIsContentLoading}
              isOverAIQuota={isOverAIQuota}
            />
          )}
        </Stack>

        <Stack
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            id="ssml-editor"
            multiline
            fullWidth
            rows={10}
            value={inputText}
            inputRef={textRef}
            inputProps={{
              maxLength: 3000,
              style: { fontSize: 14, fontFamily: "Roboto" },
            }}
            helperText={`${inputText.length}/3000`}
            error={inputText.length >= 3000}
            onChange={handleTextChange}
            InputProps={{
              startAdornment: isContentLoading ? (
                <Box sx={{ position: "absolute", left: 8, top: 8 }}>
                  <CircularProgress size={20} />{" "}
                  {/* Show the CircularProgress loading icon */}
                </Box>
              ) : null,
            }}
          />
        </Stack>
        <Stack
          sx={{ maxWidth: "750px" }}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          mt={2}
        >
          <Stack>
            <FormControl variant="standard" sx={{}}>
              <InputLabel sx={{ fontSize: "12px" }} id="voice-label">
                Voice
              </InputLabel>
              <Select
                sx={{ fontSize: "12px" }}
                labelId="voice-select-label"
                id="voice-select"
                label="Voice"
                value={selectedVoice}
                onChange={handleVoiceChange}
              >
                {voices.map((voice) => (
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    key={voice.voice}
                    value={voice.voice}
                    cloud={voice.cloud}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {voice.display_name} &nbsp;
                      <Typography style={{ fontSize: "10px" }} color="gray">
                        {voice.gender}
                      </Typography>
                      &nbsp;&nbsp;
                      {voice.emotions && (
                        <Chip
                          variant="filled"
                          color="primary"
                          size="small"
                          sx={{
                            backgroundColor:
                              voice.quality === "Premium+"
                                ? "#FFAB91"
                                : "#FFCC80", // replace with your custom color
                            color: "#424242",
                            borderRadius: 0.5,
                            height: "12px",
                            fontSize: "8px",
                          }}
                          label={
                            <strong>{voice.emotions.length} emotions</strong>
                          }
                        />
                      )}
                      {voice.preview && (
                        <Tooltip title="Preview" placement="right">
                          <IconButton
                            size="small"
                            color="primary"
                            sx={{
                              color: "gray",
                            }}
                            onClick={(e) => playPreview(e, voice.preview)}
                          >
                            <Iconify icon="bi:play-btn" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {voiceEmotions.length > 0 && (
              <FormControl variant="standard" sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontSize: "10px" }} id="emotion-label">
                  Voice Emotion
                </InputLabel>
                <Select
                  sx={{
                    fontSize: "10px",
                    paddingTop: 0,
                  }}
                  labelId="emotion-select-label"
                  value={selectedEmotion}
                  id="emotion-select"
                  label="Emotion"
                  disabled={voiceEmotions.length === 0}
                  onChange={(e) => setSelectedEmotion(e.target.value)}
                >
                  <MenuItem sx={{ fontSize: "12px" }} value="">
                    Default
                  </MenuItem>
                  {voiceEmotions.map((emotion) => (
                    <MenuItem
                      sx={{ fontSize: "12px" }}
                      key={emotion}
                      value={emotion}
                    >
                      {emotion}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
          <Stack>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 80 }}>
              <InputLabel sx={{ fontSize: "12px" }} id="language-input-label">
                Language
              </InputLabel>
              <Select
                sx={{ fontSize: "12px" }}
                labelId="langugage-select-label"
                id="language-select"
                label="Language"
                value={selectedLang}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                {languages.map((language) => (
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    key={language.language}
                    value={language.code}
                  >
                    {language.language}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="standard" sx={{ minWidth: 120 }}>
              <InputLabel sx={{ fontSize: "10px" }} id="bgmusic-input-label">
                Background Music
              </InputLabel>
              <Select
                sx={{
                  fontSize: "10px",
                  paddingTop: 0,
                }}
                labelId="bgmusic-select-label"
                value={selectedMusic}
                id="bgmusic-select"
                label="Background Music"
                onChange={(e) => setSelectedMusic(e.target.value)}
              >
                <MenuItem sx={{ fontSize: "12px" }} value="">
                  None
                </MenuItem>
                {bgTracks.map((track) => (
                  <MenuItem
                    sx={{ fontSize: "12px" }}
                    key={track.display}
                    value={track.track_url}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {track.display} (
                      <Typography style={{ fontSize: "10px" }} color="gray">
                        {track.time}
                      </Typography>
                      )
                      <Tooltip title="Preview" placement="right">
                        <IconButton
                          size="small"
                          color="primary"
                          sx={{
                            color: "gray",
                          }}
                          onClick={(e) => playPreview(e, track.track_url)}
                        >
                          <Iconify icon="bi:play-btn" />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>

        {/* Synthesize button */}
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <LoadingButton
            loading={processing}
            variant="contained"
            color="primary"
            disabled={processing}
            startIcon={<Iconify icon="eva:activity-fill" />}
            onClick={processRequest}
          >
            Synthesize
          </LoadingButton>
          {audioUrl && (
            <ReactAudioPlayer
              src={audioUrl}
              preload="none"
              controls
              autoPlay
              controlslist="nodownload"
            />
          )}
        </Stack>

        <ShowMessage
          showFileError={showError}
          setShowFileError={setShowError}
          severity="error"
          message="Something went wrong. Please try again or try refeshing"
        />
        <ShowMessage
          showFileError={showAnnounce}
          setShowFileError={setshowAnnounce}
          severity="info"
          message="🚀 Try our newly launched Video Creator"
          link="/video"
        />
      </Container>
    </>
  );

  function processRequest() {
    logEvent(analytics, "button_click", {
      buttonName: "Synthesize",
      chars: inputText.length,
    });

    setProcessing(true);

    if (selectedVoiceCloud == "azure") {
      sendTextAzure();
    } else {
      sendTextGoogle();
    }
  }
}
