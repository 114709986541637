import React, { useState } from "react";

import PageHeader from "../shared/PageHeader";

import ActionButtons from "./component/ActionButtons";
import AIAvatars from "./component/AIAvatars";
import Uploader from "./component/FileUploader";
import StockVideos from "./component/StockVideos";
import UserVideoFiles from "./component/UserVideoFiles";

import { Stack, Tabs, Tab } from "@mui/material";

const Media = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Stack>
        <PageHeader heading="Add Media" />
        <Uploader />
        {/*<ActionButtons />
        <AIAvatars />*/}

        <Stack mt={4}>
          <Tabs
            textColor="primary"
            variant="fullWidth"
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Stock"></Tab>
            <Tab label="Uploaded"></Tab>
          </Tabs>
          {activeTab === 0 && <StockVideos />}
          {activeTab === 1 && <UserVideoFiles />}
        </Stack>
      </Stack>
    </div>
  );
};

export default Media;
