import {fabric} from 'fabric';
import {debounce} from "lodash";

export function canvasPanZoom(canvas) {
    enableCanvasPanning(canvas)
    enableCanvasZoom(canvas)
}

 function enableCanvasZoom(canvas) {
    if (!canvas) return;

    // Zoom in and out of the canvas
    canvas.on('mouse:wheel', handleMouseWheel);
    /**
     * This functions handles canvas zoom and pan
     * if control or command key are pressed it will zoom
     * if not it will pan (scroll)
     *
     */
    function handleMouseWheel({ e }) {
        e.preventDefault();
        e.stopPropagation();


        const { ctrlKey, metaKey, shiftKey, wheelDeltaX, wheelDeltaY, altKey, deltaY, offsetX, offsetY } = e;

        // scroll the canvas
        var zoom = canvas.getZoom();

        if (!ctrlKey && !metaKey) {

            canvasZoomScrollStart(canvas, 'scroll:start');
            let OFFSET = 0.909 / 1.5;
            if (shiftKey) OFFSET = 0.1;
            let x = wheelDeltaX * OFFSET;
            let y = wheelDeltaY * OFFSET;

            // For mice without horizontal scroll
            if (altKey) {
                x = wheelDeltaY * OFFSET;
                y = wheelDeltaX * OFFSET;
            }

            // fire scrolling event
            canvas.fire('scrolling', { canvas });

            canvas.relativePan({ x, y });

            // trigger the scroll end event
            canvasScrollZoomEnd(canvas, 'scroll:end');

        } else {
            // or zoom the canvas
            canvasZoomScrollStart(canvas, 'zoom:start');

            const delta = deltaY;
            let zoomDelta = 1.005;
            if (altKey || shiftKey) {
                zoomDelta = 1.001;
            }

            zoom /= zoomDelta ** delta;
            if (zoom > fabric.Canvas.prototype.MAX_ZOOM_LEVEL) zoom = fabric.Canvas.prototype.MAX_ZOOM_LEVEL;
            if (zoom < fabric.Canvas.prototype.MIN_ZOOM_LEVEL) zoom = fabric.Canvas.prototype.MIN_ZOOM_LEVEL;

            canvas.zoomToPoint({ x: offsetX, y: offsetY }, zoom);

            e.preventDefault();
            e.stopPropagation();

            // fire zooming event
            canvas.fire('zooming', { canvas });


            // trigger the zoom end event
            canvasScrollZoomEnd(canvas, 'zoom:end');
        }

        return false;
    }


}


 function enableCanvasPanning(canvas) {

    // handle panning
    canvas.on('mouse:down', handleMouseDown);
    canvas.on('mouse:move', handleMouseMove);
    canvas.on('mouse:up', handleMouseUp);


    // Pen tool events END
    // =============================


    /**
     * Starts the panning mode, and sets the point of click
     * to calculate the delta in mouse move function
     *
     */
    function handleMouseDown(opt) {

        let { e } = opt;

        if (e.button === 2) {
            const pointer = canvas.getPointer(e, false);
            canvas.__lastRightClickX = pointer.x;
            canvas.__lastRightClickY = pointer.y;
        }

        if (canvas.__pan || e.button === 1) {
            canvas.enterCanvasPanMode();

            canvas.setCursor('grabbing', true);

            canvas.__panning = true;
            canvas.__lastClientX = e.clientX;
            canvas.__lastClientY = e.clientY;

            return;
        }


    }

    /**
     * Pans the canvas around by calculating the delta
     * from the __lastClientX and __lastClientY set in handleMouseDown
     *
     */
    function handleMouseMove({ e }) {
        let deltaX = 0;
        let deltaY = 0;


        if (canvas.__panning) {
            canvas.__panned = true;

            // Calculate deltas
            if (canvas.__lastClientX) {
                deltaX = e.clientX - canvas.__lastClientX;
            }
            if (canvas.__lastClientY) {
                deltaY = e.clientY - canvas.__lastClientY;
            }
            // Update the last X and Y values
            canvas.__lastClientX = e.clientX;
            canvas.__lastClientY = e.clientY;

            canvas.relativePan({ x: deltaX, y: deltaY });
            canvas.setCursor('grabbing', true);
            canvas.fire('scrolling', { canvas });
        }
        else if(canvas.__pan){
            canvas.setCursor('grab', true);
        }
    }

    /**
     * Disables the pan mode and unset all data set
     * when the pas started
     *
     */
    async function handleMouseUp({  e }) {

        if (canvas.__pan) {
            canvas.setCursor('grab');
        }

        if (canvas.__panning)
            canvas.fire('scroll:end', { canvas });

        if (e.button === 1 && canvas.__panning) {
            canvas.__pan = false;
            canvas.__panning = false;
            canvas.exitCanvasPanMode();
            canvas.setCursor('select', true);

        }

        canvas.__panning = false;

        delete canvas.__lastClientX;
        delete canvas.__lastClientY;
    }
}

const canvasZoomScrollStart = debounce((canvas, type) => {
    // To improve performance turn the following features off
    if (canvas.selection && !canvas.staticCanvas) canvas.selection = false;

    // Fire the event for the canvas to be used elsewhere
    canvas.fire(`${type}`, { canvas });
},200 );

const canvasScrollZoomEnd = debounce((canvas, type) => {
    // turn this back on if it's not on
    if (!canvas.selection && !canvas.staticCanvas && !canvas.__pan && !canvas.__panning) canvas.selection = true;

    // Fire the event for the canvas to be used elsewhere
    canvas.fire(`${type}`, { canvas });
},200);
