import { Helmet } from "react-helmet-async";
// @mui

import { Container } from "@mui/material";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../UserAuthContext";
import CircularProgress from "@mui/material/CircularProgress";

// ----------------------------------------------------------------------

export default function VerifyEmailPage() {
  const { user, verifyEmail } = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    verifyEmail();
  }, []);

  useEffect(() => {
    const plan = window.localStorage.getItem("plan");

    const routeChange = () => {
      let path = "/";

      let isNewUser = window.localStorage.getItem("isNewUser");

      if (isNewUser) {
        window.localStorage.removeItem("isNewUser");
      }

      if (plan) {
        path = `/subscribe?plan=${plan}`;
      }
      navigate(path);
    };

    if (user) {
      routeChange();
    }
  }, [user, navigate]);

  return (
    <>
      <Helmet>
        <title> Verify Email | Acoust </title>
      </Helmet>
      <Container>
        <CircularProgress />
      </Container>
    </>
  );
}
