import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Icons } from "../../utils/Icons";
import { Box, Tooltip } from "@mui/material";

import SingleNavItem from "./SingleNavItem";
import { useNavigate } from "react-router";

const Navbar = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const menuData = [
    {
      label: "media",
      icon: "carbon:media-library",
      link: `/v/${id}/media`,
    },
    {
      label: "audio",
      icon: "gridicons:audio",
      link: `/v/${id}/audio`,
    },
    /* {
      label: "subtitles",
      icon: "navSubtitles",
      link: `/v/${id}/subtitles`,
    },*/
    {
      label: "text",
      icon: "icon-park-solid:text",
      link: `/v/${id}/text`,
    },
    {
      label: "settings",
      icon: "material-symbols:settings",
      link: `/v/${id}/settings`,
    },
    // {
    //   label: 'elements',
    //   icon: 'navElement',
    //   link: '/elements',
    // },
    // {
    //   label: 'record',
    //   icon: 'navRecord',
    //   link: '/record',
    // },
  ];

  const { pathname } = useLocation();
  // console.log('pathname', pathname)

  const handleMenuClick = () => {
    navigate("/");
  };

  return (
    <nav className="bg-black border-solid border-t-0 border-l-0 border-b-0 border-r border-zinc-900 flex flex-col shrink-0 h-full w-20 overflow-hidden py-4">
      <div className="flex justify-center">
        <Tooltip title="Go Home">
          <Box
            component="img"
            src="/shortlogo.png"
            sx={{ width: 40, height: 40, cursor: "pointer" }}
            onClick={handleMenuClick}
          ></Box>
        </Tooltip>
      </div>
      {/*<div className="flex justify-center">*/}
      {/*  <div*/}
      {/*    className={`py-0 px-6 relative box-sizing cursor-pointer flex justify-center items-center flex-col user-select-none`}*/}
      {/*  >*/}
      {/*    <Icons iconName="navSearch" className="mt-3" />*/}
      {/*    <span className="text-xs">Search</span>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="flex flex-col mt-4">
        {menuData.map((item) => {
          return (
            <SingleNavItem
              key={item.label}
              item={item}
              active={pathname.includes(item.link)}
            />
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
