import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Link,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { useUserAuth } from "../../../UserAuthContext";

// ----------------------------------------------------------------------

export default function LoginForm(props) {
  const navigate = useNavigate();
  const { signInEmailPassword, sendForgotEmailPassword, signInSSO } =
    useUserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);

  const [showForgotPasswordMessage, setShowForgotPasswordMessage] =
    useState(false);

  const isValidEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (!isValidEmail()) {
      setShowEmailError(true);
      setLoading(false);
      return;
    }

    if (props.mode === "sso") {
      signInSSO(email).catch((error) => {
        console.log(error);
        setShowError(true);
        setLoading(false);
      });
    } else {
      signInEmailPassword(email, password).catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        setShowError(true);
        setLoading(false);
      });
    }
  };

  const handleForgotPassword = () => {
    if (!email) {
      setShowEmailError(true);
      return;
    }
    setShowForgotPasswordMessage(true);
    sendForgotEmailPassword(email).catch(() => {});
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setShowError(false); // Set showError to false on email change
    setShowForgotPasswordMessage(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setShowError(false); // Set showError to false on email change
    setShowForgotPasswordMessage(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3} padding={1}>
        <TextField
          name="email"
          label="Email address"
          onChange={handleEmailChange}
          error={showError || showEmailError}
        />

        {props.mode !== "sso" && (
          <TextField
            name="password"
            label="Password"
            error={showError}
            type={showPassword ? "text" : "password"}
            onChange={handlePasswordChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>

      {(props.error || showError) && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <Typography variant="body1" sx={{ color: "red", fontWeight: "bold" }}>
            {props.error || "Invalid Username or password"}
          </Typography>
        </Stack>
      )}

      {showForgotPasswordMessage && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ my: 2 }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            You will shortly receive an email with reset instructions if you
            have an existing account
          </Typography>
        </Stack>
      )}

      {props.mode !== "sso" && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ my: 2 }}
        >
          <Link
            variant="subtitle2"
            underline="hover"
            onClick={handleForgotPassword}
          >
            Forgot password?
          </Link>
        </Stack>
      )}

      <LoadingButton
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        disabled={!email || (props.mode !== "sso" && !password)}
      >
        Sign in
      </LoadingButton>

      {props.mode !== "sso" && (
        <Stack mt={2} alignItems="center">
          <Link variant="subtitle2" underline="hover" href="/sso">
            Login with SSO
          </Link>
        </Stack>
      )}
    </form>
  );
}
