import React from "react";
import { formatTimeToMinSecMili } from "../../../utils";

const CurrentTime = ({ currentTime, timeLimit }) => {
  return (
    <div className="flex items-center ml-3 gap-2">
      <p className="font-mono text-zinc-300 text-xs leading-4 text-gray-700 font-bold">
        {formatTimeToMinSecMili(currentTime)}
      </p>
      <p className="text-zinc-300 text-xs leading-4 text-gray-400 font-medium">
        /
      </p>
      <p className="font-mono text-zinc-300 text-xs leading-4 text-gray-700 font-bold ">
        {formatTimeToMinSecMili(timeLimit)}
      </p>
    </div>
  );
};

export default CurrentTime;
