import {fabric} from 'fabric';
import * as utils from './canvasUtils'
const canvasActions = {
    setZoom(canvas, value) {
        debugger
        const focusObject = canvas.getActiveObject();
        const zoom = value / 100 || 1;

        if (focusObject) {
            canvas.setZoom(zoom);

            canvas.absolutePan({
                x: -canvas.getCenter().left + focusObject.getCenterPoint().x * zoom,
                y: -canvas.getCenter().top + focusObject.getCenterPoint().y * zoom,
            });
        } else {
            canvas.zoomToPoint(new fabric.Point(canvas.width / 2, canvas.height / 2), zoom);
        }

        canvas.fire('zoom:end', {canvas});
    },
    remove: (target) => {

        const canvas = target?.canvas;
        if (!canvas) return;

        const {parentArtboard} = target;
        const artBoard = canvas.getObjects('artboard')[0];
        canvas._discardActiveObject();
        canvas.remove(target);
        artBoard.removeChild(target)
        canvas.renderAll()

        canvas.fire('user:object:removed', {
            target,
            parentArtboard
        });
    },
    /**
     * @description Clones target, adds it to canvas and returns the cloned object
     *
     * @param {fabric.Object} target
     * @returns {Promise<fabric.Object>}
     */
    duplicate: async (target, isPasteAction = false, fireEvents = true) => {
        function setGroupedOffset(group, offset) {
            offset.x += group.left;
            offset.y += group.top;

            if (group.group) {
                setGroupedOffset(group.group, offset);
            }
        }

        if (target.type === 'activeSelection') {
            const left = isPasteAction ? (target.canvas.__lastRightClickX || 0) - (target.left - target.width / 2) : 0;
            const top = isPasteAction ? (target.canvas.__lastRightClickY || 0) - (target.top - target.height / 2) : 0;
            var cloned = await utils.cloneActiveSelection(target, left, top, false);

            cloned.forEachObject((obj) => {
                if (obj.__parentArtboard) {
                    obj.__parentArtboard.children[obj._id] = obj;
                }
            });
        }
         else {
            if (target.group) {
                const topGroup = utils.findTopMostGroup(target);
                const transform = {
                    original: {
                        ...fabric.util.saveObjectTransform(topGroup),
                    },
                };
                const offset = { x: 0, y: 0 };
                setGroupedOffset(target.group, offset);

                cloned = await utils.cloneObject(target, offset.x, offset.y, false);
                target.group.addWithUpdate(cloned);

                fireEvents && target.canvas.fire('group:object:added', { target: cloned, group: target.group });
                fireEvents && target.canvas.fire('object:modified', { target: topGroup, transform, e: {} });
            } else {
                let offsetX = 0;
                let offsetY = 0;
                if (isPasteAction) {
                    offsetX = (target.canvas.__lastRightClickX || 0) - target.left;
                    offsetY = (target.canvas.__lastRightClickY || 0) - target.top;
                }

                cloned = await utils.cloneObject(target, offsetX, offsetY);
            }
        }

        if (cloned?.__parentArtboard) {
            cloned.__parentArtboard.children[cloned._id] = cloned;
        }

        fireEvents && target.canvas?.fire('object:cloned', { cloned, target, action: 'clone' });

        return cloned;
    },

};

export default canvasActions;
