import { React, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { doc, serverTimestamp, getDoc, updateDoc } from "firebase/firestore";

import { db } from "../../firebase.js";
import mixpanel from "mixpanel-browser";

import { generateRandomString } from "../../utils/utils";
import { remove } from "lodash";
import Iconify from "../iconify/Iconify.js";

export default function ShareOptions(props) {
  const [url, setUrl] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [creatingUrl, setCreatingUrl] = useState(false);
  const [removingUrl, setRemovingUrl] = useState(false);

  const handleImportDialogClose = () => {
    props.setshowShareDialog(false);
  };

  const handleShare = async () => {
    setCreatingUrl(true);

    const id = generateRandomString();

    await updateDoc(doc(db, "projects", props.projectId), {
      public_link: id,
    });

    setUrl(window.location.origin + "/stream/" + id);

    mixpanel.track("Share Project", { id: id, projectId: props.projectId });
    setCreatingUrl(false);
  };

  const handleUnshare = async () => {
    setRemovingUrl(true);

    await updateDoc(doc(db, "projects", props.projectId), {
      public_link: null,
    });

    setUrl("");
    mixpanel.track("Unshare Project", { projectId: props.projectId });

    setRemovingUrl(false);
  };

  const handleCopyClick = (event) => {
    const text = event.target.value;
    navigator.clipboard.writeText(text).then(() => {
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 1500);
    });
  };

  useEffect(() => {
    const loadShare = async () => {
      try {
        const projectDoc = doc(db, "projects", props.projectId);
        const projectSnap = await getDoc(projectDoc);

        if (projectSnap.exists()) {
          if (projectSnap.data().public_link) {
            setUrl(
              window.location.origin +
                "/stream/" +
                projectSnap.data().public_link
            );
          }

          if (!projectSnap.data().mergedAudioUrl) {
            setShowWarning(true);
          }
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error loading document:", error);
      }
    };
    loadShare();
  }, []);

  return (
    <Dialog
      open={props.showShareDialog}
      maxWidth="sm"
      fullWidth
      onClose={handleImportDialogClose}
      PaperProps={{
        sx: {
          minHeight: 400,
        },
      }}
    >
      <DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h6">Share</Typography>
          <IconButton onClick={handleImportDialogClose}>
            <Iconify icon="heroicons:x-mark" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="caption" color="textSecondary">
            Share your project with your team by creating a public link. This
            link provides access to the audio content only.
          </Typography>
          {showWarning && (
            <Typography variant="caption" color="red">
              Warning: You haven't generated any audio
            </Typography>
          )}

          <LoadingButton
            loading={creatingUrl}
            variant="contained"
            onClick={handleShare}
          >
            {url ? "Change Public Link" : "Create Public Link"}
          </LoadingButton>
        </Stack>

        {url && (
          <Stack mt={4} spacing={2}>
            <Typography>
              <strong>Link</strong>
            </Typography>

            <Tooltip
              title="Copied!"
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
            >
              <TextField
                value={url}
                onClick={handleCopyClick}
                InputProps={{
                  readOnly: true,
                }}
                helperText={url ? "Click to copy" : ""}
              ></TextField>
            </Tooltip>
            <Stack mt={4} direction="row" spacing={2} alignItems="flex-end">
              <Button variant="text" href={url}>
                Preview
              </Button>
              <LoadingButton
                loading={removingUrl}
                sx={{
                  color: "#C70039",
                  "&:hover": {
                    backgroundColor: "darkred",
                    color: "white",
                  },
                }}
                variant="text"
                onClick={handleUnshare}
              >
                Remove Sharing
              </LoadingButton>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}
