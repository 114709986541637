import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  Grid,
  Stack,
  Typography,
  Paper,
  Toolbar,
  Tooltip,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { useUserAuth } from "../../UserAuthContext";
import ReactPlayer from "react-player";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import { analytics, db, logEvent } from "../../firebase";

const storage = getStorage();

export default function UserMedia(props) {
  const [loading, setLoading] = useState(false);
  const [playPreview, setPlayPreview] = useState(false);
  const [playerUrl, setPlayerUrl] = useState("");

  const [userFiles, setUserFiles] = useState([]);
  const { user } = useUserAuth();

  useEffect(() => {
    async function getMediaFiles() {
      const q = query(
        collection(db, "uploads"),
        where("user", "==", user.email),
        where("type", "==", props.mediaType),
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      setUserFiles(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          timestamp: doc.data()["timestamp"].toDate().toLocaleString(),
          gsUrl: doc.data()["gs_url"],
          url: doc.data()["player_url"],
          name: doc.data()["file_name"],
        }))
      );
    }
    setLoading(true);
    getMediaFiles();
    setLoading(false);
  }, []);

  const insertMedia = async (name, gsUrl, previewUrl) => {
    console.log(props.index);
    if (props.index !== null && props.index >= 0) {
      props.handleFieldMediaChange(gsUrl, previewUrl, props.index);
    } else {
      props.handleMediaChange(name, gsUrl, previewUrl);
    }
    props.closeMediaModal();
  };

  const handlePlayPreview = (url) => {
    setPlayerUrl(url);
    setPlayPreview(!playPreview);
  };
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Grid container mt={4}>
        {userFiles.map((file, index) => (
          <Grid item key={index} m={2}>
            <Paper elevation={4}>
              <Stack width="150px" spacing={1} padding={2} alignItems="center">
                {props.mediaType === "audio" ? (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handlePlayPreview(file.url)}
                  >
                    {playPreview && file.url === playerUrl ? (
                      <Iconify
                        width="40px"
                        icon="material-symbols:pause-circle-rounded"
                      />
                    ) : (
                      <Iconify
                        width="40px"
                        icon="material-symbols:play-circle-rounded"
                      />
                    )}
                  </IconButton>
                ) : (
                  <ReactPlayer width="120px" height="60px" url={file.url} />
                )}
                <Tooltip title={file.name} arrow placement="top">
                  <Typography
                    variant="caption"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "100px", // Set your desired maximum width
                    }}
                  >
                    {file.name}
                  </Typography>
                </Tooltip>

                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => insertMedia(file.name, file.gsUrl, file.url)}
                >
                  Select
                </Button>
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <ReactPlayer playing={playPreview} url={playerUrl} />
    </>
  );
}
