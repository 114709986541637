import React, { useState } from "react";
import { TextField, Button, Stack } from "@mui/material";

import { db } from "../../firebase.js";
import {
  doc,
  updateDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import ShowMessage from "../ShowMessage/ShowMessage.js";

export default function ManageBannerMessage(props) {
  // State to store form data
  const [formData, setFormData] = useState({
    message: "",
    expire_days: 14,
  });

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    // console.log(name);
    // console.log(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "expire_days"
          ? Number(value)
          : name === "languageCodes"
          ? value.split(",")
          : value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const docRef = doc(db, "zadmin", "notifications");
      formData.expiry = new Date(Date.now());
      formData.expiry.setDate(formData.expiry.getDate() + formData.expire_days); // reset 30 days from now
      await updateDoc(docRef, formData);
      ShowMessage("Updated");
    } catch (error) {
      console.error("Error creating document: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mt={4} maxWidth={600}>
        <TextField
          label="Icon"
          name="icon"
          value={formData.icon}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          label="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          fullWidth
        />

        {/* Display Name */}
        <TextField
          label="Expire after"
          name="expire_days"
          value={formData.expire_days}
          onChange={handleChange}
          fullWidth
        />

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary">
          Update
        </Button>
      </Stack>
    </form>
  );
}
