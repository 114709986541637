import { applyMiddleware, combineReducers, createStore } from 'redux';
import {thunk} from 'redux-thunk';

import * as reducers from '../reducers/reducers';

export const configure = () =>{
  const rootReducer = combineReducers({
    selectedProjectIndices: reducers.selectedProjectIndices,
  });
  const store = createStore(rootReducer, applyMiddleware(thunk));

  return store;
};
