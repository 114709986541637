const alphanumeric =
  "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

export function generateRandomString(length = 8) {
  const alphanumericLength = alphanumeric.length;
  const resultArray = new Array(length);
  const randomValues = new Uint32Array(length);

  // Fill the array with cryptographically secure random values
  window.crypto.getRandomValues(randomValues);

  for (let i = 0; i < length; i++) {
    // Map the random value to an index in the alphanumeric string
    const randomIndex = randomValues[i] % alphanumericLength;
    resultArray[i] = alphanumeric[randomIndex];
  }

  return resultArray.join("");
}

export function secondsToHMS(seconds, srt = false) {
  if (!seconds) {
    return srt ? "00:00:00,000" : "";
  }
  seconds = Math.round(seconds);
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds % 3600) / 60);
  var remainingSeconds = seconds % 60;

  // Add leading zeros if necessary
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  remainingSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;

  return srt
    ? hours + ":" + minutes + ":" + remainingSeconds + ",000"
    : hours + ":" + minutes + ":" + remainingSeconds;
}
