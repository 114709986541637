import React from "react";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { CanvasStore } from "../canvas/store/canvasStore";
function Seekbar({ left }) {
  const { activeCanvas } = React.useContext(CanvasStore);
  const handleClick = (e) => {
    activeCanvas.fire("seekber:mouse:down", e);
  };

  return (
    <div
      onMouseDown={handleClick}
      className="absolute pl-[4px] top-[52px] bottom-[20px] z-20 keep-animated"
      style={{
        left: `${left}px`,
      }}
    >
      <div className={"    triangle "} style={{ background: "#0098fd" }}></div>
      <div
        className={"ml-[4px] mt-[-2px]  w-[2px]  h-full"}
        style={{ background: "#0098fd" }}
      ></div>
    </div>
  );
}
export default Seekbar;
