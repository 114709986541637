import React from "react";

import ContentHeader from "./ContentHeader";
import MainContent from "./MainContent";
import { Helmet } from "react-helmet-async";

const ContentArea = () => {
  return (
    <>
      <Helmet>
        <title> Video Editor | Acoust AI</title>
      </Helmet>
      <div className="flex flex-col flex-1 bg-slate-900 z-1 relative">
        <ContentHeader />
        <MainContent />
      </div>
    </>
  );
};

export default ContentArea;
