import React from "react";
function AnimationThumbnail({
  isActive,
  animation,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) {
  const handleTransitionChange = () => {
    if (onClick) onClick(animation);
  };
  return (
    <div className="w-full" onClick={handleTransitionChange}>
      <div
        className={`group`}
        onMouseEnter={() => onMouseEnter(animation)}
        onMouseLeave={onMouseLeave}
      >
        <div
          className={`flex items-center justify-center h-[100px] w-[100px] border-[1px] border-solid rounded-[8px] ${
            !isActive ? "border-slate-700" : "border-primary"
          } cursor-pointer`}
        >
          <AnimationIcon iconName={animation.iconName} />
        </div>
      </div>
      <span
        className={`text-xs text-slate-300 flex items-center p-2 justify-center  ${
          isActive && "text-[#5666f5]"
        }`}
      >
        {animation.title}
      </span>
    </div>
  );
}

function AnimationIcon({ onClick, iconName, classes = "" }) {
  // let href= `${monoIcons}#${iconName}`
  // if (workingEnv === 'build')
  //     href = window.location.origin+`/assets/designer/static/media/icons-mono.svg?v=${cacheBuster}#`+iconName

  /*return (
        <svg onClick={(e)=>{
            onClick&&onClick(e)
        }} role="img" className={`icon  ${classes}`}>
            <use xlinkHref={href}/>
        </svg>
    );*/
  return <img src={`/images/animationPreviews/${iconName}`} />;
}

export default AnimationThumbnail;
