import RedoRoundedIcon from "@mui/icons-material/RedoRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";
import { IconButton } from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { CanvasStore } from "../../canvas/store/canvasStore";
const UndoRedoButton = () => {
  let { activeCanvas } = useContext(CanvasStore);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("history:updated", handleUpdateBtnState);
      activeCanvas.on("undo", handleUpdateBtnState);
      activeCanvas.on("redo", handleUpdateBtnState);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("history:updated", handleUpdateBtnState);
        activeCanvas.off("undo", handleUpdateBtnState);
        activeCanvas.off("redo", handleUpdateBtnState);
      }
    };
  }, [activeCanvas]);

  function handleUpdateBtnState() {
    setCanUndo(activeCanvas.history.canUndo());
    setCanRedo(activeCanvas.history.canRedo());
  }

  return (
    <div className="flex gap-2">
      <IconButton
        disabled={!canUndo}
        onClick={() => {
          activeCanvas.undo();
        }}
        className={`hover:bg-gray-900 hover:bg-black ${!canUndo && "disabled"}`}
        aria-label="undo"
      >
        <UndoRoundedIcon sx={{ color: "#fff", opacity: canUndo ? 1 : 0.5 }} />
      </IconButton>
      <IconButton
        disabled={!canRedo}
        onClick={() => {
          activeCanvas.redo();
        }}
        className={`hover:bg-gray-900 hover:bg-black ${!canRedo && "disabled"}`}
        aria-label="redo"
      >
        <RedoRoundedIcon sx={{ color: "#fff", opacity: canRedo ? 1 : 0.5 }} />
      </IconButton>
    </div>
  );
};

export default UndoRedoButton;
