import { createContext, useMemo, useState } from "react";

export const CanvasStore = createContext(null);

export const StoreContext = ({ children }) => {
  const [activeCanvas, setActiveCanvas] = useState(null);
  const [activeObject, setActiveObject] = useState(null);
  const [FPS, setFPS] = useState(25);
  const [animationDuration, setAnimationDuration] = useState(5000);
  const [projectInfo, setProjectInfo] = useState({});

  const canvasProviderValues = useMemo(
    () => ({
      FPS,
      setFPS,
      animationDuration,
      setAnimationDuration,
      activeCanvas,
      setActiveCanvas,
      activeObject,
      setActiveObject,
      projectInfo,
      setProjectInfo,
    }),
    [activeCanvas, activeObject, FPS, animationDuration, projectInfo]
  );

  return (
    <CanvasStore.Provider value={{ ...canvasProviderValues }}>
      {children}
    </CanvasStore.Provider>
  );
};
