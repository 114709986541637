import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui

import {
  CircularProgress,
  Container,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { db } from "../firebase.js";
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
} from "firebase/firestore";

import Iconify from "../components/iconify";
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 600,
  margin: "auto",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  padding: theme.spacing(2, 0),
  marginTop: 150,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2), // Add padding for small devices
    marginLeft: theme.spacing(2), // Add margin for mobile
    marginRight: theme.spacing(2),
  },
}));

function ReleaseNotesPage() {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, "zadmin"),
          where("type", "==", "relnotes"),
          orderBy("date", "desc")
        );
        const querySnapshot = await getDocs(q);
        const releaseNotes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          date: doc.data().date.toDate().toLocaleDateString(),
          features: doc.data().features,
        }));
        setReleaseNotes(releaseNotes);
      } catch (error) {
        console.error("Error fetching release notes:", error);
      }
    };
    fetchData().then(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> Welcome | Acoust </title>
      </Helmet>

      <StyledContent>
        <Stack sx={{ mb: 4 }}>
          <Typography variant="h4" gutterBottom>
            Release Notes
          </Typography>
        </Stack>

        {loading && <Iconify icon="svg-spinners:3-dots-fade" color="inherit" />}

        {releaseNotes.map((releaseNote) => (
          <Stack mt={2} key={releaseNote.id}>
            <Typography variant="h6" gutterBottom>
              {releaseNote.date}
            </Typography>
            <List>
              {releaseNote.features?.map((feature, index) => (
                <ListItem key={index} sx={{ padding: "0px" }}>
                  <ListItemIcon>
                    <Iconify icon="icon-park-outline:dot" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="body1" gutterBottom>
                      {feature}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Stack>
        ))}
      </StyledContent>
    </>
  );
}

export default ReleaseNotesPage;
