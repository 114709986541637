import React, { useContext } from "react";
import PageHeader from "../../shared/PageHeader";
import { CanvasStore } from "../../canvas/store/canvasStore";
import AnimationStartEndControl from "../../shared/AnimationStartEndControl";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import { Button } from "@mui/material";
import VolumeControl from "../../shared/VolumeControl";
import PlaybackSpeed from "../../shared/PlaybackSpeed";
import canvasActions from "../../canvas/utils/canvasActions";
import { useNavigate } from "react-router";
const EditAudio = ({}) => {
  const { activeObject, activeCanvas, projectInfo } = useContext(CanvasStore);
  const router = useNavigate();

  const handleDelete = () => {
    if (!activeObject) return;
    canvasActions.remove(activeObject);
    activeCanvas.renderAll();
    router(`/v/${projectInfo.id}/audio/`);
  };

  return (
    <div>
      <PageHeader
        heading="Edit Audio"
        subHeading={activeObject?.custom?.name ?? ""}
        showBackButton={true}
        onClicked={() => router(`/v/${projectInfo.id}/audio/`)}
      />

      {!activeObject ? (
        <div>No audio object selected.</div>
      ) : (
        <div className={"flex flex-col gap-2"}>
          <PlaybackSpeed object={activeObject} />
          <VolumeControl audio={true} object={activeObject} />

          <div
            className={
              "border border-b my-7 border-solid rounded border-[#e1e1e3]"
            }
          />

          <AnimationStartEndControl object={activeObject} />

          <div>
            {/*<div
              className={
                "flex-grow hover:border-primary hover:text-primary cursor-pointer gap-3  py-2 flex items-center rounded-[8px] px-4 border border-[#e1e1e3] border-solid  shadow-sm"
              }
            >
              <FlipCameraAndroidOutlinedIcon />
              <span className={"text-sm flex-grow"}>Replace Audio</span>
            </div>*/}
            <div>
              <Button
                fullWidth
                variant="outlined"
                startIcon={<DeleteOutlinedIcon fontSize={"small"} />}
                onClick={handleDelete}
                sx={{
                  color: "#fff", // Text color
                  borderColor: "#942407", // Border color
                  backgroundColor: "#942407", // Background color
                  "&:hover": {
                    backgroundColor: "#7a1b03", // Darker red when hovered
                    borderColor: "#7a1b03",
                  },
                }}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditAudio;
