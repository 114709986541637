import React, { useEffect, useState } from "react";

import { Button, ButtonGroup, Stack, Typography } from "@mui/material";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import { useUserAuth } from "../../../UserAuthContext";
import VideoSlider from "./components/VideoSlider";

const UserVideoFiles = () => {
  const [videosList, setVideosList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mediaType, setMediaType] = useState("video");

  const { user } = useUserAuth();

  useEffect(() => {
    const fetchUserMedia = async () => {
      setLoading(true);
      const q = query(
        collection(db, "uploads"),
        where("user", "==", user.email),
        where("type", "==", mediaType),
        orderBy("timestamp", "desc")
      );

      const querySnapshot = await getDocs(q);
      console.log(querySnapshot.docs);
      setVideosList(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          timestamp: doc.data()["timestamp"].toDate().toLocaleString(),
          gsUrl: doc.data()["gs_url"],
          url: doc.data()["player_url"] || doc.data()["url"],
          name: doc.data()["file_name"],
        }))
      );
      setLoading(false);
    };

    fetchUserMedia();
  }, [mediaType]);

  const onChangeMediaType = (value) => {
    setMediaType(value);
  };

  return (
    <div className="mt-2 text-xs">
      <div className="flex gap-4 mt-6 items-center justify-center text-slate-300">
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          className="text-xs"
        >
          <Button
            className="text-xs"
            onClick={() => onChangeMediaType("image")}
            variant={mediaType === "image" ? "contained" : "outlined"}
          >
            Images
          </Button>
          <Button
            className="text-xs"
            onClick={() => onChangeMediaType("video")}
            variant={mediaType === "video" ? "contained" : "outlined"}
          >
            Video
          </Button>
        </ButtonGroup>
      </div>
      {/* <ButtonTabs /> */}
      {!videosList.length && (
        <Stack
          justifyContent="center"
          alignItems="center"
          padding={4}
          spacing={2}
        >
          <Typography variant="caption">
            You don't seem to have any {mediaType}s uploaded yet
          </Typography>
        </Stack>
      )}
      <VideoSlider
        videoList={videosList}
        loading={loading}
        mediaType={mediaType}
      />
    </div>
  );
};

export default UserVideoFiles;
