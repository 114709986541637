import FastForwardRoundedIcon from "@mui/icons-material/FastForwardRounded";
import FastRewindRoundedIcon from "@mui/icons-material/FastRewindRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Button, IconButton } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import Iconify from "../../iconify/Iconify";
import useOnclickOutside from "react-cool-onclickoutside";

import React, { useContext } from "react";
import { CanvasStore } from "../../../canvas/store/canvasStore";
const PlayButtonGroup = ({
  onCancelAnimation,
  onTogglePlay,
  isPlaying,
  jumpTime,
}) => {
  const { FPS } = useContext(CanvasStore);
  const ref = useOnclickOutside(
    () => {
      onCancelAnimation();
    },
    {
      ignoreClass: "keep-animated",
    }
  );
  return (
    <div className="flex justify-center items-center gap-1" ref={ref}>
      <IconButton
        onClick={() => {
          jumpTime(-1000 / FPS);
        }}
        size="small"
      >
        <FastRewindRoundedIcon sx={{ fontSize: 16, color: "#fff" }} />
      </IconButton>
      <Button
        onClick={onTogglePlay}
        sx={{
          width: "40px", // Set the width of your button
          height: "40px", // Set the height of your button
          minWidth: "40px",
          borderRadius: "50%",
          backgroundImage:
            "linear-gradient(90deg, rgba(93,26,138,1) 0%, rgba(202,10,10,1) 70%, rgba(233,145,21,1) 100%)",
        }}
      >
        {isPlaying ? (
          <Iconify width="25px" color="#fff" icon="solar:pause-bold" />
        ) : (
          <Iconify width="25px" color="#fff" icon={"solar:play-bold"} />
        )}
      </Button>
      <IconButton
        onClick={() => {
          jumpTime(1000 / FPS);
        }}
        size="small"
      >
        <FastForwardRoundedIcon sx={{ fontSize: 16, color: "#fff" }} />
      </IconButton>
    </div>
  );
};

export default PlayButtonGroup;
