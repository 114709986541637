import React from "react";

const SectionHeader = ({ heading }) => {
  return (
    <div
      className="text-grayLight text-sm leading-tight tracking-normal font-semibold m-0 whitespace-nowrap font-inter 
  "
    >
      {heading}
    </div>
  );
};

export default SectionHeader;
