import React, { useState } from "react";
import {
  Button,
  IconButton,
  Input,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { alpha } from "@mui/material/styles";

import LoadingButton from "@mui/lab/LoadingButton";

import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import Iconify from "../iconify";

export default function ChatGPT(props) {
  const [promptText, setPromptText] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePromptChange = (e) => {
    setPromptText(e.target.value);
  };

  const handleGenerateContent = async () => {
    try {
      //props.handlePaste('');
      setLoading(true);

      // Call the Firebase Cloud Function using the 'generateContent' name
      const generateContentFunction = httpsCallable(
        functions,
        "generateContent"
      );
      const result = await generateContentFunction({ promptText });
      console.log(result);
      // console.log(result.data.content)
      props.handleContent(result.data.content.content, 0, true);
      setLoading(false);

      const aiUsageFunction = httpsCallable(functions, "aiUsage");
      await aiUsageFunction({ promptText });
    } catch (error) {
      console.error("Error generating content:", error);
      setLoading(false);
    }
  };
  if (props.isOverAIQuota) {
    return (
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" color="red">
          Uh oh! You have exceeded your quota of AI prompts.
        </Typography>
        <Button
          color="primary"
          href="https://www.acoust.io/#Pricing"
          target="_blank"
          variant="text"
        >
          Upgrade
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Stack
        sx={{
          bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
          borderRadius: "15px",
        }}
        spacing={5}
        padding={2}
        mt={2}
        direction="row"
        mb={2}
        justifyContent="space-around"
      >
        <Input
          value={promptText}
          onChange={handlePromptChange}
          fullWidth
          placeholder="Jump start your project with our AI-Powered assistant. Simply ask: Write a short story for kids or explain blackholes"
          sx={{
            "& ::placeholder": {
              fontSize: "12px", // Change the font size here
            },
          }}
        />
        <Tooltip title="Generate Content" placement="top" arrow>
          <LoadingButton
            size="small"
            color="primary"
            loading={loading}
            onClick={handleGenerateContent}
            sx={{
              color: "#fff",
            }}
            disabled={props.isContentLoading || promptText.length === 0}
          >
            <Iconify icon="mdi:magic" />
          </LoadingButton>
        </Tooltip>
      </Stack>
    </>
  );
}
