import { Helmet } from "react-helmet-async";
// @mui
import { Container, Typography } from "@mui/material";

import Templates from "../components/Templates/Templates";

// ----------------------------------------------------------------------

export default function TemplatesPage() {
  return (
    <>
      <Helmet>
        <title> Templates | Acoust </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom>
          Templates
        </Typography>

        <Typography variant="body1" gutterBottom sx={{ color: "#ccc", mb: 5 }}>
          Getting started is even easier with our curated templates
        </Typography>

        <Templates></Templates>
      </Container>
    </>
  );
}
