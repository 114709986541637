import React from "react";

const Color = ({ value, className }) => {
  return (
    <div
      className={` w-[20px] h-[20px] rounded-full ${className}`}
      style={{ backgroundColor: value }}
    ></div>
  );
};

export default Color;
