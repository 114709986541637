import React, { useContext, useEffect, useState } from "react";
import Rotate90DegreesCcwOutlinedIcon from "@mui/icons-material/Rotate90DegreesCcwOutlined";
import { CanvasStore } from "../../canvas/store/canvasStore";

function RotationControl({ object }) {
  const { activeCanvas } = useContext(CanvasStore);

  const [rotation, setRotation] = useState(object.rotation || 0);
  const [inputType, setInputType] = useState("text");

  useEffect(() => {
    if (!object) return;
    setRotation(object.angle);
  }, [object]);

  const handleBlur = () => {
    setInputType("text");
  };

  const handleFocus = () => {
    setInputType("number");
  };

  const handleChange = (event) => {
    let newRotation = parseInt(event.target.value);
    newRotation = Math.min(Math.max(newRotation, 0), 360);
    setRotation(newRotation);
    object.set("angle", newRotation);
    activeCanvas.renderAll();
  };

  return (
    <div
      className={
        "py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid shadow-sm"
      }
    >
      <Rotate90DegreesCcwOutlinedIcon
        sx={{ color: "white" }}
        fontSize={"small"}
      />
      <span className={"text-white text-sm mx-4"}>Rotation</span>
      {inputType === "text" ? (
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={`${rotation}%`}
          className={
            "text-right px-2 py-[6px] ml-3 w-[50px] bg-00 rounded border-0 outline-0 min-h-[28px] h-auto"
          }
          onChange={handleChange}
          type={"text"}
        />
      ) : (
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={rotation}
          onChange={handleChange}
          className={
            "focus:text-primary text-right px-2 py-[6px] ml-3 w-[50px] bg-[#f7f7f8] rounded border-0 outline-0 min-h-[28px] h-auto"
          }
          type={"number"}
          min={0}
          max={360} // Limit input to 0-360 range
        />
      )}
    </div>
  );
}

export default RotationControl;
