import React from "react";
import FlipIcon from "@mui/icons-material/Flip";
import IconButton from "../IconButton";
import { Tooltip } from "@mui/material";

function FlipButton({ type, onClick }) {
  let style = {};

  if (type === "Vertical") {
    style = { transform: "rotate(90deg)" };
  }
  return (
    <Tooltip title={`Flip ${type}`}>
      <span>
        <IconButton>
          <FlipIcon onClick={onClick} style={style} fontSize={"small"} />
        </IconButton>
      </span>
    </Tooltip>
  );
}

export default FlipButton;
