import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { db } from "../firebase.js";
import { useUserAuth } from "../UserAuthContext";

import ReactAudioPlayer from "react-audio-player";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/iconify";
import UploadAudio from "../components/UploadAudio/UploadAudio.js";
import useResponsive from "../hooks/useResponsive";

// ----------------------------------------------------------------------

export default function VoiceCloning() {
  const { user, isUserPremium } = useUserAuth();
  const clonedVoiceLimit = isUserPremium ? 5 : 1;

  // const [isPremiumUser, setisPremiumUserl] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [playerUrl, setPlayerUrl] = useState(null);
  const [clonedVoices, setClonedVoices] = useState([]);
  const [clonedVoiceName, setClonedVoiceName] = useState("");
  const [acknowledge, setAcknowledge] = useState(false);
  const [deletingVoice, setDeletingVoice] = useState(false);
  const [playingVoice, setPlayingVoice] = useState(null);
  const [showThanks, setShowThanks] = useState(false);

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const playerRef = useRef(null);

  const navigate = useNavigate();

  const handleDialogClose = async () => {
    //setOpenUploadModal(false);
    setOpenDialog(false);
    //setLoading(true);
    await getUserCloneVoices();
  };

  const handleDialogOpen = async () => {
    //setOpenUploadModal(true);
    setOpenDialog(true);
  };

  const deleteVoice = async (voiceId) => {
    setDeletingVoice(voiceId);

    const docRef = doc(db, "clonedvoices", voiceId);
    await deleteDoc(docRef);
    //setClonedVoice("");
    setDeletingVoice("");
    getUserCloneVoices();
  };

  const playVoice = (index) => {
    setPlayingVoice(index);
    // console.log(clonedVoices[index].player_url);
    setPlayerUrl(clonedVoices[index].player_url);
  };

  const handleStopVoice = () => {
    if (playerRef.current && !playerRef.current.audioEl.current.paused) {
      playerRef.current.audioEl.current.pause();
    }
    setPlayerUrl(null);
    setPlayingVoice(null);
    setIsAudioPlaying(false);
  };

  const handleAudioUpload = async (voiceDoc) => {
    const tempVoices = await getUserCloneVoices();
    setOpenDialog(false);
    const projectRef = doc(db, "clonedvoices", voiceDoc.id);
    const unsubscribe = onSnapshot(projectRef, async (snapshot) => {
      const index = tempVoices.findIndex((voice) => voice.id === snapshot.id);
      if (index !== -1) {
        //console.log("found match", snapshot.data().status);
        if (snapshot.data().status !== "pending") {
          tempVoices[index].status = snapshot.data().status;
          unsubscribe();
        }
        setClonedVoices(tempVoices);
        await getUserCloneVoices();
      }
    });
  };

  const handleAudioStarted = () => {
    setIsAudioPlaying(true);
  };

  const handleAudioEnded = () => {
    setPlayerUrl(null);
    setIsAudioPlaying(false);
  };

  const handleVoiceNameChange = (e) => {
    setClonedVoiceName(e.target.value);
  };

  const handleAcknowledgeChange = (e) => {
    setAcknowledge(e.target.checked);
  };

  const getUserCloneVoices = async () => {
    const q = query(
      collection(db, "clonedvoices"),
      where("user", "==", user.email)
    );

    const querySnapshot = await getDocs(q);
    const clonedVoices = [];
    querySnapshot.forEach((doc) => {
      clonedVoices.push({
        id: doc.id,
        timestamp: doc.data()["timestamp"].toDate().toLocaleString(),
        gsUrl: doc.data()["gs_url"],
        player_url: doc.data()["player_url"],
        name: doc.data()["file_name"],
        display_name: doc.data()["display_name"],
        status: doc.data()["status"],
        cloud: doc.data()["cloud"],
        voice: doc.data()["voice"],
        code: doc.data()["code"],
      });
    });
    setClonedVoices(clonedVoices);
    return clonedVoices;
  };

  const handleNewProject = async (voice) => {
    setProcessing(true);
    const docRef = await addDoc(collection(db, "projects"), {
      user: user.email,
      name: `Proj ${new Date().toLocaleDateString()}`,
      created: serverTimestamp(),
      archived: false,
      type: "audio",
      selectedVoice: voice,
      simple_mode: true,
    });
    // console.log(docRef.id);

    // mixpanel.track("Create Project Cloned Voice", { projectId: docRef.id });
    setProcessing(false);

    let path = `/editor?id=${docRef.id}`;
    navigate(path);
  };

  const handleButtonClick = async () => {
    await addDoc(collection(db, "feedback"), {
      to: "hi@acoust.io",
      user: user.email,
      timestamp: serverTimestamp(),
      message: {
        subject: `Cloning interest ${user.email}`,
        text: "User is interested",
      },
    });
    setShowThanks(true);
  };

  useEffect(() => {
    getUserCloneVoices().then(() => {
      setLoading(false);
    });
  }, []);

  if (!isUserPremium) {
    return (
      <>
        <Helmet>
          <title> Voice Cloning | Acoust AI </title>
        </Helmet>

        <Container>
          <Typography variant="h4" gutterBottom>
            Voice Cloning
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ color: "#ccc", mb: 8 }}
          >
            Create personalized voiceovers with your own voice
          </Typography>
          <Stack maxWidth={800} spacing={4}>
            <Typography gutterBottom sx={{ mb: 4 }}>
              Voice cloning is currently a preview feature and requires a
              subscription. Contact us today for a demo.
            </Typography>
            {showThanks ? (
              <Typography>Thank you! Our team will reach out.</Typography>
            ) : (
              <Button variant="outlined" onClick={handleButtonClick}>
                Join Waitlist
              </Button>
            )}
          </Stack>
        </Container>
      </>
    );
  }

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Voice Cloning | Acoust AI </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom sx={{ mb: 1 }}>
          Voice Cloning
        </Typography>

        <Typography variant="body1" gutterBottom sx={{ color: "#ccc", mb: 5 }}>
          Create personalized voiceovers with your own voice
        </Typography>

        <Stack maxWidth={650} alignItems="flex-start">
          <Chip
            size="large"
            sx={{
              color: "white",
              width: "200px",
              height: "50px",
              border: "2px double transparent",
              backgroundImage:
                "linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
              boxShadow: "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
            }}
            variant="outlined"
            disabled={clonedVoices.length >= clonedVoiceLimit}
            onClick={handleDialogOpen}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="zondicons:add-solid"></Iconify>
                <Typography
                  sx={{
                    fontSize: "12px",
                  }}
                >
                  Add Voice Clone
                </Typography>
              </Stack>
            }
          ></Chip>

          {clonedVoices.length >= clonedVoiceLimit && (
            <Typography variant="subtitle2" mt={2} color="textSecondary">
              You have reached the limit of {clonedVoiceLimit} clones. Delete an
              existing voice to add more
            </Typography>
          )}
        </Stack>

        <Stack maxWidth={650} mt={2}>
          {clonedVoices.length === 0 && (
            <Stack padding={2}>
              <Stack alignItems="center" spacing={2} padding={2} mb={2}>
                <Typography variant="subtitle2">
                  You do not have any cloned voices yet
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                minHeight={300}
                sx={{
                  backgroundImage: "url(/clone.svg)",
                  //bgcolor: (theme) => alpha(theme.palette.primary.main, 0.5),
                  backgroundSize: "contain", // You can adjust this based on your needs
                  backgroundPosition: "bottom", // You can adjust this based on your needs
                  backgroundRepeat: "no-repeat",
                  height: "60%",
                }}
              ></Stack>
            </Stack>
          )}
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {clonedVoices?.map((voice, index) => (
                  <TableRow key={index}>
                    <TableCell>{voice.display_name}</TableCell>
                    <TableCell>
                      {voice.status === "approved" ? (
                        <Chip
                          color="success"
                          label="Ready"
                          sx={{
                            color: "#0cdc00",
                            backgroundColor: alpha("#045400", 0.3),
                          }}
                        />
                      ) : voice.status === "pending" ? (
                        <Chip
                          color="warning"
                          label="Processing"
                          sx={{
                            color: "#ffc354",
                            backgroundColor: alpha("#ff8149", 0.3),
                          }}
                        />
                      ) : (
                        <Chip
                          color="error"
                          sx={{
                            color: "#fff",
                            backgroundColor: alpha("#cc0000", 0.9),
                          }}
                          label="Failed"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <LoadingButton
                        loading={processing}
                        onClick={() => handleNewProject(voice)}
                      >
                        Use Voice
                      </LoadingButton>
                    </TableCell>
                    <TableCell align="right">
                      {playingVoice === index ? (
                        <IconButton onClick={handleStopVoice} color="primary">
                          <Iconify icon="material-symbols:stop" />
                        </IconButton>
                      ) : (
                        <IconButton
                          disabled={voice.status !== "approved"}
                          onClick={() => playVoice(index)}
                          color="primary"
                        >
                          <Iconify icon="material-symbols:play-circle-rounded" />
                        </IconButton>
                      )}
                      <IconButton
                        disabled={deletingVoice === voice.id}
                        onClick={() => deleteVoice(voice.id)}
                      >
                        {deletingVoice === voice.id ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Iconify icon="material-symbols:delete" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="Add New Voice"
      >
        <DialogTitle>
          <Stack justifyContent="space-between" direction="row">
            Add New Voice
            <IconButton size="small" variant="text" onClick={handleDialogClose}>
              <Iconify icon="material-symbols:close" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            margin: "20px",
          }}
        >
          <Stack mt={2} spacing={4}>
            <Stack
              maxWidth={700}
              border={0.5}
              padding={4}
              sx={{
                backgroundColor: (theme) =>
                  alpha(theme.palette.common.black, 0.5),
                borderRadius: "20px",
              }}
            >
              <Typography variant="h5">
                Tips for best results{" "}
                <Iconify icon="ic:outline-tips-and-updates" />
              </Typography>
              <ol style={{ fontSize: "14px" }}>
                <li>Ensure there is no background noise</li>
                <li>Record in a room with good acoustics</li>
                <li>
                  Read a text passage to avoid pauses and to ensure there is
                  variation in words and pronunciation.
                </li>
                <li>Avoid long pauses</li>
                <li>
                  Record atleast 1 minute of your voice. Recommended 1 - 5 mins.
                </li>
              </ol>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={4} mt={2}>
            <Iconify icon="fxemoji:warningsign" />
            &nbsp; Available for English only at this time
          </Stack>
          <Stack maxWidth={750} mt={2} spacing={2} direction="row">
            <Checkbox
              value={acknowledge}
              onChange={handleAcknowledgeChange}
            ></Checkbox>
            <Typography>
              I hereby confirm that I have all necessary rights or consents to
              upload and clone this voice and that I will not use the generated
              content for any illegal, fraudulent, or harmful purposes.
            </Typography>
          </Stack>
          <Stack mt={2} maxWidth={500} spacing={1}>
            <Typography variant="caption2">Voice Name</Typography>
            <Stack direction="row">
              <TextField
                placeholder="My Voice"
                size="small"
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: 32 }}
                error={clonedVoiceName.length >= 32}
                helperText={
                  clonedVoiceName.length >= 32 ? "32 characters max" : null
                }
                onChange={handleVoiceNameChange}
              ></TextField>
            </Stack>

            <UploadAudio
              handleAudioUpload={handleAudioUpload}
              clonedVoiceName={clonedVoiceName}
              audioType="clone"
              maxFileSize={10}
              disabled={!clonedVoiceName || !acknowledge}
            ></UploadAudio>
          </Stack>
        </DialogContent>
      </Dialog>
      <ReactAudioPlayer
        src={playerUrl}
        onPlay={handleAudioStarted}
        onEnded={handleAudioEnded}
        preload="none"
        autoPlay
        ref={playerRef}
      />
    </>
  );
}
