//---------History start
import {propertiesToInclude} from "../customFabric";

export function registerHistoryEvents(canvas) {
    canvas.on('object:modified', updateHistory)
    canvas.on('canvas:modified', updateHistory)
    canvas.on('artboard:child:added', updateHistory)
    canvas.on('artboard:child:removed', updateHistory)
}
function updateHistory() {
    if(this.disabledHistory){
        return true;
    }
    let state = this.getObjects('artboard')[0]?.toJSON(propertiesToInclude);
    if (state) {
        state._selectedObjects = this.getActiveObjects().map(a => a._id);
        this.history.update(state).then(()=>{
            this.fire('history:updated')
        });
    }
}
//---------History end
