import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Stack,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  tableCellClasses,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import { styled } from "@mui/material/styles";

import { db } from "../firebase.js";
import {
  addDoc,
  getDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  updateDoc,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore";

import { functions, httpsCallable } from "../firebase"; // import Firebase functions instance

import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../UserAuthContext";
import { useNavigate } from "react-router-dom";
import ManageSSO from "../components/ManageSSO/ManageSSO";

import Iconify from "../components/iconify";

// ----------------------------------------------------------------------
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function TeamPage() {
  const [team, setTeam] = useState([]);
  const [teamAdmins, setTeamAdmins] = useState([]);
  const [emails, setEmails] = useState("");
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [activeTeamMembers, setActiveTeamMembers] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const [sendingInvite, setSendingInvite] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const { user, isTeamMember } = useUserAuth();

  const sendInviteFunction = httpsCallable(functions, "sendInvite");
  const [activeTab, setActiveTab] = useState(0);

  // Checks if user is part of
  async function getTeam() {
    // console.log("loading templates");
    let q = query(
      collection(db, "teams"),
      where("admins", "array-contains", user.email)
    );

    //console.log(await isTeamMember());

    let querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Get the first document from the result
      const teamDoc = querySnapshot.docs[0];

      // Access the document data
      const teamDocData = teamDoc.data();

      // Set the specific document in your state
      setTeam({
        id: teamDoc.id,
        ...teamDocData,
        role: "admin",
      });

      setTeamAdmins(teamDocData.admins);

      // return id to fetch active members
      return teamDoc.id;
    } else {
      q = query(
        collection(db, "teams"),
        where("members", "array-contains", user.email)
      );
    }
    querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const teamDoc = querySnapshot.docs[0];

      // Access the document data
      const teamDocData = teamDoc.data();
      // Set the specific document in your state
      setTeam({
        id: teamDoc.id,
        ...teamDocData,
      });
    } else {
      setTeam(null);
    }
    setLoading(false);
  }

  const getActiveTeamMembers = async (teamId) => {
    const getActiveTeamMembersFunction = httpsCallable(
      functions,
      "activeMembers"
    );

    const { data } = await getActiveTeamMembersFunction({ teamId });
    setActiveTeamMembers(data.members);
  };

  useEffect(() => {
    getTeam().then(async (teamId) => {
      // console.log(teamId);
      await getActiveTeamMembers(teamId);
      setLoading(false);
    });
  }, []);

  const handleAddUsers = async () => {
    setErrorMessage("");
    //setSendingInvite(true);
    setInvalidEmails([]);

    let invalidEmailFound = false;
    const parsedList = emails
      .split(",")
      .map((item) => item.trim())
      .filter((item) => {
        const isValid = isValidEmail(item);
        if (!isValid) {
          setInvalidEmails((prevInvalidEmails) => [...prevInvalidEmails, item]);
          invalidEmailFound = true;
        }
        return item !== "" && isValid;
      });

    console.log(parsedList.length);
    if (parsedList.length > 10) {
      setErrorMessage("Only 10 users can be bulk added at this time");
      setSendingInvite(false);
      return;
    }

    if (invalidEmailFound) {
      setErrorMessage("Invalid emails found:");
      setSendingInvite(false);
      return;
    }

    console.log("pased emails:" + parsedList);

    const newMembers = parsedList.filter(
      (item) => !team.members.includes(item)
    );

    console.log(newMembers);

    // Add the new members to the team
    const allMembers = [...team.members, ...newMembers];

    console.log(allMembers);

    if (newMembers.length === 0) {
      setErrorMessage("Members already exist in the team");
      setSendingInvite(false);
      return;
    }

    const docRef = doc(db, "teams", team.id);

    await setDoc(
      docRef,
      {
        members: allMembers,
      },
      { merge: true }
    );

    // send invites
    await sendInvites(newMembers);

    await getTeam();
    setShowDialog(false);
    setSendingInvite(false);
  };

  const sendInvites = async (emailList) => {
    try {
      await sendInviteFunction({ emailList });
    } catch (error) {
      console.log(error);
    }
  };

  function isValidEmail(email) {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleShowDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setErrorMessage("");
    setInvalidEmails([]);
  };

  // Changes the active tab
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Stack mt={2}>
        <CircularProgress />
      </Stack>
    );
  }

  // if the user is not a part of a team or doesn't have admin, then show
  // appropriate message. Admins get full view of their teams and can add users
  if (team === null || team.role !== "admin") {
    return (
      <>
        <Helmet>
          <title> Team Settings | Acoust </title>
        </Helmet>

        <Container>
          <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
            Teams
          </Typography>
          <Stack
            direction="row"
            sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
          >
            <Stack minWidth={400} padding={6}>
              {team === null ? (
                <>
                  <Typography variant="h4">
                    You currently don't have a team account
                  </Typography>
                  <br></br>
                  Contact us to enquire about tailored solutions for your team
                  (hi@acoust.io)
                </>
              ) : (
                <Typography variant="h5">
                  You're a member of {team.name}'s team account
                </Typography>
              )}
            </Stack>
            <Stack
              minHeight={400}
              sx={{
                backgroundImage: "url(/acoust-team.png)",
                backgroundSize: "contain", // You can adjust this based on your needs
                backgroundPosition: "bottom", // You can adjust this based on your needs
                backgroundRepeat: "no-repeat",
                width: "80%",
                height: "60%",
              }}
            ></Stack>
          </Stack>
        </Container>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title> Team Settings | Acoust </title>
      </Helmet>

      <Container>
        {!team && <Typography>Contact us!</Typography>}
        <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
          {team.name}
        </Typography>

        <Stack width={600} direction="row" m={2}>
          <Tabs
            textColor="inherit"
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Team" />
            <Tab label="Single Sign On" />
          </Tabs>
        </Stack>

        {activeTab === 1 && (
          <ManageSSO
            tenantProvider={"oidc.azure"}
            tenantId={"teradyne-com-2cv48"}
          ></ManageSSO>
        )}

        {activeTab === 0 && (
          <Stack>
            <Stack direction="row" justifyContent="flex-end" m={2}>
              <Button variant="contained" onClick={handleShowDialog}>
                Add Member
              </Button>
            </Stack>

            <Stack direction="row">
              <TableContainer component={Paper}>
                <Table aria-label="Members table">
                  <TableHead>
                    <TableRow key={team.id}>
                      <StyledTableCell>Email</StyledTableCell>
                      <StyledTableCell>Role</StyledTableCell>
                      <StyledTableCell>Status</StyledTableCell>
                      <StyledTableCell>Joined</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {team.admins.concat(team.members).map((member) => (
                      <TableRow
                        key={member}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell width="25%">{member}</TableCell>
                        <TableCell>
                          {teamAdmins.includes(member) ? (
                            <Typography variant="subtitle2">admin</Typography>
                          ) : (
                            "member"
                          )}
                        </TableCell>
                        <TableCell>
                          {activeTeamMembers.find(
                            (activeMember) => activeMember.email === member
                          ) ? (
                            <Typography
                              variant="subtitle2"
                              color="success.main"
                            >
                              active
                            </Typography>
                          ) : (
                            "pending"
                          )}
                        </TableCell>
                        <TableCell>
                          {(() => {
                            const joinDateString = activeTeamMembers.find(
                              (activeMember) => activeMember.email === member
                            )?.joinDate;

                            if (joinDateString) {
                              const joinDate = new Date(joinDateString);
                              return joinDate.toLocaleString();
                            } else {
                              return "n/a";
                            }
                          })()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        )}
        <Dialog
          open={showDialog}
          onClose={handleCloseDialog}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Invite Members</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              minRows={4}
              placeholder="Enter comma separated email addresses: user@example.com, user2@example.com"
              onChange={(e) => setEmails(e.target.value)}
            />
            <Stack direction="row" mt={2}>
              {errorMessage && (
                <div>
                  <Typography color="red">{errorMessage}</Typography>
                  <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                    {invalidEmails.map((email, index) => (
                      <li key={index}>
                        <Typography>{email}</Typography>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </Stack>
            <Stack direction="row" justifyContent="flex-end" mt={2}>
              <LoadingButton
                loading={sendingInvite}
                variant="contained"
                onClick={handleAddUsers}
              >
                Send Invite
              </LoadingButton>
            </Stack>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}
