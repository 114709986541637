import { Helmet } from "react-helmet-async";
import { useState } from "react";
// @mui

import {
  TextField,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Button,
  Stack,
  Typography,
} from "@mui/material";

import { db } from "../firebase.js";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import { useUserAuth } from "../UserAuthContext";
import { useNavigate } from "react-router-dom";

import Iconify from "../components/iconify";
import { now } from "lodash";

// ----------------------------------------------------------------------

function SurveyPage() {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  const questions = [
    {
      label: "What is your use case for TTS?",
      id: "usecase",
      options: [
        "Voice-over for Instagram Reels/Tiktok",
        "Voice-over for YouTube",
        "Voice-over for Explainer / Training video",
        "IVR / Voicemail",
        "Create audiobooks",
        "Listening to pdf/audiobook",
        "Embed audio on my blog",
        "Other",
      ],
    },
    {
      label: "What else is important for you?",
      id: "must-have",
      options: [
        "Video Creator",
        "Translation in mulitple languages",
        "Voice cloning (use your voice)",
        "Summarize articles and docs using AI",
        "Easily read content across the web",
        "API access",
        "Other",
      ],
    },
    {
      label: "How often do you need to generate TTS?",
      id: "frequency",
      options: [
        "Frequently / Daily",
        "Weekly: 1-2 times a week",
        "Monthly",
        "This is a one time deal",
      ],
    },
  ];

  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormErrors({ ...formErrors, [name]: false });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let errors = {};
    questions.forEach((question) => {
      if (!formData[question.id]) {
        errors[question.id] = true;
      }
    });
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      const docRef = await addDoc(collection(db, "survey"), {
        uid: user.email,
        formData,
        timestamp: serverTimestamp(),
      });

      let path = `/`;
      navigate(path);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <>
      <Helmet>
        <title> Welcome | Acoust </title>
      </Helmet>

      <Stack
        width={375}
        direction="column"
        mb={6}
        ml={10}
        alignItems="flex-start"
        justifyContent="space-around"
      >
        <Typography variant="h6" paragraph>
          Welcome! we are so excited to have you 🤩
        </Typography>
        <Typography variant="body" paragraph mb={5}>
          Before we get started, tell us a bit about yourself so we can serve
          you better
        </Typography>

        <form onSubmit={handleSubmit}>
          {questions.map((question) => (
            <FormControl key={question.label} component="fieldset">
              <FormLabel required>{question.label}</FormLabel>
              {formErrors[question.id] && (
                <FormHelperText error>Please select an option.</FormHelperText>
              )}

              <RadioGroup
                aria-label={question.id}
                name={question.id}
                key={question.id}
                value={formData[question.id] || ""}
                onChange={handleInputChange}
              >
                {question.options.map((option) => (
                  <FormControlLabel
                    key={option}
                    value={option}
                    control={<Radio size="small" />}
                    label={<Typography variant="body2">{option}</Typography>}
                  />
                ))}
              </RadioGroup>
              {formData[question.id] === "Other" && (
                <Stack>
                  <Typography>Please specify</Typography>
                  <TextField
                    key={`other_${question.id}`}
                    name={`other_${question.id}`}
                    onChange={handleInputChange}
                    fullWidth
                  />
                </Stack>
              )}
              <br></br>
            </FormControl>
          ))}

          <Stack mt={4}>
            <Button fullWidth type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
}

export default SurveyPage;
