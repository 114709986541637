import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";

// Use firestore
import { getFirestore } from "firebase/firestore";

// Use Google Auth
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";

// Stripe
import { getStripePayments } from "@stripe/firestore-stripe-payments";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpT1pTa4OZJy9JTtvnIKq4BZPv_tGPbAc",
  authDomain: "auth.acoust.io",
  projectId: "textspeech-55a09",
  storageBucket: "textspeech-55a09.appspot.com",
  messagingSenderId: "1081604157673",
  appId: "1:1081604157673:web:e3f5bb09a56202be550078",
  measurementId: "G-FZPLMZTKEP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

// Used for callable functions
const functions = getFunctions(app);

// used by stripe
const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

const signInWithGoogle = async () => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const user = result.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.customData.email;
      const credential = GoogleAuthProvider.credentialFromError(error);
    });
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await updateProfile(user, {
      displayName: name,
    });
    await sendEmailVerification(user);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export {
  analytics,
  auth,
  db,
  functions,
  httpsCallable,
  logEvent,
  registerWithEmailAndPassword,
  signInWithGoogle,
};
