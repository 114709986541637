import React from "react";
import Button from "@mui/material/Button";

function IconButton({ className, children, onClick }) {
  return (
    <Button
      onClick={onClick}
      className={` h-[46px] min-w-[42px]  p-0  hover:border-primary  border  border-solid  shadow-sm ${className}`}
    >
      {children}
    </Button>
  );
}

export default IconButton;
