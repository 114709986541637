
export const selectedProjectIndices = (state = [], action) => {
  switch (action.type) {
    case 'SELECTED_PROJECT_INDICES':
      return action.projectIndices;
    case 'ACTIVE_OBJECT':
      return action.activeObject;


    default:
      return state;
  }
};
