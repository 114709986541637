import LoadingButton from "@mui/lab/LoadingButton";
import {
  Chip,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { CanvasStore } from "../../canvas/store/canvasStore";

import ReactPlayer from "react-player";
import { handleAddAudio } from "../../canvas/utils/helper";
import VoiceSelection from "../../components/VoiceSelection/VoiceSelection";
import { useUserAuth } from "../../UserAuthContext";
import Iconify from "../iconify";

import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import {
  doc,
  updateDoc,
  getDocs,
  query,
  collection,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";

const storage = getStorage();
const synthesizeAudio = httpsCallable(functions, "genaudio", {
  timeout: 120000,
});

export default function VideoTextToSpeech(props) {
  const { activeCanvas, projectInfo } = useContext(CanvasStore);

  const [loading, setLoading] = useState(true);
  const [playPreview, setPlayPreview] = useState(false);
  const [playerUrl, setPlayerUrl] = useState("");
  const [inputText, setInputText] = useState("");
  const [audioUrl, setAudioUrl] = useState("");
  const [voice, setVoice] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [languages, setLanguages] = useState([]);
  const [openVoiceModal, setOpenVoiceModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!projectInfo) {
      return;
    }

    const loadLanguages = async () => {
      const q = query(
        collection(db, "languages"),
        orderBy("order"),
        orderBy("language")
      );
      const querySnapshot = await getDocs(q);
      setLanguages(querySnapshot.docs.map((doc) => ({ ...doc.data() })));

      if (projectInfo.projectLang) {
        setSelectedLanguage(projectInfo.projectLang);
      } else {
        setSelectedLanguage(querySnapshot.docs[0].data().code);
      }
    };

    loadLanguages();
    setInputText(projectInfo.tts_text || "");
    setVoice(projectInfo.tts_voice || "");
    setAudioUrl(projectInfo.audioUrl || "");
    setLoading(false);
  }, []);

  const handleTextChange = (event) => {
    setInputText(event.target.value);
    //setAudioGsUrl("");
    setAudioUrl("");
  };

  const generateAudio = async () => {
    setProcessing(true);
    try {
      const result = await synthesizeAudio({
        voice: voice.voice,
        source: voice.cloud,
        langCode: voice.code,
        text: inputText,
      });
      let gsUrl = result.data.gcsUrl;
      let audioUrl = await getDownloadURL(ref(storage, gsUrl));
      setAudioUrl(audioUrl);
      handlePlayPreview(audioUrl);

      updateDoc(doc(db, "projects", projectInfo.id), {
        tts_text: inputText,
        tts_audioUrl: audioUrl,
        tts_voice: voice,
      });

      return audioUrl;
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
    }
  };

  const handlePlayPreview = (url) => {
    setPlayerUrl(url);
    if (url === playerUrl) {
      setPlayPreview(false);
      setPlayerUrl("");
    } else {
      setPlayPreview(true);
    }
  };

  const handleCloseVoiceModal = () => {
    setOpenVoiceModal(false);
  };

  const handleOpenVoiceModal = () => {
    setOpenVoiceModal(true);
  };

  const handleVoiceChange = (voice) => {
    console.log("handle", voice);
    setVoice(voice);
    setAudioUrl("");
  };

  const handleAudioEnded = () => {
    setPlayPreview(false);
    setPlayerUrl("");
  };

  const handleAddAudioClick = (url) => {
    const text = `Text to Speech ${inputText.slice(0, 10)}`;
    handleAddAudio(url, "Text to Speech", activeCanvas);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Stack minHeight="70vh">
      <Stack mt={4}>
        <Tooltip title="Select voice" placement="top" arrow>
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            sx={{
              color: "white",
              border: "2px double transparent",
              backgroundImage:
                "linear-gradient(rgb(13, 14, 33), rgb(13, 14, 33)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
              boxShadow: "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
            }}
            onClick={handleOpenVoiceModal}
            onDelete={handleOpenVoiceModal}
            deleteIcon={<Iconify icon="eva:chevron-down-outline" />}
            label={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Iconify icon="mingcute:voice-fill" />
                &nbsp;
                <>
                  {voice ? (
                    `${voice.display_name}`
                  ) : (
                    <CircularProgress size={10} />
                  )}

                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "text.secondary",
                    }}
                  ></Typography>
                </>
              </Stack>
            }
          />
        </Tooltip>
      </Stack>
      <Stack mt={2}>
        <TextField
          value={inputText}
          placeholder="Enter text here..."
          variant="standard"
          multiline
          fullWidth
          minRows={12}
          helperText={inputText ? `${inputText.length}/3000 characters` : null}
          onChange={handleTextChange}
          inputProps={{
            maxLength: 3000,
            style: { fontSize: 14, fontFamily: "Roboto", color: "white" },
          }}
          sx={{
            border: (theme) => `solid 1px ${theme.palette.divider}`,
            //bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
          error={inputText.length >= 3000}
        />

        <Stack direction="row" spacing={1} mt={2} justifyContent="flex-end">
          <LoadingButton
            loading={processing}
            disabled={!inputText}
            variant="outlined"
            onClick={() =>
              audioUrl ? handlePlayPreview(audioUrl) : generateAudio()
            }
            startIcon={
              <Iconify
                icon={playPreview ? "material-symbols:pause" : "octicon:play"}
              />
            }
          >
            Preview
          </LoadingButton>
          <LoadingButton
            disabled={!audioUrl}
            variant="contained"
            onClick={() => handleAddAudioClick(audioUrl)}
          >
            Add to Timeline
          </LoadingButton>
        </Stack>
      </Stack>
      <VoiceSelection
        openVoiceModal={openVoiceModal}
        handleCloseVoiceModal={handleCloseVoiceModal}
        setSelectedVoice={setVoice}
        selectedVoice={voice}
        setSelectedLanguage={setSelectedLanguage}
        selectedLang={selectedLanguage}
        languages={languages}
        handleVoiceChange={handleVoiceChange}
      />
      <div style={{ display: "none" }}>
        <ReactPlayer
          playing={playPreview}
          url={playerUrl}
          onEnded={handleAudioEnded}
        />
      </div>
    </Stack>
  );
}
