import { copyObject, pasteObject } from "../utils/actions";
import canvasActions from "../utils/canvasActions";

export function allowKeyboardEvent(target) {
  return !(
    target.tagName === "INPUT" ||
    target.tagName === "TEXTAREA" ||
    target.id === "video-canvas"
  );
}
export function handleShortcutKeys(canvas) {
  const keyDownHandler = async (event) => {
    const { target, code, metaKey, ctrlKey } = event;
    if (!allowKeyboardEvent(target)) return;
    let activeObject = canvas.getActiveObject();
    //check if ctrl is active
    if (ctrlKey || metaKey) {
      if (code === "KeyC" || code === "KeyX") {
        if (activeObject) {
          copyObject(activeObject);
        }
      } else if (code === "KeyV") {
        pasteObject(canvas);
      }
    }
    if (code === "Delete" || code === "Backspace") {
      if (canvas.inAppState === "viewOnly") return;

      const active = canvas.getActiveObject();
      if (!active) return;
      canvasActions.remove(active);
      canvas.remove(active);
      canvas.renderAll();
    }
  };

  document.addEventListener("keydown", keyDownHandler);

  canvas.__keyboardEvents = [
    {
      name: "keydown",
      handler: keyDownHandler,
    },
  ];
}
