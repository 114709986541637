import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Button,
  CircularProgress,
  IconButton,
  Grid,
  Paper,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";

import { db } from "../../firebase.js";
import {
  addDoc,
  getDoc,
  collection,
  doc,
  getDocs,
  setDoc,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore";

import useResponsive from "../../hooks/useResponsive";

import { useUserAuth } from "../../UserAuthContext";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

import Iconify from "../iconify";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { alpha, useTheme } from "@mui/material/styles";
// ----------------------------------------------------------------------

export default function Templates(props) {
  const theme = useTheme();
  const [templates, setTemplates] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [playingIndex, setPlayingIndex] = useState("");

  const [loading, setLoading] = useState(true);
  const [elevation, setElevation] = useState(12);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);

  const { user } = useUserAuth();
  const isDesktop = useResponsive("up", "lg");

  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 40,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      partialVisibilityGutter: 10,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  };

  const handleMouseEnter = (index) => {
    setSelectedCardIndex(index);
    setElevation(12); // Set a higher elevation when hovering
  };

  const handleMouseLeave = () => {
    setElevation(8); // Reset elevation when not hovering
    setSelectedCardIndex(-1);
  };

  const cardData = [
    {
      icon: "/audio-waves.png",
      title: "Audio",
      description:
        "Create engaging content using multiple voices, advanced customizations and emotions",
      type: "audio",
      highlights: [
        "Voices with emotion",
        "Dialogue (multi-voice)",
        "Speech to text",
        "Video Import",
      ],
    },
    {
      icon: "/audio-book.png",
      title: "Narrator",
      description:
        "Ideal for narrating long-form content, such as PDFs, books, or articles",
      type: "longsyn",
      highlights: [
        "Narration optimized voices",
        "250K character limit",
        "AI Summarizer",
        "PDF support",
      ],
    },
    /*{
      icon: "fa-solid:video",
      title: "Video",
      description:
        "Create captivating videos by combining text to speech and video clips",
      type: "video",
      highlights: ["Voices with emotion", "Stock Media", "AI Writer"],
    },*/
  ];

  async function getTemplates() {
    // console.log("loading templates");
    const q = query(
      collection(db, "projects"),
      where("isTemplate", "==", true)
    );

    const querySnapshot = await getDocs(q);
    // console.log(`here: ${querySnapshot.docs.length}`);
    setTemplates(
      querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          created: doc.data()["created"].toDate().toLocaleDateString(),
          name: doc.data()["name"],
          type: doc.data()["type"],
          mergedAudioUrl:
            doc.data()["mergedAudioUrl"] || doc.data()["audioUrl"],
          mergedVideoUrl: doc.data()["mergedVideoUrl"],
          templateImage: doc.data()["templateImage"] || "",
        }))
        .sort((a, b) => a.type.localeCompare(b.type))
    );
  }

  useEffect(() => {
    getTemplates().then(() => {
      setLoading(false);
    });
  }, [user]);

  const handleNewTemplate = async (type) => {
    const docRef = await addDoc(collection(db, "projects"), {
      user: user.email,
      name: `Proj ${new Date().toLocaleDateString()}`,
      created: serverTimestamp(),
      archived: false,
      type: type,
    });
    console.log(docRef.id);
    handleUserNavigation(docRef.id, type);
  };

  const handleUserNavigation = (templateId, type) => {
    // default audio
    let path = `/editor?id=${templateId}`;

    if (type === "longsyn") {
      path = `/books?id=${templateId}`;
    }

    navigate(path);
  };

  const playPreview = async (index) => {
    if (index === playingIndex) {
      setPlayingIndex(null);
      return;
    }
    setPlayingIndex(index);
    //setPreviewUrl(url);
  };

  const handlePreviewEnded = () => {
    setPreviewUrl(null);
  };

  const handleProjectCloning = async (e, templateId) => {
    console.log(templateId);

    try {
      const sourceDocumentRef = doc(db, "projects", templateId);
      const sourceDocumentSnapshot = await getDoc(sourceDocumentRef);

      if (sourceDocumentSnapshot.exists()) {
        const sourceData = sourceDocumentSnapshot.data();
        sourceData.name += " copy";
        sourceData.user = user.email;
        sourceData.created = serverTimestamp();
        sourceData.isTemplate = false;
        // Create a new document with the same data
        console.log("here");
        const projectsCollectionRef = collection(db, "projects");
        const newDocumentRef = doc(projectsCollectionRef);
        console.log(newDocumentRef);
        console.log(sourceData);
        await setDoc(newDocumentRef, sourceData);

        console.log("Document copied successfully");
        handleUserNavigation(newDocumentRef.id, sourceData.type);
      } else {
        console.log("Source document does not exist");
      }
    } catch (error) {
      console.error("Error copying document:", error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  const renderItem = (template, index) => {
    return (
      <Paper
        key={index}
        elevation={index === selectedCardIndex ? elevation : 4}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={handleMouseLeave}
      >
        <Stack sx={{ width: 250 }} padding={2}>
          <Stack direction="row" spacing={2}>
            {template.type && template.type === "video" ? (
              <Iconify
                width={20}
                icon="fa-solid:video" // fix this when launching
                sx={{ color: "gray" }}
              />
            ) : template.type === "longsyn" ? (
              <img
                src={cardData[1].icon}
                alt="Audiobook"
                style={{ maxWidth: "20px", height: "auto" }}
              />
            ) : (
              <img
                src={cardData[0].icon}
                alt="Audio Content"
                style={{ maxWidth: "20px", height: "auto" }}
              />
            )}
            <Tooltip title={template.name} placement="top" arrow>
              <Typography
                variant="subtitle1"
                display="block"
                sx={{
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  maxWidth: "120px",
                }}
              >
                {template.name}
              </Typography>
            </Tooltip>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              minHeight: "150px",
              background:
                selectedCardIndex === index || template.mergedVideoUrl
                  ? "inherit"
                  : template.templateImage
                  ? `url(${template.templateImage})`
                  : `url("/shortlogo.png")`,
              backgroundSize: template.templateImage ? "60%" : "40%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
            }}
          >
            {selectedCardIndex === index && (
              <div style={{ position: "absolute", zIndex: 1 }}>
                <IconButton
                  color="primary"
                  onClick={() => playPreview(index)}
                  sx={{
                    color: theme.palette.common.white,
                  }}
                >
                  <Iconify
                    width="50px"
                    icon={
                      playingIndex === index
                        ? "material-symbols:pause"
                        : "icon-park-solid:play"
                    }
                  />
                </IconButton>
              </div>
            )}
            <ReactPlayer
              height="80%"
              width="80%"
              url={template.mergedVideoUrl || template.mergedAudioUrl}
              preload="none"
              playing={index === playingIndex}
            />
          </Stack>
          <Stack mt={1}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              aria-label="edit"
              onClick={(e) => handleProjectCloning(e, template.id)}
              sx={{
                border: "0px",
                "&:hover": {
                  border: "0px",
                  backgroundColor: alpha(theme.palette.primary.main, 0.3),
                },
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
              }}
            >
              Edit
            </Button>
          </Stack>
        </Stack>
      </Paper>
    );
  };

  return (
    <>
      {props.carousel ? (
        <Carousel
          responsive={responsive}
          additionalTransfrom={0}
          arrows
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          containerClass="container"
          dotListClass=""
          draggable
          focusOnSelect={false}
          infinite={false}
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
        >
          {templates.map((template, index) => (
            <Stack key={template.id} alignItems="center">
              {renderItem(template, index)}
            </Stack>
          ))}
        </Carousel>
      ) : (
        <Grid container spacing={2}>
          {templates.map((template, index) => (
            <Grid item key={template.id}>
              {renderItem(template, index)}
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
