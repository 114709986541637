import React, { useContext, useState, useEffect } from "react";
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  Paper,
  Tooltip,
} from "@mui/material";

import { CanvasStore } from "../../canvas/store/canvasStore";

import Iconify from "../iconify";
import { useUserAuth } from "../../UserAuthContext";
import ReactPlayer from "react-player";
import { handleAddAudio } from "../../canvas/utils/helper";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase";

export default function UserProjectsList(props) {
  const { activeCanvas } = useContext(CanvasStore);

  const [loading, setLoading] = useState(false);
  const [playPreview, setPlayPreview] = useState(false);
  const [playerUrl, setPlayerUrl] = useState("");

  const [userFiles, setUserFiles] = useState([]);
  const { user } = useUserAuth();

  const handlePlusButtonClick = (item) => {
    handleAddAudio(item.url, item.name, activeCanvas);
  };

  useEffect(() => {
    async function getProjects() {
      const q = query(
        collection(db, "projects"),
        where("user", "==", user.email),
        where("archived", "==", false),
        where("type", "==", "audio"),
        orderBy("created", "desc")
      );

      const querySnapshot = await getDocs(q);

      setUserFiles(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          //timestamp: doc.data()["timestamp"],
          gsUrl: doc.data()["gs_url"],
          url: doc.data()["mergedAudioUrl"] || null,
          name: doc.data()["name"],
        }))
      );
    }
    setLoading(true);
    getProjects();
    setLoading(false);
  }, []);

  const handlePlayPreview = (url) => {
    setPlayerUrl(url);
    if (url === playerUrl) {
      setPlayPreview(false);
      setPlayerUrl("");
    } else {
      setPlayPreview(true);
    }
  };

  const handleAudioEnded = () => {
    setPlayPreview(false);
    setPlayerUrl("");
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className="my-4 group">
        {!userFiles.length && (
          <Stack
            justifyContent="center"
            alignItems="center"
            padding={4}
            spacing={2}
          >
            <Typography variant="caption">
              hmm, can't seem to locate any projects
            </Typography>
          </Stack>
        )}

        <div className="relative flex flex-col gap-3">
          {userFiles.map((file, index) => (
            <Paper elevation={4} key={index}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                padding={2}
              >
                <Tooltip title={file.name} arrow placement="top">
                  <Typography
                    variant="caption"
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: "100px", // Set your desired maximum width
                      color: "#fff",
                    }}
                  >
                    {file.name}
                  </Typography>
                </Tooltip>

                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <IconButton
                    size="small"
                    color="primary"
                    disabled={!file.url}
                    onClick={() => handlePlayPreview(file.url)}
                  >
                    {playPreview && file.url === playerUrl ? (
                      <Iconify icon="material-symbols:pause" />
                    ) : (
                      <Iconify icon="octicon:play" />
                    )}
                  </IconButton>

                  <IconButton
                    variant="contained"
                    color="primary"
                    disabled={!file.url}
                    onClick={() => handlePlusButtonClick(file)}
                  >
                    <Iconify icon={"zondicons:add-solid"} />
                  </IconButton>
                </Stack>
              </Stack>
            </Paper>
          ))}
        </div>
      </div>
      <div style={{ display: "none" }}>
        <ReactPlayer
          playing={playPreview}
          url={playerUrl}
          onEnded={handleAudioEnded}
        />
      </div>
    </>
  );
}
