/**
 * @description Checks if TouchEvent is present in document
 *
 * @return {boolean}
 */
export function isTouchDevice() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * @description clones an array or object deeply and immutably
 * does safety checks for undefined etc.
 *
 * @param {object|array} obj
 *
 * @returns {object|array}
 */
export function cloneDeepImmutable(obj) {
  try {
    if (obj) return JSON.parse(JSON.stringify(obj));
    else return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export function formatTimeToMinSecMili(time) {
  const mili = Math.floor((time % 1000) / 10 );
  return formatTimeToMinSec(time / 1000) + `.${appendZero(mili, 2)}`;
}
export function formatTimeToMinSec(time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${appendZero(seconds,2)}`;
}
function appendZero(value, minDigits = 2) {
  return value.toString().padStart(minDigits, "0");
}

