import React, { useContext, useState, useRef, useEffect } from "react";
import { Modal, Box, Tabs, Tab, Popover } from "@mui/material";
import ColorPickerModal from "../../color/ColorPicker";
import Uploader from "../../media/component/FileUploader";
import { CanvasStore } from "../../canvas/store/canvasStore";

const Background = ({ id, open, handleClose, anchorEl, selectedColor }) => {
  const [tabValue, setTabValue] = useState("color");
  const { activeCanvas } = useContext(CanvasStore);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const modalStyle = {
    height: 400,
    bgcolor: "#fff",
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 3,
  };

  const uploaderContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  };

  const handleCanvasBgColor = (color) => {
    activeCanvas.getObjects("artboard")[0].set({ fill: color });
    selectedColor(color);
    activeCanvas.requestRenderAll();
  };

  const modalBody = (
    <Box sx={modalStyle}>
      {/*<Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant="fullWidth"
            >
                <Tab label="Color" value="color" sx={{ color: tabValue === 'color' ? 'black' : 'rgba(0, 0, 0, 0.54)', fontWeight: tabValue === 'color' ? 'bold' : 'normal' }} />
                <Tab label="Image" value="image" sx={{ color: tabValue === 'image' ? 'black' : 'rgba(0, 0, 0, 0.54)', fontWeight: tabValue === 'image' ? 'bold' : 'normal' }} />
            </Tabs>*/}
      <div style={uploaderContainerStyle}>
        {tabValue === "color" && (
          <ColorPickerModal
            onClose={() => {}}
            onSelectedColor={(color) => handleCanvasBgColor(color)}
          />
        )}
        {tabValue === "image" && <Uploader image={true} />}
      </div>
    </Box>
  );

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{ vertical: "bottom", horizontal: "left" }}
      sx={{
        "& .MuiPaper-root": {
          width: "280px",
          background: "white",
          borderRadius: "0.625rem",
          boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 6px",
        },
      }}
    >
      <div>{modalBody}</div>
    </Popover>
  );
};

export default Background;
