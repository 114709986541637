"use client";

import React from "react";

import PageHeader from "../shared/PageHeader";

import ActionButtons from "./component/ActionButtons";
import Uploader from "./component/FileUploader";
import StockMusic from "./component/StockMusic";
import { Stack, Tabs, Tab, Tooltip } from "@mui/material";
import UserMediaFiles from "../components/UserMediaFiles/UserMediaFiles";
import UserProjectsList from "../components/UserProjectsList/UserProjectsList";
import VideoTextToSpeech from "../components/VideoTextToSpeech/VideoTextToSpeech";

const Audio = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Stack>
      <PageHeader heading="Add Audio" />
      <Uploader />

      <Stack mt={4}>
        <Tabs
          textColor="primary"
          variant="fullWidth"
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tooltip
            title="Import audio from your projects"
            arrow
            placement="top"
          >
            <Tab label="Text To Speech" sx={{ fontSize: "10px" }}></Tab>
          </Tooltip>
          <Tooltip
            title="Import audio from your projects"
            arrow
            placement="top"
          >
            <Tab label="Audio Projects" sx={{ fontSize: "10px" }}></Tab>
          </Tooltip>
          <Tooltip
            title="Spice up your video with stock audio"
            arrow
            placement="top"
          >
            <Tab label="Stock" sx={{ fontSize: "10px" }}></Tab>
          </Tooltip>
          <Tooltip title="Personalize with your audio" arrow placement="top">
            <Tab label="Uploads" sx={{ fontSize: "10px" }}></Tab>
          </Tooltip>
        </Tabs>

        {activeTab === 0 && <VideoTextToSpeech />}
        {activeTab === 1 && <UserProjectsList />}
        {activeTab === 2 && <StockMusic />}
        {activeTab === 3 && <UserMediaFiles mediaType="music" />}
      </Stack>
    </Stack>
  );
};

export default Audio;
