import React from 'react';

function TextBtn({isActive,onClick,text,onMouseLeave,onMouseEnter}) {
    return (
        <div className={`  rounded-[8px] px-2   hover:border-primary hover:text-primary cursor-pointer border border-[#e1e1e3] border-solid shadow-sm ${isActive?'bg-[#eff9ff] border-primary text-primary':''}`}
             onMouseEnter={onMouseEnter}
             onMouseLeave={onMouseLeave}
             onClick={onClick}>
                    <span className={'text-sm capitalize'}>
                        {text}
                    </span>
        </div>
    );
}

export default TextBtn;