import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Crisp } from "crisp-sdk-web";

import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithEmailAndPassword,
  isSignInWithEmailLink,
  signOut,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  OAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "./firebase";

import { clarity } from "clarity-js";
import mixpanel from "mixpanel-browser";

import { db } from "./firebase.js";
import { functions, httpsCallable } from "./firebase"; // import Firebase functions instance

import { doc, serverTimestamp, setDoc, getDoc } from "firebase/firestore";

// -----
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [isUserPremium, setIsUserPremium] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const manageSsoFunction = httpsCallable(functions, "managesso");

  // function signUp(email, password) {
  //   return createUserWithEmailAndPassword(auth, email, password);
  // }
  function logOut() {
    signOut(auth)
      .then(() => {
        setUser(null);
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }

  const verifyEmail = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      console.log("trying to sign in");
      let email = window.localStorage.getItem("emailForSignIn");
      let name = window.localStorage.getItem("nameForSignIn");
      // console.log(`email: ${email}`);

      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }
      try {
        console.log("logging in");
        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        );

        if (name) {
          await updateProfile(result.user, {
            displayName: name,
          });
        }

        const { isNewUser } = getAdditionalUserInfo(result);

        if (isNewUser) {
          window.localStorage.setItem("isNewUser", isNewUser);
          mixpanel.track("Email Sign up");
        }

        // Clear email from storage.
        window.localStorage.removeItem("emailForSignIn");
        window.localStorage.removeItem("nameForSignIn");
      } catch (err) {
        console.log(err);
      }
    }
  };

  function signInEmailPassword(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  const signInSSO = async (email) => {
    const emailParts = email.split("@")[1].split(".");

    const emailDomain =
      emailParts.length > 1 ? emailParts.slice(-2).join(".") : emailParts[0];

    // console.log(emailDomain);

    let provider;
    let tenantId;
    try {
      console.log("trying to sign in");
      const ssoProvider = await manageSsoFunction({ emailDomain });
      const customerSsoProvider = ssoProvider.data.tenantProvider;
      provider = new OAuthProvider(customerSsoProvider); // new OAuthProvider(customerProvider);
      tenantId = ssoProvider.data.tenantId;
      //console.log(provider);
      // console.log("tenantId" + tenantId);
    } catch (err) {
      if (err.code === "functions/not-found") {
        console.log("SSO provider not found");
        navigate("/sso?error=fail", { replace: true });
        return;
      }
    }

    if (!tenantId) {
      console.error("tenantId is null");
      return;
    }

    auth.tenantId = tenantId;

    signInWithPopup(auth, provider)
      .then((result) => {
        // User is signed in.
        const credential = OAuthProvider.credentialFromResult(result);
        // This gives you an access token for the OIDC provider. You can use it to directly interact with that provider
      })
      .catch((error) => {
        navigate("/sso?error=fail", { replace: true });
        console.log(error);
      });
  };

  function signUpEmailPassword(name, email, password, plan) {
    //console.log(name, email, password, plan);

    window.localStorage.setItem("emailForSignIn", email);

    if (plan) {
      window.localStorage.setItem("plan", plan);
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed up
        const user = userCredential.user;
        sendEmailVerification(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }

  function sendForgotEmailPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  async function signUpMagicLink(name, email, plan) {
    window.localStorage.setItem("nameForSignIn", name);
    if (plan) {
      window.localStorage.setItem("plan", plan);
    }
    await sendMagicLink(email);
  }

  async function sendMagicLink(email) {
    const actionCodeSettings = {
      url: "https://app.acoust.io/verify",
      // This must be true.
      handleCodeInApp: true,
    };
    window.localStorage.setItem("emailForSignIn", email);
    await sendSignInLinkToEmail(auth, email, actionCodeSettings).catch(
      (error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      }
    );
  }

  async function isPremiumUser(currentuser) {
    if (!currentuser) {
      return;
    }
    await currentuser.getIdToken(true);
    const decodedToken = await currentuser.getIdTokenResult();
    let isPremium = !!decodedToken.claims.stripeRole;

    if (!isPremium) {
      //Check if user is a team member
      // console.log(await isTeamMember());
      isPremium = !!(await isTeamMember(currentuser));
    }
    console.log(isPremium);
    setIsUserPremium(isPremium);
  }

  async function isTeamMember(currentuser) {
    if (currentuser) {
      const documentSnapshot = await getDoc(
        doc(db, "customers", currentuser.uid)
      );
      const documentData = documentSnapshot.data();
      return documentData.team;
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      // console.log("Auth", currentuser);
      if (currentuser) {
        //console.log(currentuser);

        if (!currentuser.tenantId && !currentuser.emailVerified) {
          navigate("/vp", { replace: true });
        }

        const updateUserInfo = async () => {
          // Log when the user last logged in
          const userDocRef = doc(db, "customers", currentuser.uid);

          const dataToUpdate = {
            last_login: serverTimestamp(), // set to false by the function
          };

          try {
            await setDoc(userDocRef, dataToUpdate, { merge: true });
          } catch (error) {
            console.log(error);
          }

          await isPremiumUser(currentuser);
        };

        updateUserInfo().then(() => {
          setUser(currentuser);
          setIsLoading(false);
          clarity.set("user_email", currentuser.email);
          Crisp.user.setEmail(currentuser.email);
        });

        mixpanel.identify(currentuser.uid);
        mixpanel.people.set({
          $email: currentuser.email,
          //isPremium: isUserPremium(),
          // Add anything else about the user here
        });

        mixpanel.track("Sign in");

        // Set identity in the session trackers
        //Hotjar.identify(currentuser.email, {
        //  email: currentuser.email,
        //});
        //LogRocket.identify(currentuser.email, {
        //  email: currentuser.email,
        //});
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        logOut,
        isLoading,
        sendMagicLink,
        signUpMagicLink,
        verifyEmail,
        isUserPremium,
        signUpEmailPassword,
        signInEmailPassword,
        sendForgotEmailPassword,
        isTeamMember,
        signInSSO,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
