import React, { useEffect, useContext, useRef, useState } from "react";
import { CanvasStore } from "../../canvas/store/canvasStore";

export const maxTimelineLengthInSeconds = 300;
const SIDEBAR_WIDTH = 110;
const RULER_DOT_SIZE = 1;
const TimelineRuler = ({
  onChange,
  zoom,
  animationDuration,
  onPixelPerSecondChange,
  scrollLeft,
}) => {
  const canvasRef = useRef(null);
  const { activeCanvas } = useContext(CanvasStore);
  const refIsMouseDown = useRef(false);
  const clicks = Array.from(Array(maxTimelineLengthInSeconds).keys());
  const [timeLineRefWidth, setTimeLineRefWidth] = useState(50);
  const [pixelsPerSecond, setPixelsPerSecond] = useState(1000);

  useEffect(() => {
    let ppx = (timeLineRefWidth * zoom) / maxTimelineLengthInSeconds;
    setPixelsPerSecond(ppx);
    onPixelPerSecondChange(ppx);
  }, [zoom, timeLineRefWidth, maxTimelineLengthInSeconds]);

  useEffect(() => {
    // update canvas size based on container size
    const handleResize = () => {
      if (canvasRef.current) {
        setTimeLineRefWidth(canvasRef.current.parentElement?.clientWidth);
      }
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (activeCanvas) {
      activeCanvas.on("seekber:mouse:down", handleRulerMouseDown);
    }
    return () => {
      if (activeCanvas) {
        activeCanvas.off("seekber:mouse:down", handleRulerMouseDown);
      }
    };
  });

  function updateFromMouseEvent(e) {
    if (e.clientX < 0) e.clientX = 0;
    let translateX =
      refIsMouseDown.current.initialValue +
      (e.clientX - refIsMouseDown.current.clientX);

    if (translateX < 0) translateX = 0;

    let newTime = (translateX / pixelsPerSecond) * 1000;

    if (newTime > animationDuration) newTime = animationDuration;
    onChange(newTime);
  }

  const handleStopSeeking = function (e) {
    document.body.style.userSelect = "default";

    window.removeEventListener("mousemove", updateFromMouseEvent);
    window.removeEventListener("mouseup", handleStopSeeking);
  };
  const handleRulerMouseDown = (e) => {
    document.body.style.userSelect = "none";
    refIsMouseDown.current = e;
    refIsMouseDown.current.initialValue =
      e.clientX - SIDEBAR_WIDTH + scrollLeft;
    updateFromMouseEvent(e);
    window.addEventListener("mousemove", updateFromMouseEvent);
    window.addEventListener("mouseup", handleStopSeeking);
  };
  let oneFourthSecond = pixelsPerSecond / 4;
  let skipCount = 0;
  if (pixelsPerSecond < 32) {
    skipCount = 10;
  } else if (pixelsPerSecond < 65) {
    skipCount = 5;
  } else if (pixelsPerSecond < 125) {
    skipCount = 2;
  }
  // let oneThirdSecond = (pixelsPerSecond) / 2;
  let dotStyle = {
    left: `calc(25% - ${RULER_DOT_SIZE / 2}px)`,
    top: `calc(50% - ${RULER_DOT_SIZE / 2}px)`,
    height: RULER_DOT_SIZE,
    width: RULER_DOT_SIZE,
    borderRadius: "50%",
    backgroundColor: "rgb(161, 161, 170)",
  };

  let dashStyle = {
    left: `calc(25% - ${RULER_DOT_SIZE / 2}px)`,
    top: `calc(25% - ${RULER_DOT_SIZE / 2}px)`,
    height: `${RULER_DOT_SIZE * 10}px`, // Adjust the height to be thinner, creating a dash effect
    width: `${RULER_DOT_SIZE / 2}px`, // Adjust the width to be longer
    backgroundColor: "rgb(161, 161, 170)",
  };

  let shortDashStyle = {
    left: `calc(25% - ${RULER_DOT_SIZE / 2}px)`,
    top: `calc(100% - ${RULER_DOT_SIZE - 3}px)`,
    height: RULER_DOT_SIZE * 4,
    width: RULER_DOT_SIZE * 4,
    borderRadius: "50%",
    backgroundColor: "rgb(161, 161, 170)",
  };

  const formatTime = (i) => {
    const minutes = Math.floor(i / 60);
    const seconds = i % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div className="relative w-full" onMouseDown={handleRulerMouseDown}>
      <div
        className="w-full relative w-full h-6   block border-b border-zinc-400 rounded-none overflow-hidden"
        style={{
          borderBottom: "1px solid #e4e4e6",
        }}
        ref={canvasRef}
      >
        <div
          className="absolute pl-[10px] right-0 top-0 flex items-end h-full"
          style={{
            left: 0 - scrollLeft,
          }}
        >
          {clicks.map((click, i) => {
            let isA = !skipCount;
            if (skipCount) {
              isA = i % skipCount === 0;
            }

            return (
              <div
                key={i}
                className="relative flex items-center h-full flex-shrink-0"
                style={{ width: pixelsPerSecond }}
              >
                {isA ? (
                  <div
                    style={{
                      left: -oneFourthSecond / 2,
                      width: oneFourthSecond,
                      fontSize: 10,
                    }}
                    className="absolute flex flex-col justify-center items-center h-2 -mt-4"
                  >
                    <span className="text-slate-200 text-text-03 text-caption-01">
                      {formatTime(i)}
                    </span>
                    <div
                      className={"absolute"}
                      style={{
                        ...shortDashStyle,
                        left: 0,
                      }}
                    ></div>
                  </div>
                ) : (
                  <div
                    className={"absolute"}
                    style={{ ...(i % 5 === 0 ? dashStyle : dotStyle), left: 0 }}
                  ></div>
                )}
              </div>
            );
          })}
        </div>

        <div
          style={{
            left:
              (animationDuration / 1000) * pixelsPerSecond - scrollLeft + 10,
            display: "inline-block",
            borderLeft: "1px solid #e4e4e6",
            background: `repeating-linear-gradient(-45deg, rgba(228, 228, 230, 0.2), rgba(228, 228, 230, 0.2) 1px, transparent 1px, transparent 6px)`,
            //background: "rgb(63 63 70)",
          }}
          className="absolute   right-0 top-0 flex items-end h-full"
        ></div>
      </div>
    </div>
  );
};

export default TimelineRuler;
