import ObjectID from "bson-objectid/objectid";
import { registerHistoryEvents } from "./historyEvents";
import { gestureEvents } from "./gestureEvents";
import {switchObjectParent,handleSkipObjectsSelection} from "../utils/canvasUtils";

export function commonCanvasEvents(canvas) {
  registerHistoryEvents(canvas);
  // saveDesignEvent(canvas);
  gestureEvents(canvas);
  handleSelectionEvents(canvas);
  handleObjectMovingEvents(canvas)
  canvas.on("object:added", (opts) => {
    let { target } = opts;
    if (!target._id) target._id = ObjectID().toHexString();
    if (target.skipAddingAsChild) return;
    if (target.parentArtboard) {
      if (!target.__parentArtboard) {
        const artboard = canvas.getObjectById(
          target.parentArtboard,
          "artboard"
        );
        if (artboard) {
          artboard.addChild(target);
        }
      }
    } else {
      let artboards = canvas.getObjects("artboard");
      let artboard = artboards
        .filter((artboard) => artboard.checkContainedWithin(target))
        .pop();
      if (artboard) {
        artboard.addChild(target);
      }
    }
  });
}
function handleObjectMovingEvents(canvas) {
  canvas.on("object:moving",switchObjectParent)
}



function handleSelectionEvents(canvas) {
  canvas.on("selection:created", handleSkipObjectsSelection);
  canvas.on("selection:updated", handleSkipObjectsSelection);
}

