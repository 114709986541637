/**
 * @description limits the number of decimals in a float
 *
 * @param {number} float - the float number
 * @param {number} limit - number of decimals, defaults to 4
 *
 * @returns {number}
 */
export function toFixed(float, limit = 4) {
  return Math.round(float * Math.pow(10, limit)) / Math.pow(10, limit);
}

/**
 * @description rotates a point around another point
 *
 * @param {number} x - of point to rotate
 * @param {number} y - of point to rotate
 * @param {number} cx - centerX of rotation
 * @param {number} cy - centerY of rotation
 * @param {number} angle - angle in degrees
 *
 * @return {{x: number, y: number}}
 */
export function rotateAroundCenter(x, y, cx, cy, angle) {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) + sin * (y - cy) + cx;
  const ny = cos * (y - cy) - sin * (x - cx) + cy;

  return { x: nx, y: ny };
}

export const lerp = (a, b, t) => a + (b - a) * t;
/**
 * @description linear interpolation on a line
 *
 * @param {{x: number, y: number}} p1 - point 1
 * @param {{x: number, y: number}} p2 - point 2
 * @param {number} t - normalized interpolation amount
 *
 * @returns {{x: number, y: number}}
 */
export const lerp2D = (p1, p2, t) => ({
  x: lerp(p1.x, p2.x, t),
  y: lerp(p1.y, p2.y, t),
});
