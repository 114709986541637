import { useEffect, useState } from "react";
import {
  Button,
  Check,
  Chip,
  IconButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import { useTheme } from "@mui/material/styles";

import Iconify from "../iconify";

export default function SpeechOptions(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const isDesktop = useResponsive("up", "lg");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!selectedOption && props.labelText === "Speed") {
      setSelectedOption(1);
    } else if (!selectedOption && props.labelText === "Pitch") {
      setSelectedOption("Default");
    }
  }, [selectedOption, props.labelText]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuClick(label, option) {
    setSelectedOption(option);
    // console.log(option)
    if (label === "Speed") {
      props.handleSpeedChange(option, props.index);
    } else if (label === "Pitch") {
      props.handlePitchChange(option, props.index);
    }
    handleClose();
  }

  return (
    <div>
      <Tooltip title={props.tip} placement="top">
        {isDesktop ? (
          <Button
            sx={{
              color: theme.palette.text.secondary,
              textTransform: "none",
            }}
            variant="text"
            disabled={props.disabled}
            size="small"
            onClick={handleClick}
            startIcon={<Iconify icon={props.icon} />}
          >
            {props.labelText}: {selectedOption}
            {props.labelText === "Speed" ? "x" : ""}
          </Button>
        ) : (
          <IconButton
            variant="text"
            disabled={props.disabled}
            size="small"
            onClick={handleClick}
          >
            <Iconify icon={props.icon} />
          </IconButton>
        )}
      </Tooltip>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {props.options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuClick(props.labelText, option)}
          >
            {option === selectedOption && (
              <ListItemIcon>
                <Iconify icon="material-symbols:check-small" />
              </ListItemIcon>
            )}
            {option === selectedOption && (
              <ListItemText>
                {option}
                {props.labelText === "Speed" ? "x" : ""}
              </ListItemText>
            )}
            {option !== selectedOption && (
              <ListItemText inset>
                {option}
                {props.labelText === "Speed" ? "x" : ""}
              </ListItemText>
            )}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
