import { ChromePicker, CirclePicker } from "react-color";
import React, { useRef, useState, useEffect } from "react";

function ColorPickerModal({ onSelectedColor, onClose }) {
  const [color, setColor] = useState("#fff");
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleColorChange = (color) => {
    setColor(color.hex);
    onSelectedColor(color.hex);
  };
  const handleClick = (e) => {
    e.stopPropagation();
  };

  const swatchColors = [
    "#FFFFFF", // White
    "#000000", // Black
    "#FF5733", // Orange
    "#3498DB", // Blue
    "#00BFFF", // Deep Sky Blue
    "#1ABC9C", // Turquoise
    "#9B59B6", // Purple
    "#E74C3C", // Red
    "#F1C40F", // Yellow
    "#2ECC71", // Green
    "#34495E", // Dark Blue
    "#95A5A6", // Gray
    "#FF69B4", // Pink
  ];
  const handleColorSwatches = (selected) => {
    onSelectedColor(selected.hex);
    setColor(selected.hex);
  };

  const handleChange = (color, event) => {
    setColor(color.hex);
  };

  return (
    <div
      className="color-picker-container"
      onClick={handleClick}
      ref={modalRef}
    >
      <ChromePicker
        disableAlpha
        color={color}
        onChange={handleChange}
        onChangeComplete={handleColorChange}
      />
      <div className="swatches-container">
        <CirclePicker
          //colors={swatchColors}
          circleSize={18}
          circleSpacing={10}
          width="auto"
          onChangeComplete={handleColorSwatches}
        />
      </div>
    </div>
  );
}

export default ColorPickerModal;
