import React, { useEffect, useRef, useContext, useState } from "react";
import { intializeCanvas } from "../utils/loadProjectToCanvas";
import customizeFabric from "./customFabric";
import createHistory from "../services/history";
import PropTypes from "prop-types";
import { FontsList } from "../utils/common";
import { getDefaultInitCanvasJson, getUserDesignApi } from "../services";
import { CanvasStore } from "./store/canvasStore";
import WebFont from "webfontloader";
import { useParams } from "react-router-dom";

import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  onSnapshot,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { analytics, db, logEvent } from "../firebase.js";
import { el } from "date-fns/locale";

const options = {
  static: false,
  autoResize: true,
  enableZoom: true,
  enablePan: true,
  showRulers: false,
  uniformScaling: true,
  height: 607,
  width: 816,
};
const Canvas = ({ onDesignInfoLoad = () => {}, onSetCanvas = () => {} }) => {
  const canvasElRef = useRef(null);
  const canvasRef = useRef(null);
  const canvasWrapperRef = useRef(null);
  const historyRef = useRef(createHistory());
  const { setActiveCanvas, setActiveObject, setFPS, setProjectInfo } =
    useContext(CanvasStore);

  const { id } = useParams();

  useEffect(() => {
    const loadProject = async () => {
      // console.log(id);
      const projectRef = doc(db, "projects", id);
      const projSnapShot = await getDoc(projectRef);
      if (projSnapShot.data()) {
        setProjectInfo({ id: id, ...projSnapShot.data() });
      }
    };

    async function runAsync() {
      let fabricCanvas = await intializeCanvas(
        canvasElRef.current,
        { ...options },
        {},
        historyRef.current
      );

      canvasRef.current = fabricCanvas;
      onSetCanvas(fabricCanvas);
      setActiveCanvas(fabricCanvas);
      window.canvas = fabricCanvas;

      fabricCanvas.backgroundColor = "#000";
      await loadDesign(fabricCanvas);
      await loadAllGoogleFonts();
      registerCanvasEvents(fabricCanvas);
    }

    const initialize = async () => {
      customizeFabric(id);
      await loadProject();
      await runAsync();
    };

    initialize();
  }, []);

  const registerCanvasEvents = (fabricCanvas) => {
    fabricCanvas.on("selection:updated", setActiveObjectInStore);
    fabricCanvas.on("selection:created", setActiveObjectInStore);
    fabricCanvas.on("object:added", setNewActiveObjectInStore);
    fabricCanvas.on("selection:cleared", setClearedActiveObjectInStore);
  };

  const setClearedActiveObjectInStore = function () {
    setActiveObject(null);
  };
  const setNewActiveObjectInStore = function (e) {
    let activeObject = e.target;
    if (activeObject.type !== "artboard") {
      setActiveObject(activeObject);
    } else {
      setActiveObject(null);
    }
  };
  const setActiveObjectInStore = function (e) {
    let activeObject = e.selected[0];
    let object = activeObject.type !== "artboard" ? activeObject : null;
    setActiveObject(object);
  };

  async function loadAllGoogleFonts() {
    FontsList.forEach((font) => {
      WebFont.load({
        google: {
          families: [font],
        },
      });
    });
  }
  async function loadDesign(canvas) {
    let designInfo = null;
    /**
     * Current Implementation:
     * - User designs are currently saved in the browser's local storage.
     * - `saveUserDesignApi` is used to save the design.
     * - `getUserDesignApi` is used to fetch the saved design.
     *
     * TODO: Firestore Integration for Saving and Loading Designs
     * - Replace the existing logic with Firestore to save and fetch designs.
     *
     * File Path:
     * - The functions `saveUserDesignApi` and `getUserDesignApi` are located in:
     *   `video/src/services/modules/canvas.js`
     *
     * Reloading Design Flow:
     * - When the editor is opened, check if the user has a saved design.
     * - If a saved design exists, prompt the user with an alert box.
     *   - If the user clicks "Yes," load the existing design.
     *   - If the user clicks "No," load a blank canvas.
     */

    let savedDesign = await getUserDesignApi(id);

    if (savedDesign) {
      console.log("Saved design found");
      designInfo = savedDesign;
    } else {
      designInfo = await getDefaultInitCanvasJson();
      if (designInfo.error) {
        alert("Something went wrong. Contact support if the issue persists");
        console.log("init canvas didn't load");
        return;
      }
    }
    onDesignInfoLoad(designInfo);
    canvas.designInfo = designInfo;

    try {
      await canvas.loadDesignPage(designInfo);
      setFPS(canvas.__artboard?.FPS);
    } catch (e) {
      console.log("Design not found.", e);
    }
  }

  return (
    <div id={"canvas-wrapper"} className={"w-full"} ref={canvasWrapperRef}>
      <canvas id="canvas" ref={canvasElRef} />
    </div>
  );
};
Canvas.propTypes = {
  onDesignInfoLoad: PropTypes.func,
  recipeSharedMode: PropTypes.bool,
  onSetCanvas: PropTypes.func,
};
export default Canvas;
