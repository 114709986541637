import React, { useEffect } from "react";
import { TextField, Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function FontFamilyDropdown({ fontFamilyOptions, onSelected, defaultValue }) {
  const [value, setValue] = React.useState(fontFamilyOptions[0]);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "14px",
            color: "white",
          },
        }}
        PaperComponent={({ children }) => (
          <Paper style={{ color: "#fff", background: "rgb(15 23 42)" }}>
            {children}
          </Paper>
        )}
        componentsProps={{ popper: { style: { width: "fit-content" } } }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelected(newValue);
        }}
        renderOption={(props, option) => (
          <li {...props} style={{ fontFamily: option.fontFamily }}>
            {option.fontFamily}
          </li>
        )}
        options={fontFamilyOptions}
        getOptionLabel={(option) => option.fontFamily}
        renderInput={(params) => <TextField {...params} label="Font Family" />}
        style={{ width: 150 }}
        disableClearable
      />
      <p style={{ fontFamily: value.value }}></p>
    </>
  );
}

export default FontFamilyDropdown;
