import React, { useRef } from "react";
import { IconButton } from "@mui/material";
import Iconify from "../../components/iconify/Iconify";

const timelineHeight = 250;

function TimelineResizer({ timelineSize, setTimelineSize }) {
  const componentHeightRef = useRef(250);

  const handleResizeDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const MIN_ClientY = 72;
    let _height = timelineSize.height + componentHeightRef.current - e.clientY;
    if (_height < 0) _height = 0;
    else if (e.clientY < MIN_ClientY) {
      _height = timelineSize.height + componentHeightRef.current - MIN_ClientY;
    }
    let seekbarHeight = _height - 16;

    setTimelineSize({
      height: _height,
      seekbarHeight: seekbarHeight,
      timeLimitSeekHeight: timelineHeight - 40,
      layerAreaHeight: _height - 60,
    });
  };

  const handleResizeDragHandleMouseUp = (e) => {
    componentHeightRef.current = e.clientY;
    document.removeEventListener("mousemove", handleResizeDrag);
    document.removeEventListener("mouseup", handleResizeDragHandleMouseUp);
  };

  const handleResizeMouseDown = (e) => {
    componentHeightRef.current = e.clientY;
    document.addEventListener("mousemove", handleResizeDrag);
    document.addEventListener("mouseup", handleResizeDragHandleMouseUp);
  };

  const handleToggleTimeline = () => {
    setTimelineSize({
      height: timelineSize.height === 0 ? 250 : 0,
    });
  };

  return (
    <div
      style={{ cursor: "row-resize" }}
      className={"hover:bg-[#334155] z-10  h-1 absolute left-0 right-0"}
      onMouseDown={handleResizeMouseDown}
    >
      <div className={`absolute right-[10px] -top-[10px] cursor-pointer`}>
        <Iconify
          onClick={handleToggleTimeline}
          width="25px"
          color="primary"
          icon={
            timelineSize.height
              ? "tabler:square-chevron-down-filled"
              : "tabler:square-chevron-up-filled"
          }
          className="hover:text-primary"
        ></Iconify>
      </div>
    </div>
  );
}

export default TimelineResizer;
