import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import {
  Link,
  Container,
  Typography,
  Divider,
  Stack,
  Button,
  CircularProgress,
} from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";

import Paper from "@mui/material/Paper";

// components
import Logo from "../components/logo";
// sections
import { LoginForm } from "../sections/auth/login";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useUserAuth } from "../UserAuthContext";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const location = useLocation();
  const mdUp = useResponsive("up", "md");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const { user, isLoading } = useUserAuth();

  useEffect(() => {
    if (error) {
      return;
    }
    if (user) {
      let path = "/";
      let queryParams;

      const from = location.state?.from;

      if (from) {
        path = from.pathname;
        queryParams = from.search;
        navigate(`${path}${queryParams}`, { replace: true });
      } else {
        navigate(path, { replace: true });
      }
    }
  });

  return (
    <>
      <Helmet>
        <title> SSO Login | Acoust </title>
      </Helmet>
      <StyledRoot>
        <Container maxWidth="sm">
          <Paper elevation={10}>
            <StyledContent>
              <Stack
                direction="column"
                spacing={2}
                mb={6}
                alignItems="center"
                justifyContent="space-around"
              >
                <Logo></Logo>
              </Stack>

              <Stack spacing={4} mt={4} alignItems="center">
                Login with SSO
              </Stack>

              <Stack spacing={4} mt={4}>
                <LoginForm
                  mode="sso"
                  error={
                    error
                      ? error === "noteam"
                        ? "Unable to load your team"
                        : "Unable to login via SSO"
                      : null
                  }
                />
              </Stack>
            </StyledContent>
          </Paper>
        </Container>
      </StyledRoot>
    </>
  );
}
