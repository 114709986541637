import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.js";
import { apiBaseURL } from "../../utils/common";
import axiosClient from "../axiosClient";

const json = "";

export const getFormData = (object = {}) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) =>
    formData.append(
      key,
      typeof object[key] === "object"
        ? JSON.stringify(object[key])
        : object[key]
    )
  );
  return formData;
};

export const getUserDesignApi = async (projectId) => {
  // const savedData = localStorage.getItem(`canvasDesign`);

  const documentSnapshot = await getDoc(doc(db, "projects", projectId));
  const savedData = documentSnapshot.data().canvas;
  // console.log(savedData);
  if (savedData) {
    return JSON.parse(savedData);
  }
  return null;
};

export const getDefaultInitCanvasJson = async () => {
  try {
    const { data } = await axiosClient.get(
      `${apiBaseURL}/1/designs/1/pages/init_canvas.json`
    );
    return data;
  } catch (e) {
    return json;
  }
};

export const saveUserDesignApi = async (payload, projectId) => {
  // Save the design data to localStorage
  localStorage.setItem(`canvasDesign`, payload.pageJson);
  console.log(projectId);
  const projectRef = doc(db, "projects", projectId);
  await updateDoc(projectRef, {
    canvas: payload.pageJson,
  });
};
