import React from "react";
const RectangularButton = ({ onClick, label, children, className, del }) => {
  const borderColor = del ? "#B71C1C" : "gray";
  return (
    <button
      onClick={onClick}
      className={`text-white appearance-none bg-transparent outline-none p-0 select-none flex items-center justify-center relative box-border border  hover:!border-primary transition duration-300  cursor-pointer  rounded-md  text-center font-inter font-sm leading-4 tracking-normal text-gray-700 
      h-12 w-full flex-row
      gap-2
      ${className}
  `}
      style={{
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 1px 1px",
        border: `1px solid ${borderColor}`,
      }}
    >
      {children}
      <span className={`text-charcoalDark `}>{label}</span>
    </button>
  );
};

export default RectangularButton;
