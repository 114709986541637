import React, { useContext, useEffect, useState } from "react";
import OpacityIcon from "@mui/icons-material/Opacity";
import Slider from "../Slider";
import { CanvasStore } from "../../canvas/store/canvasStore";

function OpacityControl({ value, object }) {
  const { activeCanvas } = useContext(CanvasStore);
  const [opacity, setOpacity] = useState(value);

  useEffect(() => {
    if (!object) return;
    setOpacity(object.opacity * 100);
  }, [object]);

  const handleOpacityChange = (event, newValue) => {
    object.set({ opacity: newValue / 100 });
    activeCanvas.requestRenderAll();
    setOpacity(newValue);
  };

  return (
    <div
      className={
        "py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid  shadow-sm"
      }
    >
      <OpacityIcon sx={{ color: "white" }} fontSize={"small"} />
      <span className={"text-white text-sm mx-4"}>Opacity</span>
      <div className={"flex flex-grow items-center"}>
        <Slider
          value={opacity}
          onChange={handleOpacityChange}
          className={"w-full"}
        />
      </div>
    </div>
  );
}

export default OpacityControl;
