import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

import { db } from "../../firebase.js";
import { doc, addDoc, collection } from "firebase/firestore";

export default function NewVoice(props) {
  // State to store form data
  const [formData, setFormData] = useState({
    cloud: "oa",
    voice: "",
    display_name: "",
    languageCodes: ["en-US"],
    language: "English (US)",
    gender: "Male",
    order: 0,
    popular: true,
  });

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value, checked } = e.target;

    // console.log(name);
    // console.log(value);

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "order"
          ? Number(value)
          : name === "languageCodes"
          ? value.split(",")
          : value,
    }));
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);

    try {
      const docRef = await addDoc(collection(db, "voices"), formData);
      console.log("Document written with ID: ", docRef.id);
    } catch (error) {
      console.error("Error creating document: ", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} mt={4} maxWidth={600}>
        <TextField
          label="voice"
          name="voice"
          value={formData.voice}
          onChange={handleChange}
          fullWidth
        />

        {/* Display Name */}
        <TextField
          label="Display Name"
          name="display_name"
          value={formData.display_name}
          onChange={handleChange}
          fullWidth
        />

        {/* Cloud */}
        <FormControl fullWidth>
          <InputLabel id="cloud-label">Cloud</InputLabel>
          <Select
            labelId="cloud-label"
            label="cloud"
            id="cloud"
            name="cloud"
            value={formData.cloud}
            onChange={handleChange}
          >
            <MenuItem value="google">Google</MenuItem>
            <MenuItem value="azure">Azure</MenuItem>
            <MenuItem value="oa">OA</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Language"
          name="language"
          value={formData.language}
          onChange={handleChange}
          fullWidth
        />

        <TextField
          label="Lang Code"
          name="languageCodes"
          value={formData.languageCodes}
          onChange={handleChange}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            label="gender"
            name="gender"
            value={formData.gender}
            onChange={handleChange}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Neutral">Neurtral</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="order"
          name="order"
          value={formData.order}
          onChange={handleChange}
          fullWidth
        />

        <FormControl component="fieldset">
          <FormLabel id="demo-radio-buttons-group-label">Popular</FormLabel>

          <RadioGroup
            aria-label="popular"
            name="popular"
            value={formData.popular}
            onChange={handleChange}
          >
            {["true", "false"].map((option) => (
              <FormControlLabel
                key={option}
                value={option}
                control={<Radio />}
                label={option.charAt(0).toUpperCase() + option.slice(1)}
              />
            ))}
          </RadioGroup>
        </FormControl>

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary">
          Add
        </Button>
      </Stack>
    </form>
  );
}
