import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Box,
  Button,
  Chip,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Stack,
  Typography,
  Table,
  TableContainer,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Tooltip,
  tableCellClasses,
  TextField,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { db } from "../firebase.js";
import {
  collection,
  doc,
  getDocs,
  updateDoc,
  where,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { useUserAuth } from "../UserAuthContext";

import Iconify from "../components/iconify";

// ----------------------------------------------------------------------

export default function TeamAceeptPage() {
  const [team, setTeam] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  const { user } = useUserAuth();

  async function getTeam() {
    console.log("loading team");
    const q = query(
      collection(db, "teams"),
      where("members", "array-contains", user.email)
    );

    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      // Get the first document from the result
      const teamDoc = querySnapshot.docs[0];

      // Access the document data
      const teamDocData = teamDoc.data();

      // Set the specific document in your state
      setTeam({
        id: teamDoc.id,
        ...teamDocData,
      });
    } else {
      console.log("no teams");
      setErrorMessage("hmm, we couldn't find any team invites for you");
    }

    setLoading(false);
  }

  useEffect(() => {
    getTeam().then(() => {
      setLoading(false);
    });
  }, []);

  const handleJoinTeam = async () => {
    try {
      const userDocRef = doc(db, "customers", user.uid);
      await updateDoc(userDocRef, {
        team: team.id,
        team_join: serverTimestamp(),
      });
      setShowSuccess(true);
    } catch (error) {
      console.log(error);
      setErrorMessage("Unable to join team. Try again later");
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Team Settings | Acoust </title>
      </Helmet>

      <Container>
        <Typography variant="h4" gutterBottom sx={{ mb: 5 }}>
          {team.name}
        </Typography>

        <Dialog open={true} maxWidth="lg" fullWidth>
          <DialogTitle>Join team {team.name}</DialogTitle>
          <DialogContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              padding={2}
              mt={2}
            >
              <Stack spacing={4} padding={2} alignItems="center">
                {errorMessage !== "" ? (
                  <Typography variant="h5" color="palette.success.main">
                    {errorMessage}
                  </Typography>
                ) : showSuccess ? (
                  <>
                    <Typography variant="h5" color="success">
                      Hooray! You've successfully joined the team! 🎉
                    </Typography>
                    <Button fullWidth variant="contained" href="/">
                      View Projects
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography variant="h5">
                      You're invited to join {team.name}'s team account
                    </Typography>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleJoinTeam}
                    >
                      Accept Invite
                    </Button>
                  </>
                )}
              </Stack>
              <Stack
                maxWidth={"70%"}
                minHeight={400}
                sx={{
                  backgroundImage: "url(/acoust-team.png)",
                  backgroundSize: "contain", // You can adjust this based on your needs
                  backgroundPosition: "right", // You can adjust this based on your needs
                  backgroundRepeat: "no-repeat",
                  width: "90%",
                  height: "100%",
                }}
              ></Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </Container>
    </>
  );
}
