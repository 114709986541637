import { fabric } from "fabric";
import { imageAssetsUrl } from "../../utils/common";

// border of selection
const BORDER_COLOR = "#00aaff";
const HOVER_STROKE_COLOR = "#6775F8";
const BOTTOM_CONTROLS_COLOR = "#949494";

// Customize controls global settings
fabric.Object.prototype.transparentCorners = false;
fabric.Object.prototype.cornerColor = "#FFFFFF";
fabric.Object.prototype.cornerStrokeColor = BORDER_COLOR;
fabric.Object.prototype.cornerSize = 7;
fabric.Object.prototype.borderColor = BORDER_COLOR;
fabric.Canvas.prototype.hoverStrokeColor = HOVER_STROKE_COLOR;
fabric.Object.prototype.borderOpacityWhenMoving = 1;
fabric.Object.prototype.snapAngle = 1;
fabric.Object.prototype.cornerStyle = "circle";

fabric.Textbox.prototype.transparentCorners = false;
fabric.Textbox.prototype.cornerColor = "#FFFFFF";
fabric.Textbox.prototype.cornerStrokeColor = BORDER_COLOR;
fabric.Textbox.prototype.cornerSize = 7;
fabric.Textbox.prototype.borderColor = BORDER_COLOR;
fabric.Textbox.prototype.hoverStrokeColor = HOVER_STROKE_COLOR;
fabric.Textbox.prototype.borderOpacityWhenMoving = 1;
fabric.Textbox.prototype.snapAngle = 1;
fabric.Object.prototype.cornerStyle = "circle";

fabric.Textbox.prototype.editingBorderColor = HOVER_STROKE_COLOR;
fabric.Textbox.prototype.bottomControlsColor = BOTTOM_CONTROLS_COLOR;

var img = document.createElement("img");

let deleteIcon = imageAssetsUrl + "remove_icon.svg";

img.src = deleteIcon;

/*fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 0,
    cursorStyle: 'pointer',
    mouseUpHandler: deleteObject,
    render: renderIcon,
    cornerSize: 14
});*/
// fabric.Textbox.prototype.controls.deleteControl=fabric.Object.prototype.controls.deleteControl;

// function deleteObject(eventData, transform) {
//     var target = transform.target;
//     var canvas = target.canvas;
//     canvas.remove(target);
//     canvas.requestRenderAll();
// }
/*
export function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    var size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size/2, -size/2, size, size);
    ctx.restore();
}

*/
