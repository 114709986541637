import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          fontSize: "14px",
        },
        input: {
          fontSize: "14px",
        },
      },
    },
  },
});

function FontSizeDropdown({ fontSizeOptions, onSelected, defaultValue }) {
  const [value, setValue] = React.useState(fontSizeOptions[1]);

  useEffect(() => {
    if (!defaultValue) return;
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Autocomplete
        sx={{
          "& .MuiInputBase-input": {
            fontSize: "14px",
            color: "white",
          },
        }}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelected(newValue);
        }}
        options={fontSizeOptions}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Font Size" />}
        style={{ width: 100 }}
        disableClearable
      />
    </>
  );
}

export default FontSizeDropdown;
