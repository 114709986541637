import React, { useContext, useState } from "react";
import { Modal, Box, Typography, TextField } from "@mui/material";
import { CanvasStore } from "../canvas/store/canvasStore";
import Slider from "../shared/Slider";

const SpacingModal = ({ open, handleClose }) => {
  const [lineHeight, setLineHeight] = useState(0);
  const [letterSpacing, setLetterSpacing] = useState(0);
  const { activeCanvas, activeObject } = useContext(CanvasStore);

  const modalStyle = {
    position: "absolute",
    top: "45%",
    left: "32%",
    transform: "translate(-50%, -50%)",
    // width: 'auto',
    width: "350px",
    bgcolor: "rgb(15 23 42)",
    borderRadius: "16px",
    boxShadow: 24,
    p: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  };

  const handleLetterSpacing = (value) => {
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ charSpacing: value * 100 });
      setLetterSpacing(value);
      activeCanvas.renderAll();
    }
  };

  const handleLineHeight = (value) => {
    if (activeObject && activeObject.type === "i-text") {
      activeObject.set({ lineHeight: value * 2 });
      setLineHeight(value);
      activeCanvas.renderAll();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="spacing-modal-title"
        aria-describedby="spacing-modal-description"
      >
        <Box sx={modalStyle}>
          <span className={"font-semibold text-medium"}>Spacing</span>
          <div className={"w-full "}>
            <div className={"flex items-center justify-between flex-grow mb-2"}>
              <span className={"mr-auto w-[110px] text-sm"}>Line Height</span>
              <Slider
                unit={""}
                value={lineHeight}
                onChange={(event, newValue) => handleLineHeight(newValue)}
                step={1}
                min={1}
                max={10}
                aria-labelledby="line-height-slider"
              />
            </div>
            <div className={"flex items-center justify-between flex-grow"}>
              <span className={"mr-auto w-[110px] text-sm"}>
                Letter Spacing
              </span>
              <Slider
                unit={""}
                value={letterSpacing}
                onChange={(event, newValue) => handleLetterSpacing(newValue)}
                step={1}
                min={1}
                max={10}
                aria-labelledby="letter-spacing-slider"
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default SpacingModal;
