import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Button,
  Typography,
  Container,
  Box,
  CircularProgress,
  Paper,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerificationPendingPage() {
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(() => {
    return localStorage.getItem("emailForSignIn");
  });

  return (
    <>
      <Helmet>
        <title> Natural Voices | Acoust </title>
      </Helmet>

      <Container>
        <StyledContent sx={{ textAlign: "center", alignItems: "center" }}>
          <Paper elevation={4}>
            <Stack mt={4} spacing={4} padding={2}>
              <Typography variant="h3" paragraph>
                Verify your email
              </Typography>

              <Typography>
                Click the link sent from Acoust AI to your email{" "}
                <strong>({email})</strong>
                <br />
                <br />
                Don't forget to check your spam folder.
              </Typography>

              <Box
                component="img"
                src="/email_pending.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />
            </Stack>
          </Paper>
        </StyledContent>
      </Container>
    </>
  );
}
