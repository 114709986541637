import { fabric } from "fabric";
import { propertiesToInclude } from "../canvas/customFabric";
import { commonCanvasEvents } from "../canvas/events/canvasEvents";
import { getDesignId } from "./common";
import { handleShortcutKeys } from "../canvas/events/keyboardShortcuts";
import { canvasPanZoom } from "../canvas/utils/zoomingPanning";
export const DEFAULT_BG_COLOR = "rgb(15 23 42)";
export async function intializeCanvas(canvasEl, options, user, history) {
  const designId = getDesignId();
  return new Promise(async (resolve) => {
    const __mainEditorWrapperEl = document.getElementById("canvas-wrapper");
    const rect = __mainEditorWrapperEl.getBoundingClientRect();
    const Canvas = options.static ? fabric.StaticCanvas : fabric.Canvas;
    const canvas = new Canvas(canvasEl, {
      __user: user,
      uid: user?.userID,
      designId: designId,
      selectionColor: "rgba(40, 123, 239, 0.25)",
      selectionBorderColor: "rgba(40, 123, 239, 0.65)",
      selectionLineWidth: 1,
      enableRetinaScaling: !localStorage.getItem("lowPowerMode"), //TODO: get this from project instead
      preserveObjectStacking: true,
      controlsAboveOverlay: false,
      fireRightClick: true,
      staticCanvas: options.static,
      __mainEditorWrapperEl,
      drawPixelGrids: true,
      snapToPixelGrids: true,
      _groupedObjects: [],
      projectColors: [],
      __keyboardEvents: [],
      rulerGuides: [],
      showRulers: options.showRulers || false,
      uniformScaling: options.uniformScaling || true,
      canvasWidth: rect.width, //will be updated
      width: rect.width,
      canvasHeight: rect.height, //will be updated
      height: rect.height,
      skipOffscreen: true,
      isActiveCanvas: true,
      hoverCursor: "default",
      defaultCursor: "default",
      backgroundColor: DEFAULT_BG_COLOR,
    });
    canvas.history = history;
    canvas.requestRenderAll();
    canvas.undo = undo;
    canvas.redo = redo;
    // Only start listening when the canvas is ready
    commonCanvasEvents(canvas);
    //canvasPanZoom(canvas)
    handleShortcutKeys(canvas);
    // window.getStack = canvas.history.getStack;
    canvas.resizeCanvas = canvas?.resizeCanvas?.bind(canvas);

    // Disable pan and zoom
    canvas.on("mouse:wheel", function (opt) {
      opt.e.preventDefault();
      opt.e.stopPropagation();
    });

    // Disable touchpad pinch-to-zoom
    canvas.upperCanvasEl.addEventListener("gesturestart", function (e) {
      e.preventDefault();
    });

    canvas.upperCanvasEl.addEventListener("gesturechange", function (e) {
      e.preventDefault();
    });

    canvas.upperCanvasEl.addEventListener("gestureend", function (e) {
      e.preventDefault();
    });

    // Disable touchpad two-finger panning
    canvas.upperCanvasEl.addEventListener("touchmove", function (e) {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    });
    resolve(canvas);
  });
}

export function undo() {
  let newState = this.history.undo();
  if (newState) {
    this.loadCanvasState(newState).then(() => {
      this.fire("undo");
    });
  }
}

export function redo() {
  let newState = this.history.redo();
  if (newState) {
    this.loadCanvasState(newState).then(() => {
      this.fire("redo");
    });
  }
}

export function handleLoadJson(canvas, json, cb = () => {}) {
  canvas.clear();
  json = JSON.parse(JSON.stringify(json));
  canvas.loadFromJSON(
    json,
    async () => {
      let state = canvas.toJSON(propertiesToInclude);
      state.objects = state.objects.filter((o) => !o.parentArtboard);
      canvas.history.update(state);
      cb && cb();
    },
    (i, object) => {
      delete object.controls.deleteControl;
    }
  );
}
