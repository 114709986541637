import React, { useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Tooltip,
  Stack,
  Typography,
} from "@mui/material";
import Iconify from "../iconify";
import { db } from "../../firebase.js";
import { doc, updateDoc } from "firebase/firestore";

export default function ProjectName({ pName, id }) {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(pName);
  const [projectId, setProjectId] = useState(id);

  const handleTextChange = (event) => {
    setName(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      updateProject();
    }
  };

  const handleEditToggle = () => {
    setIsEditing((prev) => !prev);
  };

  const updateProject = async () => {
    console.log(`updating: ${projectId}`);
    const projectRef = doc(db, "projects", id);
    await updateDoc(projectRef, {
      name: name,
    });
    setIsEditing(false);
  };

  return (
    <Stack direction="row" alignItems="center" justifyItems="center">
      {isEditing ? (
        <Stack direction="row">
          <TextField
            variant="standard"
            value={name}
            onChange={handleTextChange}
            onKeyDown={handleKeyDown}
            // onBlur={handleEditEnd}
            autoFocus
          />

          <IconButton onClick={updateProject}>
            <Iconify icon="flat-color-icons:checkmark" />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center">
          <Typography
            variant="h5"
            // onMouseEnter={handleEditToggle}
            // onMouseLeave={handleEditToggle}
            onClick={handleEditToggle}
          >
            {name}
          </Typography>
          <Tooltip title="Edit project name" placement="top">
            <IconButton onClick={handleEditToggle}>
              <Iconify icon="akar-icons:edit" />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
    </Stack>
  );
}
