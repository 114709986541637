import React, { useContext, useEffect, useState } from "react";
import IconButton from "../IconButton";
import Slider from "../Slider";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import VolumeOffOutlinedIcon from "@mui/icons-material/VolumeOffOutlined";
import { CanvasStore } from "../../canvas/store/canvasStore";

function VolumeControl({ audio = false, object }) {
  const { activeCanvas } = useContext(CanvasStore);

  const [volume, setVolume] = useState(10);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    if (!object) return;
    let mute = audio ? object.muted : object.videoMuted;
    setVolume(Math.round((object.volume || 1) * 100));
    setIsMuted(mute);
  }, [object]);

  const toggleMute = () => {
    if (!object) return;
    // console.log(!isMuted)
    setIsMuted(!isMuted);
    setVolume(isMuted ? 10 : 0);
    object.muteAudio(!isMuted);
    activeCanvas.renderAll();
  };

  const handleVolumeChange = (newValue) => {
    setVolume(newValue);
    // console.log(newValue/100)
    object.audioVolume(newValue / 100);
  };

  return (
    <div className={"flex gap-2"}>
      <div>
        <IconButton color="primary" onClick={toggleMute}>
          {isMuted ? (
            <VolumeOffOutlinedIcon fontSize={"small"} />
          ) : (
            <VolumeUpOutlinedIcon fontSize={"small"} />
          )}
        </IconButton>
      </div>
      <div
        className={
          "flex-grow  py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid  shadow-sm"
        }
      >
        <Slider
          value={volume}
          step={1}
          max={100}
          marks={[{ value: 100 }]}
          onChange={handleVolumeChange}
        />
      </div>
    </div>
  );
}

export default VolumeControl;
