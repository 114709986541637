import { Dialog, DialogContent } from "@mui/material";
import React from "react";

const CommonModal = ({ open, onClose, children, zIndex = 999, ...props }) => {
  return (
    <Dialog open={open} sx={{ zIndex: zIndex }} onClose={onClose} {...props}>
      <DialogContent sx={{ minWidth: "500px" }}>{children}</DialogContent>
    </Dialog>
  );
};

export default CommonModal;
