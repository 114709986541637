import { Helmet } from "react-helmet-async";
// @mui

import { styled } from "@mui/material/styles";

import { Box, Container, Stack, Typography } from "@mui/material";

import LinearProgress from "@mui/material/LinearProgress";

import { useState, useEffect } from "react";
import { analytics, db, logEvent } from "../firebase.js";
import { collection, onSnapshot, addDoc, setDoc } from "firebase/firestore";
import { useUserAuth } from "../UserAuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default function SubscribePage() {
  const allPlans = new Map();
  allPlans.set("starter", "price_1MvZshBvAR6IVYttpZkBG8sU"); // starter
  allPlans.set("pro", "price_1NQ4apBvAR6IVYttGoV5voG8"); // pro
  allPlans.set("premium", "price_1MAMzEBvAR6IVYttkXyXVJm2"); // prem
  allPlans.set("ultra", "price_1OZU2iBvAR6IVYttCjDZj3sk"); // ultra
  // yearly plans
  allPlans.set("pro_y", "price_1OuU2vBvAR6IVYttlNoQVX8E"); // pro
  allPlans.set("premium_y", "price_1OuU50BvAR6IVYttyIkRlTOZ"); // prem
  allPlans.set("ultra_y", "price_1Ouhc3BvAR6IVYtt1gBm5E37"); // ultra
  // prepaid plans
  allPlans.set("ppro", "price_1O0vPYBvAR6IVYttveGFG2rs"); // tts pre-paid ($20)
  allPlans.set("pprem", "price_1OCdS6BvAR6IVYttLZ9mBFiH"); // tts pre-paid ($40)

  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(true);
  const [isSubscribed, setisSubscribed] = useState(false);
  const [searchParams] = useSearchParams();
  const { user } = useUserAuth();
  const prices = {};

  const [loadingPercent, setLoadingPercent] = useState(0);

  useEffect(() => {
    const subscribe = async () => {
      if (!user.uid) {
        console.log("no user");
        return;
      }
      console.log("start subscribe process...", user.uid);
      //plan is passed from the marketing site
      const selectedPlan = searchParams.get("plan");
      const planPrice = allPlans.get(selectedPlan);
      const selectedPrice = {
        price: planPrice,
        quantity: 1,
      };
      // console.log(selectedPrice)

      // Increment the checkout bar
      // setLoadingPercent(10);

      const checkoutSession = {
        automatic_tax: true,
        tax_id_collection: true,
        collect_shipping_address: true,
        allow_promotion_codes: true,
        line_items: [selectedPrice],
        success_url:
          "http://app.acoust.io/thanks?session_id={CHECKOUT_SESSION_ID}",
        cancel_url: window.location.origin,
        //metadata: {
        //  key: "value",
        //},
      };

      // Increment the checkout bar
      // setLoadingPercent(20);

      if (window.Rewardful && window.Rewardful.referral) {
        checkoutSession.client_reference_id = window.Rewardful.referral;
      }

      // Increment the checkout bar
      // setLoadingPercent(30);

      // Required for one time payment options
      if (selectedPlan === "ppro" || selectedPlan === "pprem") {
        checkoutSession.mode = "payment";
      }

      // setLoadingPercent(40);
      try {
        // console.log(`customer selected: ${customerPlan}`)
        const docRef = await addDoc(
          collection(db, "customers", user.uid, "checkout_sessions"),
          checkoutSession
        );
        console.log(docRef);
        // Increment the checkout bar
        // setLoadingPercent(60);

        // Wait for the CheckoutSession to get attached by the extension
        const unsubscribe = onSnapshot(docRef, (snap) => {
          // Increment the checkout bar
          //setLoadingPercent(90);
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and then inspect your function logs.
            alert(`An error occured: ${error.message}`);
          }
          if (url) {
            logEvent(analytics, "begin_checkout", {
              item_name: selectedPlan,
              price: selectedPrice,
            });
            window.location.assign(url);
          }
        });
        return () => {
          unsubscribe();
        };
      } catch (error) {
        console.log(error);
      }
    };
    subscribe();
  }, []);

  return (
    <>
      <Helmet>
        <title> Subscribe | Acoust </title>
      </Helmet>
      <Container
        id="subscribe"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
          justifyContent: "center", // Center vertically
          height: "60vh", // Set the height of the container to the full viewport height
        }}
      >
        <Stack>
          <Stack direction="row" alignItems="center" mt={1}>
            <Box component="img" src="/lock.png"></Box>
          </Stack>
          <Stack>
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="indeterminate" />
            </Box>
            <Typography variant="body2" color="text.secondary">
              Please wait... This can take a minute
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}
