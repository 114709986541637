import React, { useState } from "react";
import { Button, Box, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useUserAuth } from "../../UserAuthContext";

import { db } from "../../firebase";

// Create a root reference
const storage = getStorage();
const bucketName = "textspeech-55a09.appspot.com";

export default function UploadAudio(props) {
  const maxFileSize = props.maxFileSize
    ? props.maxFileSize * 1024 * 1024
    : 5 * 1024 * 1024;

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("error");
  const { user } = useUserAuth();

  const uploadFiletoStorage = async (file) => {
    const fname = `${file.name}`;

    const uid = user.uid;
    // console.log(uid)

    const baseUrl = `${uid}/media`;
    const folder = props.audioType === "clone" ? "clone" : "audio";
    const storageRef = ref(storage, `${baseUrl}/${folder}/${fname}`);
    const mediaGsUrl = `gs://${bucketName}/${baseUrl}/${folder}/${fname}`;

    try {
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);

      const mediaUrl = await getDownloadURL(
        ref(storage, `${baseUrl}/${folder}/${fname}`)
      );

      if (props.audioType === "clone") {
        const docRef = await addDoc(collection(db, "clonedvoices"), {
          display_name: props.clonedVoiceName || "My voice",
          user: user.email,
          file_name: fname,
          timestamp: serverTimestamp(),
          type: props.audioType,
          gs_url: mediaGsUrl,
          player_url: mediaUrl,
          status: "pending",
          cloud: "custom",
        });
        return docRef;
      } else {
        await addDoc(collection(db, "uploads"), {
          user: user.email,
          file_name: fname,
          timestamp: serverTimestamp(),
          type: props.audioType,
          gs_url: mediaGsUrl,
          player_url: mediaUrl,
        });
      }

      return mediaGsUrl;
    } catch (error) {
      console.log("Unable to upload file");
    }
  };

  const handleFileChange = async (event) => {
    setIsFileUploading(true);
    const files = event.target.files;
    const file = files[0];
    // console.log(file)

    if (file.size > maxFileSize) {
      setIsFileUploading(false);
      setShowFileError(true);
      setErrorMessage(`File exceeds size limit`);
      return;
    }

    const fileUrl = await uploadFiletoStorage(file);

    if (file.type.startsWith("audio/")) {
      setIsFileUploading(false);
      // const previewUrl = await getPreviewUrl(fileUrl)

      if (props.audioType === "voiceswap") {
        props.closeUploadModal();
        props.handleUploadedAudio(file.name);
      } else {
        props.handleAudioUpload(fileUrl);
      }
    } else {
      // Show error message or take appropriate action for disallowed file types
      setShowFileError(true);
      setErrorMessage("Invalid format");
      setIsFileUploading(false);
    }
  };

  return (
    <Stack maxWidth={500} border={0} mt={4} mb={2}>
      <input
        style={{ display: "none" }}
        type="file"
        accept="audio/mpeg, audio/mp3, audio/x-m4a, audio/aac, .m4a"
        id="contained-button-file"
        onChange={handleFileChange}
      />

      <label htmlFor="contained-button-file">
        <LoadingButton
          component="span"
          disabled={props.disabled ? props.disabled : false}
          fullWidth
          loading={isFileUploading}
          variant="contained"
          startIcon={<Iconify icon="material-symbols:cloud-upload" />}
        >
          Upload
        </LoadingButton>
      </label>

      <ShowMessage
        showFileError={showFileError}
        setShowFileError={setShowFileError}
        severity={errorSeverity}
        message={errorMessage}
      />
    </Stack>
  );
}
