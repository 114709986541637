import { useState, useEffect } from "react";
// @mui
import { alpha } from "@mui/material/styles";
import { keyframes } from "@mui/system";

import { Link } from "react-router-dom";
import useResponsive from "../../../hooks/useResponsive";

import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
  Popover,
  TextField,
} from "@mui/material";

import { useNavigate } from "react-router";

import { db } from "../../../firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import Iconify from "../../../components/iconify";

import { red } from "@mui/material/colors";
import { useUserAuth } from "../../../UserAuthContext";
import { Icon } from "@iconify/react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  /* {
     label: 'Home',
     icon: 'eva:home-fill',
   },
   {
     label: 'Profile',
     icon: 'eva:person-fill',
   },
   {
     label: 'Settings',
     icon: 'eva:settings-2-fill',
   },*/
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const [openSupportMenu, setOpenSupportMenu] = useState(null);

  const [userInitial, setUserInitial] = useState("");
  const { logOut, user, isUserPremium } = useUserAuth();
  const [showForm, setShowForm] = useState(false);
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);
  const [feedbackText, setFeedbackText] = useState(false);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const navigate = useNavigate();

  const handleFormClick = () => {
    setShowForm(true);
  };

  const handleOpenSupportMenu = (event) => {
    setOpenSupportMenu(event.currentTarget);
  };

  const handleCloseSupportMenu = async () => {
    setOpenSupportMenu(null);
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = async () => {
    setOpen(null);
  };

  const handleLogout = () => {
    console.log("logging out...");
    setOpen(null);
    logOut();
    //navigate("/login");
  };

  const openFeedbackForm = () => {
    setShowFeedbackForm(true);
  };

  const closeFeedbackForm = () => {
    setShowFeedbackForm(false);
  };

  const handleTextChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const subitFeedbackForm = async () => {
    setShowFeedbackForm(false);
    await addDoc(collection(db, "feedback"), {
      to: "hi@acoust.io",
      user: user.email,
      timestamp: serverTimestamp(),
      message: {
        subject: `New feedback from ${user.email}`,
        text: feedbackText,
      },
    });
  };

  const handleUpgradeClick = () => {
    navigate("/billing");
  };

  useEffect(() => {
    if (user.displayName) {
      setUserInitial(user.displayName[0]);
    }
    //isUserPremium().then((isPrem) => {
    // console.log(isPrem);
    // setIsPremiumUser(isPrem);
    // });
  }, [user]);

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        {/*!isPremiumUser && (
          <Button
            onClick={handleUpgradeClick}
            variant="contained"
            startIcon={<Iconify icon="fluent:premium-28-filled" />}
            sx={{
              background:
                "linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",

              backgroundSize: "300%",
              backgroundPositionX: "100%",
            }}
          >
            Upgrade
          </Button>
        )*/}

        {/*<IconButton onClick={handleOpenSupportMenu}>
          <Iconify width="35px" icon="material-symbols:help" />
        </IconButton>*/}

        <Button
          href="/new"
          variant="outlined"
          startIcon={<Iconify icon="streamline-emojis:rocket" />}
        >
          What's New
        </Button>

        <Button
          variant="outlined"
          spacing={1}
          onClick={openFeedbackForm}
          endIcon={<Iconify icon="emojione:money-bag" />}
        >
          <Typography variant="subtitle2">Earn</Typography>
        </Button>
      </Stack>

      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[100], 0.5),
            },
          }),
        }}
      >
        <Avatar
          sx={{
            width: 36,
            height: 36,
            color: (theme) => `${theme.palette.common.white}`,
            bgcolor: (theme) => `${theme.palette.primary.dark}`,
            border: (theme) => `solid 2px ${theme.palette.primary.dark}`,
          }}
        >
          {userInitial ? (
            userInitial
          ) : (
            <Icon style={{ fontSize: "22px" }} icon="mdi:user" />
          )}
        </Avatar>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider />
        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>

      {/* Support popover */}

      <Popover
        open={Boolean(openSupportMenu)}
        anchorEl={openSupportMenu}
        onClose={handleCloseSupportMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem
          sx={{ m: 1 }}
          component={Link}
          to="/new"
          onClick={handleCloseSupportMenu}
        ></MenuItem>
      </Popover>

      {/* Feedback form */}
      <Dialog
        maxWidth="md"
        fullWidth
        open={showFeedbackForm}
        onClose={closeFeedbackForm}
      >
        <DialogTitle>Earn Money & Credits</DialogTitle>
        <DialogContent>
          <Stack spacing={2} padding={2}>
            <Typography>
              💵 Earn a recurring 30% commission through our
              <Button
                variant="text"
                href="https://www.acoust.io/acoust-affiliate-program"
              >
                Affliate program
              </Button>
            </Typography>
            <Divider />
            <Typography>
              🎁 Submit your detailed feedback to earn free credits
            </Typography>
            <TextField
              autoFocus
              multiline
              rows={3}
              margin="dense"
              id="feedback"
              fullWidth
              onChange={handleTextChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeFeedbackForm}>Cancel</Button>
          <Button
            disabled={feedbackText.length === 0}
            variant="contained"
            onClick={subitFeedbackForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
