
import {  DiffPatcher } from 'jsondiffpatch';

import cloneDeep from 'lodash/cloneDeep';

// properties on objects that history doesn't care about
const FILTERED_PROPERTIES = ['selected'];

// this number is a delay between two updates to be considered separate updates
// also it's low enough so that person actually CAN NOT do meaningful updates in this time
// and mostly used to prevent internal changes (that come in bursts) to pollute history stack
const UPDATE_DELAY = 250;

// initial load can take some time and cause lots of updates
const INITIAL_DELAY = 1000;

const jdf = new DiffPatcher({
    propertyFilter:  name => {
        return !FILTERED_PROPERTIES.includes(name);
    },
});

const ignoreFunctions = (context) => {
    if (
        typeof context.left === 'function' ||
        typeof context.right === 'function'
    ) {
        context.setResult(undefined);
        context.exit();
    }
};
ignoreFunctions.filterName = 'ignoreFunctions';

jdf.processor.pipes.diff.before('trivial', ignoreFunctions);

export const HistoryState = '';

/*export const HistoryUtil ={
    undo,
    redo: (skipLock?: boolean) => HistoryState | undefined;
    update: (
        updatedState: HistoryState
    ) => Promise<void | HistoryState | undefined>;
    getState: () => HistoryState | undefined;
    drop: () => void;
    reset: (newState: HistoryState) => void;
}*/

const createHistory = (initialState=HistoryState) => {
    let state = cloneDeep(initialState);
    let stack  = [];

    let position = 0;
    let locked = false;
    let updateTimeout;

    const getStack=()=>{
        return stack;
    };

    const drop = () => {
        stack = [];
        position = 0;
        locked = false;
    };

    const reset = (newState)=> {
        drop();
        state = cloneDeep(newState);
    };

    const unlock = ()  => {
        setTimeout(() => {
            locked = false;
        }, UPDATE_DELAY);
    };

    const lock = () => {
        locked = true;
        unlock();
    };

    const canUndo = () => {
        return position > 0;
    };

    const canRedo = () => {
        return position < stack.length;
    };

    const undo = (skipLock = false) => {
        if (canUndo()) {
            !skipLock && lock();
            position--;
            state = jdf.unpatch(state, stack[position]);
            return getState();
        } else {
            return undefined;
        }
    };

    const redo = (skipLock = false) => {
        if (canRedo()) {
            !skipLock && lock();
            state = jdf.patch(state, stack[position]);
            position++;
            return getState();
        } else {
            return undefined;
        }
    };

    const getState = () => cloneDeep(state);

    // TODO: won't resolve if called 2+ times in a row
    const update = async (
        updatedState
    ) => {
        const newState = cloneDeep(updatedState);
        // don't save state right after undo/redo
        if (locked) {
            return Promise.resolve();
        }
        // lots of successive small changes are combined into one
        if (updateTimeout) {
            clearTimeout(updateTimeout);
        }
        const delay = state ? UPDATE_DELAY : INITIAL_DELAY;
        return new Promise((resolve) => {
            updateTimeout = window.setTimeout(() => {
                // if initialState wasn't set, use first newState as initialState
                if (!state) {
                    state = newState;
                    return resolve(getState());
                }
                const delta = jdf.diff(state, newState);
                if (delta) {
                    stack = stack.slice(0, position);
                    position++;
                    stack.push(delta);
                    state = newState;
                    clearTimeout(updateTimeout);
                }

                resolve(getState());

            }, delay);
        });
    };


    return {
        undo,
        redo,
        update,
        getState,
        drop,
        reset,
        canUndo,
        canRedo,
        getStack
    };
};

export default createHistory;
