import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography, Divider, Link, Stack } from "@mui/material";

import Paper from "@mui/material/Paper";

// components
import Logo from "../components/logo";

// sections
import { SignupForm } from "../sections/auth/login";
import { signInWithGoogle } from "../firebase";

import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GoogleButton from "react-google-button";
import { useUserAuth } from "../UserAuthContext";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

export default function SignupPage() {
  const { user } = useUserAuth();
  const [searchParams] = useSearchParams();
  const plan = searchParams.get("plan");
  const navigate = useNavigate();

  useEffect(() => {
    const routeChange = () => {
      let path = `/`;
      if (plan) {
        path = `/subscribe?plan=${plan}`;
      }
      navigate(path);
    };

    if (user) {
      routeChange();
    }
  }, [user, plan, navigate]);

  return (
    <>
      <Helmet>
        <title> Sign Up | Acoust </title>
      </Helmet>
      <StyledRoot>
        <Container maxWidth="sm">
          <Paper elevation={18}>
            <StyledContent>
              <Stack
                direction="column"
                spacing={2}
                mb={4}
                alignItems="center"
                justifyContent="space-around"
              >
                <Logo></Logo>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="space-around">
                <GoogleButton
                  label="Sign up with Google"
                  type="light"
                  onClick={signInWithGoogle}
                />
              </Stack>
              <Stack spacing={4} mt={4}>
                <Divider sx={{ my: 3 }}>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    OR
                  </Typography>
                </Divider>
                <SignupForm plan={plan} />
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  By signing up, you agree to Acoust AI's{" "}
                  <Link href="https://www.acoust.io/terms-conditions">
                    Terms of Service
                  </Link>
                </Typography>
              </Stack>
            </StyledContent>
          </Paper>
        </Container>
      </StyledRoot>
    </>
  );
}
