import { Helmet } from "react-helmet-async";
// @mui
import {
  Button,
  LinearProgress,
  CircularProgress,
  Chip,
  Link,
  Container,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";

// components
import SubscribeOffer from "../components/SubscribeOffer/SubscribeOffer";

import Iconify from "../components/iconify";
// mock
import { useState, useEffect } from "react";
import { db } from "../firebase.js";
import { useNavigate, useLocation } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  where,
  query,
} from "firebase/firestore";
import { useUserAuth } from "../UserAuthContext";
import { alpha } from "@mui/material/styles";

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

// ----------------------------------------------------------------------

export default function BillingPage() {
  const [loading, setLoading] = useState(true);

  const [charsUsed, setcharsUsed] = useState(0);
  const [charsQuota, setcharsQuota] = useState(0);

  const [cloningQuota, setCloningQuota] = useState(0);
  const [cloningUsage, setCloningUsage] = useState(0);

  const [aiPromptsQuota, setAiPromptsQuota] = useState(0);
  const [aiPromptUsed, setAiPromptUsed] = useState(0);
  const [resetDate, setResetDate] = useState("");

  const [userPlan, setUserPlan] = useState("");
  const [userTeam, setUserTeam] = useState("");

  const [manageLink, setManageLink] = useState("");

  const { user, isTeamMember, isUserPremium } = useUserAuth();

  const navigate = useNavigate();

  const createPortalLink = httpsCallable(
    functions,
    "ext-firestore-stripe-payments-createPortalLink"
  );
  const location = useLocation();
  const state = location.state;
  var isOverQuota = false;
  if (state) {
    isOverQuota = state.overquota;
  }

  useEffect(() => {
    createPortalLink({
      returnUrl: `${window.location.origin}/billing`,
      locale: "auto",
    }).then((result) => {
      const data = result.data;
      setManageLink(data.url);
    });

    const getSubscription = async () => {
      const q = query(
        collection(db, "customers", user.uid, "subscriptions"),
        where("status", "in", ["trialing", "active"])
      );
      const snapshot = await getDocs(q);
      if (!snapshot.empty) {
        const subscription = snapshot.docs[0].data();
        if (subscription) {
          const planDoc = await getDoc(subscription.product);
          const plan = planDoc.data();
          setUserPlan(plan.name);
        }
      }
    };

    const getCustomerData = async () => {
      console.log(userTeam);
      let snapDoc = doc(db, "customers", user.uid);

      const team = await isTeamMember(user);
      if (team) {
        setUserTeam(team);
      }

      if (team) {
        snapDoc = doc(db, "teams", team);
      }

      const snapshot = await getDoc(snapDoc);
      if (!snapshot.empty) {
        const customerData = snapshot.data();
        setcharsUsed(customerData.usage);
        setcharsQuota(customerData.quota);
        setCloningQuota(customerData.cloning_quota);
        setCloningUsage(customerData.cloning_usage);
        setAiPromptUsed(customerData.ai_usage || 0);
        setAiPromptsQuota(customerData.ai_quota || 0);
        setResetDate(customerData.reset.toDate().toDateString());
      }
    };
    const fetchData = async () => {
      setLoading(true);
      await getSubscription();
      await getCustomerData();
      setLoading(false);
    };
    fetchData();
  }, []);

  const percentage = (value, total) => {
    const percent = (value / total) * 100;
    return isNaN(percent) ? 0 : percent > 100 ? 100 : percent;
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title> Billing | Acoust </title>
      </Helmet>

      <Container id="subscribe">
        <Typography variant="h4" gutterBottom>
          Subscription
        </Typography>

        <Stack direction="row">
          {userTeam ? (
            <Typography variant="body1" sx={{ mb: 5 }}>
              {`You are a part of a team plan. Contact your administrator to learn more.`}
            </Typography>
          ) : (
            <>
              <Typography variant="body1" sx={{ mb: 5 }}>
                You are currently subscribed to the{" "}
                <strong>{userPlan === "" ? "Free" : userPlan}</strong> plan.
              </Typography>
              &nbsp;
              {userPlan && <Link href={manageLink}>Manage</Link>}
            </>
          )}
        </Stack>

        {isOverQuota && (
          <Typography sx={{ fontWeight: "bold", color: "#D50000", mb: 5 }}>
            Uh oh, you have exceeded your plan limits.{" "}
            <Link href="https://www.acoust.io/acoust-pricing">Upgrade</Link>
          </Typography>
        )}

        {/*!isUserPremium && (
          <Stack
            alignItems="center"
            mt={2}
            mb={2}
            spacing={2}
            padding={2}
            sx={{
              border: "4px double transparent",
              backgroundImage:
                "linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)), linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
              backgroundOrigin: "border-box",
              backgroundClip: "padding-box, border-box",
              boxShadow: "rgba(var(--primary-color), 0.5) 0px 0px 20px 0px",
              borderRadius: "10px",
            }}
          >
            <Typography variant="subtitle">
              🎃 Spooktacular Offer - won't last long 👻
            </Typography>
            <Typography variant="h5">Save 50% on all plans</Typography>

            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="subtitle">Use the code</Typography>
              <Chip
                label={
                  <Typography sx={{ fontSize: "18px" }} variant="subtitle2">
                    50OFF
                  </Typography>
                }
                size="large"
                //onClick={handleCopy}
                variant="contained"
                sx={{
                  color: "#01f0ff",
                  backgroundColor: alpha("#006bd5", 0.3),
                  borderRadius: "10px",
                }}
              ></Chip>
              <Typography variant="subtitle">during checkout</Typography>
            </Stack>
            <Button
              color="primary"
              variant="text"
              onClick={() => navigate("/billing")}
              style={{
                color: "#fff",
                minWidth: "150px",
                borderRadius: "10px",
                backgroundImage:
                  "linear-gradient(90deg, rgba(93,26,138,1) 10%, rgba(202,10,10,1) 80%, rgba(233,145,21,1) 100%)",
              }}
            >
              Upgrade Now
            </Button>
          </Stack>
        )*/}

        {!userPlan && !userTeam && (
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h4" gutterBottom>
                Available Plans
              </Typography>
              <Link href="https://www.acoust.io/acoust-pricing">(details)</Link>
            </Stack>

            <SubscribeOffer tableOnly={true} />
          </Stack>
        )}
        <br></br>
        <Typography variant="h4" gutterBottom>
          Usage
        </Typography>
        <TableContainer component={Paper} sx={{ maxWidth: 750 }}>
          <Table>
            <TableBody>
              {/* Plan */}
              <TableRow>
                <TableCell width={"40%"}>Plan</TableCell>
                <TableCell width={"40%"}>
                  {userTeam ? "Team" : userPlan === "" ? "Free" : userPlan}
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              {/* Char Quotas used */}
              <TableRow>
                <TableCell>AI TTS usage</TableCell>
                <TableCell>
                  {charsUsed.toLocaleString()}/{charsQuota.toLocaleString()}
                </TableCell>
                <TableCell align="left">
                  <LinearProgress
                    variant="determinate"
                    value={percentage(charsUsed, charsQuota)}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Cloning usage</TableCell>
                <TableCell>
                  {cloningUsage.toLocaleString()}/
                  {cloningQuota.toLocaleString()}
                </TableCell>
                <TableCell align="left">
                  <LinearProgress
                    variant="determinate"
                    value={percentage(cloningUsage, cloningQuota)}
                  />
                </TableCell>
              </TableRow>
              {/* Row 3 */}

              <TableRow>
                <TableCell>Next Reset</TableCell>
                <TableCell>{resetDate}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Stack mt={4}>
          <Typography variant="h4" gutterBottom>
            Support
          </Typography>
          For any inquiries, support requests, or assistance, please contact us
          via email at: <Link href="mailto:hi@acoust.io">hi@acoust.io</Link>
        </Stack>
      </Container>
    </>
  );
}
