import FileDownloadRounded from "@mui/icons-material/FileDownloadRounded";
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  FormLabel,
  Stack,
  Typography,
  Radio,
  RadioGroup,
} from "@mui/material";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import LoadingButton from "@mui/lab/LoadingButton";

import { styled } from "@mui/material/styles";

import Iconify from "../../components/iconify/Iconify";

import React, { useContext, useEffect, useState, useRef } from "react";
import { saveAs } from "file-saver";
import PageHeader from "../../shared/PageHeader";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { formatTimeToMinSecMili } from "../../utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { sortBy } from "lodash";
import { deepFindVideos } from "../../canvas/utils/canvasUtils";
import { propertiesToInclude } from "../../canvas/customFabric";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { convertVideoToMp4, prepareAudio } from "./../../services";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance

import { useUserAuth } from "../../UserAuthContext";

const storage = getStorage();

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1, ml: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 25,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const ExportModal = ({ open, anchorEl, handleClose }) => {
  const { activeCanvas, FPS } = useContext(CanvasStore);
  const [totalVideoLength, setTotalVideoLength] = useState(2000);
  const [canvasImage64, setCanvasImage64] = useState("");
  const [isExporting, setIsExporting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [autoSave, setAutoSave] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();
  const [quality, setQuality] = useState("720p");

  // Used for tracking and displaying export progress
  const [progress, setProgress] = useState(0);
  const [progressInfo, setProgressInfo] = useState({
    percent: 0,
    phase: "",
  });

  useEffect(() => {
    const fetchCanvasImage = async () => {
      if (!activeCanvas) return;
      setLoading(true);
      let artboard = activeCanvas.getObjects("artboard")[0];
      if (!artboard) return;
      setTotalVideoLength(artboard.animationDuration);
      const object = await artboard.toCanvasElement({
        enableRetinaScaling: true,
      });
      const canvasDataURL = object.toDataURL({
        format: "jpeg",
        quality: 0.7,
      });
      setCanvasImage64(canvasDataURL);
      setLoading(false);
    };

    fetchCanvasImage();
  }, [activeCanvas]);

  const handleSaveVideo = async () => {
    setIsSaving(true);

    // combine the audio layers
    let audioObject = await prepAudio();
    setProgressInfo({
      phase: "Waking up the hamsters...",
      percent: 10,
    });

    // convert canvas to video
    const { url, duration } = await handleExportVideo();
    let videoObject = {
      videoUrl: url,
      duration: duration,
    };

    /* DEBUG

     let audioObject = {
       audioUrl:
         "https://firebasestorage.googleapis.com/v0/b/textspeech-55a09.appspot.com/o/RWqpAn03Uwba1hUnDASSECIJMqC3%2Fprojects%2Fmixed-audio-5d676864-9087-4f42-8c86-89c725321601.mp3?alt=media&token=30f92e02-11e1-4205-8d6d-1c5312a2776e",
       animationStartTime: 0,
     };
    let videoObject = {
      videoUrl:
        "https://firebasestorage.googleapis.com/v0/b/textspeech-55a09.appspot.com/o/RWqpAn03Uwba1hUnDASSECIJMqC3%2Fvideos%2F824b2331-4835-41e2-84b0-da5b15b7184a.mp4?alt=media&token=a7db87da-a69f-4e1d-8207-48fe53676637",
      duration: 2001,
    };
*/
    console.log(audioObject, videoObject);
    setProgressInfo({ phase: "Almost there...", percent: 90 });

    let finalUrl;
    if (audioObject.audioUrl === null) {
      console.log("no audio...");
      finalUrl = url;
    } else {
      finalUrl = await mergeVideoAudio(videoObject, audioObject);
    }

    console.log(finalUrl);

    try {
      setProgressInfo({ phase: "Almost there...", percent: 99 });
      const response = await fetch(finalUrl, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      setIsSaving(false);

      saveAs(blob, activeCanvas.projectTitle);
    } catch (error) {
      console.error("Error downloading the video:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const prepAudio = async () => {
    // find videos with audio
    const videosWithAudio = sortBy(
      deepFindVideos(activeCanvas.getObjects("artboard")[0])
        .slice()
        .filter((ch) => ch.isVideo && !ch.videoMuted && ch.hasAudio)
        .map((o) => o.toObject(propertiesToInclude)),
      (video) => video.animationStartTime
    );

    console.log(videosWithAudio);
    // now find any other audios loaded in the canvas
    const audioLayers = sortBy(
      activeCanvas
        .getObjects("artboard")[0]
        .getChildren("audio")
        .slice()
        .filter((ch) => ch.type === "audio" && !ch.muted)
        .map((o) => o.toObject(propertiesToInclude)),
      (audio) => audio.animationStartTime
    );

    const audioList = audioLayers; //.concat(videosWithAudio);
    const minAnimationStartTime = Math.min(
      ...audioList.map((audio) => audio.animationStartTime)
    );

    let audioObject = {
      audioUrl: null,
    };
    // Check if there is any audio
    if (audioList.length > 0) {
      console.log(audioList.length);

      const generateAudioFunction = httpsCallable(functions, "prepaudio", {
        timeout: 300000,
      });
      try {
        const result = await generateAudioFunction({
          audioLayers: JSON.stringify(audioList),
        });
        let audioGsUrl = result.data;
        console.log(audioGsUrl);
        let audioUrl = await getDownloadURL(ref(storage, audioGsUrl));
        console.log(audioUrl);
        audioObject = {
          audioUrl,
          animationStartTime: minAnimationStartTime,
        };
        console.log(JSON.stringify(audioObject));
        return audioObject;
      } catch (error) {
        console.log(error);
      }
    }
    return audioObject;
  };
  const mergeVideoAudio = async (videoInfo, audioInfo) => {
    const generateVideoFunction = httpsCallable(functions, "mergeav", {
      timeout: 300000,
    });

    try {
      const result = await generateVideoFunction({
        video: videoInfo,
        audio: audioInfo,
      });

      let gsUrl = result.data;
      let videoUrl = await getDownloadURL(ref(storage, gsUrl));
      return videoUrl;
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportVideo = async () => {
    if (isExporting) return;
    setIsExporting(true);
    let startTime = performance.now();
    let { url, duration } = await activeCanvas.exportAsImages(
      FPS,
      user.uid,
      setProgressInfo,
      quality
    );
    let endTime = performance.now();
    let executionTime = (endTime - startTime) / 1000;
    console.log(`Execution time: ${executionTime} seconds`);
    return { url, duration: duration };
  };

  const handleExportButtonClick = async () => {
    await handleSaveVideo();
    handleClose();
  };

  const handleSave = () => {
    activeCanvas.exportToBackend();
  };

  const handleChangeQuality = (event) => {
    setQuality(event.target.value);
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: {
          minHeight: 450,
        },
      }}
    >
      <DialogTitle>
        <Stack justifyContent="space-between" direction="row">
          <Typography sx={{ color: "#fff" }}>
            {activeCanvas.projectTitle}
          </Typography>
          <IconButton size="small" variant="text" onClick={handleClose}>
            <Iconify icon="material-symbols:close" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <div className="p-6 bg-black bg-opacity-40">
          <div className="h-72 w-full rounded-lg mb-2 border border-gray-300">
            {loading && (
              <Stack minWidth={450}>
                <LinearProgress></LinearProgress>
              </Stack>
            )}

            <div className="relative h-full w-full">
              {!loading && canvasImage64 && (
                <img
                  src={canvasImage64}
                  alt="Video Preview"
                  className="h-full w-full object-contain rounded-lg"
                />
              )}
              {totalVideoLength > 120000 && (
                <span
                  className={
                    "absolute bottom-1 left-[130px] p-2  right-[130px] font-medium text-xs w-[160px] bg-black bg-opacity-80 rounded text-red-500"
                  }
                >
                  Export is limited to 2 mins
                </span>
              )}
            </div>

            {/*<p className="flex items-center gap-2 px-2 py-1.5 bg-[#ffeaca] rounded-md text-[13px] m-0 font-medium font-inter text-[#18191b]">
              <div className="bg-orangeMedium w-4 h-4 flex justify-center items-center rounded-sm">
                <Watermark />
              </div>
              <span>Remove Watermark</span>
            </p>*/}
          </div>

          {isSaving && (
            <>
              <Box sx={{ width: "100%" }} mb={2}>
                <Typography class="text-slate-300 text-xs">
                  {progressInfo.phase
                    ? progressInfo.phase
                    : "Assembling pixels like a digital Lego master..."}
                </Typography>
                {/* TODO: Find a better way to show status */}

                <LinearProgressWithLabel value={progressInfo?.percent} />
              </Box>
            </>
          )}
          {/*<Box>{JSON.stringify(progressInfo)}</Box>*/}

          <div className="flex gap-6 mt-2 justify-center items-center text-slate-300">
            <div
              className={`flex items-center inset-x-0 p-2 gap-2 text-slate-300 justify-center items-center  rounded`}
            >
              <AccessTimeOutlinedIcon sx={{ fontSize: 18 }} />
              {formatTimeToMinSecMili(totalVideoLength)}
            </div>
            <FormLabel
              id="demo-row-radio-buttons-group-label"
              className="text-white font-bold	"
            >
              Video Quality
            </FormLabel>
            <RadioGroup
              row
              aria-label="video-quality"
              name="video-quality"
              value={quality}
              onChange={handleChangeQuality}
            >
              <FormControlLabel
                value="1080p"
                control={<Radio size="small" />}
                label="1080p"
              />
              <FormControlLabel
                value="720p"
                control={<Radio size="small" />}
                label="720p"
              />
              <FormControlLabel
                value="480p"
                control={<Radio size="small" />}
                label="480p"
              />
            </RadioGroup>
          </div>
          <div className="flex gap-4 mt-6 justify-center items-center">
            <LoadingButton
              onClick={handleExportButtonClick}
              disabled={
                activeCanvas.getObjects("artboard")[0].children.length === 0
              }
              loading={isSaving}
              variant="contained"
              fullWidth
              sx={{ width: "500px" }}
            >
              Export Video
            </LoadingButton>
          </div>
          {/* <div className="flex justify-between gap-2 pb-4 border-b border-[#e4e4eb] mb-2">
            <div className="flex items-center gap-2">
              <SaveIcon className="text-[#4a4e70]" />
              <span className="text-[#18191b] font-medium text-[15px]">
                Auto save video
              </span>
            </div>
            <Switch
              checked={autoSave}
              onChange={() => setAutoSave(!autoSave)}
              size="small"
              color="primary"
            />
          </div>*/}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ExportModal;

const Time = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="sc-aXZVg gFHStW"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M12 6V12L16 14"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export const Watermark = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.94101 5.40007h2.58777c.36358 0 .58977.33558.40591.60221L3.9354 11.8016c-.24261.3518-.87641.2056-.87641-.2022V6.59993H.471217c-.363579 0-.589773-.33559-.4059043-.60221L4.0646.198373c.24261-.351804.87641-.20553566.87641.202259V5.40007Z"
        fill="#fff"
      ></path>
      <path
        d="M7.52878 5.40007H4.94101v1h2.58777c.04286 0 .08381.00466.1224.01332l.28351-.41111c.18386-.26663-.04233-.60221-.40591-.60221ZM.348819 6.58661 4.0646 1.19837c.24261-.351801.87641-.205532.87641.20226V.400632c0-.40779466-.6338-.554063-.87641-.202259L.0653127 5.99772c-.1621954.2352-.0053025.52405.2835063.58889Z"
        fill="#fff"
      ></path>
      <defs>
        <linearGradient
          id="a"
          x1="3.41645"
          y1="10.9569"
          x2="3.59467"
          y2="0.532008"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFF6E9"></stop>
          <stop offset="1" stop-color="#FFEDD3"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const Translate = ({ color }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17L16.0715 9.32119C16.4068 8.48304 17.5932 8.48304 17.9285 9.32119L21 17M13 17L11 22M13 17H21M21 17L23 22"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={color}
      ></path>
      <path
        d="M2.36363 4.27274H12.1364M12.1364 4.27274H14.1818M12.1364 4.27274C11.5766 8.20618 8.89876 11.513 5.16761 12.8782L3.8409 13.3636"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={color}
      ></path>
      <path
        d="M8.27272 3.81818V2"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={color}
      ></path>
      <path
        d="M4.72726 7.31818L5.08835 7.95117C5.98286 9.51929 7.38147 10.7388 9.05683 11.4113L10.6364 12.0454"
        stroke={"currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        className={color}
      ></path>
    </svg>
  );
};
