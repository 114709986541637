import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  TextField,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { useUserAuth } from "../../../UserAuthContext";
import InputAdornment from "@mui/material/InputAdornment";

// ----------------------------------------------------------------------

export default function SignupForm({ plan }) {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signUpEmailPassword } = useUserAuth();
  const [errorMessage, setErrorMessage] = useState("");

  const register = (e) => {
    e.preventDefault();
    if (password.length < 8 || password.length > 64) {
      setErrorMessage("Password must be between 8 and 64 characters");
      return;
    }
    signUpEmailPassword(name, email, password, plan);
    //signUpMagicLink(name, email, plan);
    navigate("/vp", { replace: true });
  };

  return (
    <>
      <Stack spacing={3} padding={1}>
        <TextField
          required
          name="name"
          label="Name"
          onChange={(e) => setName(e.target.value)}
        />

        <TextField
          required
          name="email"
          label="Email address"
          onChange={(e) => setEmail(e.target.value)}
        />

        {
          <TextField
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            inputProps={{
              minLength: 8,
              maxLength: 16,
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      </Stack>
      <Stack>
        <Typography variant="subtitle1" sx={{ color: "red" }}>
          {errorMessage}
        </Typography>
      </Stack>

      <LoadingButton
        disabled={!name || !email || !password}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={register}
      >
        Sign up
      </LoadingButton>
    </>
  );
}
