import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import Canvas from "../../canvas/Canvas";
import Background from "../../settings/components/Background";

import Color from "../../shared/Color";

import SizeDropdown from "./SizeDropdown";
import { CanvasStore } from "../../canvas/store/canvasStore";

const dropdownList = [
  { label: "Landscape", icon: "original", value: "Landscape" },
  { label: "YouTube (16:9)", icon: "youTube", value: "YouTube (16:9)" },
  {
    label: "YouTube Short (9:16)",
    icon: "youTube",
    value: "YouTube Short (9:16)",
  },
  { label: "TikTok (9:16)", icon: "tikTok", value: "TikTok (9:16)" },
  {
    label: "Instagram Post (1:1)",
    icon: "instagramPost",
    value: "Instagram Post (1:1)",
  },
  {
    label: "Instagram Story (9:16)",
    icon: "instagramStory",
    value: "Instagram Story (9:16)",
  },
  {
    label: "Instagram Reel (9:16)",
    icon: "instagramReel",
    value: "Instagram Reel (9:16)",
  },
];
const MainContent = () => {
  const { activeCanvas } = useContext(CanvasStore);
  const [backgroundShow, setBackgroundShow] = useState(false);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [canvas, setCanvas] = useState();
  const [canvasFill, setCanvasFill] = useState(null);
  const [activeCanvasFrame, setActiveCanvasFrame] = useState(null);
  const [openWarning, setOpenWarning] = useState(true);

  const anchorRef = React.useRef(null);

  useEffect(() => {
    if (activeCanvas) {
      const artboard = activeCanvas.getObjects("artboard")[0];
      const frame = artboard?.activeFrameRatio || dropdownList[0].label;
      setActiveCanvasFrame(dropdownList.find((item) => item.value === frame));
    }
  }, [activeCanvas]);

  const handleShowBackground = (show) => {
    setBackgroundShow(show);
  };

  const handleClick = (event) => {
    setDropdownShow(true);
  };

  const handleOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleClose = () => {
    setDropdownShow(false);
  };

  const id = "simple-popover";

  return (
    <div className="w-full h-full flex flex-col">
      <div className="relative flex-1 flex">
        <Canvas onSetCanvas={setCanvas} />
        <div className="absolute left-[calc(50%-190px)] bottom-0 flex justify-center p-4">
          {/*<div>
            <ButtonGroup
              size="small"
              color="primary"
              ref={anchorRef}
              sx={{
                backgroundColor: "white",
                border: "1px solid #eaeaea",
                borderRadius: "10px",
              }}
              variant="outlined"
              aria-label="Basic button group"
            >
              <Button
                size="small"
                sx={{
                  color: "#18191b",
                  fontSize: "10px",
                  border: "0px",
                  marginX: "4px",
                  marginY: "4px",
                  paddingY: "12px",
                  minWidth: "200px",
                  borderRight: "1px solid #eaeaea !important",
                  "&:hover": {
                    border: 0, // Set border radius to 0 on hover
                    background: "#5c5e6521",
                  },
                }}
                startIcon={<Original />}
                endIcon={<KeyboardArrowDownOutlinedIcon />}
                onClick={handleClick}
              >
                {activeCanvasFrame.label}
                <span className="text-gray-400 ml-2 ">
                  {activeCanvasFrame.size}
                </span>
              </Button>
              <Button
                size="small"
                sx={{
                  color: "#18191b",
                  fontSize: "10px",
                  border: "0px",
                  marginX: "4px",
                  marginY: "4px",
                  width: "175px",
                  "&:hover": {
                    border: 0, // Set border radius to 0 on hover
                    background: "#5c5e6521",
                  },
                }}
                onClick={() => handleShowBackground(true)}
                startIcon={
                  <Color
                    className={"border-solid border-[1px] border-gray-300"}
                    value={canvasFill}
                  />
                }
              >
                background
              </Button>
            </ButtonGroup>
          </div>*/}
        </div>

        {/*<SizeDropdown
          id={id}
          handleClose={handleClose}
          open={dropdownShow}
          dropdownList={dropdownList}
          anchorEl={anchorRef.current}
          onSelectedFrame={(frame) => setActiveCanvasFrame(frame)}
        />
        <Background
          id={id}
          anchorEl={anchorRef.current}
          open={backgroundShow}
          selectedColor={(color) => setCanvasFill(color)}
          handleClose={() => {
            handleShowBackground(false);
          }}
        />*/}
      </div>
    </div>
  );
};

const Original = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#71737C"
      data-testid="@floating/component-text/generic-icon"
      className="sc-aXZVg gXLylL"
    >
      <path
        d="M19 7.75C20.7949 7.75 22.25 9.20507 22.25 11L22.25 14C22.25 15.7949 20.7949 17.25 19 17.25L5 17.25C3.20507 17.25 1.75 15.7949 1.75 14L1.75 11C1.75 9.20507 3.20507 7.75 5 7.75L19 7.75Z"
        stroke="currentColor"
      ></path>
    </svg>
  );
};

export default MainContent;
