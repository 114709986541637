import React, { useState } from "react";
import {
  Button,
  Box,
  Stack,
  Tooltip,
  CircularProgress,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import {
  addDoc,
  doc,
  collection,
  onSnapshot,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { db } from "../../firebase";
import { useUserAuth } from "../../UserAuthContext";

// Create a root reference
const storage = getStorage();
const maxFileSize = 10 * 1024 * 1024; // 10MB

export default function UploadFile(props) {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorSeverity, setErrorSeverity] = useState("error");
  const [voiceEmotions, setVoiceEmotions] = useState([]);
  const [selectedEmotion, setSelectedEmotion] = useState("");
  const { user } = useUserAuth();

  const uploadFiletoStorage = async (file) => {
    if (!file) {
      return;
    }
    const timestamp = new Date().getTime();
    const fname = `${timestamp}_${file.name}`;
    const uid = user.uid;

    const storageRef = ref(storage, `${uid}/uploads/${fname}`);
    try {
      // Upload the file to Firebase Storage
      await uploadBytes(storageRef, file);

      await addDoc(collection(db, "uploads"), {
        user: props.user,
        file_name: `${uid}/uploads/${fname}`,
        timestamp: serverTimestamp(),
      });
      return fname;
    } catch (error) {
      console.log("Unable to upload file");
    }
  };

  const updateInputonSuccess = (fileContents) => {
    /* if (fileContents.length > 5000) {
      fileContents = fileContents.substring(0, 5000);
      setShowFileError(true)
      setErrorMessage("File contents exceed character limit and was truncated. Try our Audio Books.")
      setErrorSeverity("warning")
    }*/
    // console.log(fileContents)
    if (props.handleImportOverflowClose) {
      props.handleImportOverflowClose();
    }
    props.setIsContentLoading(false);
    props.setInputText(fileContents);
    setIsFileUploading(false);
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    const file = files[0];

    // make sure there is an actual file being uploaded
    if (!file) {
      return;
    }

    //props.setInputText("");
    setIsFileUploading(true);

    props.setIsContentLoading?.(true);

    const filename = await uploadFiletoStorage(file);

    let fileContents;

    if (file.size > maxFileSize) {
      setShowFileError(true);
      setErrorMessage(`File size exceeds 5 MB limit.`);
      props.setIsContentLoading?.(false);
      setIsFileUploading(false);
      return;
    }
    if (file.type.startsWith("text/plain")) {
      const reader = new FileReader();
      reader.addEventListener("load", (event) => {
        fileContents = event.target.result;
        updateInputonSuccess(fileContents);
      });
      // Read the file as text
      reader.readAsText(file);
    } else if (file.type.startsWith("application/pdf")) {
      setIsFileUploading(false);
      await processPDF(filename);
    } else {
      // Show error message or take appropriate action for disallowed file types
      setShowFileError(true);
      setErrorMessage("Invalid format. Only text files and pdfs are supported");
    }
  };

  const processPDF = async (filename) => {
    props.setIsContentLoading?.(true);

    await updateDoc(doc(db, "projects", props.projectId), {
      processingPdf: true, // set to false by the function
    });

    const extractTextFunction = httpsCallable(functions, "audioBooks");

    try {
      await extractTextFunction({
        filename: filename,
        projectId: props.projectId,
      });
    } catch (error) {
      setShowFileError(true);
      setErrorMessage("Unable to convert PDF. Try again later.");
      props.setIsContentLoading(false);
      setIsFileUploading(false);
    }

    const projectRef = doc(db, "projects", props.projectId);

    const unsubscribe = onSnapshot(projectRef, (snapshot) => {
      if (snapshot.exists()) {
        const projData = snapshot.data();
        if (projData.text) {
          props.setInputText(projData.text);
          props.setIsContentLoading(projData.processingPdf);
          if (props.handleImportOverflowClose) {
            props.handleImportOverflowClose();
          }
          // Unsubscribe once processing is complete
          unsubscribe();
        }
      }
    });

    // Clean up by unsubscribing when component unmounts or when projectData is received
    return unsubscribe;
  };

  return (
    <>
      <Box flexGrow={1}>
        <input
          style={{ display: "none" }}
          type="file"
          accept={props.importFileType === "pdf" ? ".pdf" : ".txt"}
          id={`contained-button-file_${props.importFileType}`}
          onChange={handleFileChange}
        />

        <label htmlFor={`contained-button-file_${props.importFileType}`}>
          <Stack spacing={1} width={250} padding={4} sx={{ cursor: "pointer" }}>
            {props.importFileType === "pdf" ? (
              <Iconify icon="teenyicons:pdf-solid" />
            ) : (
              <Iconify icon="bx:file" />
            )}
            <Stack direction="row">{props.label}</Stack>
            <Stack direction="row">
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {props.description}
              </Typography>
            </Stack>
          </Stack>
        </label>
      </Box>
      <ShowMessage
        showFileError={showFileError}
        setShowFileError={setShowFileError}
        severity={errorSeverity}
        message={errorMessage}
      />
    </>
  );
}
