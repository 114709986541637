import React, { useState } from "react";
import {
  Button,
  IconButton,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";

import { secondsToHMS } from "../../utils/utils";
import Iconify from "../iconify";

export default function SrtGeneator(props) {
  const [srtAnchorEl, setSrtAnchorEl] = useState(null);
  const [srtOffsetSeconds, setSrtOffsetSeconds] = useState(0);
  const [showSrtError, setShowSrtError] = useState(false);

  const removeTags = (text) => {
    var regex = new RegExp("\\B" + ":pause:" + "\\B", "g");
    const updatedText = text.replace(regex, "").trim();
    return updatedText;
  };

  const handleDownloadSrt = () => {
    let allText = "";
    let currentTime = srtOffsetSeconds; // Start time in seconds
    const wordsPerLine = 4;
    let totalLines = 0;
    props.fields.forEach((field, index) => {
      // Split the text into an array of words
      const tagFreeText = removeTags(field.text);
      const words = tagFreeText.split(" ");

      // Calculate the number of lines needed based on 4 words per line

      const linesNeeded = Math.ceil(words.length / wordsPerLine);

      // Calculate the time duration for each line
      const lineDuration = field.audioDuration / linesNeeded;

      for (let i = 0; i < linesNeeded; i++) {
        // Calculate the start and end time for the current line
        const lineStartTime = currentTime + i * lineDuration;
        const lineEndTime = lineStartTime + lineDuration;

        // Format the time in HH:mm:ss,SSS (SRT format)
        let startTime = secondsToHMS(lineStartTime, true);
        let endTime = secondsToHMS(lineEndTime, true);

        // Extract 4 words for the current line
        const lineWords = words.slice(i * wordsPerLine, (i + 1) * wordsPerLine);
        const lineText = lineWords.join(" ");
        //console.log(lineText);

        // Add the formatted text and duration to the SRT content
        allText += `${
          totalLines + i + 1
        }\n${startTime} --> ${endTime}\n${lineText}\n\n`;
      }
      // console.log(allText);
      // Update the current time for the next iteration
      totalLines += linesNeeded;
      currentTime += field.audioDuration;
    });

    const blob = new Blob([allText], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    props.TriggerDownload(props.project.name + ".srt", url);
    //props.logEvent(props.analytics, "button_click", {
    //  buttonName: "Download SRT",
    //});

    handleSrtClose();
  };

  const handleSrtClick = (event) => {
    setSrtAnchorEl(event.currentTarget);
  };

  const handleSrtClose = (event) => {
    setSrtAnchorEl(null);
  };

  const handleSrtOffsetChange = (event) => {
    setShowSrtError(false);
    let newValue = parseInt(event.target.value);
    if (!isNaN(newValue)) {
      setSrtOffsetSeconds(newValue);
    } else {
      setShowSrtError(true);
    }
  };

  return (
    <>
      {props.allSectionsReady && props.fields[0]?.audioDuration && (
        <Tooltip title="Download SRT for your project" placement="top">
          <Button
            variant="outlined"
            onClick={handleSrtClick}
            startIcon={<Iconify icon="basil:file-download-outline" />}
          >
            Download SRT
          </Button>
        </Tooltip>
      )}

      <Popover
        open={Boolean(srtAnchorEl)}
        anchorEl={srtAnchorEl}
        onClose={handleSrtClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Stack spacing={4} m={2} padding={2}>
          <TextField
            inputProps={{
              maxLength: 4,
            }}
            onChange={handleSrtOffsetChange}
            size="small"
            required
            id="outlined-required"
            label="Start offset"
            defaultValue={0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">seconds</InputAdornment>
              ),
            }}
            error={showSrtError}
            helperText={showSrtError ? "Please enter a valid number" : ""}
          />

          <Button
            disabled={showSrtError}
            variant="contained"
            onClick={handleDownloadSrt}
          >
            Export
          </Button>
        </Stack>
      </Popover>
    </>
  );
}
