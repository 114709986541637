import React, { useEffect, useState } from "react";

import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { debounce } from "lodash";
import Header from "../../../components/Header";
import { db, functions, httpsCallable } from "../../../firebase";
import { useUserAuth } from "../../../UserAuthContext";
import VideoSlider from "./components/VideoSlider";

const StockVideos = () => {
  const [videosList, setVideosList] = useState([]);
  const [filteredVideosList, setFilteredVideosList] = useState([]);
  const [userQuery, setUserQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const { user } = useUserAuth();

  useEffect(() => {
    const fetchVideoStocks = async () => {
      setLoading(true);
      let videos = [];
      if (!userQuery) {
        const q = query(collection(db, "stock"), where("type", "==", "video"));
        const querySnapshot = await getDocs(q);
        videos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          duration: doc.data()["duration"],
          url: doc.data()["url"],
        }));
      } else {
        try {
          console.log("calling", userQuery);
          const getMediaFunction = httpsCallable(functions, "getMedia");
          const result = await getMediaFunction({ query: userQuery });
          console.log(result);
          videos = result.data.videos.videos;
        } catch (error) {
          console.log("Unable to fetch stock media");
          setLoading(false);
        }
      }
      console.log(videos);
      setVideosList(videos);
      setLoading(false);
    };

    fetchVideoStocks();
  }, [userQuery]);

  const handleSearchVideo = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (!searchValue) {
      return;
    } else {
      // console.log(searchValue);
      setUserQuery(searchValue);
    }
  };

  const debouncedHandleSearchVideo = debounce(handleSearchVideo, 500);

  return (
    <div className="mt-5">
      <Header onSearch={debouncedHandleSearchVideo} heading={"Stock Videos"} />
      {/* <ButtonTabs /> */}
      <VideoSlider videoList={videosList} loading={loading} />
    </div>
  );
};

export default StockVideos;
