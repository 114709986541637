import { useState } from "react";
import {
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../iconify";
import ShowMessage from "../ShowMessage/ShowMessage";
import useResponsive from "../../hooks/useResponsive";
import { useTheme } from "@mui/material/styles";

export default function ButtonWithMenu(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showError, setShowError] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  const options = ["pause", "hmm", "ummm", "ahhh", "uhh"];
  const isLlmVoice =
    props.voice?.voice?.includes("Journey") ||
    props.voice?.voice?.includes("Casual") ||
    props.voice?.cloud === "oa";
  const handleClick = (event, label) => {
    // console.log(label);
    if (isLlmVoice) {
      setAnchorEl(event.currentTarget);
    } else {
      applyTag("pause");
    }
  };

  const insertPause = (value) => {
    const pause = isLlmVoice ? "..." : ":pause:";
    let field;
    if (props.index !== undefined) {
      field = document.getElementById(`ssml-editor_${props.index}`);
    } else {
      field = document.getElementById(`ssml-editor`);
    }

    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;
    const selectedText = field.value.substring(startPos, endPos);
    const newText = `${field.value.substring(
      0,
      startPos
    )} ${pause} ${selectedText}${field.value.substring(endPos)}`;

    if (props.index !== undefined) {
      props.updateText(newText, props.index);
    } else {
      props.updateText(newText);
    }
    //generateSsml(newText);
    field.focus();
    field.setSelectionRange(startPos, endPos);
  };

  const applyTag = (option) => {
    let tag;
    if (option.includes("pause")) {
      // :pause: is acoust syntax that gets replaced by ssml
      tag = isLlmVoice ? "..." : ":pause:";
    } else {
      tag = `,${option},`; // add commas around the tag
    }

    let field = "";
    if (props.index !== undefined) {
      field = document.getElementById(`ssml-editor_${props.index}`);
    } else {
      field = document.getElementById(`ssml-editor`);
    }

    const startPos = field.selectionStart;
    const endPos = field.selectionEnd;

    const selectedText = field.value.substring(startPos, endPos);
    const newText = `${field.value.substring(
      0,
      startPos
    )} ${tag} ${field.value.substring(endPos)}`;

    if (props.index !== undefined) {
      props.updateText(newText, props.index);
    } else {
      props.updateText(newText);
    }
    //generateSsml(newText);
    field.focus();
    field.setSelectionRange(startPos + tag.length + 2, endPos + tag.length + 2);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuClick(label, option) {
    applyTag(option);
    handleClose();
  }

  return (
    <div>
      <Tooltip title={props.tip} placement="top" arrow>
        {isDesktop ? (
          <Button
            sx={{
              color: theme.palette.text.secondary,
            }}
            variant="text"
            disabled={props.disabled}
            size="small"
            onClick={(e) => handleClick(e, props.labelText)}
            startIcon={<Iconify icon={props.icon} />}
          >
            {isLlmVoice ? "Insert" : "Add pause"}
          </Button>
        ) : (
          <IconButton
            disabled={props.disabled}
            size="small"
            onClick={(e) => handleClick(e, props.labelText)}
          >
            <Iconify icon={props.icon} />
          </IconButton>
        )}
      </Tooltip>
      {options && (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {options.map((option) => (
            <MenuItem
              key={option}
              onClick={() => handleMenuClick(props.labelText, option)}
            >
              <Typography sx={{ fontSize: "12px" }}>{option}</Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
      <ShowMessage
        showFileError={showError}
        setShowFileError={setShowError}
        severity="error"
        message="Please select text to apply emphasis"
      />
    </div>
  );
}
