import React from "react";

import { Icons } from "../../utils/Icons";
import { Link } from "react-router-dom";
import Iconify from "../iconify/Iconify";
import { Stack } from "@mui/material";

const SingleNavItem = ({ item, active }) => {
  return (
    <Stack mt={2}>
      <Link
        to={item.link}
        className={`${
          active ? "bg-sky-800 bg-opacity-90" : "group"
        } py-0 px-6 relative box-sizing cursor-pointer flex justify-center items-center flex-col user-select-none no-underline`}
      >
        <Stack
          padding={1}
          alignContent="center"
          alignItems="center"
          justifyContent="center"
        >
          <Iconify color="#fff" width="25px" icon={item.icon} />
          <span className="text-xs text-white capitalize font-medium">
            {item.label}
          </span>
        </Stack>
      </Link>
    </Stack>
  );
};

export default SingleNavItem;
