import React, { useState } from "react";
import { Slider as MUISlider } from "@mui/material";

function Slider({
  min = 0,
  max = 100,
  step,
  value,
  onChange = () => {},
  hasInput = true,
  className,
  unit = "%",
  ...props
}) {
  const [inputType, setInputType] = useState("text");

  const handleBlur = () => {
    setInputType("text");
  };

  const handleFocus = () => {
    setInputType("number");
  };

  const handleChange = ({ target }) => {
    let value = target.value;
    if (value < min) {
      value = min;
    } else if (value > max) {
      value = max;
    }
    onChange(value);
  };

  return (
    <div className={"flex flex-grow items-center"}>
      <MUISlider
        style={{ padding: "10px 0px" }}
        className={className}
        size={"small"}
        value={value}
        onChange={handleChange}
        step={step}
        min={min}
        max={max}
        {...props}
      />

      {hasInput && (
        <>
          {inputType === "text" ? (
            <input
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={`${value}${unit}`}
              className={
                "text-slate-100 text-right px-2 py-[6px] ml-3 w-[50px] bg-transparent rounded border-0 outline-0 min-h-[28px] h-auto"
              }
              type={"text"}
              onChange={onChange}
            />
          ) : (
            <input
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              onChange={handleChange}
              className={
                "focus:text-primary text-slate-100 text-right px-2 py-[6px] ml-3 w-[50px] bg-transparent rounded border-0 outline-0 min-h-[28px] h-auto"
              }
              type={"number"}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Slider;
