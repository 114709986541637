import ObjectID from "bson-objectid";
import { fabric } from "fabric";

import { cloneDeepImmutable } from "../../utils";
import {
  generateVideoThumbnails,
  getVideoElement,
  waitForVideoCanPlayThrough,
  waitForVideoSeeked,
} from "../../Animations/utils";
import Matrix from "../utils/matrix2D";
import validator from "validator";

const props = [
  "_id",
  "title",
  "videoMuted",
  "hasAudio",
  "videoSrc",
  "isVideo",
  "speed",
  "volume",
  "name",
];

/**
 * Video subclass
 * @class fabric.Video
 * @extends fabric.Video
 * @return {fabric.Video} thisArg
 *
 */
fabric.Video = class extends fabric.Image {
  type = "video";
  fill = null;
  stroke = null;
  srcFromAttribute = true;
  FPS = 30;
  uniformScaling = true;
  videoMuted = false;
  timelineFrames = [];
  stateProperties = fabric.Image.prototype.stateProperties.concat(...props);
  speed = 1;
  volume = 1;
  constructor(element, options = {}) {
    super(options);

    if (options) this.setOptions(options);

    this._id = options._id || ObjectID().toHexString();

    this.originX = options.originX || "center";
    this.originY = options.originY || "center";

    element && this._initElement(element, options);

    this.photoCanvas = document.createElement("canvas");
    this.photoCtx = this.photoCanvas.getContext("2d");

    element && this.updatePhotoCanvas();

    this.on("added", this._startEvents);
    this.on("removed", this.stopVideo);

    this.lockMovementX = false;
    this.lockMovementY = false;
  }

  setPlaybackSpeed(speed) {
    this.speed = speed;
    if (this.__videoElement) {
      this.__videoElement.playbackRate = speed;
      this.canvas.fire("animate:prop:updated", { target: this });
    }
  }

  cacheProperties = fabric.Image.prototype.cacheProperties.concat(...props);

  /**
   *
   * @private
   */
  _startEvents = () => {
    if (!this.canvas) return;

    this.width *= this.scaleX * 2;
    this.height *= this.scaleY * 2;
    this.scaleX = 0.5;
    this.scaleY = 0.5;
    this.updatePhotoCanvas();

    this.setVideoElement(this.videoSrc).then(() => {
      if (
        this.__parentArtboard &&
        this.__parentArtboard.durationType !== "fixed"
      ) {
        if (this.__parentArtboard?.autoCalculateDuration) {
          this.__parentArtboard.autoCalculateDuration();
        }
      }
    });

    this.on("scaling", this.updatePhotoCanvas);
    this.on("resizing", this.updatePhotoCanvas);
  };

  async checkHasAudio(video = this.__videoElement) {
    try {
      if (!video) {
        this.hasAudio = false;
        return false;
      }

      // Check if the video element has audio tracks
      const hasAudioTracks =
        video.audioTracks?.length > 0 || video.getAudioTracks?.().length > 0;

      this.hasAudio = Boolean(hasAudioTracks);
      return this.hasAudio;
    } catch (error) {
      this.hasAudio = false;
      return false;
    }
  }

  setVideoElement(src = this.videoSrc, options) {
    return new Promise((resolve, reject) => {
      if (this.isVideo && src) {
        getVideoElement(src, this.videoMuted || false, false)
          .then(async (videoElement) => {
            this.__videoElement = videoElement;
            if (typeof this.hasAudio !== "boolean") {
              try {
                await this.checkHasAudio(this.__videoElement);
              } catch (error) {
                reject(error);
              }
            }

            const newOptions = options || {
              scaleX: this.scaleX,
              scaleY: this.scaleY,
              width: this.width,
              height: this.height,
            };

            this.setElement(videoElement, newOptions);

            this.updatePhotoCanvas();
            this.canvas?.renderAll();

            videoElement.onseeking = () => {
              this.updatePhotoCanvas();
              this.canvas?.renderAll();
            };

            // this._getAudioContext();

            resolve(videoElement);
          })
          .catch((err) => {
            reject(err);
            console.error(err);
          });
      } else {
        resolve(null);
        delete this.videoSrc;
      }
    });
  }

  audioVolume(volume = 1) {
    if (this.__videoElement) {
      const transform = {
        action: "volume",
        e: {},
        original: {
          ...fabric.util.saveObjectTransform(this),
        },
      };

      this.__videoElement.volume = volume;
      this.volume = volume;
      if (volume == 0) {
        this.__videoElement.muted = true;
        this.videoMuted = true;
      }

      this.canvas.fire("object:modified", {
        target: this,
        transform,
        e: {},
        action: "volume",
      });
    }
  }

  muteAudio(muted = false) {
    if (this.__videoElement) {
      const transform = {
        action: "mute",
        e: {},
        original: {
          ...fabric.util.saveObjectTransform(this),
        },
      };

      this.__videoElement.muted = muted;
      this.videoMuted = this.__videoElement.muted;

      this.canvas.fire("object:modified", {
        target: this,
        transform,
        e: {},
        action: "mute",
      });
    }
  }

  async playVideoFrom(time) {
    if (this._videoIsPlaying) return;

    this._videoIsPlaying = true;
    this._videoIsPaused = false;
    this._videoIsEnded = false;

    await this.seekVideo(time);
    this.playVideo(true);
  }

  async playVideo() {
    if (this._videoIsPlaying) return;
    clearInterval(this.framesIntervalRef);

    this._videoIsPlaying = true;
    this._videoIsPaused = false;
    this._videoIsEnded = false;

    if (this.isVideo && this.videoSrc) {
      const videoElement = this.__videoElement;
      if (typeof videoElement?.play === "function") {
        // console.log(videoElement.playbackRate)
        const playPromise = videoElement.play();

        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              this._videoIsPlaying = true;
              this._videoIsPaused = false;
              this._videoIsEnded = false;
              this.canvas?.requestRenderAll();
            })
            .catch((error) => {
              console.error(error);

              this._videoIsPlaying = false;
              this._videoIsPaused = true;
              this._videoIsEnded = false;
              this.canvas?.requestRenderAll();
            });
        }

        videoElement.onended = () => {
          this._videoIsPlaying = false;
          this._videoIsPaused = false;
          this._videoIsEnded = true;
          this.canvas?.requestRenderAll();

          if (this.framesIntervalRef) {
            clearInterval(this.framesIntervalRef);
          }
        };

        const render = () => {
          const backend = fabric.filterBackend;
          if (backend && backend.evictCachesForKey) {
            backend.evictCachesForKey(this.cacheKey);
            backend.evictCachesForKey(this.cacheKey + "_filtered");
          }
          this.applyFilters();
          this.updatePhotoCanvas();
          this.canvas?.requestRenderAll();
        };

        this.framesIntervalRef = setInterval(() => {
          render();
        }, 1000 / this.FPS);

        render();
      }
    }
  }

  async seekVideo(time) {
    if (this.isVideo && this.videoSrc) {
      const videoElement = this.__videoElement;
      if (typeof videoElement?.play === "function") {
        this._videoIsPlaying = false;
        this._videoIsPaused = true;
        this._videoIsEnded = false;

        videoElement.currentTime = time || 0;
        if (this.group) this.group.dirty = true;

        await waitForVideoSeeked(videoElement);

        this.applyFilters();
        this.updatePhotoCanvas();
        this.canvas?.renderAll();
      }
    }
  }
  async getVideoTimelineFrames(options) {
    // let r=this.__videoElement.width/this.__videoElement.height;
    //
    // options.height=options.width/r;
    this.timelineFrames = await generateVideoThumbnails(this.videoSrc, options);
  }

  async stopVideo() {
    clearInterval(this.framesIntervalRef);

    if (this.isVideo && this.videoSrc) {
      const videoElement = this.__videoElement;

      this._videoIsPlaying = false;
      this._videoIsPaused = false;
      this._videoIsEnded = false;

      if (typeof videoElement?.pause === "function") {
        videoElement.load();
        await waitForVideoCanPlayThrough(videoElement);

        this.updatePhotoCanvas();
        this.canvas?.renderAll();
      }
    }
  }

  pauseVideo() {
    clearInterval(this.framesIntervalRef);

    if (this.isVideo && this.videoSrc) {
      const videoElement = this.__videoElement;
      if (typeof videoElement?.pause === "function") {
        videoElement.pause();
        this._videoIsPlaying = false;
        this._videoIsPaused = true;

        this.updatePhotoCanvas();
        this.canvas?.renderAll();
      }
    }
  }

  resetPhotoState = (e) => {
    // TODO: reset animations too
    const _width = this.width;
    const _height = this.height;
    const transform = {
      corner: "br",
      original: {
        ...fabric.util.saveObjectTransform(this),
        width: _width,
        height: _height,
      },
    };

    const { _originalElement } = this;
    const elWidth = _originalElement.naturalWidth || _originalElement.width;
    const elHeight = _originalElement.naturalHeight || _originalElement.height;

    this.set({
      filters: [],
      shadow: null,
      flipX: false,
      flipY: false,
    });

    this.set({
      width: elWidth * 2,
      height: elHeight * 2,
      scaleX: 0.5,
      scaleY: 0.5,
    });

    this.applyFilters();
    if (this.type === "video") {
      this.updatePhotoCanvas();
    }

    if (this.group) {
      this.group.addWithUpdate();
    }

    this.canvas.requestRenderAll();

    this.canvas.fire("video:reset", { target: this });
    this.canvas.fire("object:resized", { target: this, e, transform });
    this.fire("resized", { e, transform });
  };

  /**
   * @private
   * @param {CanvasRenderingContext2D} ctx Context to render on
   */ /** @ts-ignore */
  _render(ctx) {
    // culling
    if (
      this.canvas &&
      this.canvas.skipOffscreen &&
      !this.group &&
      !this.isOnScreen()
    )
      return;
    fabric.util.setImageSmoothing(ctx, this.imageSmoothing);

    if (this.isMoving !== true && this.resizeFilter && this._needsResize()) {
      this.applyResizeFilters();
    }

    const tfx = this.transformEffect;
    if (tfx?.copies > 0) ctx.globalCompositeOperation = "source-over";

    this._stroke(ctx);
    this._renderPaintInOrder(ctx);

    if (tfx?.copies > 0) {
      ctx.save();
      for (let i = 0; i < tfx.copies; i++) {
        let transform = new Matrix([
          tfx.scale.x,
          tfx.skew.y,
          tfx.skew.x,
          tfx.scale.y,
          tfx.translate.x,
          tfx.translate.y,
        ]);
        transform.rotateDeg(tfx.angle);
        if (tfx.flipX) transform.flipX();
        if (tfx.flipY) transform.flipY();
        if (tfx.invert) transform = transform.getInverse();

        ctx.transform(...transform.toArray());

        this._setShadow(ctx);

        this._renderFill(ctx);

        this._stroke(ctx);
        this._renderPaintInOrder(ctx);
      }
      this._removeShadow(ctx);

      ctx.restore();
    }

    if (this.__uploading) {
      const zoom = this.canvas.getZoom();

      ctx.save();
      const width = 140 / zoom;
      const height = 35 / zoom;
      const radius = 5 / zoom;
      const fontSize = 18 / zoom;
      const x = -width / 2;
      const y = -height / 2;

      ctx.beginPath();

      ctx.moveTo(x + radius, y);
      ctx.arcTo(x + width, y, x + width, y + height, radius);
      ctx.arcTo(x + width, y + height, x, y + height, radius);
      ctx.arcTo(x, y + height, x, y, radius);
      ctx.arcTo(x, y, x + width, y, radius);

      ctx.closePath();
      ctx.fillStyle = "rgba(0, 0, 0, 0.75)";
      ctx.strokeStyle = "rgba(255, 255, 255, 0.75)";
      ctx.stroke();
      ctx.fill();
      ctx.font = `500 ${fontSize}px sans-serif`;
      ctx.textAlign = "center";
      ctx.fillStyle = "rgba(255, 255, 255, 0.95)";
      ctx.fillText("uploading...", 0, 4 / zoom);
      ctx.restore();
    }
  }

  _renderFill(ctx) {
    // culling
    if (
      this.canvas &&
      this.canvas.skipOffscreen &&
      !this.group &&
      !this.isOnScreen()
    )
      return;

    ctx.save();
    const width = this.width;
    const height = this.height;

    ctx.translate(-width / 2, -height / 2);
    ctx.drawImage(
      this.photoCanvas,
      0,
      0,
      this.photoCanvas.width,
      this.photoCanvas.height
    );

    ctx.restore();
  }

  updatePhotoCanvas() {
    if (!this.visible) return;

    try {
      if (this.isVideo) {
        if (
          !this.getElement() ||
          this.getElement().type === "video" ||
          this.getElement().type === "image"
        )
          return;
      }
      return this._updateImageFillCanvas();
    } catch (error) {
      console.error(error);
    }
  }

  _updateImageFillCanvas() {
    const image = this._element;
    if (!image) return;
    if (image?.type === "video") return;

    const elWidth = image.naturalWidth || image.width;
    const elHeight = image.naturalHeight || image.height;

    this.photoCanvas.width = Math.max(this.width, 1);
    this.photoCanvas.height = Math.max(this.height, 1);

    const w = this.photoCanvas.width;
    const h = this.photoCanvas.height;
    const aspectRatio = Math.max(w / elWidth, h / elHeight);

    const x = w / 2 - (elWidth / 2) * aspectRatio;
    const y = h / 2 - (elHeight / 2) * aspectRatio;

    this.photoCtx.save();
    this.photoCtx.beginPath();

    const { scaleX, scaleY } = this;
    this.photoCtx.scale(1 / scaleX, 1 / scaleY);

    this.photoCtx.scale(scaleX, scaleY);

    this.photoCtx.clearRect(0, 0, w, h);
    this.photoCtx.drawImage(
      image,
      x,
      y,
      elWidth * aspectRatio,
      elHeight * aspectRatio
    );

    this.photoCtx.restore();
  }

  /**
   * Returns object representation of an instance
   * @param {Array} [propertiesToInclude] Any properties that you might want to additionally include in the output
   * @return {Object} object representation of an instance
   */
  toObject(propertiesToInclude) {
    var filters = [];

    this.filters.forEach(function (filterObj) {
      if (filterObj && typeof filterObj.toObject === "function") {
        filters.push(filterObj.toObject());
      }
    });

    const isValidSrc = !!(this.src && validator.isURL(this.src));

    const object = fabric.util.object.extend(
      this.callSuper("toObject", props.concat(propertiesToInclude)),
      {
        src: isValidSrc ? this.src : this.getSrc(),
        crossOrigin: this.getCrossOrigin(),
        filters: filters,
      }
    );

    if (this.resizeFilter) {
      object.resizeFilter = this.resizeFilter.toObject();
    }

    return object;
  }

  /* async toImageBlob(format = 'png', quality = 0.8, pixelRatio) {
    const img = await getLayerAsFile(
      {
        type: { value: format },
        quality,
        pixel: { value: pixelRatio || 2 },
      },
      await cloneObject(this, 0, 0, false),
      false,
      null,
      true,
    );

    return img;
  }
*/
};

/**
 * Creates an instance of fabric.Video from its object representation
 * @static
 * @param {Object} object Object to create an instance from
 * @param {Function} callback Callback to invoke when an image instance is created
 */
fabric.Video.fromObject = async function (_object, callback) {
  const object = cloneDeepImmutable(_object);

  fabric.util.loadImage(
    await object.src,
    function (img, isError) {
      if (isError) {
        callback && callback(null, true);

        return;
      }

      fabric.Video.prototype._initFilters.call(
        object,
        object.filters,
        function (filters) {
          object.filters = filters || [];
          fabric.Video.prototype._initFilters.call(
            object,
            [object.resizeFilter],
            function (resizeFilters) {
              object.resizeFilter = resizeFilters[0];

              fabric.util.enlivenObjects(
                [object.clipPath],
                async function (enlivedProps) {
                  object.clipPath = enlivedProps[0];
                  const image = new fabric.Video(img, object);

                  try {
                    image.isVideo &&
                      image.videoSrc &&
                      (await asyncCallWithTimeout(
                        image.setVideoElement(image.videoSrc),
                        5000
                      ));

                    callback(image, false);
                  } catch (error) {
                    callback(image, true);
                  }
                }
              );
            }
          );
        }
      );
    },
    null,
    object.crossOrigin || "anonymous"
  );
};

/**
 * Creates an instance of fabric.Video from an URL string
 * @static
 * @param {String} url URL to create an image from
 * @param {Function} [callback] Callback to invoke when image is created (newly created image is passed as a first argument). Second argument is a boolean indicating if an error occurred or not.
 * @param {Object} [imgOptions] Options object
 */
fabric.Video.fromURL = function (url, callback, imgOptions) {
  fabric.util.loadImage(
    url,
    async function (img, isError) {
      const image = new fabric.Video(img, imgOptions);

      try {
        image.isVideo &&
          image.videoSrc &&
          (await asyncCallWithTimeout(
            image.setVideoElement(image.videoSrc),
            5000
          ));

        callback && callback(image, isError);
      } catch (error) {
        callback && callback(image, true);
      }
    },
    null,
    imgOptions && imgOptions.crossOrigin
  );
};

/**
 * Call an async function with a maximum time limit (in milliseconds) for the timeout
 * @param {Promise<any>} asyncPromise An asynchronous promise to resolve
 * @param {number} timeLimit Time limit to attempt function in milliseconds
 * @returns {Promise<any> | undefined} Resolved promise for async function call, or an error if time limit reached
 */
export const asyncCallWithTimeout = async (asyncPromise, timeLimit) => {
  let timeoutHandle;

  const timeoutPromise = new Promise((_resolve, reject) => {
    timeoutHandle = setTimeout(
      () => reject(new Error("Async call timeout limit reached")),
      timeLimit
    );
  });

  return Promise.race([asyncPromise, timeoutPromise]).then((result) => {
    clearTimeout(timeoutHandle);
    return result;
  });
};

/**
 * Returns {@link fabric.Video} instance from an SVG element
 * @static
 * @param {SVGElement} element Element to parse
 * @param {Object} [options] Options object
 * @param {Function} callback Callback to execute when fabric.Video object is created
 * @return {fabric.Video} Instance of fabric.Video
 */
fabric.Video.fromElement = function (element, callback, options) {
  const parsedAttributes = fabric.parseAttributes(
    element,
    fabric.Image.ATTRIBUTE_NAMES
  );

  fabric.Video.fromURL(
    parsedAttributes["xlink:href"],
    callback,
    fabric.util.object.extend(
      options ? cloneDeepImmutable(options) : {},
      parsedAttributes
    )
  );
};

fabric.Video.fromElementAsync = function (element, options) {
  return new Promise((resolve) => {
    return fabric.Video.fromElement(
      element,
      (object) => {
        resolve(object);
      },
      options
    );
  });
};
