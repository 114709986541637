import React, { useContext, useEffect, useState } from "react";
import { CanvasStore } from "../../canvas/store/canvasStore";
import { withModifiedEvent } from "../../canvas/utils/helper";
import TextBtn from "../TextBtn";

function PlaybackSpeed({ object }) {
  const [videoSpeed, setVideoSpeed] = useState(1);
  const { activeCanvas } = useContext(CanvasStore);

  useEffect(() => {
    if (!object) return;
    setVideoSpeed(object.speed);
  }, [object]);

  const handleSpeedChange = (speed) => {
    if (!object) return;
    withModifiedEvent(
      object,
      activeCanvas,
      () => {
        setVideoSpeed(speed);
        object.setPlaybackSpeed(speed);
        activeCanvas.requestRenderAll();
      },
      ["speed"],
      "playbackSpeedChange"
    )();
  };

  return (
    <div
      className={
        "flex-grow py-2 flex items-center rounded-[8px] px-4 border border-slate-700 border-solid shadow-sm"
      }
    >
      <span className={"text-white text-sm mr-auto"}>Speed</span>
      <div className="flex gap-2">
        <TextBtn
          text={"0.5x"}
          isActive={videoSpeed === 0.5}
          onClick={() => handleSpeedChange(0.5)}
        />
        <TextBtn
          text={"1x"}
          isActive={videoSpeed === 1}
          onClick={() => handleSpeedChange(1)}
        />
        <TextBtn
          text={"1.5x"}
          isActive={videoSpeed === 1.5}
          onClick={() => handleSpeedChange(1.5)}
        />
        <TextBtn
          text={"2x"}
          isActive={videoSpeed === 2}
          onClick={() => handleSpeedChange(2)}
        />
      </div>
    </div>
  );
}

export default PlaybackSpeed;
