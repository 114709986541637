import React, { useState, useEffect } from "react";
import { TextField, Button, Stack, Typography } from "@mui/material";
import { db } from "../../firebase.js";
import {
  doc,
  updateDoc,
  collection,
  serverTimestamp,
  orderBy,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import ShowMessage from "../ShowMessage/ShowMessage.js";

export default function ManageClonedMessages(props) {
  const [voices, setVoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVoices = async () => {
      setLoading(true);
      try {
        const q = query(collection(db, "clonedvoices"), orderBy("timestamp"));
        const querySnapshot = await getDocs(q);
        const texts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          timestamp: doc.data()["timestamp"].toDate().toLocaleDateString(),
          user: doc.data().user,
          //...doc.data(),
        }));
        setVoices(texts);
      } catch (error) {
        console.error("Error fetching documents: ", error);
      }
      setLoading(false);
    };

    fetchVoices();
  }, []);

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "clonedvoices", id));
      setVoices(voices.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  return (
    <div>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        <Stack spacing={2}>
          {voices.map((item) => (
            <Stack
              key={item.id}
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Typography>{item.user || "No Text"}</Typography>
              <Typography>{item.timestamp}</Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDelete(item.id)}
              >
                Delete
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
    </div>
  );
}
