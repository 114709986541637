import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

import SectionHeader from "../shared/SectionHeader";

const Header = ({ onSearch, heading }) => {
  const handleSearchChange = (event) => {
    const { value } = event.target;
    onSearch(value);
  };

  return (
    <div className="flex justify-between items-center mt-8">
      <SectionHeader heading={heading} />
      <TextField
        variant="standard"
        placeholder="Search"
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchRoundedIcon />
            </InputAdornment>
          ),
          sx: {
            color: "#fff",
            fontSize: "13px",
            "& .MuiSvgIcon-root": {
              color: "#ccc",
            },
          },
        }}
        InputLabelProps={{ style: { color: "white" } }}
        sx={{ width: "150px" }}
      />
    </div>
  );
};

export default Header;
