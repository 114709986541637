import { fabric } from "fabric";
import { cloneDeep, debounce } from "lodash";
import ObjectID from "bson-objectid";
import { getDesignJsonApi, saveUserDesignApi } from "../../services";
import { deepFindVideos, getPageBoundings } from "../utils/canvasUtils";
import { toFixed } from "../utils/math";
import { DEFAULT_BG_COLOR } from "../../utils/loadProjectToCanvas";
import { handleRewindAllMedia, shouldPlayMedia } from "../../Animations/utils";
import { setIntervalAsync } from "set-interval-async/dynamic";
import { clearIntervalAsync } from "set-interval-async/fixed";
import slugify from "slugify";
import WebMWriter from "webm-writer";
import { sortBy } from "lodash";
import JSZip from "jszip";
import { initializeAnimation } from "../../Animations/rekapi/utils";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance

import { generateRandomString } from "../../utils/utils";

const storage = getStorage();

export const propertiesToInclude = [
  "_id",
  "orderIndex",
  "evented",
  "selectable",
  "lockMovementX",
  "lockMovementY",
  "lockScalingX",
  "lockScalingY",
  "objectType",
  "objectName",
  "parentArtboard",
  "hasControls",
  "hoverCursor",
  "defaultCursor",
  "_templateInfo",
  "checkboxIconId",
  "iconSrc",
  "selectable",
  "skipFromSelection",
  "startTime",
  "endTime",
  "animationStartTime",
  "duration",
  "FPS",
  "custom",
  "transitions",
];

export default function customizeFabric(projectId) {
  require("../fabricControls/globalControls");
  require("./Page/Artboard");
  require("../extend/Video");
  require("../extend/Audio");
  fabric.FRAME_TITLE_HEIGHT = 13;
  const zip = new JSZip();

  fabric.Object.prototype.objectCaching = false;
  fabric.Object.prototype.cloneAsync = function (extraExportOptions) {
    return new Promise((resolve) => {
      this.clone((res) => resolve(res), extraExportOptions || []);
    });
  };
  fabric.Object.prototype.getZIndex = function () {
    if (!this.canvas) return -1;

    return this.canvas.getObjects().indexOf(this);
  };
  fabric.StaticCanvas.prototype.getObjectById = function (_id, type) {
    if (!_id) return null;

    if (typeof this.getActiveObject === "function") {
      const active = this.getActiveObject();
      if (active && active.type === "activeSelection") {
        if (active._id === _id) {
          return active;
        }
      }
    }

    const objs = this.getObjects(type);
    for (let i = 0, len = objs.length; i < len; i++) {
      if (objs[i]._id === _id) {
        return objs[i];
      }
      if (objs[i].type === "group") {
        const obs = objs[i].getObjects(type);
        for (let i = 0, len = obs.length; i < len; i++) {
          if (obs[i]._id === _id) {
            return obs[i];
          }
        }
      }
    }
    return null;
  };
  fabric.StaticCanvas.prototype.getObjectsByObjectType = function (type) {
    if (!type) return [];
    return this.getObjects().filter((a) => a.objectType === type);
  };

  fabric.Canvas.prototype.createSelectionByIds = function (ids) {
    let objects = [];
    ids.forEach((id) => {
      let object = this.getObjectById(id);
      if (object) objects.push(object);
    });
    if (objects.length > 1) {
      const selection = new fabric.ActiveSelection(objects, {
        canvas: this,
        _id: ObjectID().toHexString(),
      });

      this._setActiveObject(selection);
      selection.addWithUpdate();
    } else if (objects.length === 1) {
      this._setActiveObject(objects[0]);
    }
    this.renderAll();
  };

  fabric.Canvas.prototype.toProjectData = function () {
    let active = this._activeObject;
    let isDirty = false;
    if (active?.type === "textbox" && active.isEditing) {
      active.selectable = true;
      isDirty = true;
    }
    const projectData = this.toJSON(propertiesToInclude);

    projectData.objects = projectData.objects.filter(
      (object) => !object.parentArtboard && !object.parent
    );
    if (isDirty) {
      active.selectable = false;
    }
    projectData.height = this.canvasHeight;
    projectData.width = this.canvasWidth;
    projectData.viewportTransform = this.viewportTransform;
    return projectData;
  };
  fabric.Canvas.prototype.generateThumbnail = async function () {
    return new Promise(async (resolve) => {
      let firstFrame = this.getObjects("artboard")[0];
      if (!firstFrame) return resolve("");
      let element = await firstFrame.toCanvasElement();
      let canvas = document.createElement("canvas");
      let zoom = 300 / firstFrame.width;
      canvas.width = firstFrame.width * zoom;
      canvas.height = firstFrame.height * zoom;
      let context = canvas.getContext("2d");
      context.drawImage(
        element.lowerCanvasEl,
        0,
        0,
        canvas.width,
        canvas.height
      );

      resolve(canvas.toDataURL());
    });
  };
  fabric.StaticCanvas.prototype.resizeCanvas = function () {
    if (!this.__mainEditorWrapperEl) return;

    const rect = this.__mainEditorWrapperEl.getBoundingClientRect();
    this.setWidth(rect.width);
    this.setHeight(rect.height);
  };
  fabric.Canvas.prototype.generatePageImage = async function (options) {
    return new Promise(async (resolve) => {
      const productActiveSide = cloneDeep(this.productSideData);
      let pageCanvas = new fabric.Canvas(document.createElement("canvas"));
      pageCanvas.height = this._activePage.height;
      pageCanvas.width = this._activePage.width;

      pageCanvas.setBackgroundImage(
        productActiveSide.backgroundImage,
        async () => {
          let boundings = this.getObjects("artboard");

          for (let i = 0; i < boundings.length; i++) {
            let frame = boundings[i];
            let visible = frame.visible;
            frame.show();
            let group = await frame.toGroup();
            let newPath = new fabric.Rect({
              left: -group.width / 2,
              top: -group.height / 2,
              width: group.width,
              height: group.height,
            });
            if (!visible) frame.hide();
            pageCanvas.add(group);
            group.clipPath = newPath;
          }
          if (options.format === "image/svg+xml") {
            resolve(pageCanvas.toSVG());
          } else {
            resolve(pageCanvas.toDataURL({ format: options.format }));
          }
        },
        {
          crossOrigin: "anonymous",
        }
      );
    });
  };

  fabric.Canvas.prototype.saveToDatabaseSync = async function () {
    // if (this instanceof fabric.StaticCanvas) return false;

    this.fire("canvas:saving:start");

    const projectData = this.toProjectData();
    const pageMeta = {
      designId: this.designId,
    };
    const options = JSON.parse(
      JSON.stringify({
        ...projectData,
        ...pageMeta,
      })
    );

    try {
      let json = cloneDeep(this._activePage);
      // json.image = await this.generateThumbnail();

      delete json.json;
      Object.assign(json, options);

      await saveUserDesignApi(
        {
          pageJson: JSON.stringify(json),
          designId: pageMeta.designId,
        },
        projectId
      );

      this.fire("canvas:saving:end");
    } catch (error) {
      this.fire("canvas:saving:error");
      console.error(error);
    }
  };

  fabric.Canvas.prototype.saveToDatabase = debounce(async function () {
    this.saveToDatabaseSync();
  }, 300);

  fabric.Canvas.prototype.loadDesignPageJson =
    async function handleLoadPageJson(page) {
      return new Promise(async (resolve) => {
        let canvas = this;

        let json = cloneDeep(page);
        json.backgroundColor = DEFAULT_BG_COLOR;
        canvas.history.reset();
        // TODO Check if there is a downside of no clearing
        // canvas.clear();
        canvas.remove(...canvas.getObjects());
        canvas.loadFromJSON(json, async () => {
          let canvasState = canvas.toJSON(propertiesToInclude);
          canvasState.objects = canvasState.objects.filter(
            (o) => !o.parentArtboard
          );
          let artboard = canvas.getObjects("artboard")[0];
          canvas.__artboard = artboard;
          canvas.zoomToFit(artboard);
          canvas.history.update(artboard.toJSON(propertiesToInclude));
          canvas.renderAll();
          canvas._activePage = page;
          resolve();
        });
      });
    };

  fabric.Canvas.prototype.loadDesignPage = debounce(async function (page) {
    return new Promise(async (resolve, reject) => {
      if (this._activePage?.pageId === page.pageId) {
        return resolve(this._activePage);
      }
      // await this.saveToDatabaseSync();
      // page = this.designInfo.pages.find((p) => p.pageId === page.pageId);
      // if (!page) {
      //  return reject(new Error("Page not found"));
      //}
      await this.loadDesignPageJson(page);

      this.fire("page:loaded", { page });
      resolve(page);
    });
  }, 300);

  fabric.Canvas.prototype.panToObject = function (target) {
    this.zoomToFit(target);
    this.renderAll();
  };
  fabric.Canvas.prototype.loadCanvasState = function (state) {
    let canvas = this;
    canvas.diableSaveState = true;
    canvas.disabledHistory = true;
    canvas.disableEventsListeners = true;
    // canvas.clear();
    // remove artboard which is being undone or redone

    return new Promise((resolve) => {
      fabric.util.enlivenObjects([state], (enlivedObjects) => {
        let frame = canvas
          .getObjects("artboard")
          .find((a) => a._id == state._id);
        canvas.remove(frame);
        delete state._selectedObjects;

        canvas.add(...enlivedObjects);
        canvas._activeArtboard = enlivedObjects[0];
        canvas.disableEventsListeners = false;
        canvas.disabledHistory = false;
        canvas.diableSaveState = false;

        canvas.renderAll();
        resolve();
      });
    });
  };

  // default functions==============
  fabric.Canvas.prototype.zoomToFit = function (target) {
    let zoom = 1;
    const offset = 200; // An arbitrary offset to avoid zooming in too close
    if (target) {
      zoom = toFixed(
        Math.min(
          this.getWidth() / (target.getScaledWidth() + offset),
          this.getHeight() / (target.getScaledHeight() + offset)
        ),
        2
      );
      zoom = Math.max(zoom, 0.01);
    }

    this.setZoom(zoom);

    target &&
      this.absolutePan({
        x: -this.getCenter().left + target.getCenterPoint().x * zoom,
        y: -this.getCenter().top + target.getCenterPoint().y * zoom + 30, // 48 is the height of the minified motion menu
      });

    this.fire("zooming", { canvas: this });
    this.fire("scrolling", { canvas: this });
    this.fire("zoom:end", { canvas: this });
    this.fire("scroll:end", { canvas: this });

    return zoom;
  };

  fabric.Canvas.prototype.enterCanvasPanMode = function () {
    this.__lastActive = this.getActiveObject();
    this._discardActiveObject();
    this.canvasPanMode = true;

    this.setCursor("grab");
    this.defaultCursor = "grab";
    this.hoverCursor = "grab";

    this.selection = false;

    this.skipTargetFind = true;

    this.requestRenderAll();
    this.fire("user:canvasPan", true);
  };

  fabric.Canvas.prototype.exitCanvasPanMode = function () {
    delete this.canvasPanMode;

    if (this.__lastActive) {
      if (this.__lastActive.type === "activeSelection") {
        const selection = new fabric.ActiveSelection(
          this.__lastActive._objects,
          {
            canvas: this,
          }
        );
        this._setActiveObject(selection);
      } else {
        this._setActiveObject(this.__lastActive);
      }
    }
    delete this.__lastActive;
    this.setCursor("default");
    this.defaultCursor = "default";
    this.hoverCursor = "default";
    this.selection = true;

    this.skipTargetFind = false;

    this.requestRenderAll();
    this.fire("user:canvasPan", false);
  };

  fabric.Canvas.prototype.exportAsVideo = async function (
    FPS = 30,
    format = "mp4",
    suffix = "",
    progress = () => {},
    shouldLimitExportSize
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let artboard = this.getObjects("artboard")[0];
        let currentTimeInMs = 0;

        const duration = artboard.animationDuration + 1; //milli seconds

        let { width, height } = artboard;

        // const transparent = artboard.isTransparent();

        var encoder;

        const maxSize = 720;
        if (shouldLimitExportSize && (width > maxSize || height > maxSize)) {
          if (width >= height) {
            const ratio = maxSize / width;
            width = maxSize;
            height = Math.round(toFixed(height * ratio));
          } else {
            const ratio = maxSize / height;
            height = maxSize;
            width = Math.round(toFixed(width * ratio));
          }
        }

        /*if (format === 'mp4') {
          if (artboard.width % 2 !== 0 || artboard.height % 2 !== 0) {
            return reject('MP4 video size must be multiply of 2. Please adjust the size of your artboard to even numbers.');
          }
          try {
            const HME = await import('h264-mp4-encoder');
            encoder = await HME.createH264MP4Encoder();

            // MP4 encoder doesn't support odd numbers for width and height
            if (width % 2 !== 0) width -= 1;
            if (height % 2 !== 0) height -= 1;

            encoder.outputFilename = slugify(artboard?.title, { lower: true });
            encoder.width = width;
            encoder.height = height;
            encoder.frameRate = FPS;
            encoder.speed = 5;
            encoder.quantizationParameter = 22;
            encoder.groupOfPictures = 1;
            encoder.temporalDenoise = false;
            encoder.initialize();
          } catch (error) {
            return reject(error.message);
          }
        }
        else */
        if (format === "webm") {
          encoder = new WebMWriter({
            quality: 0.9999,
            frameRate: FPS, // There are issues with webm at 60fps
            transparent: false,
          });
        }

        const exportCanvas = document.createElement("canvas");
        exportCanvas.width = width;
        exportCanvas.height = height;
        const exportCtx = exportCanvas.getContext("2d");

        let frame = 0;
        const artboardCanvas = await artboard.toCanvasElement({
          enableRetinaScaling: true,
        });

        await handleRewindAllMedia(artboardCanvas);
        artboardCanvas.requestRenderAll();

        const videos = deepFindVideos(artboardCanvas);
        const interval = setIntervalAsync(async () => {
          if (artboard.__cancelVideoExport) {
            clearIntervalAsync(interval);

            resolve({});
            delete artboard.__cancelVideoExport;

            return;
          }
          artboardCanvas.renderAll();
          exportCtx?.clearRect(0, 0, exportCanvas.width, exportCanvas.height);
          exportCtx?.drawImage(
            artboardCanvas.getElement(),
            0,
            0,
            exportCanvas.width,
            exportCanvas.height
          );

          await Promise.all(
            videos.map(async (video) => {
              if (shouldPlayMedia(video, currentTimeInMs)) {
                video.visible = true;
                const t =
                  currentTimeInMs / 1000 -
                  video.startTime -
                  video.animationStartTime;
                if (t > 0) {
                  await video.seekVideo(t);
                }
              } else {
                video.visible = false;
              }
            })
          );

          if (artboardCanvas) {
            try {
              if (format === "mp4") {
                encoder.addFrameRgba(
                  exportCtx?.getImageData(0, 0, encoder.width, encoder.height)
                    .data
                );
              } else if (format === "webm") {
                // FireFox and Safari don't support toDataURL('image/webp')
                encoder?.addFrame(exportCanvas);
              }
            } catch (error) {
              clearIntervalAsync(interval);

              reject(
                "your browser does not support export in the selected format. Please use Google Chrome"
              );

              return;
            }

            if (Math.round(frame * (1000 / FPS)) > duration) {
              clearIntervalAsync(interval);

              const videosWithAudio = sortBy(
                deepFindVideos(artboardCanvas)
                  .slice()
                  .filter((ch) => ch.isVideo && !ch.videoMuted && ch.hasAudio)
                  .map((o) => o.toObject(propertiesToInclude)),
                (video) => video.animationStartTime
              );

              const audioLayers = sortBy(
                artboard
                  .getChildren("audio")
                  .slice()
                  .filter((ch) => ch.type === "audio" && !ch.muted)
                  .map((o) => o.toObject(propertiesToInclude)),
                (audio) => audio.animationStartTime
              );

              if (format === "mp4") {
                encoder.finalize();

                const uint8Array = encoder.FS.readFile(encoder.outputFilename);
                let mp4blob = new Blob([uint8Array], { type: "video/mp4" });
                const name =
                  slugify(artboard?.title, { lower: true }) + suffix + ".mp4";

                // free all resources
                encoder.delete();

                progress({ progress: 0, audioPhase: true });
                return resolve({ blob: mp4blob, name });

                // todo uncomment when audio mixing is started
                // if (videosWithAudio.length === 0 && audioLayers.length === 0) {
                //   return resolve({ blob: mp4blob, name });
                // }

                // progress({ progress: 0, audioPhase: true });

                // const progressInterval = fakeProgressBar(progress);

                /*worker.postMessage(
                    {
                      name,
                      buffer: uint8Array.buffer,
                      audioLayers: JSON.stringify(videosWithAudio.concat(audioLayers)),
                      format,
                      duration,
                    },
                    [uint8Array.buffer],
                );

                worker.onmessage = ({ data: { type, buffer, error } }) => {
                  if (type?.includes('addAudioToVideoWorker')) {
                    clearInterval(progressInterval);

                    if (type === 'addAudioToVideoWorker_success') {
                      mp4blob = new Blob([buffer], { type: 'video/mp4' });

                      return resolve({ blob: mp4blob, name });
                    } else {
                      console.error(error);
                      reject(error?.message || 'An error happened while processing audio!');
                    }
                  }
                };*/
              } else if (format === "webm") {
                encoder?.complete().then(async function (webMBlob) {
                  const name =
                    slugify(artboard?.title, { lower: true }) +
                    suffix +
                    ".webm";
                  return resolve({ blob: webMBlob, name });

                  /*  if (videosWithAudio.length === 0 && audioLayers.length === 0) {
                    return resolve({ blob: webMBlob, name });
                  }

                  const progressInterval = fakeProgressBar(progress);

                  const buffer = await webMBlob.arrayBuffer();

                  worker.postMessage(
                      {
                        name,
                        buffer,
                        audioLayers: JSON.stringify(videosWithAudio.concat(audioLayers)),
                        format,
                        duration,
                      },
                      [buffer],
                  );

                  worker.onmessage = ({ data: { type, buffer, error } }) => {
                    if (type?.includes('addAudioToVideoWorker')) {
                      clearInterval(progressInterval);

                      if (type === 'addAudioToVideoWorker_success') {
                        webMBlob = new Blob([buffer], { type: 'video/webm' });

                        return resolve({ blob: webMBlob, name });
                      } else {
                        console.error(error);
                        reject(error?.message || 'An error happened while processing audio!');
                      }
                    }
                  };*/
                });
              }
            }

            progress({
              frame,
              total: Math.round((duration / 1000) * FPS),
              audioPhase: false,
            });
            currentTimeInMs = frame * (1000 / FPS);
            frame++;
          } else {
            clearIntervalAsync(interval);
          }
        }, 1000 / FPS);
      } catch (error) {
        reject(error);
      }
    });
  };
  fabric.Canvas.prototype.exportAsWebm = async function (
    FPS = 30,
    suffix = "",
    progress = () => {},
    shouldLimitExportSize
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let artboard = this.getObjects("artboard")[0];
        let { width, height } = artboard;

        const maxSize = 720;
        if (shouldLimitExportSize && (width > maxSize || height > maxSize)) {
          if (width >= height) {
            const ratio = maxSize / width;
            width = maxSize;
            height = Math.round(toFixed(height * ratio));
          } else {
            const ratio = maxSize / height;
            height = maxSize;
            width = Math.round(toFixed(width * ratio));
          }
        }

        const encoder = new WebMWriter({
          quality: 0.7,
          frameRate: FPS, // There are issues with webm at 60fps
          transparent: false,
        });

        let currentTimeInMs = 0;
        const duration = artboard.animationDuration + 1; //milli seconds

        const exportCanvas = document.createElement("canvas");
        exportCanvas.width = width;
        exportCanvas.height = height;
        const exportCtx = exportCanvas.getContext("2d");

        let frame = 0;
        const artboardCanvas = await artboard.toCanvasElement({
          enableRetinaScaling: true,
        });
        await handleRewindAllMedia(artboardCanvas);

        artboardCanvas._objects.slice(1).forEach((object) => {
          object.visible = shouldPlayMedia(object, currentTimeInMs);
        });

        artboardCanvas.requestRenderAll();

        const videos = deepFindVideos(artboardCanvas);

        // start loop
        const interval = setIntervalAsync(async () => {
          if (artboard.__cancelVideoExport) {
            clearIntervalAsync(interval);
            resolve({});
            delete artboard.__cancelVideoExport;
            return;
          }

          artboardCanvas.renderAll();
          exportCtx?.clearRect(0, 0, exportCanvas.width, exportCanvas.height);
          exportCtx?.drawImage(
            artboardCanvas.getElement(),
            0,
            0,
            exportCanvas.width,
            exportCanvas.height
          );

          artboardCanvas._objects.slice(1).forEach((object) => {
            object.visible = shouldPlayMedia(object, currentTimeInMs);
          });

          if (artboardCanvas) {
            try {
              encoder?.addFrame(exportCanvas);
            } catch (error) {
              clearIntervalAsync(interval);
              reject(
                "your browser does not support export in the selected format. Please use Google Chrome"
              );
              return;
            }

            const timeInSecs = currentTimeInMs / 1000;
            await Promise.all(
              videos.map((video) => {
                video.visible = shouldPlayMedia(video, currentTimeInMs);
                const t =
                  timeInSecs - video.startTime - video.animationStartTime;
                if (t > 0) {
                  return video.seekVideo(t * video.speed);
                }
                return Promise.resolve();
              })
            );

            if (Math.round(frame * (1000 / FPS)) > duration) {
              clearIntervalAsync(interval);
              encoder?.complete().then(async function (webMBlob) {
                const name =
                  slugify(artboard?.title, { lower: true }) + suffix + ".webm";
                return resolve({ blob: webMBlob, name, duration: duration });
              });
            }

            progress({
              frame,
              total: Math.round((duration / 1000) * FPS),
              audioPhase: false,
            });

            currentTimeInMs = frame * (1000 / FPS);
            frame++;
          } else {
            clearIntervalAsync(interval);
          }
        }, 1000 / FPS);
      } catch (error) {
        reject(error);
      }
    });
  };

  async function zipFrames(frames, uid) {
    for (let i = 0; i < frames.length; i++) {
      const frame = frames[i];
      const base64Data = frame.src.replace(/^data:image\/jpeg;base64,/, "");
      const binaryData = atob(base64Data);
      const u8Array = new Uint8Array(binaryData.length);

      for (let i = 0; i < binaryData.length; i++) {
        u8Array[i] = binaryData.charCodeAt(i);
      }
      zip.file(`frame_${i.toString().padStart(4, "0")}.jpg`, u8Array);
    }
    try {
      const content = await zip.generateAsync({ type: "blob" });

      const bucketName = "textspeech-55a09.appspot.com";
      const baseUrl = `${uid}/projects`;
      const fileName = `${generateRandomString()}.zip`;
      const storageRef = ref(storage, `${baseUrl}`);
      const gsUrl = `gs://${bucketName}/${baseUrl}/${fileName}`;

      // console.log(`${bucketName}/${baseUrl}/${fileName}`);
      const zipRef = ref(storageRef, fileName);

      await uploadBytes(zipRef, content);

      // for debugging
      // downloadZip(content);

      return gsUrl; // Return the gsUrl after successful upload
    } catch (error) {
      console.error("Upload failed:", error);
      throw error; // Re-throw the error so it can be handled by the caller
    }
  }

  // Used for debugging to view the generated zip
  const downloadZip = (zipFile) => {
    const url = URL.createObjectURL(zipFile);
    const a = document.createElement("a");
    a.href = url;
    a.download = "video.zip";
    a.click();
  };

  const createVideo = async (zipGsUrl, orientation) => {
    // console.log("creating video using...", zipGsUrl);
    const generateVideoFunction = httpsCallable(functions, "createvideo", {
      timeout: 300000,
    });
    try {
      const result = await generateVideoFunction({
        id: zipGsUrl,
        orientation: orientation,
      });
      let gsUrl = result.data;
      let videoUrl = await getDownloadURL(ref(storage, gsUrl));
      // console.log(videoUrl);
      return videoUrl;
    } catch (error) {
      console.log(error);
    }
  };

  fabric.Canvas.prototype.exportAsImages = async function (
    FPS = 25,
    uid = "",
    progress = () => {},
    quality,
    shouldLimitExportSize = true
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        let artboard = this.getObjects("artboard")[0];
        let { width, height } = artboard;
        let orientation = width > height ? "landscape" : "portrait";

        // Determine maxSize based on quality
        const maxSize =
          quality === "1080p" ? 1920 : quality === "720p" ? 1280 : 640;

        // Check if the export size needs to be limited
        if (shouldLimitExportSize) {
          if (orientation === "landscape" && width > maxSize) {
            // Landscape orientation
            const ratio = maxSize / width;
            width = maxSize;
            height = Math.round(height * ratio);
          } else if (orientation === "portrait" && height > 720) {
            // Portrait orientation
            const ratio = 720 / height;
            height = 720;
            width = Math.round(width * ratio);
          }
        }

        let currentTimeInMs = 0;

        // For export, the max is 2 mins
        const TWO_MINUTES_MS = 2 * 60 * 1000; // 2 minutes in milliseconds

        const duration = Math.min(
          TWO_MINUTES_MS,
          artboard.animationDuration + 1
        );

        const exportCanvas = document.createElement("canvas");
        exportCanvas.width = width;
        exportCanvas.height = height;
        const exportCtx = exportCanvas.getContext("2d");

        const artboardCanvas = await artboard.toCanvasElement({
          enableRetinaScaling: true,
        });
        const allObjects = artboardCanvas._objects.slice(1);

        let artboardRect = artboardCanvas._objects[0];
        artboardRect.children = allObjects;
        artboardRect.animationDuration = artboard.animationDuration;
        let animationTimeline = initializeAnimation(
          artboardRect,
          artboardCanvas,
          true
        );
        animationTimeline._animationLength =
          artboard.animationDuration / 1000 + 0.1;
        await handleRewindAllMedia(artboardCanvas);
        artboardCanvas.requestRenderAll();

        const videos = deepFindVideos(artboardCanvas);

        // start loop
        const frames = [];
        let frame = 0;
        const interval = setIntervalAsync(async () => {
          if (artboard.__cancelVideoExport) {
            clearIntervalAsync(interval);
            resolve({});
            delete artboard.__cancelVideoExport;
            return;
          }
          animationTimeline.update(currentTimeInMs);
          artboardCanvas.renderAll();
          exportCtx?.clearRect(0, 0, exportCanvas.width, exportCanvas.height);
          exportCtx?.drawImage(
            artboardCanvas.getElement(),
            0,
            0,
            exportCanvas.width,
            exportCanvas.height
          );

          if (artboardCanvas) {
            try {
              const frameImage = new Image();
              frameImage.src = exportCanvas.toDataURL("image/jpeg", 0.7);
              frames.push(frameImage);
            } catch (error) {
              clearIntervalAsync(interval);
              console.log(error);
              return;
            }

            allObjects.forEach((object) => {
              object.visible = shouldPlayMedia(object, currentTimeInMs);
            });

            const timeInSecs = currentTimeInMs / 1000;
            await Promise.all(
              videos.map((video) => {
                video.visible = shouldPlayMedia(video, currentTimeInMs);
                if (video.visible) {
                  const t =
                    timeInSecs - video.startTime - video.animationStartTime;
                  if (t > 0) {
                    return video.seekVideo(t * video.speed);
                  }
                }

                return Promise.resolve();
              })
            );

            if (Math.round(frame * (1000 / FPS)) > duration) {
              // console.log(frame * (1000 / FPS), duration);
              progress({
                percent: 71,
              });
              const zipGsUrl = await zipFrames(frames, uid);
              //  console.log(zipGsUrl);
              progress({
                percent: 75,
                phase: "Adding final touches... this can take a minute...",
              });
              const url = await createVideo(zipGsUrl, orientation);

              resolve({ url: url, duration: duration });
              clearIntervalAsync(interval);
            }

            progress({
              percent: 30 + (frame / Math.round((duration / 1000) * FPS)) * 40,
            });

            currentTimeInMs = frame * (1000 / FPS);
            frame++;
          } else {
            clearIntervalAsync(interval);
          }
        }, 1000 / FPS);
      } catch (error) {
        reject(error);
      }
    });
  };

  fabric.Canvas.prototype.exportUsingMediaRecorder = async function (
    FPS = 30,
    suffix = "",
    progress = () => {},
    shouldLimitExportSize
  ) {
    return new Promise(async (resolve, reject) => {
      let artboard = this.getObjects("artboard")[0];

      const artboardCanvas = await artboard.toCanvasElement({
        enableRetinaScaling: true,
      });

      let { width, height } = artboard;

      const exportCanvas = document.createElement("canvas");
      exportCanvas.width = width;
      exportCanvas.height = height;
      const exportCtx = exportCanvas.getContext("2d");

      document.body.appendChild(exportCanvas);

      const duration = artboard.animationDuration + 1; //milli seconds
      const totalFrames = Math.round((duration / 1000) * FPS);
      function startRecording() {
        const chunks = [];
        var options = {
          mimeType: "video/webm;codecs=avc1",
          //audioBitsPerSecond: 128000,
          videoBitsPerSecond: 5000000, // Double the default quality from 2.5Mbps to 5Mbps
        };
        const stream = exportCanvas.captureStream(60);
        const rec = new MediaRecorder(stream, options);
        // every time the recorder has new data, we will store it in our array
        rec.ondataavailable = (e) => {
          chunks.push(e.data);
        };
        // only when the recorder stops, we construct a complete Blob from all the chunks
        rec.onstop = (e) => exportVid(new Blob(chunks, { type: "video/webm" }));

        rec.start();
        setTimeout(() => rec.stop(), duration); // stop recording in 3s
      }

      function exportVid(blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "video.webm";
        a.click();
        resolve();
      }

      let currentTimeInMs = 0;
      let frame = 0;
      const videos = deepFindVideos(artboardCanvas);
      function replay() {
        const interval = setIntervalAsync(async () => {
          artboardCanvas.renderAll();
          exportCtx?.clearRect(0, 0, exportCanvas.width, exportCanvas.height);
          exportCtx?.drawImage(
            artboardCanvas.getElement(),
            0,
            0,
            exportCanvas.width,
            exportCanvas.height
          );

          artboardCanvas._objects.slice(1).forEach((object) => {
            object.visible = shouldPlayMedia(object, currentTimeInMs);
          });

          const timeInSecs = currentTimeInMs / 1000;
          await Promise.all(
            videos.map((video) => {
              video.visible = shouldPlayMedia(video, currentTimeInMs);
              const t = timeInSecs - video.startTime - video.animationStartTime;
              if (t > 0) {
                return video.seekVideo(t * video.speed);
              }
              return Promise.resolve();
            })
          );

          if (Math.round(frame * (1000 / FPS)) > duration) {
            clearIntervalAsync(interval);
          }

          progress({
            frame,
            total: Math.round((duration / 1000) * FPS),
            audioPhase: false,
          });

          currentTimeInMs = frame * (1000 / FPS);
          // console.log(currentTimeInMs);
          frame++;
        }, 1000 / FPS);
      }
      replay();
      startRecording();
    }); // end of promise
  };
}
