import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  collection,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

import Iconify from "../iconify";
import { useUserAuth } from "../../UserAuthContext";
import { analytics, db, logEvent } from "../../firebase";
import ShowMessage from "../../components/ShowMessage/ShowMessage";

export default function UserSettings(props) {
  const { user } = useUserAuth();

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [duplicateError, setDuplicateError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Duplicate words found");
  const defaultWordConfig = { word: "", sayAs: "", ipa: false };
  const [words, setWords] = useState([]);

  // Loads the settings for the user
  useEffect(() => {
    async function loadCustomWords() {
      setLoading(true);
      const customerRef = doc(db, "customers", user.uid);
      const documentSnapshot = await getDoc(customerRef);
      const documentData = documentSnapshot.data();
      if (documentData.custom_words) {
        setWords(documentData.custom_words);
      }
      setLoading(false);
    }
    loadCustomWords();
  }, []);

  const handleInputChange = (index, field, value) => {
    const updatedWords = [...words];
    updatedWords[index][field] = value;
    setWords(updatedWords);
    clearErrors(false);
  };

  const addWord = () => {
    const tempWords = [...words, defaultWordConfig];
    setWords(tempWords);
    clearErrors();
  };

  const removeWord = (index) => {
    const tempWords = [...words];
    tempWords.splice(index, 1);
    setWords(tempWords);
    clearErrors();
  };

  const handleChecbox = (index, checked) => {
    const tempWords = [...words];
    tempWords[index].ipa = checked;
    setWords(tempWords);
    // console.log(tempWords);
  };

  const checkForDuplicates = () => {
    const map = {};

    for (const item of words) {
      const value = item.word;

      if (map[value]) {
        return true;
      } else {
        map[value] = item.word;
      }
    }
    return false;
  };

  const clearErrors = () => {
    setErrors(false);
    setDuplicateError(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isEmpty = words.some((word) => word.word === "" || word.sayAs === "");

    if (isEmpty) {
      setErrors(true);
      return;
    }

    if (checkForDuplicates()) {
      setDuplicateError(true);
      return;
    }

    try {
      const customerRef = doc(db, "customers", user.uid);
      await updateDoc(customerRef, {
        custom_words: words,
      });
      setShowConfirm(true);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
    props.forceRegen();
    props.handleCloseSettingsModal();
  };

  return (
    <>
      {duplicateError && (
        <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
      )}
      {words.map((word, index) => (
        <FormControl variant="standard" sx={{ m: 1 }} fullWidth key={index}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            mb={3}
          >
            <Typography variant="body2" sx={{ marginRight: 2 }}>
              {index + 1}. {/* Line number */}
            </Typography>
            <Grid container maxWidth={400} spacing={2}>
              <Grid item>
                <TextField
                  id={`word-${index}`}
                  onChange={(e) =>
                    handleInputChange(index, "word", e.target.value)
                  }
                  value={word.word}
                  label="Word"
                  variant="standard"
                  required
                  error={errors && word.word === ""}
                  helperText={
                    errors && word.word === "" ? "Field cannot be empty" : null
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  id={`ipa-${index}`}
                  onChange={(e) =>
                    handleInputChange(index, "sayAs", e.target.value)
                  }
                  value={word.sayAs}
                  label="Say-as"
                  variant="standard"
                  required
                  error={errors && word.sayAs === ""}
                  helperText={
                    errors && word.sayAs === "" ? "Field cannot be empty" : null
                  }
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={4}>
              <Grid item>
                <Stack direction="row" alignItems="center">
                  <Tooltip
                    title="Check this box if using Phoneme (IPA only) symbols"
                    arrow
                  >
                    <Checkbox
                      size="small"
                      checked={word.ipa}
                      onClick={(e) => handleChecbox(index, e.target.checked)}
                    />
                  </Tooltip>
                  <Typography sx={{ fontSize: "11px" }}>
                    Phoneme (IPA)
                  </Typography>
                </Stack>
              </Grid>

              <Grid item>
                <Tooltip title="Delete word from dictionary" arrow>
                  <IconButton
                    size="small"
                    sx={{
                      color: "#B71C1C",
                    }}
                    onClick={(e) => removeWord(index)}
                  >
                    <Iconify icon="material-symbols:delete-outline" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Stack>
        </FormControl>
      ))}

      <Stack mt={2} direction="row" justifyContent="center" spacing={2}>
        {!words.length && (
          <Typography sx={{ color: "text.secondary" }}>
            Add custom pronunciation
          </Typography>
        )}
      </Stack>

      <Stack mt={2} direction="row" justifyContent="center" spacing={2}>
        <Tooltip title="Add new entry" placement="top" arrow>
          <IconButton
            disabled={words.length >= 20}
            variant="contained"
            color="primary"
            onClick={addWord}
          >
            <Iconify sx={{ width: 30, height: 30 }} icon="mdi:plus-box" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack mt={6} direction="row" justifyContent="flex-end" spacing={2}>
        <Button
          onClick={props.handleCloseSettingsModal}
          sx={{
            minWidth: "30%",
          }}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={{
            minWidth: "30%",
          }}
          disabled={errors}
          onClick={handleSubmit}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
      <ShowMessage
        showFileError={showConfirm}
        setShowFileError={setShowConfirm}
        severity="success"
        message="Settings updated successfully"
        link="/video"
      />
    </>
  );
}
