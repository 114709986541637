import axios from "axios"
// Request interceptors for API calls
axios.interceptors.request.use(
    config => {
        config.headers['x-auth-token'] = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NDdlMjZiY2U1NjQ0ZDM0NjQyODQ2OWQiLCJuYW1lIjoiU2hhdWthdCIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTY4NTk4OTM4MH0.IzPaQDT8Od0jtgMjsSwwDg7vOKO-LaExlX7ejF7AVAw`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default axios
