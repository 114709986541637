import { Button } from '@mui/material';
import React from 'react';

const Canvas = () => {
  return (
    <div className="mt-4">
      <p className="text-[#18191b] text-[13px] mb-1 font-semibold font-inter m-0">Canvas</p>
      <div className="flex gap-2">
        <Button className="flex-1 text-[13px] capitalize h-12" variant="neutral">
          Filter & center
        </Button>
        <Button
          className="flex-1 text-[13px] h-12 capitalize border-primary bg-primary/20"
          variant="neutral"
          style={{ border: '1px solid rgb(0 152 253)' }}
        >
          Fill & crop
        </Button>
      </div>
    </div>
  );
};

export default Canvas;
