import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  TextField,
  Stack,
  LinearProgress,
  Typography,
} from "@mui/material";
import { functions, httpsCallable } from "../../firebase"; // import Firebase functions instance
import Iconify from "../iconify";
import { LoadingButton } from "@mui/lab";
import { alpha } from "@mui/material/styles";

// firestore
import { db } from "../../firebase.js";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

import { useUserAuth } from "../../UserAuthContext";
import ShowMessage from "../ShowMessage/ShowMessage.js";
import mixpanel from "mixpanel-browser";
//import Typography from "../../theme/overrides/Typography.js";

export default function FetchUrl(props) {
  const { user } = useUserAuth();
  const [fetchingUrl, setFetchingUrl] = useState(false);
  const [inputUrl, setInputUrl] = useState("");
  const [msg, setMsg] = useState("");
  const [sampleUrl, setSampleUrl] = useState("");
  const handleInputChange = (e) => {
    const url = e.target.value;
    setInputUrl(url);
  };

  const isUrlValid = () => {
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(inputUrl);
  };

  const logRequest = async () => {
    await addDoc(collection(db, "downloads"), {
      user: user.email,
      url: inputUrl,
      timestamp: serverTimestamp(),
    });
  };

  function isYTUrl(url) {
    // Regular expression to match YouTube video ID
    var regex = /[?&]v=([^#&?]+)|youtu\.be\/([^#&?]+)/;

    // Try to match the regular expression with the provided URL
    var match = url.match(regex);

    // Check if a match is found
    if (match) {
      return true;
    } else {
      // Return false if no match is found
      return false;
    }
  }

  const handleFetchContent = async () => {
    if (!isUrlValid(inputUrl)) {
      props.handleShowMessage(
        "Invalid URL. Please ensure it is a valid site and accessible without authentication"
      );
      return;
    }

    try {
      setFetchingUrl(true);
      setMsg("Please wait. This can take a minute or two...");
      props.handleUpdateText("");

      mixpanel.track("Import URL", {
        url: inputUrl,
        source: props.fetchUrlSource,
      });

      if (isYTUrl(inputUrl)) {
        if (!props.canTranscribe) {
          props.handleShowMessage(
            "Uh oh, you have exceeded the transcription limit for this account"
          );
          return;
        }
        const fetchContentFunction = httpsCallable(functions, "ytdl");
        const result = await fetchContentFunction({ url: inputUrl });
        // console.log(result.data);
        const contents = result.data.transcription.text;
        //console.log(contents);
        props.handleUpdateText(contents, 0, true);
      } else {
        const fetchContentFunction = httpsCallable(functions, "urlfetch");
        const result = await fetchContentFunction({ url: inputUrl });
        const contents = `${result.data.content.title}\n${result.data.content.paragraphs}`;
        // console.log(contents);
        props.handleUpdateText(contents, 0, true);
      }
    } catch (error) {
      console.error("Error fetching url:", error);
      if (error.code === "file-size-exceeded") {
        props.handleShowMessage(
          "Video length exceeds the allowed limit. Please try a shorter video."
        );
      } else {
        props.handleShowMessage(
          "Something went wrong. Please ensure it is a valid url and accessible without authentication"
        );
      }
    } finally {
      await logRequest();
      setFetchingUrl(false);
      props.handleImportDialogClose();
      setMsg("");
    }
  };

  useEffect(() => {
    const url =
      props.fetchUrlSource === "YouTube"
        ? "https://www.youtube.com/watch?v=DqJwl6iXXfk"
        : props.fetchUrlSource === "Reddit"
        ? "https://www.reddit.com/r/AskReddit/comments/9gx68l/reddit_how_would_you_feel_about_a_law_that_bans/"
        : "https://www.acoust.io/";
    setSampleUrl(url);
  }, [props.fetchUrlSource]);

  return (
    <Stack
      mt={2}
      mb={2}
      sx={{
        bgcolor: (theme) => alpha(theme.palette.grey[500], 0.09),
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        borderRadius: "5px",
      }}
    >
      {fetchingUrl && <LinearProgress />}

      <Stack
        padding={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>{props.fetchUrlSource}</Typography>
        {props.handleShowFetchUrl && (
          <IconButton
            size="small"
            color="primary"
            variant="text"
            onClick={() => props.setFetchUrlSource("")}
          >
            <Iconify icon="carbon:close" />
          </IconButton>
        )}
      </Stack>

      <Stack
        padding={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <TextField
          fullWidth
          variant="standard"
          value={inputUrl}
          onChange={handleInputChange}
          placeholder={`Enter a URL e.g. ${sampleUrl}`}
          sx={{
            "& ::placeholder": {
              fontSize: "12px", // Change the font size here
            },
          }}
        />
        <IconButton
          size="small"
          color="primary"
          variant="text"
          onClick={handleFetchContent}
          loading={fetchingUrl}
          disabled={inputUrl.length === 0}
        >
          <Iconify icon="gg:chevron-right-o" />
        </IconButton>
      </Stack>
      <Stack padding={1}>
        <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>
          {msg}
        </Typography>
      </Stack>
    </Stack>
  );
}
